import React, { useEffect } from 'react'
import { OptionalString, useAction } from '@agro-club/frontend-shared'
import { StorefrontModalMode, StorefrontSku, StorefrontSkuDTO } from 'modules/domain/storefront/types'
import StorefrontItemDetailForm, {
  FormProps,
  StorefrontItemFormInitialValues,
} from 'views/pages/Storefront/Storefront/StorefrontItemModal/StorefrontItemDetailForm'
import * as Styled from './styled'
import { useSelector } from 'react-redux'
import StorefrontActions from 'modules/domain/storefront/duck'
import StorefrontSelectors from 'modules/domain/storefront/selectors'
import { Progress } from 'modules/types'
import { useStorefrontItem } from 'modules/domain/storefront/hooks'
import { useProductById } from 'modules/domain/product/hooks'

export type StorefrontItemModalData = {
  id?: string
  productId?: string
  mode?: StorefrontModalMode
  sellerId?: string
}

type StorefrontItemModalProps = {
  isOpen: boolean
  heading: string
  modalData?: StorefrontItemModalData
  onClose: () => void
}

const StorefrontItemModal: React.FC<StorefrontItemModalProps> = ({ isOpen, heading, modalData, onClose }) => {
  const addAction = useAction(StorefrontActions.addRequested)
  const addProgress = useSelector(StorefrontSelectors.addProgress)
  const updateAction = useAction(StorefrontActions.updateRequested)
  const updateProgress = useSelector(StorefrontSelectors.updateProgress)
  const { seller_id } = useSelector(StorefrontSelectors.filter)

  const editing: boolean = modalData?.mode === StorefrontModalMode.Edit
  const [, storefrontItem] = useStorefrontItem(modalData?.id || '') as [Progress, StorefrontSku]
  const [, product] = useProductById(editing ? storefrontItem?.params.product_id : modalData?.productId)

  const initialValues: StorefrontItemFormInitialValues = {
    id: modalData?.id,
    sku_id: editing ? storefrontItem.sku_id : '',
    price: editing ? storefrontItem.price : product?.price,
    price_type: editing ? storefrontItem.price_type : product?.price_type,
    special_prices: editing ? storefrontItem.special_prices : [],
    min_qty: editing ? storefrontItem.min_qty : OptionalString(product?.min_qty),
    max_qty: editing ? storefrontItem.max_qty : OptionalString(product?.max_qty),
    default_qty: editing ? storefrontItem.default_qty : OptionalString(product?.default_qty),
    status: editing ? storefrontItem.status : product?.status,
    is_out_of_stock: editing ? storefrontItem.is_out_of_stock : product?.is_out_of_stock,
    out_of_stock_date: editing ? storefrontItem.out_of_stock_date : product?.out_of_stock_date,
    available_for: editing ? storefrontItem.available_for : [],
    params: {
      type: 'product',
      product_id: editing ? storefrontItem?.params.product_id : modalData?.productId,
      product,
      package_id: editing ? storefrontItem.params.package_id : undefined,
      package_capacity: editing ? storefrontItem.params.package_capacity : 1,
      options: editing ? storefrontItem.params.options : [],
    },
  }

  const handleSubmit = (form: Partial<FormProps>) => {
    const payload: StorefrontSkuDTO = {
      sku_id: form.sku_id,
      seller_id: seller_id ?? modalData?.sellerId,
      price: form.price,
      price_type: form.price_type,
      special_prices: [],
      min_qty: form.min_qty,
      max_qty: form.max_qty,
      default_qty: form.default_qty,
      status: form.status,
      is_out_of_stock: form.is_out_of_stock,
      out_of_stock_date: form.out_of_stock_date ?? null,
      available_for: form.available_for,
      params: form.params,
    }

    if (modalData?.mode === StorefrontModalMode.Create) {
      addAction(payload)
    }

    if (modalData?.mode === StorefrontModalMode.Edit) {
      updateAction(form.id, payload)
    }
  }

  useEffect(() => {
    if (addProgress === Progress.SUCCESS || updateProgress === Progress.SUCCESS) {
      onClose()
    }
  }, [addProgress, onClose, updateProgress])

  return (
    <Styled.StorefrontModal
      isOpen={isOpen}
      onClose={onClose}
      shouldCloseOnEsc={true}
      size={'responsive'}
      id="storefront-modal"
    >
      <Styled.ModalHeading>{heading}</Styled.ModalHeading>
      <StorefrontItemDetailForm
        initialValues={initialValues}
        progress={addProgress === Progress.WORK || updateProgress === Progress.WORK ? Progress.WORK : Progress.IDLE}
        onSubmit={handleSubmit}
        onCancel={onClose}
      />
    </Styled.StorefrontModal>
  )
}

export default StorefrontItemModal
