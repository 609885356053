import React, { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikProvider, useFormikContext } from 'formik'
import { CompanyType, PriceType } from 'types/entities'
import * as Styled from './styled'
import { Checkbox, FormField, FormikHook, Input, SimpleSelect, DatePicker } from '@agro-club/frontend-shared'
import * as Yup from 'yup'
import { useWizardOptions } from 'modules/domain/productWizard/hooks'
import CategorySelect from 'views/components/CategorySelect/CategorySelect'
import SubcategorySelect from 'views/components/CategorySelect/SubcategorySelect'
import useDateFormat from 'hooks/useDateFormat'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'

export type OriginFormProps = {
  isLimited: boolean
  isOutOfStock: boolean
  isExclusive: boolean
  hideFromCrm: boolean
  isNew?: boolean
  isFeatured?: boolean
  outOfStockDate?: string
  defaultQty?: number | null
  rank?: number
  docusignDocumentId?: string
  productId?: string
  producer?: string
  category?: string
  subcategory?: string
  minQty?: number | null
  maxQty?: number | null
  price?: string
  wizard?: string
  isGift: boolean
  priceType: string
}

const WizardSelect: React.FC = () => {
  const { t } = useTranslation('product')
  const formik = useFormikContext<OriginFormProps>()
  const [progress, options] = useWizardOptions()
  const selectOptions = options?.map(e => ({ id: e.id, title: e.title }))

  return (
    <div data-test-id={'wizard-select'}>
      <SimpleSelect
        progress={progress}
        value={formik.values.wizard}
        onChange={v => {
          formik.setFieldValue('wizard', v)
        }}
        options={selectOptions || []}
        label={t('form.wizard')}
        isSearchable={true}
        isClearable={true}
      />
    </div>
  )
}

const PriceTypeSelect: React.FC = () => {
  const { t } = useTranslation('product')
  const formik = useFormikContext<OriginFormProps>()
  const options = [
    { id: PriceType.Msrp, title: t('form.msrp') },
    { id: PriceType.None, title: t('form.notSpecified') },
  ]

  return (
    <SimpleSelect
      value={formik.values.priceType}
      onChange={v => {
        formik.setFieldValue('priceType', v)
      }}
      options={options}
      label={t('form.priceType')}
      isSearchable={true}
    />
  )
}
const OriginForm: React.FC<{
  useFormik: FormikHook
  isLimited: boolean
  isOutOfStock: boolean
  productId?: string
  rank?: number
  category?: string
  producer?: string
  subcategory?: string
  wizard?: string
  minQty?: number | null
  maxQty?: number | null
  defaultQty?: number | null
  price?: string
  isNew?: boolean
  isFeatured?: boolean
  isExclusive?: boolean
  hideFromCrm?: boolean
  outOfStockDate?: string
  isGift?: boolean
  priceType: string
}> = memo(
  ({
    useFormik,
    isLimited,
    isOutOfStock = false,
    productId,
    rank,
    category,
    producer,
    subcategory,
    wizard,
    minQty,
    maxQty,
    defaultQty,
    price,
    isNew = true,
    isFeatured = false,
    isExclusive = false,
    hideFromCrm = false,
    outOfStockDate,
    isGift = false,
    priceType,
  }) => {
    const { t } = useTranslation(['product', 'common', 'validation'])

    const validationSchema = useMemo(() => {
      return Yup.object({
        category: Yup.string().required(t('validation:field_required')),
        producer: Yup.string().required(t('validation:field_required')),
        subcategory: Yup.string().required(t('validation:field_required')),
        minQty: Yup.number()
          .min(1)
          .required(t('validation:field_required')),
        maxQty: Yup.number().nullable(),
        defaultQty: Yup.mixed()
          .when('minQty', (minQty, schema: any) => {
            return schema.test({
              test: defaultQty => {
                if (!defaultQty || !minQty) return true
                return defaultQty >= minQty
              },
              message: t('validation:default_qty'),
            })
          })
          .when('maxQty', (maxQty, schema: any) => {
            return schema.test({
              test: defaultQty => {
                if (!defaultQty || !maxQty) return true
                return defaultQty <= maxQty
              },
              message: t('validation:default_qty'),
            })
          }),
        price: Yup.string().nullable(),
        productId: Yup.string().nullable(),
        priceType: Yup.string(),
      })
    }, [t])

    const formik = useFormik<OriginFormProps>({
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit: () => {},
      initialValues: {
        isNew,
        isFeatured,
        isLimited,
        isExclusive,
        isOutOfStock,
        outOfStockDate,
        rank,
        productId,
        category,
        producer,
        subcategory,
        minQty,
        maxQty,
        defaultQty,
        price,
        hideFromCrm,
        wizard,
        isGift,
        priceType,
      },
      validationSchema,
      enableReinitialize: true,
    })
    const dateFormat = useDateFormat({ withTime: true })

    const handleDefaultQtyInputChange = useCallback(
      e => {
        const value = parseInt(e.target.value.replace(/\D/g, ''), 10)

        if (!Number.isNaN(value)) {
          formik.setFieldValue('defaultQty', value)
        } else {
          formik.setFieldValue('defaultQty', 0)
        }
      },
      [formik],
    )

    const handleOutOfStockFlagChange = useCallback(
      isChecked => {
        formik.setFieldValue('isOutOfStock', isChecked)
      },
      [formik],
    )

    const handleOutOfStockDateChange = useCallback(
      value => {
        if (formik.values.outOfStockDate === null && value === '') {
          return
        }
        const updatedValue = !value ? undefined : value
        formik.setFieldValue('outOfStockDate', updatedValue)
      },
      [formik],
    )

    return (
      <FormikProvider value={formik}>
        <Styled.Grid3Col>
          <CompanySelect
            value={formik.values.producer}
            onChange={v => {
              formik.setFieldValue('producer', v)
              formik.setFieldValue('category', '')
              formik.setFieldValue('subcategory', '')
            }}
            invalid={formik.touched.producer && !!formik.errors.producer}
            errorText={formik.errors.producer}
            label={t('form.producer')}
            companyType={CompanyType.Producer}
            isSearchable
            isClearable
            required
          />
          <CategorySelect
            filter={{ seller_ids: formik.values.producer }}
            required
            label={t('form.category')}
            onChange={v => {
              formik.setFieldValue('category', v)
              formik.setFieldValue('subcategory', '')
            }}
            invalid={formik.touched.category && !!formik.errors.category}
            errorText={formik.errors.category}
            value={formik.values.category}
            isDisabled={!formik.values.producer}
            isClearable
          />
          <SubcategorySelect
            required
            filter={{ parent_id: formik.values.category, seller_ids: formik.values.producer }}
            label={t('form.subcategory')}
            onChange={v => {
              formik.setFieldValue('subcategory', v)
            }}
            invalid={formik.touched.subcategory && !!formik.errors.subcategory}
            errorText={formik.errors.subcategory}
            value={formik.values.subcategory}
            isDisabled={!formik.values.producer || !formik.values.category}
            isClearable
          />
          <Input
            {...formik.getFieldProps('productId')}
            invalid={formik.touched.productId && !!formik.errors.productId}
            errorText={formik.errors.productId}
            label={t('form.productId')}
          />
          <Input
            {...formik.getFieldProps('rank')}
            invalid={formik.touched.rank && !!formik.errors.rank}
            errorText={formik.errors.rank}
            label={t('form.rank')}
            type={'number'}
          />
          <WizardSelect />
          <Input
            {...formik.getFieldProps('price')}
            invalid={formik.touched.price && !!formik.errors.price}
            errorText={formik.errors.price}
            label={t('form.price')}
            type={'number'}
            min={0}
          />
          {formik.values.price && <PriceTypeSelect />}
        </Styled.Grid3Col>
        <Styled.Grid3Col>
          <Input
            {...formik.getFieldProps('minQty')}
            invalid={formik.touched.minQty && !!formik.errors.minQty}
            errorText={formik.errors.minQty}
            label={t('form.minQty')}
            type={'number'}
            min={0}
            required
          />
          <Input
            {...formik.getFieldProps('maxQty')}
            invalid={formik.touched.maxQty && !!formik.errors.maxQty}
            errorText={formik.errors.maxQty}
            label={t('form.maxQty')}
            type={'number'}
            min={0}
          />
          <Input
            {...formik.getFieldProps('defaultQty')}
            invalid={formik.touched.defaultQty && !!formik.errors.defaultQty}
            errorText={formik.errors.defaultQty}
            label={t('form.defaultQty')}
            type={'number'}
            min={1}
            onChange={handleDefaultQtyInputChange}
          />
        </Styled.Grid3Col>
        <Styled.Grid3Col>
          <FormField
            testId="is-new-flag"
            label={t('form.badgeNewTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.isNew}
                onChange={(_, isChecked) => {
                  formik.setFieldValue('isNew', isChecked)
                }}
                label={'Badge is shown'}
              />
            )}
          />
          <FormField
            testId="is-featured-flag"
            label={t('form.isFeaturedTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.isFeatured}
                onChange={(_, isChecked) => {
                  formik.setFieldValue('isFeatured', isChecked)
                }}
                label={t('form.isFeatured')}
              />
            )}
          />
          <FormField
            testId="is-limited-flag"
            label={t('form.isLimitedTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.isLimited}
                onChange={(_, isChecked) => {
                  formik.setFieldValue('isLimited', isChecked)
                }}
                label={t('form.isLimited')}
              />
            )}
          />
          <FormField
            testId="is-exclusive-flag"
            label={t('form.isExclusiveTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.isExclusive}
                onChange={(_, isChecked) => {
                  formik.setFieldValue('isExclusive', isChecked)
                }}
                label={t('form.isExclusive')}
              />
            )}
          />
          <FormField
            testId="is-gift-flag"
            label={t('form.isGiftTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.isGift}
                onChange={(_, isChecked) => {
                  formik.setFieldValue('isGift', isChecked)
                }}
                label={t('form.isGift')}
              />
            )}
          />
          <FormField
            testId="hide-from-crm-flag"
            label={t('form.hideFromCrmTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.hideFromCrm}
                onChange={(_, isChecked) => {
                  formik.setFieldValue('hideFromCrm', isChecked)
                }}
                label={t('form.hideFromCrm')}
              />
            )}
          />
          <FormField
            testId="is-out-of-stock-flag"
            label={t('form.isOutOfStockTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.isOutOfStock}
                onChange={(_, isChecked) => handleOutOfStockFlagChange(isChecked)}
                label={t('form.isOutOfStock')}
              />
            )}
          />
        </Styled.Grid3Col>
        {/* ent-1156 After the out_of_stock_date, the ability to add the specified product to an order, edit the fields of an existing order (ordered qty, delivered qty), and remove the product from an existing order will be closed for the retails roles (head & managers) and manufacturer managers. */}
        <DatePicker
          onChange={handleOutOfStockDateChange}
          date={formik.values.outOfStockDate}
          invalid={formik.touched.outOfStockDate && !!formik.errors.outOfStockDate}
          errorText={formik.errors.outOfStockDate}
          label={t('form.outOfStockDate')}
          format={dateFormat}
        />
      </FormikProvider>
    )
  },
)
OriginForm.displayName = 'OriginForm'

export default OriginForm
