import React, { useCallback } from 'react'
import { FormFields } from 'views/components/ReturnDeclarationDetailsForm/ReturnDeclarationDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useParams } from 'react-router-dom'
import Routes from './routes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Progress } from 'modules/types'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import ReturnDeclarationDetailsForm from 'views/components/ReturnDeclarationDetailsForm/ReturnDeclarationDetailsForm'
import { useReturnDeclarationById } from 'modules/domain/returnDeclaration/hooks'
import ReturnDeclarationActions from 'modules/domain/returnDeclaration/duck'
import ReturnDeclarationSelectors from 'modules/domain/returnDeclaration/selectors'
import { ReturnDeclarationStatus } from 'types/returnDeclaration'
import ReturnDeclarationStatusColored from 'views/components/ReturnDeclarationStatusColored/ReturnDeclarationStatusColored'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

const ReturnDeclarationEdit: React.FC<{ onUpdateInitialState(props: FormFields): void }> = ({
  onUpdateInitialState,
}) => {
  const { t } = useTranslation('returnDeclaration')
  const params = useParams<{ id: string }>()
  const push = useHistoryPush()
  const page = useSelector(ReturnDeclarationSelectors.page)
  const meta = useSelector(state => ReturnDeclarationSelectors.meta(state, params.id))
  const goBack = useCallback(() => push({ route: Routes.List, query: page ? { page } : undefined }), [page, push])

  const updateDeclaration = useAction(ReturnDeclarationActions.updateRequested)
  const removeDeclaration = useAction(ReturnDeclarationActions.removeRequested, params.id)

  const [progress, returnDeclaration] = useReturnDeclarationById(params.id)

  useHelmet({ title: t('editPageTitle', { id: returnDeclaration ? returnDeclaration.slug : '' }) })

  const justRedirect = useCallback(() => {
    push({ route: Routes.Add })
  }, [push])

  const handleSubmit = useCallback(
    (values: FormFields, options: { duplicate: boolean; dirty: boolean }) => {
      const update = (duplicate: boolean) => {
        if (returnDeclaration) {
          updateDeclaration(returnDeclaration.id, values, duplicate)
        }
      }

      if (!options.duplicate) {
        update(false)
      } else {
        onUpdateInitialState(values)
        if (options.dirty) {
          update(true)
        } else {
          justRedirect()
        }
      }
    },
    [justRedirect, onUpdateInitialState, returnDeclaration, updateDeclaration],
  )

  const handleSetSubmitStatus = useCallback(() => {
    updateDeclaration(returnDeclaration?.id, { ...returnDeclaration, status: ReturnDeclarationStatus.Submitted }, false)
  }, [updateDeclaration, returnDeclaration])

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (progress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (progress === Progress.WORK || !returnDeclaration) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title compact size={'small'} title={t('editPageTitle', { id: returnDeclaration.slug })} />
          <ReturnDeclarationStatusColored
            data-test-id={'return-declaration-status'}
            data-status={returnDeclaration.status}
            status={returnDeclaration.status}
            dangerouslySetInnerHTML={{ __html: t(`status.${returnDeclaration.status}`) }}
          />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ReturnDeclarationDetailsForm
          mode={'edit'}
          onCancel={goBack}
          onSubmit={handleSubmit}
          onRemove={removeDeclaration}
          onChangeStatusToSubmit={handleSetSubmitStatus}
          returnDeclaration={returnDeclaration}
          removeProgress={meta.removeProgress}
          progress={meta.updateProgress}
          initialValues={{
            distributor_id: returnDeclaration.distributor_id || '',
            pickup_note: returnDeclaration.pickup_note,
            producer_id: returnDeclaration.producer_id,
            status: returnDeclaration.status,
            comment: returnDeclaration.comment,
            items: returnDeclaration.items,
            declaration_date: returnDeclaration.declaration_date,
            season_id: returnDeclaration.season_id,
            type: returnDeclaration.type,
          }}
        />
      </Layout.Content>
    </>
  )
}

export default ReturnDeclarationEdit
