import { FarmerOrderItem } from 'types/farmerOrder'

export const getUniqItem = (uniqItems: {}, product_id: string) => {
  return uniqItems[product_id]
}

export const getUniqItemQty = (uniqItems: {}, product_id: string) => {
  return getUniqItem(uniqItems, product_id)?.quantity
}

export const getUniqItemInitialQty = (uniqItems: {}, product_id: string) => {
  return getUniqItem(uniqItems, product_id)?.initialQuantity
}

export const getUniqItems = (items: FarmerOrderItem[]) => {
  const uniq = {}

  items?.forEach(item => {
    const currentProductKey = item.product_id
    const currentProductKeyExists = uniq[currentProductKey]

    if (currentProductKeyExists) {
      uniq[currentProductKey].quantity += item.quantity
    } else {
      uniq[currentProductKey] = {
        product_id: item.product_id,
        quantity: item.quantity,
      }
    }
  })

  return uniq
}

export const getIniqItemsWithInitialQty = (items: FarmerOrderItem[]) => {
  const uniq = {}

  items?.forEach(item => {
    const currentProductKey = item.product_id
    const currentProductKeyExists = uniq[currentProductKey]

    if (currentProductKeyExists) {
      uniq[currentProductKey].quantity += item.quantity
    } else {
      uniq[currentProductKey] = {
        product_id: item.product_id,
        initialQuantity: item.quantity,
      }
    }
  })

  return uniq
}
