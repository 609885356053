import managers from './managers'
import { Progress } from 'modules/types'
import { useState } from 'react'

export const useTargetOrderUpdate = (onSuccess?: () => void): [Progress, () => Promise<void>] => {
  const [progress, setProgress] = useState(Progress.IDLE)

  const request = () => {
    setProgress(Progress.WORK)

    return managers
      .updateOrders()
      .then(() => {
        setProgress(Progress.SUCCESS)
        onSuccess?.()
      })
      .catch(() => {
        setProgress(Progress.ERROR)
      })
  }

  return [progress, request]
}
