import { Entity, EntityState } from 'modules/domain/types'
import { TargetFarmer } from 'views/pages/Target2/components/selects/TargetFarmerSelect'
import { TargetManufacturer } from 'views/pages/Target2/components/selects/TargetManufacturerSelect'
import { TargetProduct } from 'views/pages/Target2/components/selects/TargetProductSelect'
import { TargetRetailer } from 'views/pages/Target2/components/selects/TargetRetailerSelect'
import { TargetSeason } from 'views/pages/Target2/components/selects/TargetSeasonSelect'
import { TargetSeedTreatment } from 'views/pages/Target2/components/selects/TargetSeedTreatmentSelect'
import { TargetCategory } from 'views/pages/Target2/components/selects/TargetSubcategorySelect'
import { TargetTerritory } from 'views/pages/Target2/components/selects/TargetTerritorySelect'

export type TerritoryTargetState = EntityState<
  Target<TargetTypes.Territory>,
  TargetListRequestFilter,
  TargetListRequestSorting
> & {
  updateCounter: number
  totalFarmerOrders: number
  totalFarmerOrdersPreviousYear: number
  totalRetailerTargets: number
  totalTargetValues: number
}

export type FarmerTargetState = EntityState<
  Target<TargetTypes.Farmer>,
  TargetListRequestFilter,
  TargetListRequestSorting
> & {
  updateCounter: number
  totalFarmerOrders: number
  totalFarmerOrdersPreviousYear: number
  totalRetailerTargets: number
  totalTargetValues: number
}

export type RetailerTargetState = EntityState<
  Target<TargetTypes.Retailer>,
  TargetListRequestFilter,
  TargetListRequestSorting
> & {
  updateCounter: number
  totalFarmerOrders: number
  totalFarmerOrdersPreviousYear: number
  totalRetailerOrders: number
  totalTargetValues: number
}

export enum TargetTypes {
  Territory,
  Farmer,
  Retailer,
}

export type Target<TargetType extends TargetTypes = TargetTypes.Territory> = Entity & {
  manufacturer: TargetManufacturer
  product: TargetProduct
  territory: TargetTerritory
  target_value: number
  category?: TargetCategory
  season?: TargetSeason

  created_dttm?: string
  updated_dttm?: string
} & (TargetType extends TargetTypes.Territory
    ? {
        farmer_orders_previous_year: number
        retailer_target: number
        farmer_orders: number
      }
    : {}) &
  (TargetType extends TargetTypes.Retailer
    ? {
        sku_id: string
        seed_treatment?: TargetSeedTreatment
        farmer_target_value: number
        farmer_orders_previous_year: number
        retailer_orders?: number
        retailer: TargetRetailer
        farmer_orders: number
      }
    : {}) &
  (TargetType extends TargetTypes.Farmer
    ? {
        sku_id: string
        seed_treatment?: TargetSeedTreatment

        farmer_orders: number
        farmer: TargetFarmer
        farmer_orders_previous_year: number
        retailer: TargetRetailer
      }
    : {})

export type TerritoryTarget = Target<TargetTypes.Territory>
export type FarmerTarget = Target<TargetTypes.Farmer>
export type RetailerTarget = Target<TargetTypes.Retailer>

export type TargetListRequestFilter = {
  search?: string
  search_by?: 'territory' | 'farmer' | 'retailer' | 'product' | 'farmer'

  manufacturer_id?: string
  product_id?: string
  retailer_id?: string
  farmer_id?: string
  season_id?: string | string[]
  territory_id?: string
  subcategory_id?: string
}

export type TargetListRequestSorting = {
  sort_field?: keyof Target
  sort_reversed?: boolean
}

export type TargetCreateDTO<TargetType extends TargetTypes = TargetTypes.Territory> = Pick<
  Target<TargetType>,
  'target_value'
> & {
  manufacturer_id: string
  product_id: string
  territory_id: string
  season_id?: string
  category_id?: string
  id?: string
} & (TargetType extends TargetTypes.Retailer
    ? {
        retailer_id: string
        seed_treatment_id?: string
      }
    : {}) &
  (TargetType extends TargetTypes.Farmer
    ? {
        farmer_id: string
        seed_treatment_id?: string
      }
    : {})

export type TerritoryTargetCreateDTO = TargetCreateDTO<TargetTypes.Territory>
export type FarmerTargetCreateDTO = TargetCreateDTO<TargetTypes.Farmer>
export type RetailerTargetCreateDTO = TargetCreateDTO<TargetTypes.Retailer>

export type TargetUpdateDTO = {
  target_value: number
}
