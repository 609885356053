import { all, takeLatest, call, put } from 'redux-saga/effects'
import CollectionActions from './duck'
import * as managers from './managers'
import { CountryPhoneCode, Region } from './types'
import { ListResponse } from 'types/api'
import AuthActions from 'modules/domain/auth/duck'

export const fetchRegionsSaga = function*({
  payload: { filter, sorting, page, pageSize },
}: ReturnType<typeof CollectionActions.regionsRequested>) {
  try {
    const response: ListResponse<Region> = yield call(managers.fetchRegions, filter, sorting, page, pageSize)
    yield put(CollectionActions.regionsRequestSucceed(response.data))
  } catch (err) {
    yield put(CollectionActions.regionsRequestFailed())
  }
}

export const fetchPhoneCodesSaga = function*(_action: ReturnType<typeof CollectionActions.phoneCodesRequested>) {
  try {
    const response: ListResponse<CountryPhoneCode> = yield call(managers.fetchPhoneCodes)
    yield put(CollectionActions.phoneCodesRequestSucceed(response.data))
  } catch (err) {
    yield put(CollectionActions.phoneCodesRequestFailed())
  }
}

const CollectionSaga = function*() {
  yield all([
    takeLatest(CollectionActions.regionsRequested.type, fetchRegionsSaga),
    // fetching country codes once on the app init
    takeLatest(AuthActions.init.type, fetchPhoneCodesSaga),
  ])
}

export default CollectionSaga
