import styled, { StyledProps } from 'styled-components'
import {
  DownloadButton as DownloadButtonComponent,
  Input as InputComponent,
  TableBodyCell,
} from '@agro-club/frontend-shared'

export const DownloadButton = styled(DownloadButtonComponent)`
  margin-left: 24px;
`

export const Buttons = styled.div`
  justify-self: flex-end;
  margin: auto 0 0 auto;
  display: flex;
  gap: 16px;
`

export const Input = styled(InputComponent)`
  min-width: 70px;

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  & input {
    appearance: textfield; /* Firefox */
  }
` as typeof InputComponent

export const BodyCell = styled(TableBodyCell)<StyledProps<{ emphasis?: boolean }>>`
  background-color: ${props => (props.emphasis ? props.theme.color.primary25 : props.theme.color.onPrimaryLight)};
`
