import styled, { StyledProps } from 'styled-components'
import defaultLogo from 'assets/images/logo.svg'
import logoImage from 'assets/images/logo-image.svg'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import { useConfig } from 'modules/domain/config/hooks'

type ThemedProps = StyledProps<{}>

const Root = styled.div<StyledProps<{ barWidth?: number }>>`
  display: grid;
  grid-template-columns: min-content auto;
  width: 100vw;
  height: 100vh;
`
type ThemedColumnProps = StyledProps<{ width?: number }>

export const Column = styled.div<ThemedColumnProps>`
  height: 100vh;
  display: grid;
  grid-template-rows: min-content auto;
  width: ${({ width, theme }: ThemedColumnProps) => `${width || theme.size.leftSideBarWidth}px`};
  transition: width 0.2s ease-out;
  position: relative;
`

export const Page = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

export const LogoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  max-height: 80px;
  border-right: ${({ theme }: ThemedProps) => `1px solid ${theme.color.outlineMid}`};
  flex-shrink: 0;
`
export const Logo: React.FC<{ hideText?: boolean }> = ({ hideText = false }) => {
  const [, config] = useConfig()
  const logo = useMemo(() => (config && config.logo_url ? config.logo_url : defaultLogo), [config])

  return (
    <LogoStyled>
      <Link to={DashboardRoutes.Dashboard}>
        <img style={{ maxHeight: '80px' }} src={hideText ? logoImage : logo} alt={'logo'} />
      </Link>
    </LogoStyled>
  )
}

export const Bar = styled.div<StyledProps<{ pageWidth?: number }>>`
  border-top: ${({ theme }: ThemedProps) => `1px solid ${theme.color.outlineMid}`};
  border-right: ${({ theme }: ThemedProps) => `1px solid ${theme.color.outlineMid}`};
  flex-grow: 1;
  height: 100%;
  overflow: auto;
`
export const Header = styled.div`
  flex-shrink: 0;
`

export const Content = styled.div`
  background-color: ${({ theme }: ThemedProps) => theme.color.backgroundBase};
  flex-grow: 1;
  overflow: auto;
`

export const Layout: React.FC<{ barWidth?: number }> = ({ barWidth, children }) => (
  <Root barWidth={barWidth}>{children}</Root>
)
