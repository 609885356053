import { useAction, usePersistentScroll } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import AuthSelectors from 'modules/domain/auth/selectors'
import TerritoryTargetActions from 'modules/domain/target2/territoryTarget/duck'
import { useTerritoryTargetList } from 'modules/domain/target2/territoryTarget/hooks'
import TerritoryTargetSelectors from 'modules/domain/target2/territoryTarget/selectors'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isProducer } from 'types/entities'
import { CompoundedTableEditable } from 'views/components/CompoundedTable/CompoundedTableEditable/CompoundedTableEditable'
import { TerritoryTargetForm } from '../forms/TerritoryTargetForm'
import { BolderCell, TertiaryCell, TitleCell, TitleI18nCell } from './common'
import { TextTertiary } from '../styles'

export const TerritoryTargetTable: React.VFC = () => {
  const { t } = useTranslation('target2')
  const { pick } = useLangPicker()

  const [progress, data = []] = useTerritoryTargetList()

  const total = useSelector(TerritoryTargetSelectors.total)
  const page = useSelector(TerritoryTargetSelectors.page)
  const pages = useSelector(TerritoryTargetSelectors.pages)
  const pageSize = useSelector(TerritoryTargetSelectors.pageSize)
  const targetsTotal = useSelector(TerritoryTargetSelectors.targetsTotal)
  const retailerTargetsTotal = useSelector(TerritoryTargetSelectors.retailerTargetsTotal)
  const farmerOrdersTotal = useSelector(TerritoryTargetSelectors.farmerOrdersTotal)
  const previousYear = useSelector(TerritoryTargetSelectors.previousYear)

  const listRequested = useAction(TerritoryTargetActions.listRequested)

  const { scrollRef } = usePersistentScroll('territoryTargetList')

  const role = useSelector(AuthSelectors.role)
  const producer = isProducer(role)

  const columns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.manufacturer'),
        accessor: 'manufacturer' as const,
        width: 193,
        hidden: producer,
        Cell: TitleCell,
      },
      {
        Header: t('list.tableHeaders.territory'),
        accessor: 'territory',
        width: 193,
        Cell: TitleCell,
      },
      {
        Header: t('list.tableHeaders.season'),
        accessor: 'season',
        width: 193,
        Cell: TitleCell,
      },
      {
        Header: t('list.tableHeaders.subcategory'),
        accessor: `subcategory` as const,
        width: 160,
        Cell: TitleI18nCell(pick),
      },
      {
        Header: t('list.tableHeaders.product'),
        accessor: 'product' as const,
        width: 217,
        Cell: TitleI18nCell(pick),
      },
      {
        Header: t('list.tableHeaders.territoryTarget'),
        accessor: 'target_value' as const,
        width: 80,
        headerAlign: 'right',
        cellAlign: 'right',
      },
      {
        Header: t('list.tableHeaders.retailerTarget'),
        accessor: 'retailer_target' as const,
        width: 80,
        headerAlign: 'right',
        cellAlign: 'right',
        Cell: BolderCell,
      },
      {
        Header: t('list.tableHeaders.farmerOrders'),
        accessor: 'farmer_orders' as const,
        width: 80,
        headerAlign: 'right',
        cellAlign: 'right',
      },
      {
        Header: <TextTertiary>{t('list.tableHeaders.farmerPreviousYear')}</TextTertiary>,
        accessor: 'farmer_orders_previous_year' as const,
        width: 76,
        Cell: TertiaryCell,
        headerAlign: 'right',
        cellAlign: 'right',
      },
    ],
    [t, producer, pick],
  )

  const metaColumns = useMemo(
    () => ({
      farmer_orders: farmerOrdersTotal,
      target_value: targetsTotal,
      retailer_target: retailerTargetsTotal,
      farmer_orders_previous_year: <TextTertiary>{previousYear}</TextTertiary>,
    }),
    [targetsTotal, retailerTargetsTotal, farmerOrdersTotal, previousYear],
  )

  const canEdit = usePermissions({ capability: CAPABILITY.TERRITORY_TARGETS, permission: PERMISSION.CRU })

  return (
    <CompoundedTableEditable
      FormComponent={TerritoryTargetForm}
      columns={columns}
      metaColumns={metaColumns}
      data={data}
      ref={scrollRef}
      progress={progress}
      total={total}
      currentPage={page}
      pageSize={pageSize}
      onSetPage={num => {
        listRequested({ page: num })
      }}
      pages={pages}
      readonly={!canEdit}
      newTitle={t('form.new')}
    />
  )
}

export default TerritoryTargetTable
