import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import DistributorOrderActions from './duck'
import DistributorOrderSelectors from './selectors'
import { DistributorOrder } from 'types/distributorOrder'
import { useEffect } from 'react'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useDistributorOrderList: ResourceHook<DistributorOrder[], void[]> = () => {
  const progress = useSelector(DistributorOrderSelectors.listFetchProgress)
  const list = useSelector(DistributorOrderSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(DistributorOrderActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useDistributorOrder: ResourceHook<DistributorOrder, [string]> = (id: string) => {
  const meta = useSelector(state => DistributorOrderSelectors.meta(state, id))
  const item = useSelector(state => DistributorOrderSelectors.item(state, id))
  const fetchAction = useAction(DistributorOrderActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useDistributorOrderById: ResourceHook<DistributorOrder, [string]> = (id: string) => {
  const meta = useSelector(state => DistributorOrderSelectors.meta(state, id))
  const item = useSelector(state => DistributorOrderSelectors.item(state, id))
  const fetchAction = useAction(DistributorOrderActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}
