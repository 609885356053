import React from 'react'
import useLangPicker from 'hooks/useLangPicker'
import { Product, ProductListRequestFilter } from 'modules/domain/product/types'
import * as managers from 'modules/domain/product/managers'
import { useTranslation } from 'react-i18next'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'
import { useFilterMemo } from 'hooks/useFilterMemo'
import { ProductOptionsData } from 'modules/domain/productOptions/types'

export type ProductEntitySelectProps = EntitySelectProps<Omit<Product, 'options'>> & {
  filter?: ProductListRequestFilter
  scope?: string
  withCategories?: boolean
  isFilterSuggest?: boolean
  isAvailableByInventory?: boolean
}

export type ProductOptionsDataCustom = Omit<Product, 'options'> & { product_options: ProductOptionsData[] }

export const getProductValue = (p: Omit<Product, 'options'>) => p.id
export const getProductLabel = (p: Omit<Product, 'options'>) => p.title
export const getMappedOption = (p: Product): ProductOptionsDataCustom => {
  const newVal: ProductOptionsDataCustom & {
    options?: ProductOptionsData[]
  } = {
    ...p,
    product_options: p.options, //TODO in perspective need to replace options to product_options because our select can't get options param
  }
  delete newVal.options
  return newVal
}

export const getProductNameWithCategory = (title: string, category?: string, subcategory?: string) => {
  let resultName = title

  if (subcategory) {
    resultName = `${subcategory} / ` + resultName
  }

  if (category) {
    resultName = `${category} / ` + resultName
  }

  return resultName
}

export const ProductsSelect: React.VFC<ProductEntitySelectProps> = ({
  placeholder,
  filter,
  scope,
  withCategories,
  isFilterSuggest,
  isAvailableByInventory,
  ...props
}) => {
  const { pick } = useLangPicker()

  const { t } = useTranslation('common')
  const memoizedFilter = useFilterMemo<ProductListRequestFilter>(filter)

  const getProductLabel = (p: Omit<Product, 'options'>) =>
    withCategories
      ? getProductNameWithCategory(pick(p.title_i18n), pick(p.category?.title_i18n), pick(p.subcategory?.title_i18n))
      : pick(p.title_i18n)

  const getListManager = isAvailableByInventory
    ? managers.getListByInventory
    : isFilterSuggest
    ? managers.getListForFilter(scope)
    : managers.getList

  return (
    <SelectTestWrapper data-test-id={'product-select'}>
      <EntitySelect
        placeholder={placeholder || t('placeholders.product')}
        getEntityById={managers.getItem}
        getEntityList={getListManager}
        filter={memoizedFilter}
        getOptionValue={getProductValue}
        getOptionLabel={getProductLabel}
        getMappedOption={getMappedOption}
        {...props}
      />
    </SelectTestWrapper>
  )
}
