import useLangPicker from 'hooks/useLangPicker'
import FarmerSelectors from 'modules/domain/farmer/selectors'
import { FarmerData } from 'modules/domain/farmer/types'
import { StorefrontCard } from 'modules/domain/storefrontCard/types'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button, NumberInput, Progress, BackBtn as BackBtnComponent } from '@agro-club/frontend-shared'
import CustomerInfoShort from 'views/components/CustomerModal/CustomerInfoShort'
import { Status } from 'types/entities'
import { useCardSkus } from 'modules/domain/storefront/hooks'
import { PackageTypes } from 'modules/domain/packageTypes/types'
import { StorefrontSku } from 'modules/domain/storefront/types'
import { SkuOptionsMatcher } from 'views/components/OrderSkuCardItems/SkuOptionsMatcher'

export const Content = styled.div`
  width: 100%;
  padding: 16px 24px 0;
`
export const BackButton = styled(BackBtnComponent)`
  margin: 16px 0 0 20px;
`
export const ButtonWrapper = styled.div`
  width: 100%;
`
export const AddToFarmerCartText = styled.div`
  padding: 38px 0 28px;
  max-width: 330px;
`
export const Title = styled.h3`
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 0;
  color: ${props => props.theme.color.onPrimaryDark};
`
export const Footer = styled.div`
  width: 100%;
  height: 72px;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-top: 1px solid ${props => props.theme.color.onSurfaceLowEmphasys};

  & > * {
    margin-right: 16px;
  }
`

const MatcherWrapper = styled.div`
  min-height: 110px;
`

export type AddToCartFormProps = {
  farmer: FarmerData
  card?: StorefrontCard
  onSubmit: (quantity: number, sku_id: string, package_id: string) => void
  onBack: () => void
  onClose: () => void
}

const AddToCartForm: React.FC<AddToCartFormProps> = ({ farmer, card, onSubmit, onBack, onClose }) => {
  const { t } = useTranslation(['common', 'farmer'])
  const { pick } = useLangPicker()
  const [quantity, setQuantity] = useState(0)
  const progress = useSelector(FarmerSelectors.addProductToFarmerCartProgress)
  const product = { product: pick(card?.title_i18n) }
  const [packageType, setPackageType] = useState<string | undefined>()
  const [sku, setSku] = useState<StorefrontSku | undefined>()
  const [option, setOption] = useState<any | null>()

  const skuFilter = useMemo(
    () => ({
      status: [Status.Active, Status.Inactive],
    }),
    [],
  )

  const [skusProgress, skus] = useCardSkus(false, card?.id, skuFilter)

  const packageTypes = useMemo(() => {
    const result: PackageTypes[] = []
    skus.forEach(sku => {
      const founded = result.find(p => p?.id === sku.params.package_id)
      if (!founded) result.push((sku as StorefrontSku).params.package as PackageTypes)
    })

    return result
  }, [skus])

  const handleSkuMatched = useCallback((value?: StorefrontSku) => {
    setSku(value)
  }, [])

  const handleOptionsChange = useCallback(
    option => {
      if (option.length && option[0]) {
        setOption(option[0].value)
      }
    },
    [setOption],
  )

  const isSkuMatched = useMemo(() => {
    let matched = !!packageType
    if (sku?.params.options.length && !option) {
      matched = false
    }
    return matched
  }, [option, packageType, sku])

  return (
    <>
      <ButtonWrapper>
        <BackButton onClick={onBack} />
      </ButtonWrapper>
      {progress === Progress.SUCCESS ? (
        <AddToFarmerCartText>{t('farmer:addProductToCart.addedSuccessfully', product)}</AddToFarmerCartText>
      ) : (
        <>
          <Content>
            <CustomerInfoShort customer={farmer} hideFarmerInfoChecking />
            <Title>{t('farmer:addProductToCart.selectOptions')}</Title>
            <MatcherWrapper>
              <SkuOptionsMatcher
                availableSku={skus as StorefrontSku[]}
                availablePackageTypes={packageTypes}
                progress={skusProgress}
                packageType={packageType}
                onSkuMatched={handleSkuMatched}
                onPackageTypeChange={value => setPackageType(value)}
                onOptionChange={handleOptionsChange}
              />
            </MatcherWrapper>
            <Title>{t('farmer:addProductToCart.selectProductQty')}</Title>
            <NumberInput
              value={quantity}
              min={0}
              onChange={value => setQuantity(value)}
              size={'small'}
              disabled={!farmer}
              inputStep={0.1}
            />
            <AddToFarmerCartText>{t('farmer:addProductToCart.addToCart', product)}</AddToFarmerCartText>
          </Content>
          <Footer>
            <Button
              intent={'primary'}
              onClick={() => onSubmit(quantity, sku?.id as string, packageType as string)}
              filled={true}
              disabled={!quantity || !isSkuMatched}
              progress={progress}
            >
              {t('common:yes')}
            </Button>
            <Button intent={'cancel'} filled={false} onClick={onClose}>
              {t('common:cancel')}
            </Button>
          </Footer>
        </>
      )}
    </>
  )
}

export default AddToCartForm
