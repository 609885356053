import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as Styled from 'views/components/OrderProductItems/styles'
import { AltPackagingCell, SeedTreatment } from './OrderProductItems'
import {
  Input,
  NumberInput,
  SectionBody,
  SectionTable,
  SectionTableBody,
  SectionTableBodyCell,
  SectionTableBodyRow,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableHeadRow,
  SectionTitle,
  FormikHook,
} from '@agro-club/frontend-shared'
import { ReturnDeclarationItem } from 'types/returnDeclaration'
import * as Yup from 'yup'
import i18n from 'i18n'
import * as uuid from 'uuid'
import { clone, remove } from 'ramda'
import checkCompanyFeatureFlags from 'helpers/checkCompanyFeatureFlags'
import { generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import { FeatureFlagModifiers, FieldLocation, FieldNames } from 'types/entities'
import { useCompanyById } from 'modules/domain/company/hooks'
import { ProductsSelect } from '../ProductsSelect/ProductsSelect'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'

export type ReturnItemsFormProps = {
  items: ReturnDeclarationItem[]
}

const ReturnItems: React.FC<{
  title?: string
  useFormik: FormikHook
  onMenuClose?: () => void
  isAllowed: boolean
  items?: ReturnDeclarationItem[]
  nothingToReturn?: boolean
  producerId?: string
}> = ({ useFormik, title, isAllowed, items = [], producerId, nothingToReturn = false, onMenuClose, children }) => {
  const { t } = useTranslation(['farmerOrder', 'returnDeclaration', 'common'])
  const role = useSelector(AuthSelectors.role)
  const [, company] = useCompanyById(producerId)
  const showUnits = useMemo(
    () =>
      checkCompanyFeatureFlags(
        company,
        generateFieldModifierString(FieldLocation.OrderList, FieldNames.Units, FeatureFlagModifiers.Enabled),
        role,
      ),
    [role, company],
  )

  const validationSchema = useMemo(() => {
    if (nothingToReturn) {
      return Yup.object({})
    }

    return Yup.object({
      items: Yup.array(
        Yup.object({
          lot_number: Yup.string().required(i18n.t('validation:field_required_short')),
        }),
      ).min(1, i18n.t('validation:oneProductMinRequired')),
    })
  }, [nothingToReturn])

  const formik = useFormik<ReturnItemsFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema,
    initialValues: {
      items,
    },
    enableReinitialize: true,
  })

  const handleProductAdd = (id, product) => {
    const items = clone(formik.values.items)
    if (product) {
      const newItem: ReturnDeclarationItem = {
        product_id: id,
        quantity: 1,
        product,
        key: uuid.v4(),
      }
      const seedTreatment = product.seed_treatment ? product.seed_treatment.filter(s => s.is_active) : []
      if (seedTreatment && seedTreatment.length) {
        newItem.seed_treatment_id = seedTreatment[0].id
      }

      items.unshift(newItem)
      formik.setFieldValue('items', items)
    } else {
      console.warn("Couldn't find product with provided id")
    }
  }

  const handleQtyInputChange = useCallback(
    (idx: number) => (value: number) => {
      const update = (index, value) => {
        const items = clone(formik.values.items)
        if (items[index]) {
          items[index].quantity = value
        }
        formik.setFieldValue('items', items)
      }

      if (!Number.isNaN(value)) {
        update(idx, value)
      } else {
        update(idx, 0)
      }
    },
    [formik],
  )

  const handleRemove = useCallback(
    idx => () => {
      formik.setFieldValue('items', remove(idx, 1, formik.values.items))
    },
    [formik],
  )

  const handleAltPackagingChange = useCallback(
    idx => (_productId: string, value: number) => {
      formik.setFieldValue(`items[${idx}].packaging`, value)
    },
    [formik],
  )

  const handleSeedTreatmentChange = useCallback(
    idx => (_productId: string, value: string) => {
      formik.setFieldValue(`items[${idx}].seed_treatment_id`, value)
    },
    [formik],
  )

  const productSelectError =
    formik.touched.items && formik.errors.items && typeof formik.errors.items === 'string' ? formik.errors.items : null

  return (
    <Styled.Container isAllowed={isAllowed}>
      <Styled.Content isAllowed={isAllowed}>
        <SectionTitle>{title || t('farmerOrder:form.sectionHeaders.orders')}</SectionTitle>
        <SectionBody>
          <ProductsSelect
            onChange={handleProductAdd}
            onMenuClose={onMenuClose}
            label={t('form.labels.product')}
            placeholder={t('form.placeholders.addProduct')}
            invalid={productSelectError !== null}
            errorText={productSelectError || ''}
            filter={{ producer_id: producerId }}
            withCategories
            isSearchable
            required
          />
          {formik.values.items.length !== 0 ? (
            <SectionTable data-test-id={'product-items'}>
              <SectionTableHead>
                <SectionTableHeadRow>
                  <SectionTableHeadCell>{t('form.tableHeaders.product')}</SectionTableHeadCell>
                  <SectionTableHeadCell>{t('form.tableHeaders.package')}</SectionTableHeadCell>
                  <SectionTableHeadCell textAlign={'center'}>{t('form.tableHeaders.quantity')}</SectionTableHeadCell>
                  <SectionTableHeadCell>{t('form.tableHeaders.altPackaging')}</SectionTableHeadCell>
                  <SectionTableHeadCell />
                </SectionTableHeadRow>
              </SectionTableHead>
              <SectionTableBody>
                {formik.values.items.map((item, idx) => {
                  const lotMeta = formik.getFieldMeta(`items[${idx}].lot_number`)
                  return (
                    <SectionTableBodyRow key={item.key} data-test-id={`product-row-${item.product_id}`}>
                      <SectionTableBodyCell>
                        <div>
                          <b>
                            {item.product?.subcategory?.title || ''} {item.product?.title || ''}
                          </b>
                        </div>
                        <SeedTreatment item={item} onChange={handleSeedTreatmentChange(idx)} />
                        <Styled.LotInputWrapper>
                          <Styled.Label>{t('returnDeclaration:form.labels.lotNumber')}</Styled.Label>
                          <Input
                            {...formik.getFieldProps(`items[${idx}].lot_number`)}
                            invalid={lotMeta.touched && !!lotMeta.error}
                            errorText={lotMeta.error}
                            data-test-id={'lot-number-input'}
                            required
                          />
                        </Styled.LotInputWrapper>
                      </SectionTableBodyCell>
                      <SectionTableBodyCell style={{ whiteSpace: 'nowrap' }}>
                        {item.product?.default_packaging || ''}
                      </SectionTableBodyCell>
                      <SectionTableBodyCell style={{ whiteSpace: 'nowrap' }}>
                        <Styled.OrderQtyCell>
                          <NumberInput
                            value={item.quantity}
                            onChange={handleQtyInputChange(idx)}
                            size={'small'}
                            testId={'qty-input'}
                          />
                          {showUnits && (
                            <Styled.UnitsContainer>
                              {item.quantity > 1 ? item.product?.units?.plural : item.product?.units?.singular}
                            </Styled.UnitsContainer>
                          )}
                        </Styled.OrderQtyCell>
                      </SectionTableBodyCell>
                      <SectionTableBodyCell>
                        <AltPackagingCell item={item} onChange={handleAltPackagingChange(idx)} />
                      </SectionTableBodyCell>
                      <SectionTableBodyCell>
                        <Styled.RemoveIcon onClick={handleRemove(idx)} />
                      </SectionTableBodyCell>
                    </SectionTableBodyRow>
                  )
                })}
              </SectionTableBody>
            </SectionTable>
          ) : null}
        </SectionBody>
      </Styled.Content>
      {children}
      {!isAllowed && <Styled.Overlay>{t('form.addProductsOverlayText')}</Styled.Overlay>}
      {nothingToReturn && <Styled.Overlay>{t('returnDeclaration:form.nothingToReturn')}</Styled.Overlay>}
    </Styled.Container>
  )
}

export default ReturnItems
