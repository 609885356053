import React, { useCallback, useMemo } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import DiscountRuleRoutes from 'views/pages/DiscountRule/routes'
import { useTranslation } from 'react-i18next'
import { CellProps, useTable } from 'react-table'
import { useDiscountRuleList } from 'modules/domain/discountRule/hooks'
import { generatePath, useHistory } from 'react-router-dom'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import DiscountRuleSelectors from 'modules/domain/discountRule/selectors'
import { useSelector } from 'react-redux'
import DiscountRuleActions from 'modules/domain/discountRule/duck'
import {
  DiscountRule,
  DiscountRuleListRequestFilter,
  DiscountRuleScope,
  DiscountRuleStatus,
  DiscountRuleTypeNames,
} from 'modules/domain/discountRule/types'

import {
  AddButton,
  StatusIcon,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useHelmet,
} from '@agro-club/frontend-shared'
import styled from 'styled-components'
import { TableFilter } from 'views/components/TableFilters/TableFilters'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'

type DiscountRuleItem = Partial<DiscountRule>

const DiscountRuleTypeCell: React.FC<CellProps<DiscountRuleItem>> = ({ cell }) => {
  const type = Array.isArray(cell.value) && cell.value.length ? cell.value[0].type : ''
  return type in DiscountRuleTypeNames ? DiscountRuleTypeNames[type] : 'None'
}

const NameCell: React.FC<CellProps<DiscountRuleItem>> = ({ cell, column, row }) => {
  return (
    <div>
      <StatusIcon
        status={
          row.values.status === DiscountRuleStatus.Active
            ? 'active'
            : row.values.status === DiscountRuleStatus.Inactive
            ? 'inactive'
            : 'deleted'
        }
      />
      <TComponents.SemiBoldText key={column.id}>{cell.value}</TComponents.SemiBoldText>
    </div>
  )
}

const StatusTableFilterWrap = styled.div`
  margin-right: 16px;
`

const SeasonSelectWrapper = styled.div`
  width: 20%;
  max-width: 300px;
`

const DiscountRuleList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation('discountRules')
  const [progress, data = []] = useDiscountRuleList()
  const filterValue = useSelector(DiscountRuleSelectors.filter)
  const { status, scopes } = filterValue
  const filterUpdated = useAction(DiscountRuleActions.filterUpdated)

  const total = useSelector(DiscountRuleSelectors.total)
  const page = useSelector(DiscountRuleSelectors.page)
  const pages = useSelector(DiscountRuleSelectors.pages)
  const pageSize = useSelector(DiscountRuleSelectors.pageSize)

  const listRequested = useAction(DiscountRuleActions.listRequested)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const mappedData = useMemo(
    () =>
      data.map(item => {
        return {
          id: item.id,
          slug: item.slug,
          title: item.title,
          campaign_name: item.campaign_name,
          tiers_rules: item.tiers_rules,
          status: item.status,
          company_name: item.company_name,
        }
      }),
    [data],
  )

  const allColumns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.title'),
        accessor: 'title' as const,
        Cell: NameCell,
      },
      {
        Header: t('list.tableHeaders.slug'),
        accessor: 'slug' as const,
      },
      {
        Header: t('list.tableHeaders.campaign'),
        accessor: 'campaign_name' as const,
      },
      {
        Header: t('list.tableHeaders.company'),
        accessor: 'company_name' as const,
      },
      {
        Header: t('list.tableHeaders.type'),
        accessor: 'tiers_rules' as const,
        id: 'type',
        Cell: DiscountRuleTypeCell,
      },
      {
        Header: 'status',
        accessor: 'status' as const,
        hidden: true,
      },
    ],
    [t],
  )

  const hiddenColumns: (keyof DiscountRule)[] = useMemo(() => ['status'], [])
  const { columns, rows, prepareRow } = useTable<DiscountRuleItem>({
    columns: allColumns,
    initialState: { hiddenColumns },
    data: mappedData,
  })

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<DiscountRuleListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const handleSeasonChanged = useCallback(
    (val?: string) => handleFilterChange({ season_id: (val as string) ?? null }),
    [handleFilterChange],
  )

  const options = useMemo(() => {
    return [
      { value: DiscountRuleScope.FarmerOrders, title: t('form.labels.farmer'), bullet: true, color: 'green' as const },
      {
        value: DiscountRuleScope.DistributorOrder,
        title: t('form.labels.distributor'),
        bullet: true,
        color: 'orange' as const,
      },
    ]
  }, [t])

  const statusFilterOptions = useMemo(
    () => [
      {
        value: DiscountRuleStatus.Active,
        title: t('form.labels.isActive'),
        bullet: true,
        color: 'green' as const,
      },
      {
        value: DiscountRuleStatus.Inactive,
        title: t('form.labels.inactive'),
        bullet: true,
        color: 'orange' as const,
      },
      {
        value: DiscountRuleStatus.Deleted,
        title: t('form.labels.deleted'),
        bullet: true,
        color: 'red' as const,
      },
    ],
    [t],
  )

  useHelmet({ title: t('discountRulesMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')}>
            <AddButton to={DiscountRuleRoutes.Add} />
          </Header.Title>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TComponents.Wrapper>
          <TComponents.Filters>
            <StatusTableFilterWrap>
              <div data-test-id={'filters-discount-status'}>
                <TableFilter
                  handleFilterChange={handleFilterChange}
                  title={t('labels:status')}
                  options={statusFilterOptions}
                  filterValue={{ status }}
                  disableMulti
                />
              </div>
            </StatusTableFilterWrap>
            <div data-test-id={'filters-discount-scopes'}>
              <TableFilter
                handleFilterChange={handleFilterChange}
                filterValue={{ scopes }}
                options={options}
                title={t('form.discountFor')}
                disableMulti
              />
            </div>
            <SeasonSelectWrapper data-test-id={'filters-discount-season'}>
              <SeasonSelect
                label={t('form.labels.season')}
                value={filterValue.season_id}
                onChange={handleSeasonChanged}
                variant="small"
                isClearable
              />
            </SeasonSelectWrapper>
          </TComponents.Filters>
          <Table total={total} pages={pages} pageSize={pageSize} currentPage={page} onSetPage={fetchNextItems}>
            <TableHead>
              <TableHeadRow>
                {columns.map(column => (
                  <TableHeadCell key={column.id} hidden={column.hidden}>
                    {column.render('Header')}
                  </TableHeadCell>
                ))}
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                prepareRow(row)
                const { key, ...props } = row.getRowProps()
                return (
                  <TableBodyRow
                    key={key}
                    {...props}
                    onClick={() => {
                      history.push(generatePath(DiscountRuleRoutes.Edit, { id: row.original.id }))
                    }}
                  >
                    {row.cells.map(cell => {
                      const { key, ...props } = cell.getCellProps()
                      return (
                        <TableBodyCell key={key} {...props}>
                          {cell.render('Cell')}
                        </TableBodyCell>
                      )
                    })}
                  </TableBodyRow>
                )
              })}
              <TableNoData
                progress={progress}
                isEmpty={!rows.length}
                colSpan={allColumns.length}
                loading={<TComponents.Spinner />}
              />
            </TableBody>
          </Table>
        </TComponents.Wrapper>
      </Layout.Content>
    </>
  )
}

export default DiscountRuleList
