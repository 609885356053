import React, { useState } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import Route from 'views/components/Route/Route'

import ReturnDeclarationRoutes from './routes'
import ReturnDeclarationTable from 'views/pages/ReturnDeclaration/ReturnDeclarationTable'
import ReturnDeclarationEdit from './ReturnDeclarationEdit'
import ReturnDeclarationAdd from './ReturnDeclarationAdd'
import { FormFields } from 'views/components/ReturnDeclarationDetailsForm/ReturnDeclarationDetailsForm'
import { CAPABILITY, PERMISSION } from 'modules/permissions/permissions'

const ReturnDeclarationPage: React.FC = () => {
  const [initialState, setInitialState] = useState<FormFields | undefined>()
  return (
    <Switch>
      <Route
        capability={CAPABILITY.RETURN_DECLARATIONS}
        permission={PERMISSION.R}
        path={ReturnDeclarationRoutes.List}
        exact
      >
        <ReturnDeclarationTable />
      </Route>
      <Route
        capability={CAPABILITY.RETURN_DECLARATIONS}
        permission={PERMISSION.U}
        path={ReturnDeclarationRoutes.Edit}
        exact
      >
        <ReturnDeclarationEdit onUpdateInitialState={setInitialState} />
      </Route>
      <Route
        capability={CAPABILITY.RETURN_DECLARATIONS}
        permission={PERMISSION.C}
        path={ReturnDeclarationRoutes.Add}
        exact
      >
        <ReturnDeclarationAdd onUpdateInitialState={setInitialState} initialState={initialState} />
      </Route>
      <Redirect to={ReturnDeclarationRoutes.List} />
    </Switch>
  )
}

export default ReturnDeclarationPage
