import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ProductSelectors from 'modules/domain/product/selectors'
import ProductActions from 'modules/domain/product/duck'
import ProductRoutes from 'views/pages/Product/routes'
import ProductDetailsForm, { FormProps } from 'views/pages/Product/ProductDetailsForm/ProductDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import styled from 'styled-components'
import { useProduct } from 'modules/domain/product/hooks'
import { Progress } from 'modules/types'
import { useTranslation } from 'react-i18next'
import * as uuid from 'uuid'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import { createLocalizedValue } from 'helpers/localization'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const ProductEdit: React.FC<{ onUpdateInitialState(props: FormProps): void }> = ({ onUpdateInitialState }) => {
  const push = useHistoryPush()
  const params = useParams<{ id: string }>()
  const meta = useSelector(ProductSelectors.meta(params.id))
  const updateAction = useAction(ProductActions.updateRequested)
  const removeAction = useAction(ProductActions.removeRequested, params.id)
  const page = useSelector(ProductSelectors.page)
  const goBack = () => push({ route: ProductRoutes.List, query: { page } })
  const { t } = useTranslation('product')

  const [fetchProgress, product] = useProduct(params.id)
  const handleSubmit = (form: FormProps, options: { duplicate: boolean; dirty: boolean }) => {
    const justRedirect = () => {
      push({ route: ProductRoutes.Add })
    }
    const update = (duplicate: boolean) => {
      updateAction(params.id, form, duplicate)
    }

    if (!options.duplicate) {
      update(false)
    } else {
      onUpdateInitialState(form)
      if (options.dirty) {
        update(true)
      } else {
        justRedirect()
      }
    }
  }

  useHelmet({ title: product ? product.title : t('productsMetaTitle') })

  const data = useMemo(() => {
    if (!product) {
      return null
    }

    const makeCurrentFile = (src?: string) => {
      if (!src) {
        return undefined
      }

      return [
        {
          file: src,
          kind: 'current' as const,
          id: uuid.v4(),
          error: null,
        },
      ]
    }

    return {
      // TODO: remove this mapping (form must accept Product type)
      ...product,
      images: product.images.map(src => ({
        file: src,
        kind: 'current' as const,
        id: uuid.v4(),
        error: null,
      })),

      files: product.files?.map(file => ({
        title_i18n: file.title_i18n,
        url_i18n: createLocalizedValue(lang => makeCurrentFile(file.url_i18n && file.url_i18n[lang])),
      })),
    }
  }, [product])

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.WORK) {
    return loading()
  }

  if (fetchProgress === Progress.ERROR || !data || !product) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (!data || !product) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={product.title || ''} />
          <Id>{`ID ${product.id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ProductDetailsForm
          mode={'edit'}
          onSubmit={handleSubmit}
          onCancel={goBack}
          onRemove={removeAction}
          progress={meta.updateProgress}
          initialValues={data}
        />
      </Layout.Content>
    </>
  )
}

export default ProductEdit
