import { BasicSelect } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { LocalizedValue } from 'types/entities'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'
import { createGetList } from 'views/pages/Target2/components/selects/common'

export type TargetSeedTreatment = {
  title: LocalizedValue
  id: string
}

type TargetSeedTreatmentFilter = {
  product_id: string
  title?: string
}

type TargetSeedTreatmentSelectProps = EntitySelectProps<TargetSeedTreatment> & {
  productId?: string
  valueLabel?: string
}

const getList = createGetList<TargetSeedTreatment, TargetSeedTreatmentFilter>(endpoints.target2('seed_treatment'))

const getValue = (item: TargetSeedTreatment) => item.id

export const TargetSeedTreatmentSelect: React.VFC<TargetSeedTreatmentSelectProps> = ({
  productId = '',
  isDisabled,
  valueLabel,
  onChange,
  ...props
}) => {
  const { pick } = useLangPicker()

  const filter = useMemo(() => ({ product_id: productId }), [productId])
  const fakeValue = useMemo(
    () =>
      valueLabel
        ? {
            title: { en: valueLabel },
            id: 'fake',
          }
        : undefined,
    [valueLabel],
  )

  const getLabel = (item: TargetSeedTreatment) => pick(item.title)

  return (
    <SelectTestWrapper data-test-id="target-seed-treatment-select">
      {isDisabled ? (
        <BasicSelect isDisabled getOptionLabel={getLabel} getOptionValue={getValue} {...props} value={fakeValue} />
      ) : (
        <EntitySelect
          filter={filter}
          getEntityList={getList}
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          onChange={onChange}
          searchParamKey="title"
          {...props}
        />
      )}
    </SelectTestWrapper>
  )
}
