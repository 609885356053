import { Button, IconDocument, useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import useDownload from 'hooks/useDownload'
import AuthSelectors from 'modules/domain/auth/selectors'
import { useTargetOrderUpdate } from 'modules/domain/target2/common/hooks'
import { TargetTypes } from 'modules/domain/target2/common/types'
import FarmerTargetSelectors from 'modules/domain/target2/farmerTarget/selectors'
import { RetailerTargetSelectors } from 'modules/domain/target2/retailerTarget/selectors'
import { TerritoryTargetSelectors } from 'modules/domain/target2/territoryTarget/selectors'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'
import { octopusApiClient } from 'modules/utils/httpClient'
import React, { useMemo, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { isAdmin, isAgro, isDistributor } from 'types/entities'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
//import StatusFilter from 'views/components/TableFilters/StatusFilter'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { TargetFarmerSelect } from './components/selects/TargetFarmerSelect'
import { TargetManufacturerSelect } from './components/selects/TargetManufacturerSelect'
import { TargetProductSelect } from './components/selects/TargetProductSelect'
import { TargetRetailerSelect } from './components/selects/TargetRetailerSelect'
import { TargetSeasonSelect } from './components/selects/TargetSeasonSelect'
import { TargetSubcategorySelect } from './components/selects/TargetSubcategorySelect'
import { TargetTerritorySelect } from './components/selects/TargetTerritorySelect'
import FarmerTargetTable from './components/tables/FarmerTargetTable'
import RetailerTargetTable from './components/tables/RetailerTargetTable'
import { TerritoryTargetTable } from './components/tables/TerritoryTargetTable'
import { mappedActions, mappedDownloadCSVEndpoint, mappedSelectors, PersistentFilterStateKey } from './constants'
import Target2SkuRoutes from './routes'

const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 220px);
  grid-gap: 16px;
  padding: 24px 36px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
`

const StyledButton = styled(Button)`
  margin-left: 8px;
`
const SecondaryActionsButtons = styled.div`
  position: absolute;
  bottom: 10px;
  right: 24px;
`

const TargetList: React.FC<{ targetType: TargetTypes }> = ({ targetType }) => {
  const { t } = useTranslation('target2')

  const push = useHistoryPush()

  const actions = mappedActions[targetType]
  const selectors = mappedSelectors[targetType]

  const listUpdateRequested = useAction(actions.listUpdateRequested)

  const filterUpdated = useAction(actions.filterUpdated)
  const territoryFilterValue = useSelector(TerritoryTargetSelectors.filter)
  const farmerFilterValue = useSelector(FarmerTargetSelectors.filter)
  const retailerFilterValue = useSelector(RetailerTargetSelectors.filter)
  const updateCounter = useSelector(selectors.updateCounter)

  const role = useSelector(AuthSelectors.role)
  const agro = isAgro(role)
  const admin = isAdmin(role)
  const distributor = isDistributor(role)

  const filterValue = useMemo(() => {
    switch (targetType) {
      case TargetTypes.Territory:
        return territoryFilterValue
      case TargetTypes.Retailer:
        return retailerFilterValue
      case TargetTypes.Farmer:
        return farmerFilterValue
      default:
        return {}
    }
  }, [farmerFilterValue, retailerFilterValue, targetType, territoryFilterValue])

  useHelmet({ title: t('metaTitle') })

  const table = useMemo(() => {
    switch (targetType) {
      case TargetTypes.Territory:
        return <TerritoryTargetTable />
      case TargetTypes.Farmer:
        return <FarmerTargetTable />
      case TargetTypes.Retailer:
        return <RetailerTargetTable />
    }
  }, [targetType])

  const [showTerritoryTab, showRetailerTab] = usePermissions([
    { capability: CAPABILITY.TERRITORY_TARGETS, permission: PERMISSION.R },
    { capability: CAPABILITY.RETAILER_TARGETS, permission: PERMISSION.R },
  ])

  const [isFiltersVisible, setIsFiltersVisible] = useState(localStorage.getItem(PersistentFilterStateKey) === 'true')

  const [orderUpdateProgress, requestOrderUpdate] = useTargetOrderUpdate(listUpdateRequested)

  const handleFilterUpdate = (newFilter: Partial<typeof filterValue>) =>
    filterUpdated({
      ...filterValue,
      ...newFilter,
    })

  const handlePersistentFilterToggle = () => {
    const newState = !isFiltersVisible
    setIsFiltersVisible(newState)
    localStorage.setItem(PersistentFilterStateKey, String(newState))
  }

  const downloadEndpoint = mappedDownloadCSVEndpoint[targetType]

  const [downloadProgress, downloadCSV] = useDownload(downloadEndpoint, filterValue, undefined, octopusApiClient)

  return (
    <>
      <Layout.Header>
        <Header.RootWithSmallTabs>
          <Header.Title title={t('headerTitle')}>
            <Button onClick={handlePersistentFilterToggle} intent={'primary'} size={'small'} filled>
              {t(isFiltersVisible ? 'list.filters.hide' : 'list.filters.show')}
            </Button>
            {admin && (
              <StyledButton
                progress={orderUpdateProgress}
                onClick={requestOrderUpdate}
                intent={'primary'}
                size={'small'}
                filled
              >
                {t('list.refreshOrders')}
              </StyledButton>
            )}
          </Header.Title>

          {(showRetailerTab || showTerritoryTab) && (
            <Header.SmallTabsContainer>
              {showTerritoryTab && (
                <Header.SmallTab
                  title={t('list.tabs.territory')}
                  isActive={targetType === TargetTypes.Territory}
                  onClick={() => push({ route: Target2SkuRoutes.TerritoryList })}
                >
                  {t('list.tabs.territory')}
                </Header.SmallTab>
              )}
              {showRetailerTab && (
                <Header.SmallTab
                  title={t('list.tabs.retailer')}
                  isActive={targetType === TargetTypes.Retailer}
                  onClick={() => push({ route: Target2SkuRoutes.RetailerList })}
                >
                  {t('list.tabs.retailer')}
                </Header.SmallTab>
              )}
              <Header.SmallTab
                title={t('list.tabs.farmer')}
                isActive={targetType === TargetTypes.Farmer}
                onClick={() => push({ route: Target2SkuRoutes.FarmerList })}
              >
                {t('list.tabs.farmer')}
              </Header.SmallTab>
            </Header.SmallTabsContainer>
          )}
          <SecondaryActionsButtons>
            <Button progress={downloadProgress} intent="primary" size="small" variant="text" onClick={downloadCSV}>
              <IconDocument />
              Download CSV
            </Button>
          </SecondaryActionsButtons>
        </Header.RootWithSmallTabs>
        <AnimateHeight height={isFiltersVisible ? 'auto' : 0} duration={400} animateOpacity>
          <FilterWrapper>
            {agro && (
              <TargetManufacturerSelect
                label={t('list.filters.labels.manufacturer')}
                placeholder={t('list.filters.placeholders.manufacturer')}
                onChange={val => {
                  handleFilterUpdate({
                    manufacturer_id: val,
                    territory_id: undefined,
                    retailer_id: undefined,
                    farmer_id: undefined,
                    season_id: undefined,
                    product_id: undefined,
                    subcategory_id: undefined,
                  })
                }}
                value={filterValue.manufacturer_id}
                variant="small"
                isClearable
                scope={targetType}
                updateDeps={[updateCounter, targetType]}
              />
            )}
            <TargetTerritorySelect
              label={t('list.filters.labels.territory')}
              placeholder={t('list.filters.placeholders.territory')}
              onChange={val => {
                handleFilterUpdate({ territory_id: val, retailer_id: undefined })
              }}
              value={filterValue.territory_id}
              variant="small"
              scope={targetType}
              manufacturerId={filterValue.manufacturer_id}
              updateDeps={[updateCounter, targetType]}
              isClearable
            />
            {targetType !== TargetTypes.Territory && !distributor && (
              <TargetRetailerSelect
                label={t('list.filters.labels.retailer')}
                placeholder={t('list.filters.placeholders.retailer')}
                onChange={val => {
                  handleFilterUpdate({ retailer_id: val })
                }}
                value={filterValue.retailer_id}
                variant="small"
                scope={targetType}
                manufacturerId={filterValue.manufacturer_id}
                territoryId={filterValue.territory_id}
                updateDeps={[updateCounter, targetType]}
                isClearable
              />
            )}
            {targetType === TargetTypes.Farmer && (
              <TargetFarmerSelect
                label={t('list.filters.labels.farmer')}
                placeholder={t('list.filters.placeholders.farmer')}
                onChange={val => {
                  handleFilterUpdate({ farmer_id: val })
                }}
                value={filterValue.farmer_id}
                variant="small"
                scope={targetType}
                manufacturerId={filterValue.manufacturer_id}
                updateDeps={[updateCounter, targetType]}
                isClearable
              />
            )}
            <TargetSeasonSelect
              label={t('list.filters.labels.seasons')}
              placeholder={t('list.filters.placeholders.seasons')}
              values={filterValue.season_id}
              onChange={val => {
                handleFilterUpdate({ season_id: val })
              }}
              variant="small"
              scope={targetType}
              manufacturerId={filterValue.manufacturer_id}
              updateDeps={[updateCounter, targetType]}
              isClearable
            />
            <TargetSubcategorySelect
              label={t('list.filters.labels.subcategory')}
              placeholder={t('list.filters.placeholders.subcategory')}
              value={filterValue.subcategory_id}
              onChange={val => {
                handleFilterUpdate({ subcategory_id: val, product_id: undefined })
              }}
              variant="small"
              scope={targetType}
              manufacturerId={filterValue.manufacturer_id}
              updateDeps={[updateCounter, targetType]}
              isClearable
            />
            <TargetProductSelect
              label={t('list.filters.labels.product')}
              placeholder={t('list.filters.placeholders.product')}
              value={filterValue.product_id}
              onChange={val => {
                handleFilterUpdate({ product_id: val })
              }}
              variant="small"
              scope={targetType}
              manufacturerId={filterValue.manufacturer_id}
              subcategoryId={filterValue.subcategory_id}
              updateDeps={[updateCounter, targetType]}
              isClearable
            />
          </FilterWrapper>
        </AnimateHeight>
      </Layout.Header>
      <Layout.Content>
        <TComponents.Wrapper noFilters>{table}</TComponents.Wrapper>
      </Layout.Content>
    </>
  )
}

export default TargetList
