import React, { useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FormFields } from 'views/components/DistributorOrderDetailsForm/DistributorOrderDetailsForm'

import DistributorOrderAdd from 'views/pages/DistributorOrder/DistributorOrderAdd'
import DistributorOrderEdit from 'views/pages/DistributorOrder/DistributorOrderEdit'
import DistributorOrderTable from 'views/pages/DistributorOrder/DistributorOrderTable'
import DistributorOrderRoutes from './routes'

const DistributorOrderPage: React.FC = () => {
  const [initialState, setInitialState] = useState<FormFields | undefined>()

  return (
    <Switch>
      <Route path={DistributorOrderRoutes.List} exact={true}>
        <DistributorOrderTable />
      </Route>
      <Route path={DistributorOrderRoutes.Edit} exact={true}>
        <DistributorOrderEdit onUpdateInitialState={setInitialState} />
      </Route>
      <Route path={DistributorOrderRoutes.Add} exact={true}>
        <DistributorOrderAdd onUpdateInitialState={setInitialState} initialState={initialState} />
      </Route>
      <Redirect to={DistributorOrderRoutes.List} />
    </Switch>
  )
}

export default DistributorOrderPage
