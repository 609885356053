import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CellProps, Row, useTable } from 'react-table'
import { generatePath, useHistory } from 'react-router-dom'
import styled, { StyledProps } from 'styled-components'
import {
  CheckboxTreeSelect,
  controlStyle,
  menuListStyle,
  menuStyle,
  optionStyle,
  SimpleSelect,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useBoolean,
  usePersistentScroll,
  CustomSelectStyles,
  Button,
} from '@agro-club/frontend-shared'

import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Filter as FilterComponent } from 'views/components/TableFilters/TableFilters'
import { isDistributor, isProducer } from 'types/entities'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Progress } from 'modules/types'
import useLangPicker from 'hooks/useLangPicker'
import { Company } from 'modules/domain/company/types'

import InventoryExchangeSelectors from 'modules/domain/inventoryExchange/selectors'
import { useInventoryExchangeList } from 'modules/domain/inventoryExchange/hooks'
import TransactionModal from './TransactionModal'
import InventoryRoutes from '../routes'
import InventoryTransactionSelectors from 'modules/domain/inventoryExchangeTransaction/selectors'
import InventoryTransactionActions from 'modules/domain/inventoryExchangeTransaction/duck'
import UpdateErrorPopup from './UpdateErrorPopup'
import InventoryExchangeActions from 'modules/domain/inventoryExchange/duck'
import { InventoryItem, InventoryExchangeListRequestFilter } from 'modules/domain/inventoryExchange/types'
import TransferModal from './TransferModal'
import { StorefrontSku } from 'modules/domain/storefront/types'
import {
  useInventoryExchangeDistributorsOptions,
  useInventoryExchangeProducersOptions,
} from 'modules/domain/company/hooks'
import { ProductOptionsSelect } from 'views/components/ProductOptionsSelect/ProductOptionsSelect'
import { ProductOptionType } from 'modules/domain/productOptions/types'
import useDateFormatFn from 'hooks/useDateFormatFn'
import useDownload from 'hooks/useDownload'
import { endpoints } from 'modules/endpoints'
import { AlignWrapper } from 'views/components/AlignWrapper/AlignWrapper'
import { ProductsSelect } from 'views/components/ProductsSelect/ProductsSelect'

const customSelectStyles: CustomSelectStyles = {
  control: (...args) => ({
    ...controlStyle(...args),
    height: '32px',
    minHeight: '32px',
  }),
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  }),
}

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Filter = styled(FilterComponent)`
  margin: 0 16px 16px 0 !important;
  width: 20%;
  min-width: 250px;
  flex-grow: 1;
  justify-content: space-between;
`

const TotalQtyCellContainer = styled.div<StyledProps<{ disabled?: boolean }>>`
  height: 27px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  ${({ theme, disabled }) => !disabled && { boxShadow: `0 0 10px ${theme.color.primary100}` }}
  background-color: ${({ theme }) => theme.color.onPrimaryLight};

  &:hover {
    background-color: ${({ theme, disabled }) => (!disabled ? theme.color.primary25 : theme.color.onPrimaryLight)};
  }
`
const PRODUCT_OPT_ACTIVE_INGREDIENT = 'active_ingredient'

const useEditPermission = (row: Row<InventoryItem>) => {
  const profile = useSelector(AuthSelectors.profile)
  let hasEditPermission = true
  if (isProducer(profile?.role) || (isDistributor(profile?.role) && row.original.retailer_id !== profile?.company_id)) {
    hasEditPermission = false
  }
  return hasEditPermission
}

const PublishedDateCell: React.FC<CellProps<InventoryItem, string>> = ({ cell, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })

  return <div key={column.id}>{getFormatedDate(cell.value) || ''}</div>
}

const ProducerCell: React.FC<CellProps<InventoryItem, StorefrontSku>> = ({ cell, column }) => {
  return (
    <TComponents.SemiBoldText key={column.id}>
      {cell.value?.product?.producer?.internal_name || ''}
    </TComponents.SemiBoldText>
  )
}

const DistributorCell: React.FC<CellProps<InventoryItem, Company>> = ({ cell, column }) => {
  return <div key={column.id}>{cell.value?.internal_name || ''}</div>
}

const CategoryCell: React.FC<CellProps<InventoryItem, StorefrontSku>> = ({ cell, column }) => {
  const { pick } = useLangPicker()
  let text = ''
  const category = pick(cell.value?.product?.category?.title_i18n)
  const subCategory = pick(cell.value?.product?.subcategory?.title_i18n)
  if (category && subCategory) {
    text = `${category} / ${subCategory}`
  } else if (category) {
    text = category
  } else if (subCategory) {
    text = subCategory
  }
  return <div key={column.id}>{text}</div>
}

const ProductCell: React.FC<CellProps<InventoryItem, StorefrontSku>> = ({ cell, column }) => {
  const { pick } = useLangPicker()
  let val = ''
  if (cell.value) {
    val = `${pick(cell.value?.product?.title_i18n)}`
  }
  return <div key={column.id}>{val}</div>
}

const SKUCell: React.FC<CellProps<InventoryItem, StorefrontSku>> = ({ cell, column }) => {
  let val = ''
  if (cell.value) {
    val = cell.value?.sku_id
  }
  return <div key={column.id}>{val}</div>
}

const ActiveIngridentsCell: React.FC<CellProps<InventoryItem, StorefrontSku>> = ({ cell, column }) => {
  let val = ''
  if (cell.value?.params?.options) {
    val = cell.value.params.options
      .filter(opt => opt.type == PRODUCT_OPT_ACTIVE_INGREDIENT)
      .map(o => {
        const percentage = o.params?.percentage ? ` ${o.params?.percentage}%` : ''
        return `${o.title}${percentage}`
      })
      .join(', ')
  }
  return <div key={column.id}>{val}</div>
}

const TotalQtyCell: React.FC<CellProps<InventoryItem, number>> = ({ cell, column, row }) => {
  const { t } = useTranslation('inventoryExchange')
  const [isModalOpen, openModal, closeModal] = useBoolean(false)
  const hasEditPermission = useEditPermission(row)
  const addTransactionProgress = useSelector(InventoryTransactionSelectors.addProgress)
  const handleModalFormSubmit = useAction(InventoryTransactionActions.addRequested)

  useEffect(() => {
    if (addTransactionProgress === Progress.SUCCESS) {
      closeModal()
    }
  }, [addTransactionProgress, closeModal])
  if (!hasEditPermission) {
    return <div>{cell.value}</div>
  }
  return (
    <>
      <TotalQtyCellContainer
        key={column.id}
        onClick={e => {
          e.stopPropagation()
          openModal()
        }}
      >
        {cell.value}
      </TotalQtyCellContainer>
      {isModalOpen && (
        <TransactionModal
          heading={t('inventoryModal:modalHeading')}
          isOpen={isModalOpen}
          rowData={row.original}
          progress={addTransactionProgress}
          onClose={closeModal}
          onSubmit={handleModalFormSubmit}
        />
      )}
    </>
  )
}

const PriceCell: React.FC<CellProps<InventoryItem, string>> = ({ cell, row }) => {
  const price = cell.value ?? (row.original.sku.price || '')
  const parsed = parseFloat(price)
  if (isNaN(parsed)) {
    //return raw string if failed to parse
    return <div>{price}</div>
  }
  const formated = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parsed)
  return <div> {formated} </div>
}

const CommentCell: React.FC<CellProps<InventoryItem, string>> = ({ cell }) => {
  let comment = ''
  if (cell.value) {
    comment = cell.value?.length < 100 ? cell.value : `${cell.value.substring(0, 100)}...`
  }
  return <div style={{ overflowWrap: 'break-word' }}> {comment} </div>
}

const PackageTypeCell: React.FC<CellProps<InventoryItem>> = ({ cell }) => {
  return <div>{cell.value?.params?.package_title}</div>
}

const InventoryRow: React.FC<{
  row: Row<InventoryItem>
  history: any
}> = ({ row, history }) => {
  const { t } = useTranslation('inventoryExchangeModal')
  const profile = useSelector(AuthSelectors.profile)
  const props = row.getRowProps()
  const hasEditPermission = useEditPermission(row)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const tableRowOnClick = () => {
    if (isModalOpen) {
      return
    }
    if (!hasEditPermission) {
      if (!isProducer(profile?.role)) {
        setIsModalOpen(true)
      }
      return
    }
    history.push(generatePath(InventoryRoutes.TotalDetails, { id: row.original.id }))
  }
  return (
    <TableBodyRow {...props} onClick={tableRowOnClick}>
      {row.cells.map(cell => {
        const { key, ...props } = cell.getCellProps()
        return (
          <TableBodyCell
            key={key}
            {...cell.column}
            {...props}
            stopClickPropagation={hasEditPermission && ['total_qty'].includes(cell.column.id)}
          >
            {cell.render('Cell')}
          </TableBodyCell>
        )
      })}
      {!hasEditPermission && isModalOpen && (
        <TransferModal
          heading={t('modalHeading')}
          rowData={row.original}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
        />
      )}
    </TableBodyRow>
  )
}

const InventoryTotalList: React.FC = () => {
  const { t } = useTranslation('inventorySKU')
  const history = useHistory()
  const [progress, data = []] = useInventoryExchangeList()
  // const [packageTypesProgress, packageTypes = []] = useAllPackageTypesList()
  const listRequested = useAction(InventoryExchangeActions.listRequested)
  const filterUpdated = useAction(InventoryExchangeActions.filterUpdated)
  const filterValue = useSelector(InventoryExchangeSelectors.filter)
  const total = useSelector(InventoryExchangeSelectors.total)
  const pages = useSelector(InventoryExchangeSelectors.pages)
  const page = useSelector(InventoryExchangeSelectors.page)
  const pageSize = useSelector(InventoryExchangeSelectors.pageSize)
  const { sort_field, sort_reversed } = useSelector(InventoryExchangeSelectors.sorting)

  const [producersProgress, producers] = useInventoryExchangeProducersOptions()
  const producersOptions = useMemo(() => {
    if (!producers) {
      return []
    }
    return producers.map(i => ({ id: i.id, title: i.official_name ?? '' }))
  }, [producers])

  const [distributorsProgress, distributors] = useInventoryExchangeDistributorsOptions()
  const distributorsOptions = useMemo(() => {
    if (!distributors) {
      return []
    }
    return distributors.map(d => ({
      id: d.id,
      title: d.official_name ?? '',
      hasChildren: !!d.has_branch_company,
      parent: d.head_company_relation?.company_id,
    }))
  }, [distributors])

  const visibleColumns = React.useMemo(
    () => [
      {
        id: 'date_published',
        Header: t('publishedDate'),
        accessor: 'date_published' as const,
        Cell: PublishedDateCell,
      },
      {
        id: 'producer',
        Header: t('producer'),
        accessor: 'sku' as const,
        Cell: ProducerCell,
      },
      {
        Header: t('distributor'),
        accessor: 'retailer' as const,
        Cell: DistributorCell,
      },
      {
        id: 'category',
        Header: t('category'),
        accessor: 'sku' as const,
        Cell: CategoryCell,
      },
      {
        id: 'product',
        Header: t('product'),
        accessor: 'sku' as const,
        Cell: ProductCell,
      },
      {
        Header: t('sku'),
        accessor: 'sku' as const,
        Cell: SKUCell,
      },
      {
        id: 'activeIngredients',
        Header: t('activeIngredients'),
        accessor: 'sku' as const,
        Cell: ActiveIngridentsCell,
      },
      {
        id: 'packageType',
        Header: t('packageType'),
        accessor: 'sku' as const,
        Cell: PackageTypeCell,
      },
      {
        Header: t('totalQty'),
        accessor: 'total_qty' as const,
        Cell: TotalQtyCell,
      },
      {
        id: 'price',
        Header: t('price'),
        accessor: 'price' as const,
        Cell: PriceCell,
      },
      {
        id: 'comment',
        Header: t('comment'),
        accessor: 'comment' as const,
        Cell: CommentCell,
        width: 250,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<InventoryItem>({
    columns: visibleColumns,
    data,
    initialState: {},
  })

  const handleFilterChange = React.useCallback(
    (newFilterValue: Partial<InventoryExchangeListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const setPage = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('inventoryExchangeList')

  const renderRows = () => {
    if (progress !== Progress.SUCCESS) {
      return null
    }
    return rows.map(row => {
      prepareRow(row)
      const { key } = row.getRowProps()
      return <InventoryRow key={key} row={row} history={history} />
    })
  }

  const [progressCSV, downloadCSV] = useDownload(endpoints.inventoryExchange('download/csv'), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <FiltersWrapper>
          <Filter title={t('producer')}>
            <SimpleSelect
              customStyles={customSelectStyles}
              onChange={val => handleFilterChange({ producer_id: val ? val.toString() : null })}
              progress={producersProgress}
              value={filterValue.producer_id}
              isClearable
              options={producersOptions}
            />
          </Filter>
          <Filter title={t('labels:retailer')}>
            <CheckboxTreeSelect
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              customStyles={customSelectStyles}
              onChange={val => handleFilterChange({ retailer_id: val ? val : undefined })}
              progress={distributorsProgress}
              value={filterValue.retailer_id}
              isClearable
              options={distributorsOptions}
            />
          </Filter>
          <Filter title={t('labels:product')}>
            <ProductsSelect
              variant="small"
              onChange={val => handleFilterChange({ product_id: val })}
              value={filterValue.product_id}
              scope="sku/inventory"
              isClearable
              isFilterSuggest
            />
          </Filter>
          <Filter title={t('activeIngredient')}>
            <ProductOptionsSelect
              variant="small"
              type={ProductOptionType.ACTIVE_INGREDIENT}
              isClearable
              onChange={val => handleFilterChange({ product_options_id: val ? [val.toString()] : undefined })}
            />
          </Filter>
        </FiltersWrapper>
        <AlignWrapper horizontalAlign="left" verticalAlign="center">
          <Button filled intent="primary" onClick={downloadCSV} progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </AlignWrapper>
      </TComponents.Filters>
      <Table
        fixedLayout
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={setPage}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <TableHeadCell key={column.id} {...column}>
                  {column.render('Header')}
                </TableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {renderRows()}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          />
        </TableBody>
      </Table>
      <UpdateErrorPopup />
    </TComponents.Wrapper>
  )
}

export default InventoryTotalList
