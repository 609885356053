import { endpoints } from 'modules/endpoints'
import { createBasicManagers } from 'modules/utils/helpers/createBasicManagers'
import { octopusApiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api'

import {
  TargetSkuListRequestFilter,
  TargetSkuListRequestSorting,
  TargetSkuUpdateDTO,
  TerritoryTargetSku,
  TerritoryTargetSkuCreateDTO,
} from '../common/types'

// export default createBasicManagers<
//   TargetSkuListRequestFilter,
//   TargetSkuListRequestSorting,
//   TerritoryTargetSku,
//   TerritoryTargetSkuCreateDTO,
//   TargetSkuUpdateDTO
// >(endpoints.territoryTargetSku, octopusApiClient)

const territoryTargetSkuManagers = {
  ...createBasicManagers<
    TargetSkuListRequestFilter,
    TargetSkuListRequestSorting,
    TerritoryTargetSku,
    TerritoryTargetSkuCreateDTO,
    TargetSkuUpdateDTO
  >(endpoints.territoryTargetSku, octopusApiClient),
  getList: (
    filter: TargetSkuListRequestFilter,
    sorting: TargetSkuListRequestSorting,
    page: number,
    pageSize?: number,
  ) => {
    try {
      return octopusApiClient.get<ListResponse<TerritoryTargetSku>>(endpoints.territoryTargetSku(), {
        ...filter,
        ...sorting,
        page: page,
        page_size: pageSize,
        order_by: 'territory_asc',
      })
    } catch (err) {
      console.error('List fetch error!', err)
      throw err
    }
  },
}

export default territoryTargetSkuManagers
