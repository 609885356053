import React from 'react'
import CallBackRequestRoutes from './routes'
import { Switch, Route } from 'react-router-dom'
import CallBackRequestItem from './CallBackRequestItem'
import CallBackRequestList from './CallBackRequestList'

const CallBackRequestPage: React.FC = () => {
  return (
    <Switch>
      <Route path={CallBackRequestRoutes.List} exact>
        <CallBackRequestList />
      </Route>
      <Route path={CallBackRequestRoutes.Item}>
        <CallBackRequestItem />
      </Route>
    </Switch>
  )
}

export default CallBackRequestPage
