import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Styled from 'views/components/OrderProductItems/styles'
import { ReturnDeclarationItem } from 'types/returnDeclaration'
import { AltPackaging, Label, SeedTreatmentContainer } from './OrderProductItemsReadOnly'
import {
  SectionBody,
  SectionContainer,
  SectionTable,
  SectionTableBody,
  SectionTableBodyCell,
  SectionTableBodyRow,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableHeadRow,
  SectionTitle,
} from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'

const LotNumberInput: React.FC<{
  item: ReturnDeclarationItem
}> = ({ item }) => {
  const { t } = useTranslation('returnDeclaration')

  return (
    <Styled.LotInputWrapper>
      <Styled.Label>{t('returnDeclaration:form.labels.lotNumber')}</Styled.Label>
      <Label>{item.lot_number}</Label>
    </Styled.LotInputWrapper>
  )
}

const SeedTreatment: React.FC<{ item: ReturnDeclarationItem }> = ({ item }) => {
  const { pick } = useLangPicker()
  const { t } = useTranslation(['returnDeclaration', 'farmerOrder'])
  const seedTreatment = item.product?.seed_treatment.find(st => st.id === item.seed_treatment_id)

  if (!item.seed_treatment_id || !seedTreatment) {
    return null
  }
  return (
    <SeedTreatmentContainer>
      {t('farmerOrder:seedTreatment')}: <Label>{pick(seedTreatment.title_i18n)}</Label>
    </SeedTreatmentContainer>
  )
}

const ReturnItemsReadyOnly: React.FC<{
  title?: string
  items?: ReturnDeclarationItem[]
  invalid?: boolean
  errorText?: string
}> = ({ title, items = [], children }) => {
  const { t } = useTranslation(['farmerOrder', 'returnDeclaration', 'common'])

  return (
    <SectionContainer>
      <SectionTitle>{title || t('farmerOrder:form.sectionHeaders.orders')}</SectionTitle>
      <SectionBody>
        {!!items?.length && (
          <SectionTable data-test-id={'product-items'}>
            <SectionTableHead>
              <SectionTableHeadRow>
                <SectionTableHeadCell>{t('form.tableHeaders.product')}</SectionTableHeadCell>
                <SectionTableHeadCell>{t('form.tableHeaders.package')}</SectionTableHeadCell>
                <SectionTableHeadCell textAlign={'center'}>{t('form.tableHeaders.quantity')}</SectionTableHeadCell>
                <SectionTableHeadCell>{t('form.tableHeaders.altPackaging')}</SectionTableHeadCell>
              </SectionTableHeadRow>
            </SectionTableHead>
            <SectionTableBody>
              {items.map((item, idx) => {
                return (
                  <SectionTableBodyRow key={idx} data-test-id={`product-row-${item.product_id}`}>
                    <SectionTableBodyCell>
                      <div>
                        <b>
                          {item.product?.subcategory?.title || ''} {item.product?.title || ''}
                        </b>
                      </div>
                      <SeedTreatment item={item} />
                      <LotNumberInput item={item} />
                    </SectionTableBodyCell>
                    <SectionTableBodyCell style={{ whiteSpace: 'nowrap' }}>
                      {item.product?.default_packaging || ''}
                    </SectionTableBodyCell>
                    <SectionTableBodyCell style={{ whiteSpace: 'nowrap' }}>{item.quantity}</SectionTableBodyCell>
                    <SectionTableBodyCell>
                      <AltPackaging item={item} />
                    </SectionTableBodyCell>
                  </SectionTableBodyRow>
                )
              })}
            </SectionTableBody>
          </SectionTable>
        )}
      </SectionBody>
      {children}
    </SectionContainer>
  )
}

export default ReturnItemsReadyOnly
