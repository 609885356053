import React from 'react'
import DiscountRuleRoutes from 'views/pages/DiscountRule/routes'
import DiscountRuleAdd from 'views/pages/DiscountRule/DiscountRuleAdd'
import DiscountRuleEdit from 'views/pages/DiscountRule/DiscountRuleEdit'
import DiscountRuleList from 'views/pages/DiscountRule/DiscountRuleList'
import { Route, Switch } from 'react-router-dom'

const DiscountRulePage: React.FC = () => {
  return (
    <Switch>
      <Route path={DiscountRuleRoutes.Add}>
        <DiscountRuleAdd />
      </Route>
      <Route path={DiscountRuleRoutes.Edit}>
        <DiscountRuleEdit />
      </Route>
      <Route path={DiscountRuleRoutes.List}>
        <DiscountRuleList />
      </Route>
    </Switch>
  )
}

export default DiscountRulePage
