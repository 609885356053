import React from 'react'
import { useTranslation } from 'react-i18next'
import { MailingListEntryStatus } from 'modules/domain/mailingList/types'
import { StatusIcon } from '@agro-club/frontend-shared'

const Status: React.FC<{ status: MailingListEntryStatus }> = ({ status }) => {
  const { t } = useTranslation('mailingList')
  const mapIconStatus = (status: MailingListEntryStatus) => {
    switch (status) {
      case MailingListEntryStatus.ACTIVE:
        return 'active'
      case MailingListEntryStatus.UNSUBSCRIBED:
        return 'inactive'
    }
  }

  const mapStatusI18n = (status: MailingListEntryStatus) => {
    switch (status) {
      case MailingListEntryStatus.ACTIVE:
        return t('status.active')
      case MailingListEntryStatus.UNSUBSCRIBED:
        return t('status.unsubscribed')
      default:
        return status
    }
  }

  return (
    <div>
      <StatusIcon status={mapIconStatus(status)} />
      {mapStatusI18n(status)}
    </div>
  )
}

export default Status
