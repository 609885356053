import { useEffect, useMemo, useState } from 'react'
import { CalculatedDiscountResponse } from 'modules/domain/discountRule/types'
import { getPrettyPrice } from 'modules/utils/helpers'
import { useCompanyById } from 'modules/domain/company/hooks'
import { ProductEntry } from 'types/entities'

type PropsType = {
  orders: ProductEntry[]
  producerId?: string
  totalNet?: string
  discountInfo?: CalculatedDiscountResponse
}

type useTotalSumType = {
  actualTotalNet: string
  totalSum: string
}

const useTotalSum = ({ orders, producerId, totalNet, discountInfo }: PropsType): useTotalSumType => {
  const [, producer] = useCompanyById(producerId)
  const [actualTotalNet, setActualTotalNet] = useState(totalNet || '')

  const total = useMemo(() => {
    const items = orders
    if (!items.length) return 0

    return items.reduce((acc, item) => {
      if (item.product?.price) {
        return acc + Number(item.product.price) * item.quantity
      }
      return acc
    }, 0)
  }, [orders])

  let discountTotal: number = parseFloat(String(discountInfo?.total)) || 0
  discountTotal = Number.isInteger(discountTotal) ? Number(discountTotal) : Number(discountTotal.toFixed(2))

  const totalSum = useMemo(() => {
    return getPrettyPrice(total, producer?.currency)
  }, [producer?.currency, total])

  useEffect(() => {
    const netTotal = getPrettyPrice(total - discountTotal, producer?.currency)
    setActualTotalNet(netTotal)
  }, [discountTotal, producer?.currency, total])

  return {
    actualTotalNet,
    totalSum,
  }
}

export default useTotalSum
