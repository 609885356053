import { BusinessModel, Currency, ROLES } from 'types/entities'
import { Company } from 'modules/domain/company/types'
import { Farmer } from 'modules/domain/farmer/types'
import { Promocode } from 'modules/domain/promocode/types'
import { DocumentItem } from 'modules/domain/document/types'
import { ProductEntry } from './entities'
import { CountryCode } from 'libphonenumber-js'
import { Season } from 'modules/domain/season/types'
import { FileData } from 'views/components/FileManager/types'

export enum FarmerOrderStatus {
  New = 'new',
  Changed = 'changed',
  Confirmed = 'confirmed',
  Canceled = 'canceled',
  Completed = 'completed',
  Deleted = 'deleted',
}

// TODO this enum should be updated on backend too
export enum FarmerOrderFilterStatus {
  New = 'new',
  NewAgro = 'new_agro',
  NewProducer = 'new_producer',
  AwaitingDistributorSelection = 'distributor_selection',
  AwaitingProducerConfirmation = 'producer_confirmation',
  AwaitingDistributorConfirmation = 'distributor_confirmation',
  AwaitingFarmerConfirmation = 'farmer_confirmation',
  ConfirmedByAgroClub = 'confirmed_by_agro',
  ConfirmedByProducer = 'confirmed_by_producer',
  ConfirmedByDistributor = 'confirmed_by_distributor',
  CanceledByProducer = 'canceled_by_producer',
  CanceledByAgroClub = 'canceled_by_agroclub',
  RejectedByDistributor = 'rejected_by_distributor',
  CanceledByFarmer = 'canceled_by_farmer',
  Changed = 'changed',
  Deleted = 'deleted',
}

export type FarmerOrderItem = ProductEntry & {
  discount?: number
  delivered_qty?: number
  final_qty?: number
  // only for React list key purpose
  key: string
  comment?: string
}

export type FarmerOrderPayment = {
  payment_type: 'pre_payment' | 'payment'
  amount: number
  is_paid: boolean
  pay_date: string
  percentage: number
}

export type FarmerOrderDeliveryAddress = {
  delivery_date?: string
  desired_delivery_date?: string
  address?: string
  city?: string
  region_id?: string
  country?: CountryCode
  postal_code?: string
}

export type FarmerOrder = {
  id: string
  currency: Currency
  status: FarmerOrderStatus
  total_discount?: number
  author_id?: string
  order_source?: string
  region_id: string | null
  producer_id?: string
  distributor_id?: string | null
  owner_id: string
  producer: Company | null
  distributor?: Company | null
  items: FarmerOrderItem[]
  payments?: FarmerOrderPayment[]
  created_at?: string
  delivery_addresses: FarmerOrderDeliveryAddress[]
  customer: Farmer | null // TODO proper type
  country: CountryCode
  business_model: BusinessModel
  price?: string
  cost?: string
  discount?: number
  total?: string
  comment?: string
  slug: string
  interaction: {
    credit_offer_accepted: boolean
    confirmed_by_agroclub: boolean
    confirmed_by_producer: boolean
    confirmed_by_distributor: 'unset' | 'confirmed' | 'rejected'
    confirmed_by_farmer: boolean
    canceled_by_role: ROLES | null
    user_id_confirmed_by_agroclub?: string
    user_id_confirmed_by_distributor?: string
    user_id_confirmed_by_farmer?: string
    user_id_confirmed_by_producer?: string
  }
  promocodes: Promocode[]
  revision?: string
  discounts_ids?: string[]
  order_date?: string
  juristic_documents?: DocumentItem[]
  season_id?: string
  season?: Omit<Season, 'is_current'>
  files: FileData[]
  total_net?: string
  farmer_comment?: string
}
