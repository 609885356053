import React, { useCallback, useEffect, useRef } from 'react'
import { Filter } from 'views/components/TableFilters/TableFilters'
import { useTranslation } from 'react-i18next'
import { CellProps, HeaderProps, useRowSelect, useTable } from 'react-table'
import DistributorOrderActions from 'modules/domain/distributorOrder/duck'
import DistributorOrderSelectors from 'modules/domain/distributorOrder/selectors'
import { useDistributorOrderList } from 'modules/domain/distributorOrder/hooks'
import { DistributorOrderListRequestFilter } from 'modules/domain/distributorOrder/types'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import Routes from '../routes'
import { DistributorOrder, DistributorOrderItem } from 'types/distributorOrder'
import DistributorOrderStatusColored from 'views/components/DistributorOrderStatusColored/DistributorOrderStatusColored'
import {
  RangeDatePicker,
  AdvancedHeadCell,
  Table,
  TableHead,
  TableHeadRow,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableNoData,
  useAction,
  usePageVisibilityChange,
  usePersistentScroll,
  Button,
} from '@agro-club/frontend-shared'
import { parseISO, isValid } from 'date-fns'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Progress } from 'modules/types'
import useLangPicker from 'hooks/useLangPicker'
import {
  Columns,
  CustomFeatureName,
  FeatureFlagModifiers,
  isUsersCompanyBranchDistributor,
  Sections,
  Tables,
} from 'types/entities'
import AuthSelectors from 'modules/domain/auth/selectors'
import { FarmerOrder, FarmerOrderItem } from 'types/farmerOrder'
import styled from 'styled-components'
import useDownload from 'hooks/useDownload'
import { endpoints } from 'modules/endpoints'
import DistributorsMultiSelect from 'views/components/DistributorsMultiSelect/DistributorsMultiSelect'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import {
  generateCrmFieldAccessString,
  generateCrmTableColumnAccessString,
  generateCustomFeatureFlag,
} from 'modules/utils/generateStringHelpers'
import CategorySelect from 'views/components/CategorySelect/CategorySelect'
import SubcategorySelect from 'views/components/CategorySelect/SubcategorySelect'
import DistributorOrderStatusMultiSelect from 'views/components/DistributorOrderStatusMultiSelect/DistributorOrderStatusMultiSelect'
import { AlignWrapper } from 'views/components/AlignWrapper/AlignWrapper'
import useDateFormatFn from 'hooks/useDateFormatFn'
import useDateFormat from 'hooks/useDateFormat'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'

const LIST_UPDATE_INTERVAL = 60000

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  height: 100%;
  overflow: auto;
`

const SeasonWrapper = styled.div`
  width: 100px;
`

const SeedTreatment = styled.div`
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 650px;
  max-width: 970px;
`

const StyledFilter = styled(Filter)`
  margin: 0 16px 16px 0 !important;
  width: 30%;
  max-width: 220px;
  flex-grow: 1;
  justify-content: space-between;
`

export const Filters = styled.div`
  position: sticky;
  top: 0;
  border-top: ${({ theme }) => `1px solid ${theme.color.outlineMid}`};
  background-color: ${({ theme }) => theme.color.onPrimaryLight};
  display: flex;
  justify-content: space-between;
  padding: 12px 36px 16px;
  z-index: 2;
`

const TableWrapper = styled.div`
  max-height: calc(100vh - 318px);
  display: flex;
  overflow: auto;

  @media screen and (max-width: 1045px) {
    max-height: calc(100vh - 346px);
  }

  @media screen and (min-width: 1046px) and (max-width: 1281px) {
    max-height: calc(100vh - 318px);
  }

  @media screen and (min-width: 1282px) and (max-width: 1392px) {
    max-height: calc(100vh - 330px);
  }
`

const StatusColumnHeaderCell: React.FC<HeaderProps<DistributorOrder>> = () => {
  const { t } = useTranslation('distributorOrder')
  return (
    <TComponents.CheckboxWrapper>
      <span>{t('list.tableHeaders.status')}</span>
    </TComponents.CheckboxWrapper>
  )
}

const StatusCell: React.FC<CellProps<DistributorOrder>> = ({ row }) => {
  const { t } = useTranslation('distributorOrder')
  return (
    <TComponents.CheckboxWrapper>
      <DistributorOrderStatusColored
        status={row.values.status}
        dangerouslySetInnerHTML={{ __html: t(`status.${row.values.status}`) }}
      />
    </TComponents.CheckboxWrapper>
  )
}

const ProducerCell: React.FC<CellProps<DistributorOrder>> = ({ cell, column }) => {
  return (
    <TComponents.BoldText key={column.id}>
      {((cell.value && cell.value.internal_name) || '').toUpperCase()}
    </TComponents.BoldText>
  )
}

const SeasonCell: React.FC<CellProps<FarmerOrder>> = ({ cell, column }) => {
  return (
    <SeasonWrapper>
      <TComponents.RegularText key={column.id}>{cell.value.title || ''}</TComponents.RegularText>
    </SeasonWrapper>
  )
}

const IdDateCell: React.FC<CellProps<DistributorOrder> & { isTimeHidden: boolean }> = ({
  cell,
  row,
  column,
  isTimeHidden,
}) => {
  const getFormatedDate = useDateFormatFn({ withTime: !isTimeHidden })

  return (
    <div key={column.id} style={{ whiteSpace: 'nowrap' }}>
      <div>{row.values.slug}</div>
      {!!cell.value && <div>{getFormatedDate(cell.value)}</div>}
    </div>
  )
}

const DistributorCell: React.FC<CellProps<DistributorOrder>> = ({ cell, column }) => {
  return <span key={column.id}>{(cell.value && cell.value.internal_name) || ''}</span>
}

const OrderNumberCell: React.FC<CellProps<DistributorOrder>> = ({ cell, column }) => {
  return (
    <div key={column.id} style={{ whiteSpace: 'nowrap' }}>
      {!!cell.value && <div>{cell.value}</div>}
    </div>
  )
}

const Crop: React.FC<CellProps<DistributorOrder>> = ({ row }) => {
  const items = row.values.items as DistributorOrderItem[]
  const { pick } = useLangPicker()

  return (
    <div>
      {items.map(
        item =>
          item.product &&
          item.product?.subcategory && <div key={item.product_id}>{pick(item.product.subcategory.title_i18n)}</div>,
      )}
    </div>
  )
}

const Qty: React.FC<CellProps<DistributorOrder>> = ({ row }) => {
  const items = row.values.items as DistributorOrderItem[]

  return (
    <div>
      {items.map((item, i) => (
        <div key={i}>{item.quantity}</div>
      ))}
    </div>
  )
}

const SeedTreatmentCell: React.FC<CellProps<FarmerOrder>> = ({ cell }) => {
  if (!cell.value) {
    return <span>{''}</span>
  }

  return (
    <div>
      {cell.value.map((item: FarmerOrderItem, i: number) => {
        const id = item.seed_treatment_id
        const seedTreatment = id ? item.product?.seed_treatment.find(el => el.id === id) : null
        return (
          <SeedTreatment key={`${item.product_id}-${i}`} title={seedTreatment?.title || '-'}>
            {seedTreatment?.title || '-'}
          </SeedTreatment>
        )
      })}
    </div>
  )
}

const ItemsCell: React.FC<CellProps<DistributorOrder>> = ({ cell }) => {
  if (!cell.value) {
    return <span>{''}</span>
  }

  return (
    <div>
      {cell.value.map((item: DistributorOrderItem, i: number) => {
        return (
          <div key={`${item.product_id}-${i}`} style={{ whiteSpace: 'nowrap' }}>{`${item.product?.title || ''}`}</div>
        )
      })}
    </div>
  )
}

const SortableHeadCell = AdvancedHeadCell<keyof DistributorOrder>()

const DistributorOrderList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation(['distributorOrder', 'labels', 'common'])
  const userCompany = useSelector(AuthSelectors.userCompany)
  const isBasicRetailer = isUsersCompanyBranchDistributor(userCompany) || isUsersCompanyBranchDistributor(userCompany)
  const checkFeatureFlag = useFeatureFlags()
  const hasAccess = useCallback(flag => !checkFeatureFlag(flag), [checkFeatureFlag])
  const isSeedTreatmentHidden = !hasAccess(
    generateCrmTableColumnAccessString(Tables.DistributorOrders, Columns.SeedTreatment, FeatureFlagModifiers.Hidden),
  )
  const isOrderNumberHidden = !checkFeatureFlag(
    generateCrmFieldAccessString(Sections.RetailerOrders, 'orderNumber', FeatureFlagModifiers.Enabled),
  )
  const isTimeHidden = !hasAccess(generateCustomFeatureFlag(Sections.Orders, CustomFeatureName.WithoutTime))

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: StatusColumnHeaderCell,
        accessor: 'status' as const,
        Cell: StatusCell,
      },
      {
        Header: t('list.tableHeaders.idAndDate'),
        accessor: 'order_date' as const,
        Cell: props => <IdDateCell isTimeHidden={isTimeHidden} {...props} />,
      },
      {
        Header: t('list.tableHeaders.orderNumber'),
        accessor: 'external_order_id' as const,
        Cell: OrderNumberCell,
        hidden: isOrderNumberHidden,
      },
      {
        Header: t('list.tableHeaders.producer'),
        accessor: 'producer' as const,
        Cell: ProducerCell,
      },
      {
        Header: t('list.tableHeaders.distributor'),
        accessor: 'distributor' as const,
        Cell: DistributorCell,
        hidden: isBasicRetailer,
      },
      {
        Header: t('list.tableHeaders.season'),
        accessor: 'season' as const,
        Cell: SeasonCell,
      },
      {
        Header: t('list.tableHeaders.crops'),
        accessor: 'items' as const,
        id: 'crops',
        Cell: Crop,
      },
      {
        Header: t('list.tableHeaders.items'),
        accessor: 'items' as const,
        id: 'items',
        Cell: ItemsCell,
      },
      {
        Header: t('list.tableHeaders.quantity'),
        accessor: 'items' as const,
        id: 'qty',
        Cell: Qty,
      },
      {
        Header: t('list.tableHeaders.seedTreatment'),
        accessor: 'items' as const,
        id: 'seed-treatment',
        Cell: SeedTreatmentCell,
        hidden: isSeedTreatmentHidden,
      },
      {
        accessor: 'region_id' as const,
        hidden: true,
      },
      {
        accessor: 'created_at' as const,
        hidden: true,
      },
      {
        accessor: 'currency' as const,
        hidden: true,
      },
      {
        accessor: 'slug' as const,
        hidden: true,
      },
    ],
    [t, isBasicRetailer, isSeedTreatmentHidden, isOrderNumberHidden],
  )

  const hiddenColumns: (keyof DistributorOrder)[] = React.useMemo(
    () => ['currency', 'region_id', 'created_at', 'slug'],
    [],
  )
  const [progress, data = []] = useDistributorOrderList()
  const { columns, rows, prepareRow } = useTable<DistributorOrder>(
    {
      columns: visibleColumns,
      data,
      initialState: { hiddenColumns },
    },
    useRowSelect,
  )

  const filterUpdated = useAction(DistributorOrderActions.filterUpdated)
  const filterCleared = useAction(DistributorOrderActions.filterHasBeenReset)
  const sortingUpdated = useAction(DistributorOrderActions.sortingUpdated)
  const listRequested = useAction(DistributorOrderActions.listRequested)
  const listUpdateRequested = useAction(DistributorOrderActions.listUpdateRequested)
  const filterValue = useSelector(DistributorOrderSelectors.filter)
  const page = useSelector(DistributorOrderSelectors.page)
  const pageSize = useSelector(DistributorOrderSelectors.pageSize)
  const pages = useSelector(DistributorOrderSelectors.pages)
  const total = useSelector(DistributorOrderSelectors.total)
  const { start_date, end_date } = filterValue
  const { sort_field, sort_reversed } = useSelector(DistributorOrderSelectors.sorting)
  const updateTimeout = useRef<number | null>(null)
  const isAdmin = useSelector(AuthSelectors.isAdmin)

  const displayCategoryFilter =
    isAdmin ||
    checkFeatureFlag([generateCustomFeatureFlag(Sections.RetailerOrders, CustomFeatureName.DisplayCategoryFilter)])

  const plantUpdateTimeout = useCallback(() => {
    updateTimeout.current = window.setTimeout(() => {
      listUpdateRequested()
      plantUpdateTimeout()
    }, LIST_UPDATE_INTERVAL)
  }, [listUpdateRequested])

  const clearUpdateTimeout = useCallback(() => {
    if (updateTimeout.current) {
      clearTimeout(updateTimeout.current)
      updateTimeout.current = null
    }
  }, [])

  usePageVisibilityChange(plantUpdateTimeout, clearUpdateTimeout)

  useEffect(() => {
    if (!updateTimeout.current) {
      plantUpdateTimeout()
    }
    return clearUpdateTimeout
  }, [clearUpdateTimeout, listUpdateRequested, plantUpdateTimeout])

  const handleFilterChange = React.useCallback(
    (newFilterValue: Partial<DistributorOrderListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const handleDateRangeChange = React.useCallback(
    ([start, end]) => {
      const parsedStartDate = parseISO(start)
      const parsedEndDate = parseISO(end)
      if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
        handleFilterChange({ start_date: start, end_date: end })
      }
    },
    [handleFilterChange],
  )

  const handleClearFilters = React.useCallback(() => {
    filterCleared()
  }, [filterCleared])

  const isFilterApplied = Object.values(filterValue).some(Boolean)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('distributorOrderList')

  const [progressXLSX, downloadXLSX] = useDownload(endpoints.distributorOrder(`download/xlsx`), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  const [progressCSV, downloadCSV] = useDownload(endpoints.distributorOrder(`download/csv`), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  const dateFormat = useDateFormat({ isYearShort: true })
  return (
    <Wrapper>
      <TComponents.Filters>
        <FiltersWrapper>
          <StyledFilter title={t('labels:period')}>
            <RangeDatePicker
              start={start_date || ''}
              end={end_date || ''}
              onChange={handleDateRangeChange}
              format={dateFormat}
            />
          </StyledFilter>
          <StyledFilter title={t('labels:status')}>
            <DistributorOrderStatusMultiSelect
              selected={filterValue.filter_by_status}
              onChange={val => handleFilterChange({ filter_by_status: val })}
            />
          </StyledFilter>
          {!isBasicRetailer && (
            <StyledFilter title={t('labels:retailer')}>
              <DistributorsMultiSelect
                onChange={val => handleFilterChange({ distributor_id: val })}
                selected={filterValue.distributor_id}
              />
            </StyledFilter>
          )}
          {displayCategoryFilter && (
            <StyledFilter title={t('labels:category')}>
              <CategorySelect
                placeholder={t('common:any')}
                onChange={val => handleFilterChange({ category_id: val ? [val] : [], subcategory_id: [] })}
                value={filterValue.category_id?.[0]}
                variant="small"
                isClearable
              />
            </StyledFilter>
          )}
          {displayCategoryFilter && (
            <StyledFilter title={t('labels:subcategory')}>
              <SubcategorySelect
                placeholder={t('common:any')}
                filter={{ parent_id: filterValue.category_id?.[0] }}
                onChange={val => handleFilterChange({ subcategory_id: val ? [val] : [] })}
                value={filterValue.subcategory_id?.[0]}
                variant="small"
                isClearable
              />
            </StyledFilter>
          )}
          <StyledFilter title={t('labels:season')}>
            <SeasonSelect
              variant="small"
              value={filterValue.season_id}
              onChange={val => handleFilterChange({ season_id: val })}
              isClearable
            />
          </StyledFilter>
        </FiltersWrapper>
        <AlignWrapper verticalAlign="bottom" horizontalAlign="right" gap="8px">
          <Button onClick={downloadXLSX} intent="primary" filled progress={progressXLSX}>
            {t('labels:downloadXlsx')}
          </Button>
          <Button onClick={downloadCSV} intent="primary" filled progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </AlignWrapper>
      </TComponents.Filters>
      <TableWrapper>
        <Table
          total={total}
          pages={pages}
          pageSize={pageSize}
          currentPage={page}
          onSetPage={fetchNextItems}
          ref={scrollRef}
        >
          <TableHead>
            <TableHeadRow>
              {columns.map(column => {
                return (
                  <SortableHeadCell
                    key={column.getHeaderProps().key}
                    id={column.id as keyof DistributorOrder}
                    sortable={column.sortable}
                    hidden={column.hidden}
                    sortField={sort_field}
                    sortDesc={sort_reversed}
                    onChange={sortingUpdated}
                    width={column.width}
                  >
                    {column.render('Header')}
                  </SortableHeadCell>
                )
              })}
            </TableHeadRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              prepareRow(row)
              const { key, ...props } = row.getRowProps()
              return (
                <TableBodyRow
                  key={key}
                  {...props}
                  selected={row.isSelected}
                  onClick={() => {
                    history.push(generatePath(Routes.Edit, { id: row.original.id.toString() }))
                  }}
                >
                  {row.cells.map(cell => {
                    const { key, ...props } = cell.getCellProps()

                    if (cell.column.hidden) {
                      return null
                    }

                    return (
                      <TableBodyCell key={key} {...props} stopClickPropagation={cell.column.id === 'status'}>
                        {cell.render('Cell')}
                      </TableBodyCell>
                    )
                  })}
                </TableBodyRow>
              )
            })}
            <TableNoData
              progress={progress}
              isEmpty={!rows.length}
              colSpan={visibleColumns.length}
              loading={<TComponents.Spinner />}
            >
              <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
              {isFilterApplied && progress !== Progress.WORK && (
                <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={handleClearFilters}>
                  {t('list.resetAllFilters')}
                </TComponents.ClearButton>
              )}
            </TableNoData>
          </TableBody>
        </Table>
      </TableWrapper>
    </Wrapper>
  )
}

export default DistributorOrderList
