import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import FarmerOrderList from './FarmerOrderList/FarmerOrderList'
import FarmerOrderActions from 'modules/domain/farmerOrder/duck'
import FarmerOrderRoutes from 'views/pages/FarmerOrder/routes'
import { usePermissions, CAPABILITY, PERMISSION } from 'modules/permissions/permissions'
import { AddButton, AdvancedSearchField, useAction, useHelmet } from '@agro-club/frontend-shared'
import { FarmerOrderListSearchOption } from 'modules/domain/farmerOrder/types'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Actions, FeatureFlagModifiers, Filters, isDistributor, Sections } from 'types/entities'
import SeasonSelectors from 'modules/domain/season/selectors'
import { useSeasonList } from 'modules/domain/season/hooks'
import { Progress } from 'modules/types'
import { SpinnerWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { generateActionAccessString, generateCrmFilterAccessString } from 'modules/utils/generateStringHelpers'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'

const SearchInputWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 4px;
  width: 50%;
`

const DEFAULT_SEARCH_OPTION = 'slug'

const FarmerOrderTable: React.FC = () => {
  const filterUpdated = useAction(FarmerOrderActions.filterUpdated)
  const { t } = useTranslation(['farmerOrder', 'common'])
  const checkFeatureFlag = useFeatureFlags()
  const prevOption = useRef(DEFAULT_SEARCH_OPTION)
  const role = useSelector(AuthSelectors.role)
  const [seasonsProgress] = useSeasonList()
  const isHouseholdFilterOptionHidden = checkFeatureFlag(
    generateCrmFilterAccessString(Sections.FarmerOrders, Filters.Household, FeatureFlagModifiers.Hidden),
  )

  const handleSearchChange = React.useCallback(
    (search: string, option: string) => {
      if (!(option !== prevOption.current && !search)) {
        filterUpdated({ search: search ? search : undefined, search_by: option as FarmerOrderListSearchOption })
      }
      prevOption.current = option
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterUpdated],
  )

  useHelmet({ title: t('ordersMetaTitle') })

  const searchOptions: Array<{ id: FarmerOrderListSearchOption; title: string; placeholder: string }> = useMemo(() => {
    const arr: Array<FarmerOrderListSearchOption> = ['slug', 'user', 'distributor', 'producer', 'product']

    if (!isHouseholdFilterOptionHidden) arr.push('household')

    return arr.map(item => ({
      id: item,
      title: t(`list.searchOptions.${item}.title`),
      placeholder: t(`list.searchOptions.${item}.placeholder`),
    }))
  }, [t, isHouseholdFilterOptionHidden])

  const currentSeason = useSelector(SeasonSelectors.currentSeason)

  const isCreatePermitted =
    usePermissions({ capability: CAPABILITY.ORDERS, permission: PERMISSION.C }) &&
    ((isDistributor(role) && !currentSeason?.stop_taking_orders) || !isDistributor(role)) &&
    !checkFeatureFlag(
      generateActionAccessString(Sections.FarmerOrders, Actions.Create, FeatureFlagModifiers.Restricted),
    )

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')}>
            {isCreatePermitted && (
              <AddButton data-test-id={'button-add'} to={FarmerOrderRoutes.Add}>
                {t('common:addNew')}
              </AddButton>
            )}
          </Header.Title>
          <SearchInputWrapper>
            <AdvancedSearchField
              options={searchOptions}
              onChange={handleSearchChange}
              defaultOption={DEFAULT_SEARCH_OPTION}
            />
          </SearchInputWrapper>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        {seasonsProgress === Progress.SUCCESS ? (
          <FarmerOrderList />
        ) : (
          <SpinnerWrapper>
            <SpinnerLayout />
          </SpinnerWrapper>
        )}
      </Layout.Content>
    </>
  )
}

export default FarmerOrderTable
