import React from 'react'

import { Switch, Route } from 'react-router-dom'
import MailingListRoutes from './routes'
import MailingListList from './MailingListList'
import MailingListItem from './MailingListItem'

const MailingListPage: React.FC = () => {
  return (
    <Switch>
      <Route path={MailingListRoutes.List} exact>
        <MailingListList />
      </Route>
      <Route path={MailingListRoutes.Item} exact>
        <MailingListItem />
      </Route>
    </Switch>
  )
}

export default MailingListPage
