import React, { useCallback, useMemo } from 'react'
import useLangPicker from 'hooks/useLangPicker'
import {
  ProductOptionsData,
  ProductOptionsGrouped,
  ProductOptions as ProductOptionsType,
} from 'modules/domain/productOptions/types'
import { useTranslation } from 'react-i18next'
import { SimpleSelect } from '@agro-club/frontend-shared'

const ProductOptionsComponent: React.FC<{
  options: ProductOptionsData[]
  selectedIds?: string[]
  optionsRequiredErrors: string[]
  onChange: (value: string[]) => void
  productOptions: ProductOptionsType[]
}> = ({ options, selectedIds = [], optionsRequiredErrors = [], onChange, productOptions }) => {
  const { t } = useTranslation('productOptions')
  const { pick } = useLangPicker()

  const selects = useMemo(() => {
    const groupes: ProductOptionsGrouped[] = []
    if (!options) return []

    options.map(option => {
      const data = option.option_ids.map(id => {
        const found = productOptions?.find(item => item.id === id)
        return { id, title: pick(found?.title_i18n) as string }
      })
      let selectedId: string | undefined = undefined

      if (selectedIds?.length) {
        selectedIds?.forEach(id => {
          if (option.option_ids.includes(id)) selectedId = id
        })
      }

      groupes.push({ type: option.type, required: option.required, data, selectedId })
    })

    return groupes
  }, [options, pick, productOptions, selectedIds])

  const handleChange = useCallback(
    (prevId?: string, id?: string) => {
      const updatedIds = selectedIds?.filter(id => id !== prevId)
      updatedIds?.push(id as string)
      onChange(updatedIds as string[])
    },
    [onChange, selectedIds],
  )

  return (
    <>
      {selects.map((select, idx: number) => (
        <div
          data-test-id={`product-option-select-${idx}`}
          key={idx}
          style={{ marginBottom: optionsRequiredErrors.includes(select.type) ? '32px' : 'initial' }}
        >
          <SimpleSelect
            onChange={id => handleChange(select.selectedId, id as string)}
            label={t(`types.${select.type}`)}
            placeholder={`${t('placeholderPrefix')} ${t(`types.${select.type}`).toLowerCase()}`}
            options={select.data}
            value={select.selectedId}
            isSearchable
            minWidth="150px"
            variant="small"
            menuWidth="300px"
            menuMaxWidth="300px"
            required={select.required}
            invalid={!!optionsRequiredErrors.length}
            errorText={optionsRequiredErrors.includes(select.type) ? t('validation:field_required') : ''}
          />
        </div>
      ))}
    </>
  )
}

export default ProductOptionsComponent
