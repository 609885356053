import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Button,
  DatePicker,
  Input,
  TextArea,
  SectionBody,
  SectionContainer,
  SectionTitle,
  useAction,
  useFormManager,
  SimpleSelect,
  FormComponents,
} from '@agro-club/frontend-shared'
import { ReturnDeclaration, ReturnDeclarationItem, ReturnDeclarationStatus } from 'types/returnDeclaration'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import i18n from 'i18n'
import { Progress } from 'modules/types'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'
import {
  Actions,
  CompanyType,
  isDistributor,
  isProducersHead,
  isUsersCompanyDistributor,
  isUsersCompanyHeadDistributor,
  isUsersCompanyProducer,
  Sections,
  FeatureFlagModifiers,
} from 'types/entities'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import CompanyInfoPreview from '../CompanyInfoPreview/CompanyInfoPreview'
import { Company } from 'modules/domain/company/types'
import CompanyRoutes from 'views/pages/Company/routes'
import { useCompanyById } from 'modules/domain/company/hooks'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import ReturnItems, { ReturnItemsFormProps } from '../OrderProductItems/ReturnItems'
import ReturnDeclarationActions from 'modules/domain/returnDeclaration/duck'
import ReturnDeclarationSelectors from 'modules/domain/returnDeclaration/selectors'
import ReturnItemsReadyOnly from '../OrderProductItems/ReturnItemsReadOnly'
import { useSeasonList } from 'modules/domain/season/hooks'
import StickyFooterDeleteBtn from '../StickyFormControls/StickyFooterDeleteBtn'
import { useReturnDeclarationCounter } from 'modules/domain/returnDeclaration/hooks'
import { ReturnDeclarationType } from 'modules/domain/returnDeclaration/types'
import { useConfig } from 'modules/domain/config/hooks'
import SeasonSelectors from 'modules/domain/season/selectors'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import useDateFormat from 'hooks/useDateFormat'
import { ConfirmPopupModal } from './ConfirmPopupModal'
import * as Styled from './styled'
import { generateActionAccessString, generateCrmSectionAccessString } from 'modules/utils/generateStringHelpers'
import { CompanySelect } from '../CompanySelect/CompanySelect'
import { SeasonSelect } from '../SeasonSelect/SeasonSelect'
import { PreviewContainer } from '../PreviewContainer/PreviewContainer'
import ProgressContainer from '../ProgressContainer/ProgressContainer'
import { RETURN_DECLARATION_DATE } from 'modules/constants'

type ConfirmPopupPropsType = {
  handleCloseModal: () => void
  handleSubmit?: () => void
  isOpen: boolean
  error: string
}

const ConfirmPopup: FC<ConfirmPopupPropsType> = ({
  handleCloseModal,
  handleSubmit,
  isOpen,
  error,
}: ConfirmPopupPropsType) => {
  const { t } = useTranslation('returnDeclaration')

  if (error) {
    return (
      <ConfirmPopupModal handleCloseModal={handleCloseModal} isOpen={isOpen} wrapperText={t('modal.error')}>
        <Styled.ModalButton intent={'primary'} filled onClick={handleCloseModal} data-test-id={'modal-error-button'}>
          {t('modal.ok')}
        </Styled.ModalButton>
      </ConfirmPopupModal>
    )
  } else {
    return (
      <ConfirmPopupModal handleCloseModal={handleCloseModal} isOpen={isOpen} wrapperText={t('modal.confirm')}>
        <Styled.ModalButton intent={'cancel'} filled onClick={handleCloseModal}>
          {t('modal.cancel')}
        </Styled.ModalButton>
        <Styled.ModalButton
          data-test-id={'modal-submit-button'}
          intent={'primary'}
          filled
          onClick={() => {
            handleSubmit?.()
            handleCloseModal()
          }}
        >
          {t('modal.submit')}
        </Styled.ModalButton>
      </ConfirmPopupModal>
    )
  }
}

type InformationPopupPropsType = {
  handleCloseModal: () => void
  seasonName: string
  isOpen: boolean
}

const InformationPopup: FC<InformationPopupPropsType> = ({ seasonName, handleCloseModal, isOpen }) => {
  const { t } = useTranslation('returnDeclaration')
  const [, config] = useConfig()

  const phone = config?.contacts?.phone?.value
  const messageText = t('modal.limited', {
    phoneLink: `<a href="tel:${phone}" target=_blank>${phone}</a>`,
    seasonName,
  })

  return (
    <ConfirmPopupModal handleCloseModal={handleCloseModal} isOpen={isOpen} wrapperText={messageText}>
      <Styled.ModalButton intent={'primary'} filled onClick={handleCloseModal} data-test-id={'modal-ok-button'}>
        {t('modal.ok')}
      </Styled.ModalButton>
    </ConfirmPopupModal>
  )
}

export type FormFields = {
  distributor_id: string
  status: ReturnDeclarationStatus
  items: ReturnDeclarationItem[]
  type?: ReturnDeclarationType
  pickup_note?: string
  declaration_date?: string
  season_id?: string
  comment?: string
  producer_id?: string | null
}

type FormManagerProps = {
  main: FormFields
  items: ReturnItemsFormProps
}

type Props = {
  mode: 'edit' | 'create'
  onSubmit(values: FormFields, options: { dirty: boolean; duplicate: boolean }): void
  onChangeStatusToSubmit?(): void
  onCancel(): void
  onProducerConfirmation?: () => void
  onCancelDeclaration?: () => void
  onRemove?: () => void
  progress?: Progress
  removeProgress?: Progress
  initialValues: FormFields
  returnDeclaration?: ReturnDeclaration
}

const ReturnDeclarationDetailsForm: React.FC<Props> = ({
  returnDeclaration,
  initialValues,
  onRemove,
  onChangeStatusToSubmit,
  onCancel,
  onSubmit,
  removeProgress,
  progress,
  mode,
}) => {
  const checkFeatureFlag = useFeatureFlags()
  const { t } = useTranslation(['returnDeclaration', 'common', 'company', 'labels'])
  const [isRemovePermitted, isUpdatePermitted] = usePermissions([
    { capability: CAPABILITY.RETURN_DECLARATIONS, permission: PERMISSION.D },
    { capability: CAPABILITY.RETURN_DECLARATIONS, permission: PERMISSION.U },
  ])
  const dateFormat = useDateFormat({ withTime: true })

  const role = useSelector(AuthSelectors.role)
  const isAdmin = useSelector(AuthSelectors.isAdmin)
  const userCompany = useSelector(AuthSelectors.userCompany)
  const isEditRestricted = checkFeatureFlag(
    generateActionAccessString(Sections.ReturnDeclarations, Actions.Update, FeatureFlagModifiers.Restricted),
  )

  const headCompanyFetched = useRef(false)
  const [, seasons] = useSeasonList()
  const currentSeason = useSelector(SeasonSelectors.currentSeason)
  const seasonId = isDistributor(role) ? currentSeason?.id : initialValues.season_id

  const [selectedDistributor, setSelectedDistributor] = useState<Company | null>(
    returnDeclaration?.distributor || (isUsersCompanyDistributor(userCompany) && userCompany) || null,
  )

  const [headCompanyProgress, headCompany] = useCompanyById(selectedDistributor?.head_company_relation?.company_id)
  const [headDistributor, setHeadDistributor] = useState<Company | null>(headCompany || null)

  const [isOpen, setIsOpen] = useState(false)
  const [showInformationPopup, setShowInformationPopup] = useState(false)

  useEffect(() => {
    if (!headCompanyFetched.current && headCompany) {
      setHeadDistributor(headCompany)
      headCompanyFetched.current = true
    }
  }, [headCompany])

  const initialProducerId =
    initialValues.producer_id ||
    (isUsersCompanyProducer(userCompany) && userCompany?.id) ||
    (isUsersCompanyDistributor(userCompany) &&
      userCompany?.producers_relations?.length === 1 &&
      userCompany.producers_relations[0]) ||
    undefined

  const currentDate = useMemo(() => {
    return new Date().toISOString()
  }, [])

  const printReturnDeclarationAction = useAction(ReturnDeclarationActions.printRequested)
  const printDeclarationProgress = useSelector(ReturnDeclarationSelectors.printProgress)

  const validationSchema = useMemo(() => {
    return Yup.object({
      distributor_id: Yup.string().required(i18n.t('validation:field_required')),
      producer_id: Yup.string().required(i18n.t('validation:field_required')),
      declaration_date: Yup.string().required(i18n.t('validation:field_required')),
      status: Yup.string(),
      comment: Yup.string(),
      season_id: Yup.string().required(i18n.t('validation:field_required')),
      type: Yup.string().required(i18n.t('validation:field_required')),
    })
  }, [])

  const { register, bind, dirty, submitAll, slots } = useFormManager<FormManagerProps>()

  const formik = useFormik<FormFields>({
    initialValues: {
      producer_id: initialProducerId,
      distributor_id: initialValues.distributor_id || (isUsersCompanyDistributor(userCompany) && userCompany?.id) || '',
      status: initialValues.status,
      comment: initialValues.comment || '',
      pickup_note: initialValues.pickup_note,
      declaration_date: initialValues.declaration_date || currentDate,
      items: initialValues?.items || [],
      season_id: seasonId,
      type: initialValues.type,
    },
    validationSchema,
    enableReinitialize: true,
    // used custom submit handler
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })
  register('main', formik)

  const [, producer] = useCompanyById(formik.values.producer_id as string)

  const seasonName = useMemo(() => {
    let name = ''
    seasons?.map(season => {
      if (season.id === formik.values.season_id) name = season.title
    })
    return name
  }, [formik.values.season_id, seasons])

  const { error, count } = useReturnDeclarationCounter({
    distributor_id: userCompany?.id,
    season_id: formik.values.season_id,
    type: formik.values.type,
    status: 'submitted',
  })

  const handlePrintDeclaration = async () => {
    const [valid, values] = await submitAll()
    if (!valid) {
      return
    }
    printReturnDeclarationAction({
      pickUpLocation: values.main.pickup_note || '',
      dealerName: selectedDistributor?.official_name || '',
      date: RETURN_DECLARATION_DATE[producer?.slug || ''],
      products: (values.items?.items || initialValues.items || []).map(item => {
        const seedTreatment = item.product.seed_treatment.find(st => st.id === item.seed_treatment_id)

        return {
          variety: item.product.title,
          seedTreatment: seedTreatment?.title || '',
          lot: item.lot_number || '',
          count: item.quantity || 0,
          unitSize: `${
            item.packaging ? `${item.product.alt_packaging} ${item.packaging}` : item.product.default_packaging
          }`,
        }
      }),
    })
  }

  const shouldShowConfirmSubmit = !!(isDistributor(role) && userCompany)

  const handleChangeStatusToSubmit = async () => {
    const [valid] = await submitAll()
    if (!valid) return
    if (count && count > 0 && !isAdmin) return setShowInformationPopup(true)
    if (shouldShowConfirmSubmit) return setIsOpen(true)
    onChangeStatusToSubmit?.()
  }

  const submit = async (duplicate = false) => {
    try {
      const [valid, forms] = await submitAll()
      if (!valid) {
        return
      }
      onSubmit(
        {
          ...forms.main,
          ...forms.items,
        },
        {
          dirty: dirty,
          duplicate,
        },
      )
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const handleSubmit = async () => {
    try {
      if (count && count > 0 && !isAdmin) {
        setShowInformationPopup(true)
        return
      }

      const [valid] = await submitAll()
      if (!valid) {
        return
      }
      submit(false)
    } catch (e) {
      console.error(e)
    }
  }

  const handleNothingToReturn = () => {
    if (formik.values.status === ReturnDeclarationStatus.NothingToDeclare) {
      formik.setFieldValue('status', ReturnDeclarationStatus.New)
    } else {
      slots['items'].setFieldValue('items', [])
      formik.setFieldValue('status', ReturnDeclarationStatus.NothingToDeclare)
    }
  }

  const isSubmitted = returnDeclaration?.status === ReturnDeclarationStatus.Submitted
  const isProducerChangeAllowed = !returnDeclaration
  const isDistributorChangeAllowed =
    isAdmin ||
    isProducersHead(role) ||
    (isUsersCompanyHeadDistributor(userCompany) &&
      (!returnDeclaration || returnDeclaration?.status === ReturnDeclarationStatus.New))

  const onDistributorChange = useCallback(
    (value?: string, distributor?: Company | null, headDistributor?: Company | null) => {
      formik.setFieldValue('distributor_id', value)
      setSelectedDistributor(distributor || null)
      setHeadDistributor(headDistributor || null)
    },
    [formik],
  )

  const onProducerChange = useCallback(
    (id?: string) => {
      formik.setFieldValue('producer_id', id)
      const producerSeasons = (seasons ?? []).filter(season => season.company_id === id)
      const producerCurrentSeason = producerSeasons?.find(season => season.is_current)
      const producerPreviousSeason = producerSeasons?.find(
        season => season.end_date === producerCurrentSeason?.start_date,
      )
      const seasonId = producerPreviousSeason?.id || producerCurrentSeason?.id

      formik.setFieldValue('season_id', seasonId)
    },
    [seasons, formik],
  )

  const declarationTypeOptions = useMemo(() => {
    return [
      {
        id: ReturnDeclarationType.EndOfSeason,
        title: t('type.endOfSeason'),
      },
      {
        id: ReturnDeclarationType.InSeason,
        title: t('type.inSeason'),
      },
    ]
  }, [t])

  useValidationErrorNotification(formik.submitCount, formik.isValid)
  const isSubmitPermitted =
    !!returnDeclaration && returnDeclaration.status === ReturnDeclarationStatus.New && (isDistributor(role) || isAdmin)

  const isReturnNothingPermitted = !!(
    !isEditRestricted &&
    !returnDeclaration &&
    formik.values.producer_id &&
    formik.values.distributor_id &&
    (isDistributor(role) || isAdmin)
  )

  return (
    <>
      <StickyFooterLayout.Wrapper>
        <StickyFooterLayout.Body>
          <Styled.Wrapper>
            <Styled.Column>
              <SectionContainer>
                <SectionBody>
                  <FormComponents.FormSection title={t('form.sectionHeaders.producer')}>
                    {isProducerChangeAllowed ? (
                      <CompanySelect
                        label={t('form.labels.producer')}
                        onChange={onProducerChange}
                        value={formik.values.producer_id as string}
                        companyType={CompanyType.Producer}
                        isClearable
                        isSearchable
                        invalid={formik.touched.producer_id && !!formik.errors.producer_id}
                        errorText={formik.errors.producer_id}
                        required
                        isDisabled={
                          isEditRestricted || (!!userCompany && userCompany.company_type === CompanyType.Producer)
                        }
                        filter={{
                          feature_flags: generateCrmSectionAccessString(
                            Sections.ReturnDeclarations,
                            FeatureFlagModifiers.Enabled,
                          ),
                        }}
                      />
                    ) : (
                      <CompanyInfoPreview
                        company={returnDeclaration?.producer}
                        footer={`ID ${returnDeclaration?.producer?.id || ''}`}
                        link={
                          isAdmin && returnDeclaration && returnDeclaration.producer
                            ? generatePath(CompanyRoutes.Edit, { id: returnDeclaration.producer?.id })
                            : undefined
                        }
                      />
                    )}
                    <Styled.SeasonWrap
                      disabled={(isDistributor(role) && isSubmitted) || (isDistributor(role) && mode === 'edit')}
                    >
                      <SeasonSelect
                        companyId={formik.values.producer_id as string}
                        label={t('form.sectionHeaders.season')}
                        placeholder={t('form.placeholders.season')}
                        value={formik.values.season_id}
                        onChange={val => formik.setFieldValue('season_id', val)}
                        isDisabled={isEditRestricted || isDistributor(role)}
                        invalid={formik.touched.season_id && !!formik.errors.season_id}
                        errorText={formik.errors.season_id}
                        required
                      />
                    </Styled.SeasonWrap>
                    <Styled.DeclarationTypeWrap data-test-id={'declaration-type-select'} disabled={isDistributor(role)}>
                      <SimpleSelect
                        label={t('form.sectionHeaders.type')}
                        placeholder={t('form.placeholders.type')}
                        value={formik.values.type}
                        options={declarationTypeOptions}
                        onChange={val => formik.setFieldValue('type', val)}
                        isDisabled={isEditRestricted || isDistributor(role)}
                        invalid={formik.touched.type && !!formik.errors.type}
                        errorText={formik.errors.type}
                        required
                      />
                    </Styled.DeclarationTypeWrap>
                    {!!selectedDistributor && selectedDistributor.head_company_relation && (
                      <FormComponents.FormSection title={t('form.sectionHeaders.billingCompany')}>
                        <PreviewContainer.WrapperCompact>
                          <ProgressContainer
                            progress={headCompanyProgress}
                            errorMessage={t('errors.headDistributorFetchError')}
                          >
                            <PreviewContainer.Header>{headDistributor?.internal_name || ''}</PreviewContainer.Header>
                            <PreviewContainer.Subheader>
                              {headDistributor?.short_description || ''}
                            </PreviewContainer.Subheader>
                          </ProgressContainer>
                        </PreviewContainer.WrapperCompact>
                      </FormComponents.FormSection>
                    )}
                    {isDistributorChangeAllowed ? (
                      <CompanySelect
                        label={t('form.sectionHeaders.distributor')}
                        onChange={onDistributorChange}
                        onMenuClose={() => formik.setFieldTouched('distributor_id')}
                        value={formik.values.distributor_id}
                        isDisabled={!formik.values.producer_id}
                        companyType={CompanyType.Distributor}
                        filter={{ for_producer_id: formik.values.producer_id }}
                        invalid={formik.touched.distributor_id && !!formik.errors.distributor_id}
                        errorText={formik.errors.distributor_id}
                        isClearable
                        required
                      />
                    ) : (
                      <FormComponents.FormSection title={t('form.sectionHeaders.distributor')}>
                        <CompanyInfoPreview company={selectedDistributor} />
                      </FormComponents.FormSection>
                    )}
                    <Input
                      label={t('form.sectionHeaders.pickUp')}
                      {...formik.getFieldProps('pickup_note')}
                      disabled={
                        isEditRestricted ||
                        !isUpdatePermitted ||
                        (isDistributor(role) && isSubmitted) ||
                        formik.values.status === ReturnDeclarationStatus.NothingToDeclare
                      }
                    />
                    <Styled.DatePickerWrapper data-test-id={'declaration-date'}>
                      <DatePicker
                        onChange={value => {
                          formik.setFieldValue('declaration_date', value)
                          formik.setFieldTouched('declaration_date')
                        }}
                        date={formik.getFieldProps('declaration_date').value}
                        invalid={formik.touched.declaration_date && !!formik.errors.declaration_date}
                        errorText={formik.errors.declaration_date}
                        label={t('form.labels.date')}
                        disabled={isEditRestricted || !isAdmin}
                        required
                        format={dateFormat}
                      />
                    </Styled.DatePickerWrapper>
                  </FormComponents.FormSection>
                </SectionBody>
              </SectionContainer>
            </Styled.Column>
            <Styled.Column>
              {isEditRestricted ||
              (isDistributor(role) && returnDeclaration?.status === ReturnDeclarationStatus.Submitted) ? (
                <ReturnItemsReadyOnly items={initialValues.items}>
                  <Styled.ButtonsRow>
                    <Styled.PrintButton
                      intent={'primary'}
                      progress={printDeclarationProgress}
                      filled={true}
                      onClick={() => handlePrintDeclaration()}
                    >
                      {t('form.labels.download')}
                    </Styled.PrintButton>
                  </Styled.ButtonsRow>
                </ReturnItemsReadyOnly>
              ) : (
                <ReturnItems
                  useFormik={bind('items')}
                  isAllowed={!!formik.values.producer_id}
                  nothingToReturn={formik.values.status === ReturnDeclarationStatus.NothingToDeclare}
                  items={initialValues.items}
                  producerId={formik.values.producer_id || undefined}
                >
                  <Styled.ButtonsRow>
                    <Button intent={'warning'} onClick={handleNothingToReturn} disabled={!isReturnNothingPermitted}>
                      {t('form.labels.nothingToReturn')}
                    </Button>
                    <Styled.PrintButton
                      intent={'primary'}
                      progress={printDeclarationProgress}
                      filled={true}
                      onClick={() => handlePrintDeclaration()}
                    >
                      {t('form.labels.download')}
                    </Styled.PrintButton>
                  </Styled.ButtonsRow>
                </ReturnItems>
              )}

              <SectionContainer>
                <SectionTitle>{t('form.sectionHeaders.comment')}</SectionTitle>
                <SectionBody>
                  <TextArea
                    {...formik.getFieldProps('comment')}
                    placeholder={t('form.placeholders.comment')}
                    disabled={isEditRestricted || !isUpdatePermitted || (isDistributor(role) && isSubmitted)}
                    data-test-id={'comment-textarea'}
                  />
                </SectionBody>
              </SectionContainer>
            </Styled.Column>
          </Styled.Wrapper>
        </StickyFooterLayout.Body>
        <StickyFooterLayout.ButtonsFooter>
          <Button
            intent={'primary'}
            filled
            disabled={progress === Progress.WORK || isEditRestricted || !dirty}
            onClick={handleSubmit}
            progress={progress}
            data-test-id={'save-button'}
          >
            {t('common:save')}
          </Button>
          <Button intent={'cancel'} onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          {isSubmitPermitted && (
            <Button
              data-test-id={'submit-button'}
              intent={'action'}
              filled
              onClick={handleChangeStatusToSubmit}
              progress={progress}
              disabled={returnDeclaration?.status !== ReturnDeclarationStatus.New}
            >
              {t('common:submit')}
            </Button>
          )}

          {!!onRemove && isRemovePermitted && !!returnDeclaration && (
            <StickyFooterDeleteBtn
              onRemove={onRemove}
              removeProgress={removeProgress}
              popoverText={t('form.removeText', { id: returnDeclaration.slug })}
            />
          )}
        </StickyFooterLayout.ButtonsFooter>
      </StickyFooterLayout.Wrapper>
      {shouldShowConfirmSubmit && isOpen && (
        <ConfirmPopup
          error={error}
          handleCloseModal={() => setIsOpen(false)}
          handleSubmit={onChangeStatusToSubmit}
          isOpen={isOpen}
        />
      )}
      {showInformationPopup && (
        <InformationPopup
          seasonName={seasonName}
          handleCloseModal={() => setShowInformationPopup(false)}
          isOpen={showInformationPopup}
        />
      )}
    </>
  )
}

export default ReturnDeclarationDetailsForm
