import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import FccOrderRoutes from './routes'
import FccOrderTable from 'views/pages/FccOrder/FccOrderTable'

const FarmerOrderPage: React.FC = () => {
  return (
    <Switch>
      <Route path={FccOrderRoutes.List} exact={true}>
        <FccOrderTable />
      </Route>
      <Redirect to={FccOrderRoutes.List} />
    </Switch>
  )
}

export default FarmerOrderPage
