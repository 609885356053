import React from 'react'
import styled from 'styled-components'
import { FormikState, FormikHelpers, FormikHandlers } from 'formik'
import { useOrderUpdatePermissions } from 'modules/permissions/permissions'
import { useTranslation } from 'react-i18next'
import { DatePicker, GridArea, Input } from '@agro-club/frontend-shared'
import { FormFields } from 'views/components/FarmerOrderDetailsForm/types'
import { FormFields as FormFieldsSku } from 'views/pages/FarmerOrderSku/FarmerOrderSkuDetailsForm'
import { CountrySelect } from 'views/components/CountrySelect/CountrySelect'
import { useDetectedCountry } from 'hooks/useDetectedCountry'
import useDateFormat from 'hooks/useDateFormat'
import { RegionsSelect } from '../RegionsSelect/RegionsSelect'

const Area = GridArea.ofType<'address' | 'city' | 'province' | 'country' | 'zip' | 'date' | 'desired_delivery_date'>()
const AddressContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'address address'
    'country province'
    'city zip'
    'desired_delivery_date desired_delivery_date'
    'date date';
`

type FormikType =
  | (FormikHandlers & FormikHelpers<FormFields> & FormikState<FormFields>)
  | (FormikHandlers & FormikHelpers<FormFieldsSku> & FormikState<FormFieldsSku>)

const FarmerAddressForm: React.FC<{
  formik: FormikType
  isDesiredDeliveryDateEnabled?: boolean
  isEditable?: boolean
}> = ({ formik, isDesiredDeliveryDateEnabled, isEditable = true }) => {
  const updatePermissions = useOrderUpdatePermissions()
  const { t } = useTranslation(['farmerOrder'])

  const defaultCountry = useDetectedCountry()
  const dateFormat = useDateFormat({ withTime: true })

  const isFieldsDisabled = !updatePermissions.deliveryDetails || !isEditable

  return (
    <AddressContainer>
      <Area area={'address'}>
        <Input
          {...formik.getFieldProps('address')}
          label={t('form.labels.address')}
          invalid={formik.touched.address && !!formik.errors.address}
          errorText={formik.errors.address as string}
          disabled={isFieldsDisabled}
        />
      </Area>
      <Area area={'city'}>
        <Input
          {...formik.getFieldProps('city')}
          label={t('form.labels.city')}
          invalid={formik.touched.city && !!formik.errors.city}
          errorText={formik.errors.city as string}
          disabled={isFieldsDisabled}
        />
      </Area>
      <Area area={'province'}>
        <RegionsSelect
          country={formik.values?.country || defaultCountry}
          value={formik.values.region_id}
          onChange={(id, targetProvince) => {
            formik.setFieldValue('region_id', id)
            targetProvince && formik.setFieldValue('state', targetProvince.title)
          }}
          label={t('form.labels.province')}
          invalid={formik.touched.region_id && !!formik.errors.region_id}
          errorText={formik.errors.region_id as string}
          isDisabled={isFieldsDisabled}
          isSearchable
          placeholder={t('form.placeholders.province')}
        />
      </Area>
      <Area area={'country'}>
        <CountrySelect
          label={t('form.labels.country')}
          value={formik.values.country}
          onChange={value => {
            formik.setFieldValue('country', value)
            formik.setFieldValue('region_id', undefined)
          }}
          invalid={formik.touched.country && !!formik.errors.country}
          errorText={formik.errors.country}
          onMenuClose={() => {
            formik.setFieldTouched('country')
          }}
          isDisabled={isFieldsDisabled}
        />
      </Area>
      <Area area={'zip'}>
        <Input
          {...formik.getFieldProps('postal_code')}
          label={t('form.labels.zip')}
          invalid={formik.touched.postal_code && !!formik.errors.postal_code}
          errorText={formik.errors.postal_code as string}
          disabled={isFieldsDisabled}
        />
      </Area>
      {isDesiredDeliveryDateEnabled && (
        <Area area={'desired_delivery_date'}>
          <div data-test-id={'desired-delivery-date'}>
            <DatePicker
              label={t('form.labels.desiredDeliveryDate')}
              date={formik.values.desired_delivery_date}
              onChange={value => formik.setFieldValue('desired_delivery_date', value)}
              disabled={isFieldsDisabled}
              format={dateFormat}
            />
          </div>
        </Area>
      )}
      <Area area={'date'}>
        <DatePicker
          label={t('form.labels.date')}
          date={formik.values.delivery_date}
          onChange={value => formik.setFieldValue('delivery_date', value)}
          disabled={isFieldsDisabled}
          format={dateFormat}
        />
      </Area>
    </AddressContainer>
  )
}

export default FarmerAddressForm
