import { Dict } from 'types/generics'
import { Progress } from 'modules/types'
import { CountryCode } from 'libphonenumber-js'
import { AddError, EntityMetadata, FetchError, RemoveError, UpdateError } from 'modules/domain/types'

export type MailingListState = {
  items: Dict<MailingListEntry>
  meta: Dict<EntityMetadata<MailingListEntry>>
  ids: string[]

  listFetchProgress: Progress
  listFetchError: FetchError | null
  listFetchNextProgress: Progress
  listFetchNextError: FetchError | null
  itemFetchProgress: Progress
  itemFetchError: FetchError | null
  addProgress: Progress
  addError: AddError | null
  updateProgress: Progress
  updateError: UpdateError | null
  removeProgress: Progress
  removeError: RemoveError | null

  filter: MailingListListRequestFilter
  sorting: MailingListListRequestSorting
  page: number
  total: number
  pageSize: number
}

export enum MailingListEntryStatus {
  ACTIVE = 'active',
  UNSUBSCRIBED = 'unsubscribed',
}

export type MailingListEntry = {
  id: string
  email: string
  status: MailingListEntryStatus
  region?: CountryCode
  created_at: string
  updated_at: string
}

export type MailingListListRequestFilter = {
  email?: string
  status?: MailingListEntryStatus
}

export type MailingListListRequestSorting = {
  sort_field?: keyof MailingListEntry
  sort_reversed?: boolean
}

export type MailingListDTO = Omit<MailingListEntry, 'id'> & { id?: string }
