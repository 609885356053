import React, { useMemo } from 'react'
import * as Styled from 'views/components/OrderProductItems/styles'
import {
  IconWarning,
  NumberInput,
  SectionTableBodyCell,
  SectionTableBodyRow,
  TextArea,
  ProcoteWizardData,
  ProductWizardType,
} from '@agro-club/frontend-shared'
import { FarmerOrderItem } from 'types/farmerOrder'
import { DocumentItem } from 'modules/domain/document/types'
import { AltPackagingCell, SeedTreatment } from './OrderProductItems'
import { getPrettyPrice } from 'modules/utils/helpers'
import { useTranslation } from 'react-i18next'
import { Product } from 'modules/domain/product/types'
import {
  useMaxQty,
  useProductAllocatedLimit,
  useProductQtyAllocatedChecks,
  useQty,
} from 'modules/domain/allocation/hooks'
import { ProductOptions } from 'modules/domain/productOptions/types'
import { ProductLimit } from 'modules/domain/allocation/common/types'
import { DuckFootWizardData } from '../ProductWizards/ProductWizardDuckFoot/DuckFootWizardData'
import ProductOptionsComponent from 'views/components/ProductOptionsComponent/ProductOptionsComponent'

const OrderProductItem: React.FC<{
  idx: number
  title?: string
  item: FarmerOrderItem
  invalid?: boolean
  context: 'distributor' | 'farmer'
  errorText?: string
  documents?: DocumentItem[]
  mode: 'create' | 'edit'
  onQtyInputChange: (idx: number, value: number) => void
  onRequiredDocumentClick: ((id: string) => void) | undefined
  onSeedTreatmentChange: (idx: number) => (productId: string, seedTreatmentId: string) => void
  onProductOptionsChange: (idx: number) => (values: string[]) => void
  optionsRequiredErrors: string[][]
  isEditingExpired: (product: Product) => boolean
  onDeliveredQtyChange?: (idx: number, qty: number) => void
  onFinalQtyChange?: (idx: number, qty: number) => void
  onAltPackagingChange: (idx: number, qty: number) => void
  onCommentChange?: (idx: number, comment: string) => void
  onRemove: (idx: any) => () => void
  itemCommonInitialQty: number
  itemCommonQty: number
  productOptions: ProductOptions[]
  visibilityConfig: {
    showDeliveredQty?: boolean
    showFinalQty?: boolean
    showUnits: boolean
    showAltPackage: boolean
    showComment: boolean
    showPrices: boolean
  }
  isAdmin: boolean
  productsLimit: ProductLimit[]
}> = ({
  item,
  context,
  documents,
  mode,
  onQtyInputChange,
  idx,
  onRequiredDocumentClick,
  onSeedTreatmentChange,
  onProductOptionsChange,
  optionsRequiredErrors,
  visibilityConfig: { showPrices, showAltPackage, showComment, showDeliveredQty, showFinalQty, showUnits },
  isEditingExpired,
  onDeliveredQtyChange,
  onFinalQtyChange,
  onAltPackagingChange,
  onCommentChange,
  onRemove,
  itemCommonQty,
  itemCommonInitialQty,
  productOptions,
  isAdmin,
  productsLimit,
}) => {
  const { t } = useTranslation(['farmerOrder', 'common', 'allocation'])

  const docId = item.product?.docusign_document_id
  const needDocument = useMemo(() => {
    return context === 'distributor'
      ? false
      : !documents?.length && docId
      ? true
      : docId && documents?.find(doc => doc.id === docId && doc.status !== 'signed')
  }, [context, docId, documents])

  const productAllocatedLimit = useProductAllocatedLimit(item, productsLimit)

  const {
    isProductQtyMoreThanLimit,
    isProductDifferenceQtyMoreThanLimit,
    isProductQtyEqualLimit,
  } = useProductQtyAllocatedChecks(item, itemCommonQty, itemCommonInitialQty, mode, productsLimit)

  const maxProductQty = useMaxQty(item, mode, itemCommonQty, itemCommonInitialQty, isAdmin, productsLimit)

  const qty = useQty(
    mode,
    item.quantity,
    isAdmin,
    isProductDifferenceQtyMoreThanLimit,
    isProductQtyMoreThanLimit,
    productAllocatedLimit,
  )

  if (qty !== item.quantity) onQtyInputChange(idx, qty)

  const showAllocationLimitWarning =
    (mode === 'create' && isProductQtyMoreThanLimit) ||
    (mode === 'edit' && productAllocatedLimit?.is_limit_exceeded) ||
    isProductDifferenceQtyMoreThanLimit

  const handleDocWarningClick = () => docId && onRequiredDocumentClick?.(docId)

  const handleDeliveredQtyInputChange = (value: number) => {
    if (!Number.isNaN(value)) {
      onDeliveredQtyChange?.(idx, value)
    }
  }

  const handleFinalQtyInputChange = (value: number) => {
    if (!Number.isNaN(value)) {
      onFinalQtyChange?.(idx, value)
    }
  }

  const checkRemoveButtonVisibility = item => {
    return mode === 'create' || (mode === 'edit' && !isEditingExpired(item.product))
  }

  return (
    <>
      <SectionTableBodyRow key={item.key} data-test-id={`product-row-${item.product_id}`}>
        <SectionTableBodyCell>
          <div>
            <b>
              {item.product?.subcategory?.title || ''} {item.product?.title || ''}
            </b>
          </div>
          {needDocument ? (
            <Styled.ContractWarning onClick={handleDocWarningClick} data-test-id={'product-row-contract-warning'}>
              <IconWarning /> {t('contractWarningText')}
            </Styled.ContractWarning>
          ) : null}
          {showAllocationLimitWarning && (
            <Styled.Warning data-test-id={'product-limit-row-warning'}>
              <IconWarning /> {t('productWarningText')}
            </Styled.Warning>
          )}
          <Styled.ProductOptionsContainer>
            <SeedTreatment item={item} onChange={onSeedTreatmentChange(idx)} />
            <ProductOptionsComponent
              selectedIds={item.options}
              options={item.product?.product_options}
              onChange={onProductOptionsChange(idx)}
              optionsRequiredErrors={optionsRequiredErrors[idx]}
              productOptions={productOptions}
            />
            {item.wizard_data?.type === ProductWizardType.Procote && <ProcoteWizardData data={item.wizard_data} />}
            {item.wizard_data?.type === ProductWizardType.DuckFoot && item.wizard_comment && (
              <DuckFootWizardData note={item.wizard_comment} />
            )}
          </Styled.ProductOptionsContainer>
        </SectionTableBodyCell>
        {showPrices && (
          <SectionTableBodyCell style={{ whiteSpace: 'nowrap' }}>
            {getPrettyPrice(Number(item.product?.price), item.product?.producer?.currency)}
          </SectionTableBodyCell>
        )}
        <SectionTableBodyCell style={{ whiteSpace: 'nowrap' }}>
          {item.product?.default_packaging || ''}
        </SectionTableBodyCell>
        <SectionTableBodyCell style={{ whiteSpace: 'nowrap' }}>
          <Styled.OrderQtyCell>
            <NumberInput
              value={qty}
              onChange={val => onQtyInputChange(idx, val)}
              size={'small'}
              testId={'qty-input'}
              max={maxProductQty}
              disabled={isEditingExpired(item.product)}
            />
            {!isAdmin && isProductQtyEqualLimit && (
              <Styled.MaxQtyHint>{t('allocation:reachedQtyLimit')}</Styled.MaxQtyHint>
            )}
            {showUnits && (
              <Styled.UnitsContainer>
                {item.quantity > 1 ? item.product?.units?.plural : item.product?.units?.singular}
              </Styled.UnitsContainer>
            )}
          </Styled.OrderQtyCell>
        </SectionTableBodyCell>
        {showDeliveredQty && onDeliveredQtyChange && (
          <SectionTableBodyCell style={{ whiteSpace: 'nowrap' }}>
            <Styled.QtyCell>
              <NumberInput
                value={item.delivered_qty || 0}
                min={0}
                onChange={value => handleDeliveredQtyInputChange(value)}
                size={'small'}
                testId={'delivered-qty-input'}
                disabled={isEditingExpired(item.product)}
              />
            </Styled.QtyCell>
          </SectionTableBodyCell>
        )}
        {showFinalQty && onFinalQtyChange && (
          <SectionTableBodyCell style={{ whiteSpace: 'nowrap' }}>
            <Styled.QtyCell>
              <NumberInput
                value={item.final_qty || 0}
                min={0}
                onChange={value => handleFinalQtyInputChange(value)}
                size={'small'}
                testId={'final-qty-input'}
              />
            </Styled.QtyCell>
          </SectionTableBodyCell>
        )}
        {showAltPackage && (
          <SectionTableBodyCell>
            <AltPackagingCell item={item} onChange={(productId, qty) => onAltPackagingChange(idx, qty)} />
          </SectionTableBodyCell>
        )}
        {showComment && (
          <SectionTableBodyCell style={{ minWidth: '152px' }}>
            <TextArea value={item.comment} onChange={event => onCommentChange?.(idx, event.target.value)} />
          </SectionTableBodyCell>
        )}
        <SectionTableBodyCell>
          {checkRemoveButtonVisibility(item) && (
            <span data-test-id={'remove-product'}>
              <Styled.RemoveIcon onClick={onRemove(idx)} />
            </span>
          )}
        </SectionTableBodyCell>
      </SectionTableBodyRow>
    </>
  )
}

export default OrderProductItem
