import { AddButton, useHistoryPush } from '@agro-club/frontend-shared'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Routes from 'views/pages/Storefront/routes'
import * as Header from 'views/ui/Header/Header'

export const StorefrontHeader: React.FC<{
  tab: 'products' | 'cards' | 'packageTypes' | 'storefront'
}> = ({ tab }) => {
  const push = useHistoryPush()
  const { t } = useTranslation(['storefront', 'cards', 'packageTypes', 'product'])
  return (
    <Header.TabsRoot>
      <Header.Tab
        title={t('storefront:title')}
        onClick={() => push({ route: Routes.List })}
        isActive={tab === 'storefront'}
      />
      <Header.Tab title={t('cards:title')} onClick={() => push({ route: Routes.CardsList })} isActive={tab === 'cards'}>
        <AddButton onClick={() => push({ route: Routes.CardsAdd })} data-test-id="add-storefront-card" />
      </Header.Tab>
      <Header.Tab
        title={t('product:list.heading')}
        onClick={() => push({ route: Routes.ProductsList })}
        isActive={tab === 'products'}
      >
        <AddButton onClick={() => push({ route: Routes.ProductsAdd })} data-test-id="add-product" />
      </Header.Tab>
      <Header.Tab
        title={t('packageTypes:title')}
        onClick={() => push({ route: Routes.PackageTypesList })}
        isActive={tab === 'packageTypes'}
      >
        <AddButton onClick={() => push({ route: Routes.PackageTypesAdd })} data-test-id="add-package-type" />
      </Header.Tab>
    </Header.TabsRoot>
  )
}
