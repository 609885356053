import React, { memo, useMemo } from 'react'
import { LocalizedValue } from 'types/entities'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import * as Styled from 'views/pages/Product/ProductDetailsForm/styled'
import { FormikHook, Input } from '@agro-club/frontend-shared'

export type PackagingFormProps = {
  defaultPackaging?: string
  altPackaging?: string
}

const PackagingForm: React.FC<{
  lang: keyof LocalizedValue
  defaultPackaging?: LocalizedValue
  altPackaging?: LocalizedValue
  useFormik: FormikHook
}> = memo(({ lang, useFormik, defaultPackaging = {}, altPackaging = {} }) => {
  const { t } = useTranslation(['product', 'common', 'validation'])
  const validationSchema = useMemo(
    () =>
      Yup.object({
        defaultPackaging: Yup.string().required(t('validation:field_required')),
        altPackaging: Yup.string(),
      }),
    [t],
  )
  const formik = useFormik<PackagingFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      defaultPackaging: defaultPackaging[lang] || '',
      altPackaging: altPackaging[lang] || '',
    },
    validationSchema,
    enableReinitialize: true,
  })

  return (
    <Styled.VerticalInputsContainer>
      <Input
        {...formik.getFieldProps('defaultPackaging')}
        invalid={formik.touched.defaultPackaging && !!formik.errors.defaultPackaging}
        errorText={formik.errors.defaultPackaging}
        label={t('form.defaultPackaging', { lang })}
        required
      />
      <Input
        {...formik.getFieldProps('altPackaging')}
        invalid={formik.touched.altPackaging && !!formik.errors.altPackaging}
        errorText={formik.errors.altPackaging}
        label={t('form.alternativePackaging', { lang })}
      />
    </Styled.VerticalInputsContainer>
  )
})
PackagingForm.displayName = 'PackagingForm'

export default PackagingForm
