import React, { memo, useCallback, useMemo, useState } from 'react'
import * as Yup from 'yup'
import * as Styled from './styled'
import { FormikHook, SimpleSelect } from '@agro-club/frontend-shared'
import { ProductOptionsData, ProductOptionType } from 'modules/domain/productOptions/types'
import { useTranslation } from 'react-i18next'
import InoculantsForm from './InoculantsForm'
import DeliveryOptionsForm from './DeliveryOptionsForm'
import SeedTreatmentOptionsForm from './SeedTreatmentOptionsForm'
import ProductTypeForm from './ProductTypeForm'
import ActiveIngredientsForm from './ActiveIngredientsForm'

export type ProductOptionsFormProps = {
  options: ProductOptionsData[]
}

const ProductOptionsForm: React.FC<{
  options?: ProductOptionsData[]
  useFormik: FormikHook
  onChange: (value: ProductOptionsData[]) => void
}> = memo(({ options = [], useFormik, onChange }) => {
  const { t } = useTranslation(['product', 'productOptions'])
  const [optionsList, setOptionsList] = useState(options)
  const [types, setTypes] = useState(optionsList.map(option => option.type))
  const [hoveredOption, setHoveredOption] = useState<ProductOptionType | null>(null)

  const selectOptions = useMemo(() => {
    return Object.values(ProductOptionType)
      .filter(type => !types.find(t => t === type))
      .map(type => ({
        id: type,
        title: t(`productOptions:types.${type}`),
      }))
  }, [t, types])

  const formik = useFormik<ProductOptionsFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema: Yup.object({
      options: Yup.array(
        Yup.object({
          type: Yup.string()
            .oneOf(Object.values(ProductOptionType))
            .required(),
          option_ids: Yup.array(Yup.string()).min(1),
          required: Yup.boolean(),
        }),
      ),
    }),
    enableReinitialize: true,
    initialValues: { options },
  })

  const [inoculantsFormShowed, inoculantsFormOptions, inoculantsFormIdx] = useMemo(() => {
    const options = optionsList.find(o => o.type === ProductOptionType.INOCULANT) as ProductOptionsData
    const idx = optionsList.findIndex(o => o.type === ProductOptionType.INOCULANT)
    return [!!options, options, idx]
  }, [optionsList])

  const [deliveryOptionsFormShowed, deliveryOptionsFormOptions, deliveryOptionsFormIdx] = useMemo(() => {
    const options = optionsList.find(o => o.type === ProductOptionType.DELIVERY_OPTION) as ProductOptionsData
    const idx = optionsList.findIndex(o => o.type === ProductOptionType.DELIVERY_OPTION)
    return [!!options, options, idx]
  }, [optionsList])

  const [seedTreatmentOptionsFormShowed, seedTreatmentOptionsFormOptions, seedTreatmentOptionsFormIdx] = useMemo(() => {
    const options = optionsList.find(o => o.type === ProductOptionType.SEED_TREATMENT) as ProductOptionsData
    const idx = optionsList.findIndex(o => o.type === ProductOptionType.SEED_TREATMENT)
    return [!!options, options, idx]
  }, [optionsList])

  const [productTypeOptionsFormShowed, productTypeOptionsFormOptions, productTypeOptionsFormIdx] = useMemo(() => {
    const options = optionsList.find(o => o.type === ProductOptionType.PRODUCT) as ProductOptionsData
    const idx = optionsList.findIndex(o => o.type === ProductOptionType.PRODUCT)
    return [!!options, options, idx]
  }, [optionsList])

  const [activeIngredientsFormShowed, activeIngredientsFormOptions, activeIngredientsFormIdx] = useMemo(() => {
    const options = optionsList.find(o => o.type === ProductOptionType.ACTIVE_INGREDIENT) as ProductOptionsData
    const idx = optionsList.findIndex(o => o.type === ProductOptionType.ACTIVE_INGREDIENT)
    return [!!options, options, idx]
  }, [optionsList])

  const handleSelectedOptionsChange = useCallback(
    type => {
      if (optionsList.find(option => option.type === type)) return
      const updatedList = [...optionsList, { type, option_ids: [], required: false }]
      setTypes([...types, type])
      setOptionsList(updatedList)
      onChange(updatedList)
    },
    [onChange, optionsList, types],
  )

  const handleOptionsChange = useCallback(
    (type: ProductOptionType, option_ids: string[], required: boolean) => {
      const index = optionsList.findIndex(option => option.type === type)
      if (index === -1) return
      const updatedList = optionsList.slice()
      updatedList[index] = { type, option_ids, required }
      setOptionsList(updatedList)
      onChange(updatedList)
    },
    [onChange, optionsList],
  )

  const handleRemoveOption = useCallback(
    (type: ProductOptionType) => {
      const updatedTypes = types.filter(t => t !== type)
      const updatedList = optionsList.filter(item => item.type !== type)
      setTypes(updatedTypes)
      setOptionsList(updatedList)
      onChange(updatedList)
      setHoveredOption(null)
    },
    [onChange, optionsList, types],
  )

  return (
    <Styled.FormContainer data-test-id={'product-options-form'}>
      <Styled.OptionsSelectContainer>
        <SimpleSelect
          placeholder={t('form.optionTypeLabel')}
          onChange={handleSelectedOptionsChange}
          options={selectOptions}
          isDisabled={!selectOptions.length}
        />
      </Styled.OptionsSelectContainer>
      <Styled.OptionsFormsContainer>
        {inoculantsFormShowed && (
          <>
            <Styled.RemoveIcon
              onMouseEnter={() => setHoveredOption(ProductOptionType.INOCULANT)}
              onMouseLeave={() => setHoveredOption(null)}
              onClick={() => handleRemoveOption(ProductOptionType.INOCULANT)}
              data-test-id={'inoculants-remove-button'}
            />
            <InoculantsForm
              options={inoculantsFormOptions}
              placeholder={t('productOptions:labels.options')}
              label={t('productOptions:labels.inoculants')}
              onChange={handleOptionsChange}
              touched={!!formik.touched.options && !!formik.touched.options[inoculantsFormIdx]}
            />
            {hoveredOption !== null && hoveredOption === ProductOptionType.INOCULANT ? <Styled.RemoveOverlay /> : null}
          </>
        )}
      </Styled.OptionsFormsContainer>
      <Styled.OptionsFormsContainer>
        {deliveryOptionsFormShowed && (
          <>
            <Styled.RemoveIcon
              onMouseEnter={() => setHoveredOption(ProductOptionType.DELIVERY_OPTION)}
              onMouseLeave={() => setHoveredOption(null)}
              onClick={() => handleRemoveOption(ProductOptionType.DELIVERY_OPTION)}
              data-test-id={'delivery-options-remove-button'}
            />
            <DeliveryOptionsForm
              options={deliveryOptionsFormOptions}
              placeholder={t('productOptions:labels.options')}
              label={t('productOptions:labels.deliveryOptions')}
              onChange={handleOptionsChange}
              touched={!!formik.touched.options && !!formik.touched.options[deliveryOptionsFormIdx]}
            />
            {hoveredOption !== null && hoveredOption === ProductOptionType.DELIVERY_OPTION ? (
              <Styled.RemoveOverlay />
            ) : null}
          </>
        )}
      </Styled.OptionsFormsContainer>
      <Styled.OptionsFormsContainer>
        {seedTreatmentOptionsFormShowed && (
          <>
            <Styled.RemoveIcon
              onMouseEnter={() => setHoveredOption(ProductOptionType.SEED_TREATMENT)}
              onMouseLeave={() => setHoveredOption(null)}
              onClick={() => handleRemoveOption(ProductOptionType.SEED_TREATMENT)}
              data-test-id={'seed-treatment-remove-button'}
            />
            <SeedTreatmentOptionsForm
              options={seedTreatmentOptionsFormOptions}
              placeholder={t('productOptions:labels.options')}
              label={t('productOptions:labels.seedTreatments')}
              onChange={handleOptionsChange}
              touched={!!formik.touched.options && !!formik.touched.options[seedTreatmentOptionsFormIdx]}
            />
            {hoveredOption !== null && hoveredOption === ProductOptionType.SEED_TREATMENT ? (
              <Styled.RemoveOverlay />
            ) : null}
          </>
        )}
      </Styled.OptionsFormsContainer>
      <Styled.OptionsFormsContainer>
        {productTypeOptionsFormShowed && (
          <>
            <Styled.RemoveIcon
              onMouseEnter={() => setHoveredOption(ProductOptionType.PRODUCT)}
              onMouseLeave={() => setHoveredOption(null)}
              onClick={() => handleRemoveOption(ProductOptionType.PRODUCT)}
              data-test-id={'product-type-remove-button'}
            />
            <ProductTypeForm
              options={productTypeOptionsFormOptions}
              placeholder={t('productOptions:labels.options')}
              label={t('productOptions:labels.options')}
              onChange={handleOptionsChange}
              touched={!!formik.touched.options && !!formik.touched.options[productTypeOptionsFormIdx]}
            />
            {hoveredOption !== null && hoveredOption === ProductOptionType.PRODUCT ? <Styled.RemoveOverlay /> : null}
          </>
        )}
      </Styled.OptionsFormsContainer>
      <Styled.OptionsFormsContainer>
        {activeIngredientsFormShowed && (
          <>
            <Styled.RemoveIcon
              onMouseEnter={() => setHoveredOption(ProductOptionType.ACTIVE_INGREDIENT)}
              onMouseLeave={() => setHoveredOption(null)}
              onClick={() => handleRemoveOption(ProductOptionType.ACTIVE_INGREDIENT)}
              data-test-id={'active-ingredient-remove-button'}
            />
            <ActiveIngredientsForm
              options={activeIngredientsFormOptions}
              placeholder={t('productOptions:labels.activeIngredients')}
              label={t('productOptions:labels.activeIngredients')}
              onChange={handleOptionsChange}
              touched={!!formik.touched.options && !!formik.touched.options[activeIngredientsFormIdx]}
            />
            {hoveredOption !== null && hoveredOption === ProductOptionType.ACTIVE_INGREDIENT ? (
              <Styled.RemoveOverlay />
            ) : null}
          </>
        )}
      </Styled.OptionsFormsContainer>
    </Styled.FormContainer>
  )
})

ProductOptionsForm.displayName = 'ProductOptionsForm'

export default ProductOptionsForm
