import {
  Button,
  Checkbox,
  FormComponents,
  Progress,
  SectionBody,
  SectionContainer,
  Switch,
  useFormManager,
} from '@agro-club/frontend-shared'
import { createLocalizedValue } from 'helpers/localization'
import useLangPicker from 'hooks/useLangPicker'
import { useLangState } from 'hooks/useLangState'
import { StorefrontProduct, StorefrontProductDTO } from 'modules/domain/storefrontProduct/types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AvailableLanguages, availableLanguagesList, CompanyType, Status } from 'types/entities'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { Grid3Col } from 'views/pages/Product/ProductDetailsForm/styled'
import * as Yup from 'yup'
import { AboutForm, AboutFormProps } from './AboutForm'

type StorefrontProductDetailsFormProps = {
  mode: 'edit' | 'create'
  initialValues?: StorefrontProduct
  onRemove?(): void
  onSubmit(value: StorefrontProductDTO, duplicate: boolean): void
  onCancel(): void
  progress?: Progress
  removeProgress?: Progress
}

const FormSection = styled(FormComponents.FormSection)`
  max-width: 400px;
`

type CommonFormProps = Omit<StorefrontProductDTO, 'units' | 'title_i18n' | 'description_i18n'>

type FormikManagerData = {
  'about:en': AboutFormProps
  'about:fr': AboutFormProps
  'about:ru': AboutFormProps
  'about:es': AboutFormProps
  common: CommonFormProps
}

export const StorefrontProductDetailsForm: React.FC<StorefrontProductDetailsFormProps> = ({
  mode,
  progress,
  onSubmit,
  onCancel,
  onRemove,
  removeProgress,
  initialValues,
}) => {
  const { t } = useTranslation(['product', 'common'])
  const { pick } = useLangPicker()
  const { bind, valid, submitAll, dirty } = useFormManager<FormikManagerData>()

  const validationSchema = useMemo(() => {
    return Yup.object({
      producer_id: Yup.string().required(t('validation:field_required')),
    })
  }, [t])

  const formik = bind('common')({
    initialValues: {
      status: initialValues?.status || Status.Active,
      producer_id: initialValues?.producer_id || '',
    },
    enableReinitialize: true,
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  const isDuplicateAllowed = mode === 'edit' ? (!dirty ? true : valid) : valid

  const submit = async (duplicate = false) => {
    try {
      const [valid, forms] = await submitAll()
      if (!valid) {
        return
      }
      const langProp = (lang: AvailableLanguages, prop?: string) => (languages.includes(lang) ? prop || '' : undefined)
      onSubmit(
        {
          ...forms.common,
          title_i18n: createLocalizedValue(lang => langProp(lang, forms[`about:${lang}`]?.title) as string),
          description_i18n: createLocalizedValue(lang => langProp(lang, forms[`about:${lang}`]?.description) as string),
          units: {
            singular_i18n: createLocalizedValue(lang => forms[`about:${lang}`]?.singular),
            plural_i18n: createLocalizedValue(lang => forms[`about:${lang}`]?.plural),
          },
        },
        duplicate,
      )
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  const handleSubmit = () => {
    submit(false)
  }

  const handleDuplicate = () => {
    submit(true)
  }

  const { languages, isLangActive, handleLangChange } = useLangState(initialValues?.title_i18n)

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <SectionBody>
            <FormComponents.FormSection title={t('form.originTitle')}>
              <Grid3Col>
                <CompanySelect
                  value={formik.values.producer_id}
                  onChange={v => {
                    formik.setFieldValue('producer_id', v)
                  }}
                  invalid={formik.touched.producer_id && !!formik.errors.producer_id}
                  errorText={formik.errors.producer_id}
                  label={t('form.producer')}
                  companyType={CompanyType.Producer}
                  isSearchable
                  isClearable
                  required
                />
              </Grid3Col>
            </FormComponents.FormSection>

            <FormSection title={t('form.languagesTitle')}>
              <Grid3Col>
                {availableLanguagesList.map(lang => (
                  <Checkbox
                    label={t(`common:langNames.${lang}`)}
                    isChecked={isLangActive(lang)}
                    value={lang}
                    onChange={handleLangChange}
                    key={lang}
                    data-test-id={`lang-${lang}`}
                  />
                ))}
              </Grid3Col>
            </FormSection>
            <Grid3Col>
              {languages.map(lang => {
                return (
                  <AboutForm
                    key={lang}
                    lang={lang}
                    singular={initialValues?.units?.singular_i18n}
                    plural={initialValues?.units?.plural_i18n}
                    description={initialValues?.description_i18n}
                    title={initialValues?.title_i18n}
                    useFormik={bind(`about:${lang}` as keyof FormikManagerData)}
                  />
                )
              })}
            </Grid3Col>
            <FormComponents.FormSection title={t('form.statusTitle')}>
              <Switch
                on={formik.values.status === Status.Active}
                onClick={value => {
                  formik.setFieldValue('status', !value ? Status.Inactive : Status.Active)
                }}
                testId="status-switch"
              >
                {formik.values.status === Status.Active ? t('form.active') : t('form.inactive')}
              </Switch>
            </FormComponents.FormSection>
          </SectionBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      <StickyFooterLayout.ButtonsFooter>
        <Button
          data-test-id={'submit'}
          filled={true}
          progress={progress}
          onClick={handleSubmit}
          intent={'primary'}
          disabled={!dirty || progress === Progress.WORK}
        >
          {t('common:save')}
        </Button>
        <Button onClick={handleDuplicate} disabled={!isDuplicateAllowed} progress={progress} intent={'primary'}>
          {mode === 'edit'
            ? dirty
              ? t('form.saveAndDuplicate')
              : t('form.createDuplicate')
            : t('form.saveAndDuplicate')}
        </Button>
        <StickyFooterBtn
          heading={t('common:cancelEditingHeader')}
          text={t('common:cancelEditingText')}
          onSubmit={onCancel}
          buttonText={t('common:dontSaveChanges')}
          intent={'cancel'}
        />

        {!!onRemove && (
          <StickyFooterDeleteBtn
            onRemove={onRemove}
            removeProgress={removeProgress}
            popoverText={t('form.removeText', { title: pick(initialValues?.title_i18n) })}
          />
        )}
      </StickyFooterLayout.ButtonsFooter>
    </StickyFooterLayout.Wrapper>
  )
}

export default StorefrontProductDetailsForm
