import { BasicSelect } from '@agro-club/frontend-shared'
import { TargetTypes } from 'modules/domain/target2/common/types'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'
import { createGetList } from './common'

export type TargetFarmer = {
  id: string
  first_name: string
  last_name: string
  farm_name?: string
}

type TargetFarmerFilter = {
  manufacturer_id?: string
  retailer_id?: string
  name?: string
}

type TargetProductSelectProps = EntitySelectProps<TargetFarmer> & {
  manufacturerId?: string
  retailerId?: string
  fakeFarmerFirstName?: string
  fakeFarmerLastName?: string
  scope?: TargetTypes | 'default'
}

const getListForm = createGetList<TargetFarmer, TargetFarmerFilter>(endpoints.target2('order_farmers'))
const getListFarmerFilter = createGetList<TargetFarmer, TargetFarmerFilter>(
  endpoints.target2('filters/targets_farmer/farmers'),
)

const scopeManagerMap = {
  default: getListForm,
  [TargetTypes.Farmer]: getListFarmerFilter,
}

const getLabel = (item: TargetFarmer) => `${item.first_name} ${item.last_name}`

const getValue = (item: TargetFarmer) => item.id

export const TargetFarmerSelect: React.VFC<TargetProductSelectProps> = ({
  manufacturerId,
  retailerId,
  isDisabled,
  fakeFarmerFirstName,
  fakeFarmerLastName,
  onChange,
  scope = 'default',
  ...props
}) => {
  const filter = useMemo(() => ({ manufacturer_id: manufacturerId, retailer_id: retailerId }), [
    manufacturerId,
    retailerId,
  ])

  const fakeValue = useMemo(() => {
    return fakeFarmerFirstName && fakeFarmerLastName
      ? {
          id: 'fake',
          first_name: fakeFarmerFirstName,
          last_name: fakeFarmerLastName,
        }
      : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fakeFarmerFirstName, fakeFarmerLastName])

  return (
    <SelectTestWrapper data-test-id="target-farmer-select">
      {isDisabled ? (
        <BasicSelect isDisabled getOptionLabel={getLabel} getOptionValue={getValue} {...props} value={fakeValue} />
      ) : (
        <EntitySelect
          filter={filter}
          getEntityList={scopeManagerMap[scope]}
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          onChange={onChange}
          searchParamKey="name"
          {...props}
        />
      )}
    </SelectTestWrapper>
  )
}
