import React, { FC, useState } from 'react'
import * as Styled from 'views/components/ProductWizards/styled'
import { DuckFoot, Modal, ProductWizardNode } from '@agro-club/frontend-shared'
import { getPrettyPrice } from 'modules/utils/helpers'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { Progress } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { useProductById } from 'modules/domain/product/hooks'

type PropsType = {
  product: ProductWizardNode
  opened: boolean
  onClose: () => void
  progress: Progress
  changeOrderList: (id: string, qty: number, wizardComment: string) => void
}

export const ProductWizardDuckFoot: FC<PropsType> = ({
  product,
  opened,
  onClose,
  progress,
  changeOrderList,
}: PropsType) => {
  const { t } = useTranslation('productWizard')
  const [productId, setProductId] = useState('')
  const [, item] = useProductById(productId)

  const wizardJSX =
    progress === Progress.SUCCESS && opened ? (
      <DuckFoot
        opened={opened}
        onClose={onClose}
        productWizardData={product}
        handleProductId={setProductId}
        changeOrderList={changeOrderList}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getPrettyPrice={getPrettyPrice}
        product={item}
      />
    ) : null

  const spinnerJSX = progress !== Progress.SUCCESS && (
    <Modal isOpen={opened} onClose={onClose} heading={t('title')} size={'responsive'}>
      <Styled.SpinnerWrap>
        <SpinnerLayout />
      </Styled.SpinnerWrap>
    </Modal>
  )

  return (
    <>
      {wizardJSX}
      {spinnerJSX}
    </>
  )
}
