import React from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import CallBackTable from './CallBackTable/CallBackTable'
import { useHelmet } from '@agro-club/frontend-shared'

const CallBackRequestList: React.FC = () => {
  const { t } = useTranslation('callBackRequest')

  useHelmet({ title: t('metaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <CallBackTable />
      </Layout.Content>
    </>
  )
}

export default CallBackRequestList
