import { SearchInput, useAction, useDebounce } from '@agro-club/frontend-shared'
import React, { memo, useMemo, useState } from 'react'
import { DragSourceMonitor, useDrag } from 'react-dnd'
import { DragItemType, StorefrontSidebarFilter } from 'modules/domain/storefront/types'
import { useCategoryOptions } from 'modules/domain/category/hooks'
import useLangPicker from 'hooks/useLangPicker'
import { useTranslation } from 'react-i18next'
import { useProductsConditional } from 'modules/domain/product/hooks'
import { Category } from 'modules/domain/category/types'
import StorefrontSelectors from 'modules/domain/storefront/selectors'
import { useSelector } from 'react-redux'
import StorefrontActions from 'modules/domain/storefront/duck'
import { useCombinedProgress } from 'hooks/useCombinedProgress'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { Progress } from 'modules/types'
import * as Styled from './styled'
import { Product } from 'modules/domain/product/types'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { CompanyType } from 'types/entities'

type SidebarCategory = {
  id: string
  title: string
  parent: string
}

type SidebarItemData = {
  category: SidebarCategory
  products: Product[]
}

const CollapsableBlock: React.FC<{ title: string; isCollapsed?: boolean }> = ({ title, isCollapsed, children }) => {
  const [collapsed, setCollapsed] = useState(isCollapsed ?? false)
  const toggle = () => setCollapsed(!collapsed)

  return (
    <Styled.CollapsableBlockWrapper>
      <Styled.CollapsableBlockTitleWrapper onClick={toggle}>
        <Styled.CollapsableBlockTitle>{title}</Styled.CollapsableBlockTitle>
        {collapsed ? <Styled.CollapsablePlusIcon /> : <Styled.CollapsableMinusIcon />}
      </Styled.CollapsableBlockTitleWrapper>
      <Styled.CollapsableBlockContent collapsed={collapsed}>{children}</Styled.CollapsableBlockContent>
    </Styled.CollapsableBlockWrapper>
  )
}

const SidebarItem: React.FC<{
  isSelected: boolean
  params: { id: string; title: string; description: string }
  onSkuAdd: (productId: string) => void
  onProductFilter: (productId?: string) => void
}> = memo(({ params: { id, title, description }, onSkuAdd, onProductFilter, isSelected }) => {
  const [collected, drag] = useDrag(() => ({
    type: DragItemType.Sidebar,
    item: { productId: id },
    collect: (monitor: DragSourceMonitor) => {
      return {
        isDragging: monitor.isDragging(),
      }
    },
  }))

  const { t } = useTranslation('storefront')

  return (
    <Styled.ItemWrapper
      ref={drag}
      isDragging={collected.isDragging}
      isSelected={isSelected}
      title={isSelected ? t('sidebar.skusFilteredByThisProduct') : ''}
      data-test-id={`sidebar-item-${id}`}
    >
      <Styled.ItemInner>
        <Styled.ItemTitle>{title}</Styled.ItemTitle>
        <Styled.ItemDescription dangerouslySetInnerHTML={{ __html: `<div>${description}</div>` }} />
      </Styled.ItemInner>
      <Styled.IconButtonWrapper>
        <Styled.IconButton onClick={() => onSkuAdd(id)} title={t('sidebar.addSku')} data-test-id="add-sku-button">
          <Styled.ItemArrowIcon />
        </Styled.IconButton>
        <Styled.IconButton
          onClick={() => onProductFilter(isSelected ? undefined : id)}
          title={t('sidebar.filterSkusByThisProduct')}
          data-test-id="filter-button"
        >
          {isSelected ? <Styled.ItemIconCross /> : <Styled.ItemIconSearch />}
        </Styled.IconButton>
      </Styled.IconButtonWrapper>
    </Styled.ItemWrapper>
  )
})

const StorefrontSidebar: React.FC<{
  onSkuAdd: (productId: string) => void
  onProductFilter: (productId?: string) => void
}> = memo(({ onSkuAdd, onProductFilter }) => {
  // todo fix page_size
  const [categoriesProgress, rawCategories = []] = useCategoryOptions(undefined, undefined, 1, 1000, undefined)
  const filter = useSelector(StorefrontSelectors.sidebarFilter)
  const storefrontFilter = useSelector(StorefrontSelectors.filter)

  const filterUpdated = useAction(StorefrontActions.sidebarFilterUpdated)
  const storefrontFilterUpdated = useAction(StorefrontActions.filterUpdated)
  const debouncedFilter = useDebounce(filter, 100)
  const { pick } = useLangPicker()
  const { t } = useTranslation('storefront')
  const { producer_id, search } = filter
  // todo fix page_size
  const [productsProgress, products = []] = useProductsConditional(null, debouncedFilter, 1000, !!producer_id)
  const progress = useCombinedProgress([categoriesProgress, productsProgress])
  const [collapsed, setCollapsed] = useState(false)

  const data = useMemo(() => {
    const result: SidebarItemData[] = []

    rawCategories.forEach(({ id, parent_id, title_i18n }: Category) => {
      const parent = rawCategories.find(cat => cat.id === parent_id)
      const categoryProducts = products.filter(p => p.subcategory_id === id)
      if (!categoryProducts.length) return

      const item = {
        category: {
          id: id,
          title: parent ? `${pick(parent?.title_i18n)} / ${pick(title_i18n)}` : pick(title_i18n),
          parent: pick(parent?.title_i18n),
        },
        products: categoryProducts,
      }

      result.push(item)
    })

    return result
  }, [pick, products, rawCategories])

  const handleFilterChange = (newFilterValue: Partial<StorefrontSidebarFilter>) => {
    filterUpdated({ ...filter, ...newFilterValue })
  }

  const categoryCollapsed = data.length !== 1

  return (
    <Styled.SidebarWrapper>
      {collapsed ? (
        <Styled.Expand onClick={() => setCollapsed(false)}></Styled.Expand>
      ) : (
        <Styled.Collapse onClick={() => setCollapsed(true)}></Styled.Collapse>
      )}
      <Styled.SidebarContainer style={{ display: collapsed ? 'none' : 'block' }}>
        <Styled.ProducerFilterBlock>
          <CompanySelect
            data-test-id="storefront-manufacturer-select"
            companyType={CompanyType.Producer}
            variant="small"
            onChange={val => {
              handleFilterChange({ producer_id: val })
              storefrontFilterUpdated({ ...storefrontFilter, product_id: undefined })
            }}
            showLabel
            value={producer_id}
            isClearable
          />
        </Styled.ProducerFilterBlock>
        <Styled.SearchBlock>
          <SearchInput
            onChange={(value: string) => handleFilterChange({ ...filter, ...{ search: value } })}
            placeholder={t('sidebar.searchPlaceholder')}
            value={search}
          />
        </Styled.SearchBlock>
        <Styled.ScrollableContainer>
          <Styled.SidebarItems>
            {!producer_id ? (
              <Styled.NoData>{t('sidebar.noSelectedProducerMsg')}</Styled.NoData>
            ) : progress !== Progress.SUCCESS ? (
              <Styled.SpinnerWrapper>
                <SpinnerLayout />
              </Styled.SpinnerWrapper>
            ) : (
              data.map(item =>
                !!item.products.length ? (
                  <CollapsableBlock key={item.category.id} title={item.category.title} isCollapsed={categoryCollapsed}>
                    <Styled.ListWrapper>
                      {item.products.map(({ id, title_i18n, description_i18n }) => (
                        <SidebarItem
                          isSelected={id === storefrontFilter.product_id}
                          key={id}
                          params={{ id, title: pick(title_i18n), description: pick(description_i18n) }}
                          onSkuAdd={onSkuAdd}
                          onProductFilter={onProductFilter}
                        />
                      ))}
                    </Styled.ListWrapper>
                  </CollapsableBlock>
                ) : null,
              )
            )}
          </Styled.SidebarItems>
        </Styled.ScrollableContainer>
      </Styled.SidebarContainer>
    </Styled.SidebarWrapper>
  )
})

export default StorefrontSidebar
