import React, { useCallback, useMemo } from 'react'
import { Filter as FilterComponent, TableFilter } from 'views/components/TableFilters/TableFilters'
import { useTranslation } from 'react-i18next'
import { CellProps, HeaderProps, useRowSelect, useTable } from 'react-table'
import ReturnDeclarationSkuActions from 'modules/domain/returnDeclarationSku/duck'
import ReturnDeclarationSkuSelectors from 'modules/domain/returnDeclarationSku/selectors'
import {
  ReturnDeclarationSku,
  ReturnDeclarationSkuItem,
  ReturnDeclarationSkuListRequestFilter,
} from 'modules/domain/returnDeclarationSku/types'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import ReturnDeclarationSkuRoutes from '../routes'
import {
  RangeDatePicker,
  AdvancedHeadCell,
  Table,
  TableHead,
  TableHeadRow,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableNoData,
  useAction,
  usePersistentScroll,
  Button,
} from '@agro-club/frontend-shared'
import { parseISO, isValid } from 'date-fns'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Progress } from 'modules/types'
import ReturnDeclarationStatusSelect from 'views/components/ReturnDeclarationStatusSelect/ReturnDeclarationStatusSelect'
import useLangPicker from 'hooks/useLangPicker'
import { isUsersCompanyBranchDistributor } from 'types/entities'
import AuthSelectors from 'modules/domain/auth/selectors'
import styled from 'styled-components'
import DistributorsMultiSelect from 'views/components/DistributorsMultiSelect/DistributorsMultiSelect'
import ReturnDeclarationStatusColored from 'views/components/ReturnDeclarationStatusColored/ReturnDeclarationStatusColored'
import useDownload from 'hooks/useDownload'
import { endpoints } from 'modules/endpoints'
import useDateFormatFn from 'hooks/useDateFormatFn'
import useDateFormat from 'hooks/useDateFormat'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'
import { useReturnDeclarationSkuList } from 'modules/domain/returnDeclarationSku/hooks'
import { ReturnDeclarationType } from 'modules/domain/returnDeclaration/types'
import { ProductOptionType } from 'modules/domain/productOptions/types'
import { numberToPrecision } from 'modules/utils/helpers'
import { AlignWrapper } from 'views/components/AlignWrapper/AlignWrapper'
import CategorySelect from 'views/components/CategorySelect/CategorySelect'
import SubcategorySelect from 'views/components/CategorySelect/SubcategorySelect'
import { SellerFilterSelect } from 'views/pages/DistributorOrderSku/components/SellerFilterSelect'

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: max-content auto max-content;
  height: 100%;
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Filter = styled(FilterComponent)`
  margin: 0 16px 16px 0 !important;
  width: 30%;
  max-width: 220px;
  flex-grow: 1;
  justify-content: space-between;
`

const StatusColumnHeaderCell: React.FC<HeaderProps<ReturnDeclarationSku>> = () => {
  const { t } = useTranslation('returnDeclaration')
  return (
    <TComponents.CheckboxWrapper>
      <span>{t('list.tableHeaders.status')}</span>
    </TComponents.CheckboxWrapper>
  )
}

const StatusCell: React.FC<CellProps<ReturnDeclarationSku>> = ({ row }) => {
  const { t } = useTranslation('returnDeclaration')
  return (
    <TComponents.CheckboxWrapper>
      <ReturnDeclarationStatusColored
        status={row.original.status}
        dangerouslySetInnerHTML={{ __html: t(`status.${row.original.status}`) }}
      />
    </TComponents.CheckboxWrapper>
  )
}

const SellerCell: React.FC<CellProps<ReturnDeclarationSku>> = ({ cell, column }) => {
  return <TComponents.BoldText key={column.id}>{(cell.value?.internal_name || '').toUpperCase()}</TComponents.BoldText>
}

const IdDateCell: React.FC<CellProps<ReturnDeclarationSku>> = ({ cell, row, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })
  return (
    <div key={column.id} style={{ whiteSpace: 'nowrap' }}>
      <div>{row.original.slug}</div>
      {!!cell.value && <div>{getFormatedDate(cell.value)}</div>}
    </div>
  )
}

const DistributorCell: React.FC<CellProps<ReturnDeclarationSku>> = ({ cell, column }) => {
  return <span key={column.id}>{(cell.value && cell.value.internal_name) || ''}</span>
}

const SubcategoryCell: React.FC<CellProps<ReturnDeclarationSku>> = ({ row }) => {
  const items = row.original.sku_items || []
  const { pick } = useLangPicker()

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      {items.map(
        (item, i) =>
          item.product_card?.subcategory && (
            <div key={`${item.product_card_id}-${i}`}>{pick(item.product_card.subcategory.title_i18n)}</div>
          ),
      )}
    </div>
  )
}

const Qty: React.FC<CellProps<ReturnDeclarationSku>> = ({ row }) => {
  const items = row.original.sku_items || []

  return (
    <div>
      {items.map((item, i) => (
        <div key={i}>{item.quantity}</div>
      ))}
    </div>
  )
}

const ItemsCell: React.FC<CellProps<ReturnDeclarationSku>> = ({ cell }) => {
  const { pick } = useLangPicker()

  if (!cell.value) {
    return <span>{''}</span>
  }

  return (
    <div>
      {cell.value.map((item: ReturnDeclarationSkuItem, i: number) => {
        return (
          <div key={`${item.product_card_id}-${i}`} style={{ whiteSpace: 'nowrap' }}>{`${pick(
            item.product_card?.title_i18n,
          ) || ''}`}</div>
        )
      })}
    </div>
  )
}

export const CellWrapper = styled.div`
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SeedTreatmentCell: React.FC<CellProps<ReturnDeclarationSku>> = ({ cell }) => {
  const items = cell.value || []

  return (
    <div>
      {items.map((item: ReturnDeclarationSkuItem, i: number) => {
        const seedTreatment = item.sku?.params.options.find(o => o.type === ProductOptionType.SEED_TREATMENT)
        if (!seedTreatment) return <div key={`${item.sku_id}-${i}`}>-</div>

        return (
          <CellWrapper key={`${item.sku_id}-${i}`} title={seedTreatment?.title || '-'}>
            {seedTreatment?.title || '-'}
          </CellWrapper>
        )
      })}
    </div>
  )
}

const Packaging: React.FC<CellProps<ReturnDeclarationSku>> = ({ cell }) => {
  const items = cell.value || []

  return (
    <CellWrapper>
      {items.map((item: ReturnDeclarationSkuItem, i: number) => (
        <div key={i}>{item.sku?.params.package?.title}</div>
      ))}
    </CellWrapper>
  )
}

const StandardUnits: React.FC<CellProps<ReturnDeclarationSku>> = ({ cell }) => {
  const items = cell.value || []

  return (
    <div>
      {items.map((item: ReturnDeclarationSkuItem, i: number) => (
        <div key={i}>
          {item.standard_qty ? (item.standard_qty === '0' ? '0' : numberToPrecision(item.standard_qty)) : '-'}
        </div>
      ))}
    </div>
  )
}

const TypeCell: React.FC<CellProps<ReturnDeclarationSku>> = ({ cell, column }) => {
  const { t } = useTranslation('returnDeclaration')
  const translate = cell.value === 'in_season' ? 'type.inSeason' : 'type.endOfSeason'
  return <span key={column.id}>{t(translate)}</span>
}

const SortableHeadCell = AdvancedHeadCell<keyof ReturnDeclarationSku>()

const ReturnDeclarationSkuList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation(['returnDeclaration', 'labels'])
  const userCompany = useSelector(AuthSelectors.userCompany)
  const dateFormat = useDateFormat({ isYearShort: true })
  const isBasicRetailer = isUsersCompanyBranchDistributor(userCompany) || isUsersCompanyBranchDistributor(userCompany)

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: StatusColumnHeaderCell,
        accessor: 'status' as const,
        Cell: StatusCell,
      },
      {
        Header: t('form.sectionHeaders.type'),
        accessor: 'type' as const,
        Cell: TypeCell,
      },
      {
        Header: t('list.tableHeaders.idAndDate'),
        accessor: 'declaration_date' as const,
        Cell: IdDateCell,
      },
      {
        Header: t('list.tableHeaders.producer'),
        accessor: 'seller' as const,
        Cell: SellerCell,
      },
      {
        Header: t('list.tableHeaders.distributor'),
        accessor: 'distributor' as const,
        Cell: DistributorCell,
        hidden: isBasicRetailer,
      },
      {
        Header: t('list.tableHeaders.season'),
        accessor: 'season.title' as 'season',
      },
      {
        Header: t('list.tableHeaders.subcategory'),
        accessor: 'sku_items' as const,
        id: 'subcategory',
        Cell: SubcategoryCell,
      },
      {
        Header: t('list.tableHeaders.items'),
        accessor: 'sku_items' as const,
        id: 'items',
        Cell: ItemsCell,
      },
      {
        Header: t('list.tableHeaders.options'),
        accessor: 'sku_items' as const,
        id: 'seed-treatment',
        Cell: SeedTreatmentCell,
      },
      {
        Header: t('list.tableHeaders.packaging'),
        accessor: 'sku_items' as const,
        id: 'packaging',
        Cell: Packaging,
      },
      {
        Header: t('list.tableHeaders.standardUnits'),
        accessor: 'sku_items' as const,
        id: 'standardUnits',
        Cell: StandardUnits,
      },
      {
        Header: t('list.tableHeaders.quantity'),
        accessor: 'sku_items' as const,
        id: 'qty',
        Cell: Qty,
      },
      {
        accessor: 'created_at' as const,
        hidden: true,
      },
      {
        accessor: 'slug' as const,
        hidden: true,
      },
    ],
    [t, isBasicRetailer],
  )

  const hiddenColumns: (keyof ReturnDeclarationSku)[] = React.useMemo(() => ['region_id', 'created_at', 'slug'], [])
  const [progress, data = []] = useReturnDeclarationSkuList()
  const { columns, rows, prepareRow } = useTable<ReturnDeclarationSku>(
    {
      columns: visibleColumns,
      data,
      initialState: { hiddenColumns },
    },
    useRowSelect,
  )

  const filterUpdated = useAction(ReturnDeclarationSkuActions.filterUpdated)
  const filterCleared = useAction(ReturnDeclarationSkuActions.filterHasBeenReset)
  const sortingUpdated = useAction(ReturnDeclarationSkuActions.sortingUpdated)
  const listRequested = useAction(ReturnDeclarationSkuActions.listRequested)
  const filterValue = useSelector(ReturnDeclarationSkuSelectors.filter)
  const page = useSelector(ReturnDeclarationSkuSelectors.page)
  const pageSize = useSelector(ReturnDeclarationSkuSelectors.pageSize)
  const pages = useSelector(ReturnDeclarationSkuSelectors.pages)
  const total = useSelector(ReturnDeclarationSkuSelectors.total)
  const { start_date, end_date, type } = filterValue
  const { sort_field, sort_reversed } = useSelector(ReturnDeclarationSkuSelectors.sorting)

  const typeDeclarationOptions = useMemo(
    () => [
      { value: ReturnDeclarationType.InSeason, title: t('type.inSeason'), bullet: true, color: 'green' as const },
      {
        value: ReturnDeclarationType.EndOfSeason,
        title: t('type.endOfSeason'),
        bullet: true,
        color: 'orange' as const,
      },
    ],
    [t],
  )

  const handleFilterChange = React.useCallback(
    (newFilterValue: Partial<ReturnDeclarationSkuListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const handleDateRangeChange = React.useCallback(
    ([start, end]) => {
      if (!start && !end) {
        handleFilterChange({ start_date: undefined, end_date: undefined })
      }

      const parsedStartDate = parseISO(start)
      const parsedEndDate = parseISO(end)

      if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
        handleFilterChange({ start_date: start, end_date: end })
      }
    },
    [handleFilterChange],
  )

  const handleClearFilters = React.useCallback(() => {
    filterCleared()
  }, [filterCleared])

  const isFilterApplied = Object.values(filterValue).some(Boolean)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('returnDeclarationSkuList')

  const handleStatusChange = useCallback(
    (filter: Partial<ReturnDeclarationSkuListRequestFilter>) => {
      const { status } = filter
      handleFilterChange({
        status,
      })
    },
    [handleFilterChange],
  )

  const [progressCSV, downloadCSV] = useDownload(endpoints.returnDeclarationSku('download/csv'), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  return (
    <Wrapper>
      <TComponents.Filters>
        <FiltersWrapper>
          <Filter title={t('labels:period')}>
            <RangeDatePicker
              start={start_date || ''}
              end={end_date || ''}
              onChange={handleDateRangeChange}
              format={dateFormat}
              isClearable={!!start_date || !!end_date}
            />
          </Filter>
          <Filter title={t('labels:status')}>
            <ReturnDeclarationStatusSelect
              value={filterValue}
              onChange={handleStatusChange}
              placeholder={t('status.any')}
            />
          </Filter>
          {!isBasicRetailer && (
            <Filter title={t('labels:retailer')}>
              <DistributorsMultiSelect
                onChange={val => handleFilterChange({ distributor_id: val })}
                selected={filterValue.distributor_id}
              />
            </Filter>
          )}
          <Filter title={t('labels:season')}>
            <SeasonSelect
              onChange={value => handleFilterChange({ season_id: value })}
              variant="small"
              value={filterValue.season_id}
              isClearable
            />
          </Filter>
          <Filter title={t('labels:category')}>
            <CategorySelect
              onChange={val => {
                const newValue = val ?? null
                handleFilterChange({ category_id: newValue as string })
              }}
              value={filterValue.category_id}
              variant="small"
              placeholder={t('status.any')}
              isClearable
            />
          </Filter>
          <Filter title={t('labels:subcategory')}>
            <SubcategorySelect
              onChange={val => {
                const newValue = val ?? null
                handleFilterChange({ subcategory_id: newValue as string })
              }}
              filter={{ parent_id: filterValue.category_id }}
              value={filterValue.subcategory_id}
              isDisabled={!filterValue.category_id}
              placeholder={t('status.any')}
              variant="small"
              isClearable
            />
          </Filter>
          <Filter title={t('labels:seller')}>
            <SellerFilterSelect
              value={filterValue.seller_id}
              onChange={val => handleFilterChange({ seller_id: val })}
              variant="small"
              isClearable
              placeholder={t('status.any')}
              required
            />
          </Filter>
          <TableFilter
            data-test-id="filters-declaration-type"
            handleFilterChange={handleFilterChange}
            filterValue={{ type }}
            options={typeDeclarationOptions}
            title={t('labels:type')}
            disableMulti
          />
        </FiltersWrapper>
        <AlignWrapper verticalAlign="bottom" horizontalAlign="right">
          <Button filled intent="primary" onClick={downloadCSV} progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </AlignWrapper>
      </TComponents.Filters>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <SortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof ReturnDeclarationSku}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                  width={column.width}
                >
                  {column.render('Header')}
                </SortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                selected={row.isSelected}
                onClick={() => {
                  history.push(generatePath(ReturnDeclarationSkuRoutes.Edit, { id: row.original.id.toString() }))
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()

                  if (cell.column.hidden) {
                    return null
                  }

                  return (
                    <TableBodyCell key={key} {...props} stopClickPropagation={cell.column.id === 'status'}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={handleClearFilters}>
                {t('list.resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </Wrapper>
  )
}

export default ReturnDeclarationSkuList
