import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import FccOrderActions from './duck'
import { FccOrderItem } from 'types/fccOrder'
import FccOrderSelectors from './selectors'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useFccOrderList: ResourceHook<FccOrderItem[], void[]> = () => {
  const progress = useSelector(FccOrderSelectors.listFetchProgress)
  const list = useSelector(FccOrderSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(FccOrderActions.listRequested, page ? { page } : {})

  useDidMount(() => {
    fetchAction()
  })

  return [progress, list]
}
