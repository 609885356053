import React, { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Progress } from 'modules/types'
import {
  Button,
  Checkbox,
  helpersSlug,
  SectionContainer,
  useFormManager,
  FormComponents,
} from '@agro-club/frontend-shared'
import { AvailableLanguages, availableLanguagesList, LocalizedValue, PriceType, Status } from 'types/entities'
import * as Styled from './styled'
import OriginForm, { OriginFormProps } from './OriginForm'
import PackagingForm, { PackagingFormProps } from './PackagingForm'
import AboutForm, { AboutFormProps } from './AboutForm'
import FeaturesForm, { FeaturesFormProps } from './FeaturesForm'
import AttributesForm, { AttributesFormProps } from './AttributesForm'
import StatusForm, { StatusFormProps } from './StatusForm'
import ImageForm, { ImageFormProps } from 'views/components/ImageForm/ImageForm'
import SeedTreatmentForm, { SeedTreatmentProps } from './SeedTreatmentForm'
import QuoteForm, { QuoteFormProps } from './QuoteForm'
import BadgeSelect from 'views/components/BadgeSelect'
import { DocumentsFormProps } from 'views/pages/Product/ProductDetailsForm/DocumentsForm'
import SlugForm from 'views/components/SlugForm/SlugForm'
import { ProductFileItem, ProductUnits } from 'modules/domain/product/types'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import RequiredDocumentsForm, { RequiredDocumentsFormProps } from './RequiredDocumentsForm'
import AdditionalOptionsForm, { AdditionalOptions } from './AdditionalOptionsForm'
import { createLocalizedValue } from 'helpers/localization'
import { ProductOptionsData } from 'modules/domain/productOptions/types'
import ProductOptionsForm from './ProductOptionsForm'
import { Dict } from 'types/generics'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import ProductUnitsForm, { ProductUnitsFormProps } from './ProductUnitsForm'
import { sanitizeHtml } from '../helpers'
import StickyFooterBtn from 'views/components/StickyFooterBtn/StickyFooterBtn'
import { Badge } from 'modules/domain/badge/types'
import ProductInfoFilesForm, { ProductInfoFilesFormProps } from './ProductInfoFilesForm'
import { FileItem } from 'views/components/FileManager/types'
import UploadManagerSelectors from 'modules/domain/uploadManager/selectors'
import { useSelector } from 'react-redux'

export type FormProps = {
  producer_id?: string
  category_id?: string
  subcategory_id?: string
  producer_product_id?: string
  wizard_id?: string
  files: {
    title_i18n: LocalizedValue
    url_i18n: LocalizedValue<FileItem[] | undefined>
  }[]
  images: FileItem[]
  summary: LocalizedValue<ProductFileItem | undefined>
  insights: LocalizedValue<ProductFileItem | undefined>
  price?: string
  status: Status
  slug?: string
  is_new: boolean
  is_featured: boolean
  is_limited: boolean
  is_exclusive?: boolean
  hide_from_crm?: boolean
  video_url_i18n?: LocalizedValue
  is_out_of_stock: boolean
  out_of_stock_date?: string
  is_gift?: boolean

  rank?: number
  min_qty?: number | null
  max_qty?: number | null
  default_qty?: number | null
  attributes?: {
    title_i18n: LocalizedValue
    value_i18n: LocalizedValue
  }[]
  filter_attributes?: {
    title_i18n: LocalizedValue
    value_i18n: LocalizedValue
  }[]
  features_i18n?: LocalizedValue[]
  quote?: {
    text_i18n?: LocalizedValue
    author_i18n?: LocalizedValue
    company_i18n?: LocalizedValue
  }
  seed_treatment?: {
    id?: string
    title_i18n: LocalizedValue
    is_active: boolean
  }[]
  title_i18n?: LocalizedValue
  title_meta_i18n?: LocalizedValue
  description_i18n?: LocalizedValue
  description_meta_i18n?: LocalizedValue
  short_description_i18n?: LocalizedValue
  trademark_language_i18n?: LocalizedValue
  default_packaging_i18n?: LocalizedValue
  alt_packaging_i18n?: LocalizedValue
  badge_ids: string[]
  enrich_badges?: Badge[]
  discount_text_i18n?: LocalizedValue
  docusign_document_id?: string
  additional_options?: AdditionalOptions
  options: ProductOptionsData[]
  price_type: string
  units: Omit<ProductUnits, 'singular' | 'plural'>
}

type FormikManagerData = {
  origin: OriginFormProps
  'about:en': AboutFormProps
  'about:fr': AboutFormProps
  'about:ru': AboutFormProps
  'about:es': AboutFormProps
  'packaging:en': PackagingFormProps
  'packaging:fr': PackagingFormProps
  'packaging:ru': PackagingFormProps
  'packaging:es': PackagingFormProps
  'units:en': ProductUnitsFormProps
  'units:ru': ProductUnitsFormProps
  'units:fr': ProductUnitsFormProps
  'units:es': ProductUnitsFormProps
  features: FeaturesFormProps
  attributes: AttributesFormProps
  filter_attributes: AttributesFormProps
  images: ImageFormProps
  files: ProductInfoFilesFormProps
  status: StatusFormProps
  quote: QuoteFormProps
  seedTreatment: SeedTreatmentProps
  badge_ids: { badge_ids: string[] }
  documents: DocumentsFormProps
  requiredDocuments: RequiredDocumentsFormProps
  slug: { slug: string }
  additionalOptions: AdditionalOptions
  options: { options: ProductOptionsData[] }
}

const ProductDetailsForm: React.FC<{
  mode: 'edit' | 'create'
  onSubmit(formData: FormProps, options: { dirty: boolean; duplicate: boolean }): void
  onCancel(): void
  onRemove?(): void
  progress?: Progress
  removeProgress?: Progress
  initialValues?: Partial<FormProps>
}> = ({ onSubmit, onCancel, onRemove, progress, removeProgress, mode, initialValues = {} }) => {
  const { t } = useTranslation(['product', 'common', 'validation'])
  const { bind, slots, submitAll, dirty, valid } = useFormManager<FormikManagerData>()
  const [submitCount, setSubmitCount] = useState(0)
  const isFilesUploading = useSelector(UploadManagerSelectors.isFilesUploading)

  const [languages, setLanguages] = useState(() => {
    const result: AvailableLanguages[] = []
    const detectLang = (lang: AvailableLanguages) =>
      [
        initialValues?.short_description_i18n?.[lang],
        initialValues?.title_i18n?.[lang],
        initialValues?.title_meta_i18n?.[lang],
        initialValues?.description_i18n?.[lang],
        initialValues?.description_meta_i18n?.[lang],
        initialValues?.trademark_language_i18n?.[lang],
      ].some(Boolean)

    availableLanguagesList.forEach(lang => {
      if (detectLang(lang)) {
        result.push(lang)
      }
    })

    if (!result.length) {
      result.push('en')
    }

    return result
  })

  const submit = async (duplicate = false) => {
    try {
      const [valid, forms] = await submitAll()
      setSubmitCount(submitCount + 1)
      if (!valid) {
        return
      }

      const langProp = (lang: AvailableLanguages, prop?: string) => (languages.includes(lang) ? prop || '' : undefined)
      const toLocalizedKeys = (obj: Dict<LocalizedValue>) => {
        return Object.keys(obj).reduce((dict: Dict<LocalizedValue>, key: string) => {
          return { ...dict, [`${key}_i18n`]: obj[key] }
        }, {})
      }

      const descriptionLocalizedValue = createLocalizedValue(lang => {
        if (!forms[`about:${lang}`]?.description) return ''
        const sanitizedDescription = sanitizeHtml(forms[`about:${lang}`]?.description)
        slots[`about:${lang}`].setFieldValue(`description`, sanitizedDescription)

        return langProp(lang, sanitizedDescription) as string
      })

      onSubmit(
        // do smthng with this abomination
        {
          filter_attributes:
            (forms.filter_attributes.attributes.map(toLocalizedKeys) as FormProps['filter_attributes']) || [],
          attributes: (forms.attributes.attributes.map(toLocalizedKeys) as FormProps['attributes']) || [],
          features_i18n: forms.features.features,
          seed_treatment: forms.seedTreatment.seedTreatment.map(st => ({
            id: st.id,
            title_i18n: st.title,
            is_active: st.is_active,
          })),
          trademark_language_i18n: createLocalizedValue(
            lang => langProp(lang, forms[`about:${lang}`]?.trademarkLanguage) as string,
          ),
          discount_text_i18n: createLocalizedValue(
            lang => langProp(lang, forms[`about:${lang}`]?.discountText) as string,
          ),
          short_description_i18n: createLocalizedValue(
            lang => langProp(lang, forms[`about:${lang}`]?.shortDescription) as string,
          ),
          description_i18n: descriptionLocalizedValue,
          description_meta_i18n: createLocalizedValue(
            lang => langProp(lang, forms[`about:${lang}`]?.descriptionMeta) as string,
          ),
          title_i18n: createLocalizedValue(lang => langProp(lang, forms[`about:${lang}`]?.title) as string),
          title_meta_i18n: createLocalizedValue(lang => langProp(lang, forms[`about:${lang}`]?.titleMeta) as string),
          video_url_i18n: createLocalizedValue(lang => langProp(lang, forms[`about:${lang}`]?.videoUrl) as string),
          quote: {
            text_i18n: createLocalizedValue(lang => langProp(lang, forms['quote']?.text[lang]) as string),
            author_i18n: createLocalizedValue(lang => langProp(lang, forms['quote']?.author[lang]) as string),
            company_i18n: createLocalizedValue(lang => langProp(lang, forms['quote']?.company[lang]) as string),
          },
          default_packaging_i18n: createLocalizedValue(
            lang => langProp(lang, forms[`packaging:${lang}`]?.defaultPackaging) as string,
          ),
          alt_packaging_i18n: createLocalizedValue(
            lang => langProp(lang, forms[`packaging:${lang}`]?.altPackaging) as string,
          ),
          summary: createLocalizedValue(lang => forms[`summary:${lang}`]?.item),
          insights: createLocalizedValue(lang => forms[`insights:${lang}`]?.item),
          category_id: forms.origin.category,
          producer_id: forms.origin.producer,
          producer_product_id: forms.origin.productId,
          rank: forms.origin.rank,
          slug: forms.slug.slug,
          min_qty: typeof forms.origin.minQty === 'number' ? forms.origin.minQty : null,
          max_qty: typeof forms.origin.maxQty === 'number' ? forms.origin.maxQty : null,
          default_qty: forms.origin.defaultQty === 0 ? null : forms.origin.defaultQty,
          price: forms.origin.price ? forms.origin.price : undefined,
          is_new: forms.origin.isNew || false,
          is_featured: forms.origin.isFeatured || false,
          is_limited: forms.origin.isLimited,
          is_exclusive: forms.origin.isExclusive,
          hide_from_crm: forms.origin.hideFromCrm,
          subcategory_id: forms.origin.subcategory,
          is_out_of_stock: forms.origin.isOutOfStock,
          out_of_stock_date: forms.origin.outOfStockDate,
          status: forms.status.active ? Status.Active : Status.Inactive,
          images: forms.images.files || [],
          files: forms.files.files || [],
          badge_ids: forms.badge_ids.badge_ids,
          docusign_document_id: forms.requiredDocuments.docusignDocumentId,
          additional_options: forms.additionalOptions,
          options: forms.options.options,
          wizard_id: forms.origin.wizard,
          is_gift: forms.origin.isGift,
          price_type: forms.origin.priceType,
          units: {
            singular_i18n: createLocalizedValue(lang => forms[`units:${lang}`]?.singular),
            plural_i18n: createLocalizedValue(lang => forms[`units:${lang}`]?.plural),
          },
        },
        {
          dirty,
          duplicate,
        },
      )
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const handleSubmit = () => {
    submit(false)
  }

  const handleDuplicate = () => {
    submit(true)
  }

  const isLangActive = useMemo(() => {
    const map: Record<string, boolean> = {}
    languages.forEach(lang => {
      map[lang] = true
    })
    return (lang: AvailableLanguages) => {
      return map[lang] || false
    }
  }, [languages])

  const handleLangChange = (lang: AvailableLanguages, on: boolean) => {
    const values = new Set(languages)
    if (on) {
      values.add(lang)
    } else {
      values.delete(lang)
    }
    setLanguages([...values])
  }

  const handleOptionsChange = useCallback(
    (value: ProductOptionsData[]) => {
      slots['options'].setFieldValue('options', value)
    },
    [slots],
  )

  const isInputsShown = languages.length > 0
  useValidationErrorNotification(submitCount, valid)

  // prettier-ignore
  const isDuplicateAllowed = mode === 'edit'
    ? !dirty
      ? true
      : valid
    : valid

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <Styled.ContentBody>
            <FormComponents.FormSection title={t('form.originTitle')}>
              <OriginForm
                useFormik={bind('origin')}
                producer={initialValues.producer_id}
                wizard={initialValues.wizard_id}
                subcategory={initialValues.subcategory_id}
                productId={initialValues.producer_product_id}
                rank={initialValues.rank}
                category={initialValues.category_id}
                isNew={initialValues.is_new}
                isFeatured={initialValues.is_featured}
                minQty={initialValues.min_qty}
                maxQty={initialValues.max_qty}
                defaultQty={initialValues.default_qty}
                isLimited={!!initialValues.is_limited}
                isExclusive={!!initialValues.is_exclusive}
                hideFromCrm={!!initialValues.hide_from_crm}
                isOutOfStock={!!initialValues.is_out_of_stock}
                outOfStockDate={initialValues.out_of_stock_date}
                price={initialValues?.price}
                isGift={initialValues.is_gift}
                priceType={initialValues.price_type || PriceType.None}
              />
            </FormComponents.FormSection>
            <FormComponents.FormSection title={t('form.badgesTitle')}>
              <BadgeSelect
                useFormik={bind('badge_ids')}
                badgeIds={initialValues.badge_ids || []}
                enrichedBadges={initialValues.enrich_badges || []}
              />
            </FormComponents.FormSection>
            <FormComponents.FormSection title={t('form.aboutTitle')}>
              <Styled.FormContainer>
                <Styled.LabeledContainerStyled label={t('form.languagesTitle')}>
                  <Styled.AboutCountriesBlock>
                    {availableLanguagesList.map(lang => (
                      <Checkbox
                        label={t(`common:langNames.${lang}`)}
                        isChecked={isLangActive(lang)}
                        value={lang}
                        onChange={handleLangChange}
                        key={lang}
                      />
                    ))}
                  </Styled.AboutCountriesBlock>
                  {!languages.length && <Styled.ErrorStyled>{t('validation:language_required')}</Styled.ErrorStyled>}
                </Styled.LabeledContainerStyled>
                {isInputsShown && (
                  <Styled.VerticalFormsContainer>
                    {availableLanguagesList.map(lang => {
                      if (isLangActive(lang)) {
                        return (
                          <AboutForm
                            key={lang}
                            lang={lang}
                            title={initialValues.title_i18n}
                            titleMeta={initialValues.title_meta_i18n}
                            description={initialValues.description_i18n}
                            descriptionMeta={initialValues.description_meta_i18n}
                            shortDescription={initialValues.short_description_i18n}
                            trademarkLanguage={initialValues.trademark_language_i18n}
                            videoUrl={initialValues.video_url_i18n}
                            discountText={initialValues.discount_text_i18n}
                            useFormik={bind(`about:${lang}` as keyof FormikManagerData)}
                            setSlug={val => {
                              if (mode === 'create') slots['slug'].setFieldValue('slug', helpersSlug.slugify(val))
                            }}
                          />
                        )
                      }
                      return null
                    })}
                  </Styled.VerticalFormsContainer>
                )}
              </Styled.FormContainer>
              {isInputsShown ? (
                <>
                  <FormComponents.FormSection title={t('form.seedTreatmentTitle')}>
                    <SeedTreatmentForm
                      langList={languages}
                      useFormik={bind('seedTreatment')}
                      seedTreatment={initialValues.seed_treatment?.map(st => ({
                        ...st,
                        title: st.title_i18n,
                      }))}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('form.productOptionsFormTitle')}>
                    <ProductOptionsForm
                      useFormik={bind('options')}
                      options={initialValues.options}
                      onChange={handleOptionsChange}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('form.packagingTitle')}>
                    <Styled.VerticalFormsContainer>
                      {availableLanguagesList.map(lang => {
                        if (isLangActive(lang)) {
                          return (
                            <PackagingForm
                              lang={lang}
                              key={lang}
                              useFormik={bind(`packaging:${lang}` as keyof FormikManagerData)}
                              defaultPackaging={initialValues.default_packaging_i18n}
                              altPackaging={initialValues.alt_packaging_i18n}
                            />
                          )
                        }
                        return null
                      })}
                    </Styled.VerticalFormsContainer>
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('form.unitsTitle')}>
                    <Styled.VerticalFormsContainer>
                      {availableLanguagesList.map(lang => {
                        if (isLangActive(lang)) {
                          return (
                            <ProductUnitsForm
                              lang={lang}
                              key={lang}
                              useFormik={bind(`units:${lang}` as keyof FormikManagerData)}
                              singular={initialValues.units?.singular_i18n}
                              plural={initialValues.units?.plural_i18n}
                            />
                          )
                        }
                        return null
                      })}
                    </Styled.VerticalFormsContainer>
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('form.featuresTitle')}>
                    <FeaturesForm
                      langList={languages}
                      useFormik={bind('features')}
                      features={initialValues.features_i18n}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('form.filterAttributesTitle')}>
                    <AttributesForm
                      testId={'filter-attributes-form'}
                      useFormik={bind('filter_attributes')}
                      langList={languages}
                      attributes={initialValues?.filter_attributes}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('form.attributesTitle')}>
                    <AttributesForm
                      testId={'attributes-form'}
                      useFormik={bind('attributes')}
                      langList={languages}
                      attributes={initialValues?.attributes}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('form.filesTitle')}>
                    <ImageForm useFormik={bind('images')} files={initialValues.images} limit={10} />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('form.productInfoFilesTitle')}>
                    <ProductInfoFilesForm
                      useFormik={bind(`files` as keyof FormikManagerData)}
                      files={initialValues?.files || []}
                      langList={languages}
                      testId={'product-info-files'}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('form.quoteTitle')}>
                    <QuoteForm
                      useFormik={bind('quote')}
                      langList={languages}
                      text={initialValues?.quote?.text_i18n}
                      company={initialValues?.quote?.company_i18n}
                      author={initialValues?.quote?.author_i18n}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('form.statusTitle')}>
                    <StatusForm
                      useFormik={bind('status')}
                      active={initialValues.status ? initialValues.status === Status.Active : true}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('form.slug')}>
                    <SlugForm useFormik={bind('slug')} initialValue={initialValues.slug} disabled={mode === 'edit'} />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('form.requiredDocumentsTitle')}>
                    <RequiredDocumentsForm
                      useFormik={bind('requiredDocuments')}
                      docusignDocumentId={initialValues?.docusign_document_id}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('form.additionalOptionsTitle')}>
                    <AdditionalOptionsForm
                      useFormik={bind('additionalOptions')}
                      additionalOptions={initialValues?.additional_options}
                    />
                  </FormComponents.FormSection>
                </>
              ) : null}
            </FormComponents.FormSection>
          </Styled.ContentBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      <StickyFooterLayout.ButtonsFooter>
        <Button
          data-test-id={'submit'}
          filled={true}
          onClick={handleSubmit}
          progress={progress}
          intent={'primary'}
          disabled={!dirty || isFilesUploading || progress === Progress.WORK}
        >
          {t('common:save')}
        </Button>
        <Button
          onClick={handleDuplicate}
          disabled={!isDuplicateAllowed || isFilesUploading}
          progress={progress}
          intent={'primary'}
        >
          {mode === 'edit'
            ? dirty
              ? t('form.saveAndDuplicate')
              : t('form.createDuplicate')
            : t('form.saveAndDuplicate')}
        </Button>
        <StickyFooterBtn
          heading={t('common:cancelEditingHeader')}
          text={t('common:cancelEditingText')}
          onSubmit={onCancel}
          buttonText={t('common:dontSaveChanges')}
          intent={'cancel'}
        />

        {!!onRemove && (
          <StickyFooterDeleteBtn
            onRemove={onRemove}
            removeProgress={removeProgress}
            popoverText={t('form.removeText', { title: initialValues?.title_i18n?.en || '' })}
          />
        )}
      </StickyFooterLayout.ButtonsFooter>
    </StickyFooterLayout.Wrapper>
  )
}

export default ProductDetailsForm
