import { ProductWizardNode } from '@agro-club/frontend-shared'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api'

export const list = () => {
  return apiClient.get<ListResponse<ProductWizardNode>>(endpoints.productWizard())
}
export const getProductWizard = (id: string) => {
  return apiClient.get<ProductWizardNode>(endpoints.productWizardItem(id))
}

export const getProductWizardsList = (params?: {
  company_ids?: string[] | string
  wizard_id?: string[]
  full_data?: boolean
}) => {
  return apiClient.get<ListResponse<ProductWizardNode>>(endpoints.productWizard(), params)
}
