import { BasicSelect, LocalizedValue } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { TargetTypes } from 'modules/domain/target2/common/types'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'
import { createGetList } from './common'

export type TargetProduct = {
  id: string
  title: LocalizedValue
}

type TargetProductsFilter = {
  manufacturer_id?: string
  subcategory_id?: string
  title?: string
}

type TargetProductSelectProps = EntitySelectProps<TargetProduct> & {
  subcategoryId?: string
  manufacturerId?: string
  valueLabel?: string
  scope?: TargetTypes | 'default'
}

const getListForm = createGetList<TargetProduct, TargetProductsFilter>(endpoints.target2('products_manufacturer'))

const getListTerritoryFilter = createGetList<TargetProduct, TargetProductsFilter>(
  endpoints.target2('filters/targets_territory/product'),
)

const getListRetailerFilter = createGetList<TargetProduct, TargetProductsFilter>(
  endpoints.target2('filters/targets_retailer/product'),
)

const getListFarmerFilter = createGetList<TargetProduct, TargetProductsFilter>(
  endpoints.target2('filters/targets_farmer/product'),
)

const scopeManagerMap = {
  default: getListForm,
  [TargetTypes.Farmer]: getListFarmerFilter,
  [TargetTypes.Retailer]: getListRetailerFilter,
  [TargetTypes.Territory]: getListTerritoryFilter,
}

const getValue = (item: TargetProduct) => item.id

export const TargetProductSelect: React.VFC<TargetProductSelectProps> = ({
  subcategoryId,
  manufacturerId,
  isDisabled,
  valueLabel,
  onChange,
  scope = 'default',
  ...props
}) => {
  const { pick } = useLangPicker()

  const filter = useMemo(() => ({ manufacturer_id: manufacturerId, subcategory_id: subcategoryId }), [
    manufacturerId,
    subcategoryId,
  ])
  const fakeValue = useMemo(() => (valueLabel ? { title: { en: valueLabel }, id: 'fake' } : undefined), [valueLabel])

  const getLabel = (item: TargetProduct) => pick(item.title)

  return (
    <SelectTestWrapper data-test-id="target-product-select">
      {isDisabled ? (
        <BasicSelect isDisabled getOptionLabel={getLabel} getOptionValue={getValue} {...props} value={fakeValue} />
      ) : (
        <EntitySelect
          filter={filter}
          getEntityList={scopeManagerMap[scope]}
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          onChange={onChange}
          searchParamKey="title"
          {...props}
        />
      )}
    </SelectTestWrapper>
  )
}
