import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import ReturnDeclarationActions from './duck'
import ReturnDeclarationSelectors from './selectors'
import { ReturnDeclaration } from 'types/returnDeclaration'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'
import { DeclarationCountDto } from 'modules/domain/returnDeclaration/types'

export const useReturnDeclarationList: ResourceHook<ReturnDeclaration[], void[]> = () => {
  const progress = useSelector(ReturnDeclarationSelectors.listFetchProgress)
  const list = useSelector(ReturnDeclarationSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(ReturnDeclarationActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useReturnDeclaration: ResourceHook<ReturnDeclaration, [string]> = (id: string) => {
  const meta = useSelector(state => ReturnDeclarationSelectors.meta(state, id))
  const item = useSelector(state => ReturnDeclarationSelectors.item(state, id))
  const fetchAction = useAction(ReturnDeclarationActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useReturnDeclarationById: ResourceHook<ReturnDeclaration, [string]> = (id: string) => {
  const meta = useSelector(state => ReturnDeclarationSelectors.meta(state, id))
  const item = useSelector(state => ReturnDeclarationSelectors.item(state, id))
  const fetchAction = useAction(ReturnDeclarationActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const useReturnDeclarationCounter = ({ distributor_id, season_id, type, status }: DeclarationCountDto) => {
  const countProgress = useSelector(ReturnDeclarationSelectors.declarationCountFetchProgress)
  const error = useSelector(ReturnDeclarationSelectors.declarationCountFetchError)
  const count = useSelector(ReturnDeclarationSelectors.declarationCount)
  const fetchAction = useAction(ReturnDeclarationActions.countRequested, { distributor_id, season_id, type, status })

  useEffect(() => {
    fetchAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor_id, season_id, type, status])

  return { count, countProgress, error }
}
