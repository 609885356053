import React, { useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FarmerOrderItem } from 'types/farmerOrder'
import { Currency } from 'types/entities'
import useLangPicker from 'hooks/useLangPicker'
import styled from 'styled-components'
import { getPrettyPrice } from 'modules/utils/helpers'
import {
  SectionBody,
  SectionTable,
  SectionTableBodyCell,
  SectionTableBodyRow,
  SectionTableHead,
  SectionTableHeadCell,
  SectionTableHeadRow,
  SectionTableBody,
  SectionTitle,
  SectionFooter,
  ProductWizardType,
  ProcoteWizardData,
} from '@agro-club/frontend-shared'
import * as Styled from 'views/components/OrderProductItems/styles'
import { useAllProductOptionsList } from 'modules/domain/productOptions/hooks'
import { isNil } from 'ramda'
import { getUniqItemQty, getUniqItems } from './helpers'
import { useProductsLimit } from 'modules/domain/allocation/hooks'
import { DuckFootWizardData } from '../ProductWizards/ProductWizardDuckFoot/DuckFootWizardData'

export const AltPackagingContainer = styled.div`
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  font-size: 14px;
`

export const SeedTreatmentContainer = styled.div`
  margin-top: 8px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  font-size: 14px;
`

export const Label = styled.span`
  color: ${props => props.theme.color.onPrimaryDark};
`

export const ProductOptionsContainer = styled.div`
  margin-top: 10px;
  color: ${props => props.theme.color.onSurfaceMidEmphasys};
  font-size: 14px;
`

const SeedTreatment: React.FC<{ item: FarmerOrderItem }> = ({ item }) => {
  const { pick } = useLangPicker()
  const { t } = useTranslation('farmerOrder')
  const seedTreatment = item.product?.seed_treatment.find(st => st.id === item.seed_treatment_id)

  if (!item.seed_treatment_id || !seedTreatment) {
    return null
  }
  return (
    <SeedTreatmentContainer>
      {t('seedTreatment')}: <Label>{pick(seedTreatment.title_i18n)}</Label>
    </SeedTreatmentContainer>
  )
}

const ProductOptions: React.FC<{ item: FarmerOrderItem }> = ({ item }) => {
  const { t } = useTranslation('productOptions')
  const [, productOptions] = useAllProductOptionsList()
  const { pick } = useLangPicker()

  const data = useMemo(() => {
    const groups: { type: string; value: string }[] = []

    item.options?.map(id => {
      const found = productOptions?.find(o => o.id === id)
      if (!found) return

      groups.push({
        type: found.type,
        value: pick(found.title_i18n),
      })
    })

    return groups
  }, [item.options, productOptions, pick])

  if (!item.options?.length) {
    return null
  }

  return (
    <>
      {data.map((item, idx) => (
        <ProductOptionsContainer key={idx}>
          {t(`types.${item.type}`)}: <Label>{item.value}</Label>
        </ProductOptionsContainer>
      ))}
    </>
  )
}

export const AltPackaging: React.FC<{ item: FarmerOrderItem }> = ({ item }) => {
  const { pick } = useLangPicker()
  const altPackaging = pick(item.product?.alt_packaging_i18n)

  if (!altPackaging || !item.packaging) {
    return <div>-</div>
  }
  return (
    <AltPackagingContainer>
      {altPackaging} <Label>{item.packaging}</Label>
    </AltPackagingContainer>
  )
}

const OrderProductItemsReadOnly: React.FC<{
  items: FarmerOrderItem[]
  currency?: Currency
  showFinalQty?: boolean
  orderTotal?: number
  total?: string
  netTotal?: string
  distributorId?: string
  seasonId?: string
  mode?: 'create' | 'edit'
  setIsProductQtyMoreThanLimit?: (isQtyMoreThanLimit: boolean) => void
  context: 'farmer' | 'distributor'
}> = ({
  items,
  total,
  netTotal,
  children,
  showFinalQty = false,
  setIsProductQtyMoreThanLimit,
  mode,
  distributorId,
  seasonId,
  context,
}) => {
  const { t } = useTranslation(['farmerOrder', 'common'])

  const hasPrices = useMemo(() => !!items?.length && items.some(item => !!item.product?.price), [items])

  const netTotalJSX = netTotal && (
    <Styled.Total>
      {t('form.netTotal')} <Styled.TotalValue>{netTotal}</Styled.TotalValue>
    </Styled.Total>
  )
  const uniqItems = getUniqItems(items)

  const productsFromItems = useMemo(() => {
    return items.map(item => ({
      product_id: item.product_id,
      seed_treatment_id: item.seed_treatment_id || null,
    }))
  }, [items])

  const params = useMemo(
    () => ({
      distributorId: distributorId || '',
      seasonId: seasonId || '',
      products: productsFromItems,
    }),
    [distributorId, seasonId, productsFromItems],
  )

  const [, productsLimit = []] = useProductsLimit(context, params)
  const productWithLimit = useMemo(
    () =>
      productsLimit.find(
        item => !isNil(item.allowed_quantity) && getUniqItemQty(uniqItems, item.product_id) > item.allowed_quantity,
      ),
    [productsLimit, uniqItems],
  )

  useEffect(() => {
    if (productsLimit) {
      setIsProductQtyMoreThanLimit?.((mode === 'edit' && productWithLimit?.is_limit_exceeded) || false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsLimit])

  return (
    <Styled.Container>
      <SectionTitle>{t('form.sectionHeaders.orders')}</SectionTitle>
      <SectionBody>
        <SectionTable>
          <SectionTableHead>
            <SectionTableHeadRow>
              <SectionTableHeadCell>{t('form.tableHeaders.product')}</SectionTableHeadCell>
              {hasPrices && <SectionTableHeadCell>{t('form.tableHeaders.price')}</SectionTableHeadCell>}
              <SectionTableHeadCell>{t('form.tableHeaders.package')}</SectionTableHeadCell>
              <SectionTableHeadCell style={{ textAlign: 'center' }}>
                {t('form.tableHeaders.quantity')}
              </SectionTableHeadCell>
              {showFinalQty && (
                <SectionTableHeadCell style={{ textAlign: 'center' }}>
                  {t('form.tableHeaders.finalQty')}
                </SectionTableHeadCell>
              )}
              <SectionTableHeadCell>{t('form.tableHeaders.altPackaging')}</SectionTableHeadCell>
              <SectionTableHeadCell textAlign={'center'}>{t('form.tableHeaders.comment')}</SectionTableHeadCell>
            </SectionTableHeadRow>
          </SectionTableHead>
          <SectionTableBody>
            {items.map((item, idx) => (
              <SectionTableBodyRow key={idx}>
                <SectionTableBodyCell>
                  <div>
                    <b>
                      {item.product?.subcategory?.title || ''} {item.product?.title || ''}
                    </b>
                  </div>
                  <SeedTreatment item={item} />
                  <ProductOptions item={item} />
                  {item.wizard_data?.type == ProductWizardType.Procote && <ProcoteWizardData data={item.wizard_data} />}
                  {item.wizard_data?.type == ProductWizardType.DuckFoot && item.wizard_comment && (
                    <DuckFootWizardData note={item.wizard_comment} />
                  )}
                </SectionTableBodyCell>
                {hasPrices && (
                  <SectionTableBodyCell style={{ whiteSpace: 'nowrap' }}>
                    {getPrettyPrice(Number(item.product?.price), item.product?.producer?.currency)}
                  </SectionTableBodyCell>
                )}
                <SectionTableBodyCell style={{ whiteSpace: 'nowrap' }}>
                  {item.product?.default_packaging || ''}
                </SectionTableBodyCell>
                <SectionTableBodyCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                  {item.quantity}
                </SectionTableBodyCell>
                {showFinalQty && (
                  <SectionTableBodyCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                    {item.final_qty || '-'}
                  </SectionTableBodyCell>
                )}
                <SectionTableBodyCell style={{ whiteSpace: 'nowrap' }}>
                  <AltPackaging item={item} />
                </SectionTableBodyCell>
                <SectionTableBodyCell>{item.comment || ''}</SectionTableBodyCell>
              </SectionTableBodyRow>
            ))}
          </SectionTableBody>
        </SectionTable>
        {hasPrices && !!total && (
          <SectionFooter>
            <Styled.TotalWrap>
              <Styled.Total>
                {t('form.total')} <Styled.TotalValue>{total}</Styled.TotalValue>
              </Styled.Total>
              {netTotalJSX}
            </Styled.TotalWrap>
          </SectionFooter>
        )}
      </SectionBody>
      {children}
    </Styled.Container>
  )
}

export default OrderProductItemsReadOnly
