import { Button, Label, NumberInput, useAction } from '@agro-club/frontend-shared'
import FarmerOrderSkuActions from 'modules/domain/farmerOrderSku/duck'
import FarmerOrderSkuSelectors from 'modules/domain/farmerOrderSku/selectors'
import { Progress } from 'modules/types'
import { FarmerOrderSkuItem } from 'types/farmerOrderSku'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FileData } from '../FileManager/types'
import * as uuid from 'uuid'
import { PackageTypes } from 'modules/domain/packageTypes/types'
import { getStandardUnitsQty } from '../OrderSkuCardItems/helpers'
import { ProductUnits } from 'modules/domain/product/types'
import { FormattedQty } from '../OrderProductItems/styles'
import { ProductOptionType } from 'modules/domain/productOptions/types'

const QtyModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

const QtyModalRow = styled.div`
  display: grid;
  grid-template-columns: 10fr 10fr;
  margin-bottom: 16px;
`

const QtyModalLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`

const QtyModalInput = styled(NumberInput)`
  margin-left: auto;
  margin-right: 0;
  & > input {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, sans-serif;
  }
`

const QtyModalFooterWrapper = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 1fr 1fr;
`

const StyledLabel = styled(Label)`
  white-space: inherit;
`

const FarmerOrderSkuEnterQtyModal: React.FC<{
  orderId: string
  qtyColumn: keyof FarmerOrderSkuItem
  onClose(): void
}> = ({ orderId, qtyColumn, onClose }) => {
  const { t } = useTranslation('common')
  const order = useSelector(state => FarmerOrderSkuSelectors.item(state, orderId))

  const updateOrder = useAction(FarmerOrderSkuActions.updateRequested)
  const updateProgress = useSelector(FarmerOrderSkuSelectors.updateProgress)
  const updateListRequested = useAction(FarmerOrderSkuActions.listUpdateRequested)
  const [closeAfterSucceed, setCloseAfterSucceed] = useState(false)

  const [values, setValues] = useState(
    order?.sku_items.map(item => ({ ...item, [qtyColumn]: item[qtyColumn] || 0 })) ?? [],
  )

  const handleChange = useCallback(
    (index: number, qty: number) => {
      if (!values || !values[index]) return
      const newValues = [...values]
      newValues[index] = { ...newValues[index], [qtyColumn]: qty }
      setValues(newValues)
    },
    [values, setValues, qtyColumn],
  )

  const files = useMemo(() => {
    if (!order || !order?.files?.length) return []
    return order.files.map((item: FileData) => ({
      file: item.url,
      kind: 'current' as const,
      id: uuid.v4(),
      error: null,
    }))
  }, [order])

  const onSubmit = useCallback(() => {
    if (!order || !values) return
    updateOrder(order.id, {
      ...order,
      files,
      sku_items: values,
    })
    setCloseAfterSucceed(true)
  }, [order, values, updateOrder, setCloseAfterSucceed, files])

  useEffect(() => {
    if (updateProgress === Progress.SUCCESS && closeAfterSucceed) {
      onClose()
      updateListRequested()
      setCloseAfterSucceed(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProgress, updateListRequested, onClose])

  const getFormattedQty = useCallback((units: ProductUnits, packageType: PackageTypes, qty: number) => {
    return getStandardUnitsQty(qty, units, packageType)
  }, [])

  if (!order || !values) return null

  return (
    <>
      <QtyModalWrapper>
        {values.map((item, index) => {
          const qty = Number(item[qtyColumn]) || 0
          const seedTreatment = item.sku?.params.options.find(o => o.type === ProductOptionType.SEED_TREATMENT)
          return (
            <QtyModalRow key={item.id}>
              <QtyModalLabelWrapper>
                <StyledLabel>{item.sku?.product?.title}</StyledLabel>
                <StyledLabel>{seedTreatment?.title}</StyledLabel>
                <StyledLabel>{item.sku?.params.package_title}</StyledLabel>
              </QtyModalLabelWrapper>
              <div>
                <QtyModalInput
                  value={qty}
                  min={0}
                  size={'medium'}
                  onChange={val => handleChange(index, val)}
                  inputStep={0.1}
                />
                {item.sku?.product?.units && item.sku.params.package && (
                  <FormattedQty>{getFormattedQty(item.sku.product.units, item.sku.params.package, qty)}</FormattedQty>
                )}
              </div>
            </QtyModalRow>
          )
        })}
        <QtyModalFooterWrapper>
          <Button intent={'cancel'} filled={false} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button intent={'primary'} filled={true} onClick={onSubmit} progress={updateProgress}>
            {t('save')}
          </Button>
        </QtyModalFooterWrapper>
      </QtyModalWrapper>
    </>
  )
}

export default FarmerOrderSkuEnterQtyModal
