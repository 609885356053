import { WizardData } from '@agro-club/frontend-shared'
import { getProductWizardsList } from 'modules/domain/productWizard/managers'
import { StorefrontSku } from 'modules/domain/storefront/types'
import { StorefrontCard } from 'modules/domain/storefrontCard/types'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CardWizardManager } from './CardWizardManager'
import cardManagers from 'modules/domain/storefrontCard/managers'
import skuManagers from 'modules/domain/storefront/managers'

export type CardWizardContainerProps = {
  sellerId: string
  onChange: (
    qty: number,
    card: StorefrontCard,
    sku: StorefrontSku,
    wizard_comment?: string,
    wizard_data?: WizardData<any>,
  ) => void
  onResultCardChange?: (card: StorefrontCard, sku: StorefrontSku) => void
}

const ContainerWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 24px 0;
`

const Title = styled.div`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.48;
`

const useWizards = makeCancellableResourceListHook(makeCancelable(getProductWizardsList))

const CardWizardContainer = ({ sellerId, onChange, onResultCardChange }: CardWizardContainerProps) => {
  const { t } = useTranslation('product')
  const optionsFilter = useMemo(
    () => ({
      full_data: true,
      company_ids: sellerId,
    }),
    [sellerId],
  )
  const [, wizards = []] = useWizards(optionsFilter)

  const [card, setCard] = useState<StorefrontCard | null>(null)
  const [sku, setSku] = useState<StorefrontSku | null>(null)

  const handleResultCardChange = async (cardId: string, skuId: string) => {
    const [cardResponse, skuResponse] = await Promise.all([cardManagers.getItem(cardId), skuManagers.getItem(skuId)])
    setCard(cardResponse)
    setSku(skuResponse as StorefrontSku)
    onResultCardChange?.(cardResponse, skuResponse as StorefrontSku)
  }

  const handleChange = (
    _: string,
    __: string,
    qty: string | number,
    wizard_comment?: string,
    wizard_data?: WizardData<any>,
  ) => {
    if (card && sku) onChange(Number(qty || sku.min_qty || 1), card, sku, wizard_comment, wizard_data)
  }

  const renderWizardItems = wizards?.map(wizard => {
    return (
      <CardWizardManager
        wizardNode={wizard}
        key={wizard.id}
        changeOrderList={handleChange}
        onResultCardChange={handleResultCardChange}
      />
    )
  })

  return wizards.length ? (
    <ContainerWrapper>
      <Title>{t('product:form.wizard')}</Title>
      {renderWizardItems}
    </ContainerWrapper>
  ) : null
}

export default React.memo(CardWizardContainer)
