import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import ProductTable from './ProductList/ProductTable'
import ProductEdit from './ProductList/ProductEdit'
import ProductAdd from './ProductList/ProductAdd'
import ProductOptionsList from './ProductOptions/ProductOptionsList'
import ProductOptionsAdd from './ProductOptions/ProductOptionsAdd'
import ProductOptionsEdit from './ProductOptions/ProductOptionsEdit'
import ProductRoutes from './routes'
import { FormProps } from 'views/pages/Product/ProductDetailsForm/ProductDetailsForm'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { isAgro } from 'types/entities'

const ProductPage: React.FC = () => {
  const [initialState, setInitialState] = useState<FormProps>()
  const role = useSelector(AuthSelectors.role)

  return (
    <Switch>
      <Route path={ProductRoutes.List} exact={true}>
        <ProductTable />
      </Route>
      <Route path={ProductRoutes.Edit} exact={true}>
        <ProductEdit onUpdateInitialState={setInitialState} />
      </Route>
      <Route path={ProductRoutes.Add} exact={true}>
        <ProductAdd onUpdateInitialState={setInitialState} initialState={initialState} />
      </Route>
      {isAgro(role) && (
        <>
          <Route path={ProductRoutes.OptionsList} exact={true}>
            <ProductOptionsList />
          </Route>
          <Route path={ProductRoutes.OptionsEdit} exact={true}>
            <ProductOptionsEdit />
          </Route>
          <Route path={ProductRoutes.OptionsAdd} exact={true}>
            <ProductOptionsAdd />
          </Route>
        </>
      )}
      <Redirect to={ProductRoutes.List} />
    </Switch>
  )
}

export default ProductPage
