import { TargetTypes } from 'modules/domain/target2/common/types'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { EntityMultiSelect, EntityMultiSelectProps } from 'views/components/EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'
import { createGetList } from './common'

export type TargetSeason = {
  id: string
  title: string
}

type TargetSeasonFilter = {
  manufacturer_id?: string
  title?: string
}

type TargetSeasonSelectProps = EntityMultiSelectProps<TargetSeason> & {
  valueLabel?: string
  manufacturerId?: string
  scope?: TargetTypes
}

const getListTerritoryFilter = createGetList<TargetSeason, TargetSeasonFilter>(
  endpoints.target2('filters/targets_territory/seasons'),
)

const getListRetailerFilter = createGetList<TargetSeason, TargetSeasonFilter>(
  endpoints.target2('filters/targets_retailer/seasons'),
)

const getListFarmerFilter = createGetList<TargetSeason, TargetSeasonFilter>(
  endpoints.target2('filters/targets_farmer/seasons'),
)

const scopeManagerMap = {
  [TargetTypes.Farmer]: getListFarmerFilter,
  [TargetTypes.Retailer]: getListRetailerFilter,
  [TargetTypes.Territory]: getListTerritoryFilter,
}

const getLabel = (item: TargetSeason) => item.title

const getValue = (item: TargetSeason) => item.id

export const TargetSeasonSelect: React.VFC<TargetSeasonSelectProps> = ({
  manufacturerId,
  scope = 'territory',
  ...props
}) => {
  const filter = useMemo(() => ({ manufacturer_id: manufacturerId }), [manufacturerId])

  return (
    <SelectTestWrapper data-test-id="target-season-multi-select">
      <EntityMultiSelect
        filter={filter}
        getEntityList={scopeManagerMap[scope]}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        searchParamKey="title"
        disableTags
        hideSelectedOptions={false}
        {...props}
      />
    </SelectTestWrapper>
  )
}
