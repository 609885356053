import { Input, useAction } from '@agro-club/frontend-shared'
import { useFormik } from 'formik'
import useLangPicker from 'hooks/useLangPicker'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import AuthSelectors from 'modules/domain/auth/selectors'
import { TerritoryTarget } from 'modules/domain/target2/common/types'
import TerritoryTargetActions from 'modules/domain/target2/territoryTarget/duck'
import TerritoryTargetSelectors from 'modules/domain/target2/territoryTarget/selectors'
import { Progress } from 'modules/types'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isAgro, isProducer } from 'types/entities'
import { CompoundedTableEditableForm } from 'views/components/CompoundedTable/CompoundedTableEditable/CompoundedTableEditableRow'
import * as Yup from 'yup'
import { TargetManufacturerSelect } from '../selects/TargetManufacturerSelect'
import { TargetProductSelect } from '../selects/TargetProductSelect'
import { TargetSubcategorySelect } from '../selects/TargetSubcategorySelect'
import { TargetTerritorySelect } from '../selects/TargetTerritorySelect'
import { FormWrapper } from '../styles'

export const TerritoryTargetForm: CompoundedTableEditableForm<TerritoryTarget> = ({ Buttons, entity }) => {
  const { t } = useTranslation(['target2', 'validation'])

  const addRequested = useAction(TerritoryTargetActions.addRequested)
  const addProgress = useSelector(TerritoryTargetSelectors.addProgress)
  const addErrorDetail = useSelector(TerritoryTargetSelectors.addErrorDetail)

  const removeRequested = useAction(TerritoryTargetActions.removeRequested)
  const removeProgress = useSelector(TerritoryTargetSelectors.removeProgress)
  const removeErrorDetail = useSelector(TerritoryTargetSelectors.removeErrorDetail)

  const updateProgress = useSelector(TerritoryTargetSelectors.updateProgress)
  const meta = useSelector(TerritoryTargetSelectors.meta(entity?.id))

  const updateRequested = useAction(TerritoryTargetActions.updateRequested)
  const userCompany = useSelector(AuthSelectors.userCompany)

  const role = useSelector(AuthSelectors.role)
  const producer = isProducer(role)
  const agro = isAgro(role)

  const { pick } = useLangPicker()

  const validationSchema = useMemo(() => {
    return entity
      ? Yup.object({
          target_value: Yup.number().required(t('validation:field_required')),
        })
      : Yup.object({
          territory_id: Yup.string().required(t('validation:field_required')),
          manufacturer_id: Yup.string().required(t('validation:field_required')),
          product_id: Yup.string().required(t('validation:field_required')),
          target_value: Yup.number().required(t('validation:field_required')),
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, !!entity])

  // This is to prevent reinitialize on every render
  const initialValues = useMemo(
    () => ({
      territory_id: '',
      manufacturer_id: userCompany && producer ? userCompany.id : '',
      product_id: '',
      target_value: entity?.target_value ?? 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entity?.target_value],
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      if (entity?.id) {
        updateRequested(entity.id, { target_value: values.target_value })
      } else {
        addRequested(values, false)
      }
    },
  })

  const isEdit = !!entity

  useEffect(() => {
    if (addProgress === Progress.SUCCESS && !entity) {
      formik.resetForm()
      setSubcategoryId('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addProgress])

  // Reset form on update success
  useEffect(() => {
    formik.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity?.target_value])

  const [subcategoryId, setSubcategoryId] = useState<string | undefined>('')

  useValidationErrorNotification(
    formik.submitCount,
    formik.isValid,
    removeErrorDetail || entity ? meta.updateErrorDetail : addErrorDetail,
  )

  const handleChangeManufacturer = val => {
    formik.setFieldValue('manufacturer_id', val)
    formik.setFieldValue('territory_id', '')
    formik.setFieldValue('product_id', '')
    setSubcategoryId('')
  }

  return (
    <>
      <FormWrapper>
        {agro && (
          <TargetManufacturerSelect
            required
            label={t('form.labels.manufacturer')}
            placeholder=""
            isDisabled={isEdit}
            value={formik.values.manufacturer_id}
            valueLabel={entity?.manufacturer?.title}
            onChange={handleChangeManufacturer}
            invalid={formik.touched.manufacturer_id && !!formik.errors.manufacturer_id}
          />
        )}
        <TargetTerritorySelect
          required
          placeholder=""
          label={t('form.labels.territory')}
          isDisabled={isEdit || !formik.values.manufacturer_id}
          value={formik.values.territory_id}
          valueLabel={entity?.territory?.title}
          onChange={val => {
            formik.setFieldValue('territory_id', val)
          }}
          invalid={formik.touched.territory_id && !!formik.errors.territory_id}
          manufacturerId={formik.values.manufacturer_id}
        />
        {!isEdit && (
          <TargetSubcategorySelect
            label={t('form.labels.subcategory')}
            placeholder=""
            value={subcategoryId}
            onChange={setSubcategoryId}
            manufacturerId={formik.values.manufacturer_id}
            isClearable
            isDisabled={!formik.values.manufacturer_id}
          />
        )}
        <TargetProductSelect
          required
          placeholder=""
          label={t('form.labels.product')}
          isDisabled={isEdit || !formik.values.manufacturer_id}
          value={formik.values.product_id}
          valueLabel={pick(entity?.product?.title)}
          onChange={val => {
            formik.setFieldValue('product_id', val)
          }}
          invalid={formik.touched.product_id && !!formik.errors.product_id}
          subcategoryId={subcategoryId}
          manufacturerId={formik.values.manufacturer_id}
        />
        <Input
          required
          label={t('form.labels.target')}
          {...formik.getFieldProps('target_value')}
          invalid={formik.touched.target_value && !!formik.errors.target_value}
          type="number"
          min={0}
        />
      </FormWrapper>
      <Buttons
        onSubmit={formik.handleSubmit}
        onRemove={entity ? () => removeRequested(entity.id) : undefined}
        submitProgress={isEdit ? updateProgress : addProgress}
        removeProgress={removeProgress}
        createText={t('form.create')}
        removeText={t('form.delete')}
        updateText={t('form.update')}
        isSubmitDisabled={!formik.dirty}
      />
    </>
  )
}
