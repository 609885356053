import { ProcoteSkuProps, ProductWizardNode, ProductWizardType } from '@agro-club/frontend-shared'
import React, { useState } from 'react'
import { AdderBtn } from 'views/components/ProductWizards/AdderBtn'
import { CardWizardProcote } from './CardWizardProcote/CardWizardProcote'

export type CardWizardManagerProps = {
  wizardNode: ProductWizardNode
  changeOrderList: ProcoteSkuProps['handleCrm']
  onResultCardChange: ProcoteSkuProps['onResultCardChange']
}

export const CardWizardManager = ({ wizardNode, changeOrderList, onResultCardChange }: CardWizardManagerProps) => {
  const [openedProductWizard, setOpenedProductWizard] = useState(false)

  const handleWizardOpen = () => {
    setOpenedProductWizard(true)
  }
  const handleWizardClose = () => {
    setOpenedProductWizard(false)
  }

  const procoteJSX =
    wizardNode.type === ProductWizardType.Procote ? (
      <CardWizardProcote
        opened={openedProductWizard}
        onClose={handleWizardClose}
        wizardNode={wizardNode}
        changeOrderList={changeOrderList}
        onResultCardChange={onResultCardChange}
      />
    ) : null

  return (
    <>
      {procoteJSX}
      <AdderBtn handleWizardOpen={handleWizardOpen} productWizardType={wizardNode.type} />
    </>
  )
}
