import { FilterProps } from 'views/components/TableFilters/TableFilters'
import { BusinessModel } from 'types/entities'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'
// import { useTranslation } from 'react-i18next'

type BusinessModelFilterProps<TShape, TField extends keyof TShape> = Omit<
  FilterProps<TShape, TField, BusinessModel, true>,
  'options' | 'title'
>

export default function BusinessModelFilter<TShape, TField extends keyof TShape>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: BusinessModelFilterProps<TShape, TField>,
) {
  return null
  // const BusinessModelFilterTableFilter = TableFilterSingle<TShape, TField, BusinessModel>()
  // const { t } = useTranslation(['company', 'labels'])
  // const { t: tLabels } = useTranslation('labels')
  //
  // const options = useMemo(
  //   () => [
  //     {
  //       value: BusinessModel.Full,
  //       title: t('businessModel.full'),
  //     },
  //     {
  //       value: BusinessModel.Lite,
  //       title: t('businessModel.lite'),
  //     },
  //   ],
  //   [t],
  // )
  // return (
  //   <BusinessModelFilterTableFilter
  //     options={options}
  //     title={tLabels('labels:model')}
  //     filterValue={filterValue}
  //     handleFilterChange={handleFilterChange}
  //     multi={false}
  //   />
  // )
}
