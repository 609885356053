import { Button, Label, Modal, NumberInput, useAction } from '@agro-club/frontend-shared'
import FarmerOrderActions from 'modules/domain/farmerOrder/duck'
import FarmerOrderSelectors from 'modules/domain/farmerOrder/selectors'
import { Progress } from 'modules/types'
import { FarmerOrderItem } from 'types/farmerOrder'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FileData } from '../FileManager/types'
import * as uuid from 'uuid'
import { Product } from 'modules/domain/product/types'
import checkOutOfStockEditingIsAllowed from 'hooks/useOutOfStockEditingIsAllowed'

const QtyModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const QtyModalRow = styled.div`
  display: grid;
  grid-template-columns: 10fr 10fr;
  margin-bottom: 16px;
`

const QtyModalLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`

const QtyModalInput = styled(NumberInput)`
  margin-left: auto;
  margin-right: 0;
  & > input {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, sans-serif;
  }
`

const QtyModalFooterWrapper = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 1fr 1fr;
`

const isEditingAllowed = (product: Product) => checkOutOfStockEditingIsAllowed(product)

const FarmerOrderEnterQtyModal: React.FC<{
  orderId: string
  qtyColumn: keyof FarmerOrderItem
  heading: string
  isOpen: boolean
  onClose(): void
}> = ({ orderId, qtyColumn, heading, isOpen, onClose }) => {
  const { t } = useTranslation('common')
  const order = useSelector(state => FarmerOrderSelectors.item(state, orderId))

  const updateOrder = useAction(FarmerOrderActions.updateRequested)
  const updateProgress = useSelector(FarmerOrderSelectors.updateProgress)
  const updateListRequested = useAction(FarmerOrderActions.listUpdateRequested)
  const [closeAfterSucceed, setCloseAfterSucceed] = useState(false)

  const [values, setValues] = useState(
    order?.items.reduce((dict, item, key) => ({ ...dict, [key]: { ...item, [qtyColumn]: item[qtyColumn] || 0 } }), {}),
  )

  const handleChange = useCallback(
    (key: string, qty: number) => {
      if (!values) return
      setValues({ ...values, [key]: { ...values[key], [qtyColumn]: qty } })
    },
    [values, setValues, qtyColumn],
  )

  const files = useMemo(() => {
    if (!order || !order?.files?.length) return []
    return order.files.map((item: FileData) => ({
      file: item.url,
      kind: 'current' as const,
      id: uuid.v4(),
      error: null,
    }))
  }, [order])

  const onSubmit = useCallback(() => {
    if (!order || !values) return
    updateOrder(order.id, {
      ...order,
      files,
      items: Object.values(values),
    })
    setCloseAfterSucceed(true)
  }, [order, values, updateOrder, setCloseAfterSucceed, files])

  useEffect(() => {
    if (updateProgress === Progress.SUCCESS && closeAfterSucceed) {
      onClose()
      updateListRequested()
      setCloseAfterSucceed(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProgress, updateListRequested, onClose])

  if (!order || !values) return null

  return (
    <Modal isOpen={isOpen} onClose={onClose} heading={heading} size={'medium'}>
      <QtyModalWrapper>
        {Object.keys(values).map((key: string) => {
          const item = values[key]
          const stId = item.seed_treatment_id
          const seedTreatment = stId ? item.product?.seed_treatment.find(el => el.id === stId) : null

          return (
            <QtyModalRow key={key}>
              <QtyModalLabelWrapper>
                <Label>{item.product.title}</Label>
                <Label style={{ whiteSpace: 'pre-wrap' }}>{seedTreatment?.title}</Label>
              </QtyModalLabelWrapper>
              <QtyModalInput
                value={item[qtyColumn] || 0}
                min={0}
                size={'medium'}
                onChange={val => handleChange(key, val)}
                disabled={!isEditingAllowed(item.product)}
              />
            </QtyModalRow>
          )
        })}
        <QtyModalFooterWrapper>
          <Button intent={'cancel'} filled={false} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button intent={'primary'} filled={true} onClick={onSubmit} progress={updateProgress}>
            {t('save')}
          </Button>
        </QtyModalFooterWrapper>
      </QtyModalWrapper>
    </Modal>
  )
}

export default FarmerOrderEnterQtyModal
