import {
  AdvancedHeadCell,
  Button,
  controlStyle,
  CustomSelectStyles,
  menuListStyle,
  menuStyle,
  optionStyle,
  RangeDatePicker,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useHistoryPush,
  usePageVisibilityChange,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import useDateFormatFn from 'hooks/useDateFormatFn'
import useDateFormat from 'hooks/useDateFormat'
import { isValid, parseISO } from 'date-fns'
import DistributorOrderSkuActions from 'modules/domain/distributorOrderSku/duck'
import { useDistributorOrderSkuList } from 'modules/domain/distributorOrderSku/hooks'
import DistributorOrderSkuSelectors from 'modules/domain/distributorOrderSku/selectors'
import {
  DistributorOrderSku,
  DistributorOrderSkuItem,
  DistributorOrderSkuListRequestFilter,
} from 'modules/domain/distributorOrderSku/types'
import { Progress } from 'modules/types'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CellProps, HeaderProps, useRowSelect, useTable } from 'react-table'
import styled, { StyledProps } from 'styled-components'
import { Columns, FeatureFlagModifiers, Sections, Tables } from 'types/entities'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import DistributorOrderStatusColored from 'views/components/DistributorOrderStatusColored/DistributorOrderStatusColored'
import DistributorOrderStatusSelect from 'views/components/DistributorOrderStatusSelect/DistributorOrderStatusSelect'
import DistributorsMultiSelect from 'views/components/DistributorsMultiSelect/DistributorsMultiSelect'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'
import { Filter } from 'views/components/TableFilters/TableFilters'
import Routes from '../routes'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateCrmFieldAccessString, generateCrmTableColumnAccessString } from 'modules/utils/generateStringHelpers'
import useLangPicker from 'hooks/useLangPicker'
import { CellWrapper } from 'views/components/OrderProductItems/styles'
import { ProductOptionType } from 'modules/domain/productOptions/types'
import { DistributorOrderStatus } from 'types/distributorOrder'
import { AlignWrapper } from '../../../components/AlignWrapper/AlignWrapper'
import useDownload from '../../../../hooks/useDownload'
import { endpoints } from '../../../../modules/endpoints'
import CategorySelect from 'views/components/CategorySelect/CategorySelect'
import SubcategorySelect from 'views/components/CategorySelect/SubcategorySelect'
import { SellerFilterSelect } from './SellerFilterSelect'
import { numberToPrecision } from 'modules/utils/helpers'

type ThemedProps = StyledProps<{}>
const LIST_UPDATE_INTERVAL = 60000

const customSelectStyles: CustomSelectStyles = {
  control: (...args) => ({
    ...controlStyle(...args),
    height: '32px',
    minHeight: '32px',
    width: '200px',
  }),
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
    width: 'auto',
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  }),
}

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  height: 100%;
  overflow: auto;
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledFilter = styled(Filter)`
  margin: 0 16px 16px 0 !important;
  width: 200px;
`

export const Filters = styled.div`
  position: sticky;
  top: 0;
  border-top: ${({ theme }: ThemedProps) => `1px solid ${theme.color.outlineMid}`};
  background-color: ${({ theme }: ThemedProps) => theme.color.onPrimaryLight};
  display: flex;
  justify-content: space-between;
  padding: 12px 36px 16px;
  z-index: 2;
`

const StatusColumnHeaderCell: React.FC<HeaderProps<DistributorOrderSku>> = () => {
  const { t } = useTranslation('distributorOrder')
  return (
    <TComponents.CheckboxWrapper>
      <span>{t('list.tableHeaders.status')}</span>
    </TComponents.CheckboxWrapper>
  )
}

const StatusCell: React.FC<CellProps<DistributorOrderSku>> = ({ row }) => {
  const { t } = useTranslation('distributorOrder')
  return (
    <TComponents.CheckboxWrapper>
      <DistributorOrderStatusColored
        status={row.values.status}
        dangerouslySetInnerHTML={{ __html: t(`status.${row.values.status}`) }}
      />
    </TComponents.CheckboxWrapper>
  )
}

const ProducerCell: React.FC<CellProps<DistributorOrderSku>> = ({ cell, column }) => {
  return (
    <TComponents.BoldText key={column.id}>
      {((cell.value && cell.value.internal_name) || '').toUpperCase()}
    </TComponents.BoldText>
  )
}

const IdDateCell: React.FC<CellProps<DistributorOrderSku>> = ({ cell, row, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })

  return (
    <div key={column.id} style={{ whiteSpace: 'nowrap' }}>
      <div>{row.values.slug}</div>
      {!!cell.value && <div>{getFormatedDate(cell.value)}</div>}
    </div>
  )
}

const OrderNumberCell: React.FC<CellProps<DistributorOrderSku>> = ({ cell, column }) => {
  return (
    <div key={column.id} style={{ whiteSpace: 'nowrap' }}>
      {!!cell.value && <div>{cell.value}</div>}
    </div>
  )
}

const Category: React.FC<CellProps<DistributorOrderSku>> = ({ row }) => {
  const items = row.original.sku_items
  const { pick } = useLangPicker()
  if (!items || !items.length) return null
  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      {items.map(
        (item, i) =>
          item.sku?.product?.subcategory && (
            <div key={`${item.sku?.params.product_id}-${i}`}>{pick(item.sku?.product.subcategory.title_i18n)}</div>
          ),
      )}
    </div>
  )
}

const Packaging: React.FC<CellProps<DistributorOrderSku>> = ({ row }) => {
  return (
    <CellWrapper>
      {row.original.sku_items.map((item, i) => (
        <div key={i}>{item.sku?.params.package_title}</div>
      ))}
    </CellWrapper>
  )
}

const Qty: React.FC<CellProps<DistributorOrderSku>> = ({ row }) => {
  const items = row.original.sku_items as DistributorOrderSkuItem[]

  return (
    <div>
      {items.map((item, i) => (
        <div key={i}>{numberToPrecision(item.quantity)}</div>
      ))}
    </div>
  )
}

const StandardUnits: React.FC<CellProps<DistributorOrderSku>> = ({ row }) => {
  return (
    <div>
      {row.original.sku_items.map((item, i) => (
        <div key={i}>
          {item.standard_qty ? (item.standard_qty === '0' ? '0' : numberToPrecision(item.standard_qty)) : '-'}
        </div>
      ))}
    </div>
  )
}

const SeedTreatmentCell: React.FC<CellProps<DistributorOrderSku>> = ({ row }) => {
  const items = row.original.sku_items

  return (
    <div>
      {items.map((item: DistributorOrderSkuItem, i: number) => {
        const seedTreatment = item.sku?.params.options.find(o => o.type === ProductOptionType.SEED_TREATMENT)
        if (!seedTreatment) return <div key={`${item.sku_id}-${i}`}>-</div>

        return (
          <CellWrapper key={`${item.sku_id}-${i}`} title={seedTreatment?.title || '-'}>
            {seedTreatment?.title || '-'}
          </CellWrapper>
        )
      })}
    </div>
  )
}

const ItemsCell: React.FC<CellProps<DistributorOrderSku>> = ({ row }) => {
  if (!row.original.sku_items) {
    return <span>{''}</span>
  }

  return (
    <div>
      {row.original.sku_items.map((item: DistributorOrderSkuItem, i: number) => {
        return <div key={`${item.id}-${i}`} style={{ whiteSpace: 'nowrap' }}>{`${item.sku?.product?.title || ''}`}</div>
      })}
    </div>
  )
}

const DistributorCell: React.FC<CellProps<DistributorOrderSku>> = ({ cell, column }) => {
  return <span key={column.id}>{(cell.value && cell.value.internal_name) || ''}</span>
}

const sortFields = ['status', 'created_at', 'order_date']

const SortableHeadCell = AdvancedHeadCell<'status' | 'created_at' | 'order_date'>()

const DistributorOrderSkuList: React.FC = () => {
  const push = useHistoryPush()
  const { t } = useTranslation(['distributorOrder', 'labels', 'common'])
  const checkFeatureFlag = useFeatureFlags()
  const hasAccess = useCallback(flag => !checkFeatureFlag(flag), [checkFeatureFlag])
  const isSeedTreatmentHidden = !hasAccess(
    generateCrmTableColumnAccessString(Tables.DistributorOrders, Columns.SeedTreatment, FeatureFlagModifiers.Hidden),
  )
  const isOrderNumberHidden = !checkFeatureFlag(
    generateCrmFieldAccessString(Sections.RetailerOrders, 'orderNumber', FeatureFlagModifiers.Enabled),
  )

  const visibleColumns = useMemo(
    () => [
      {
        Header: StatusColumnHeaderCell,
        accessor: 'status' as const,
        Cell: StatusCell,
      },
      {
        Header: t('list.tableHeaders.idAndDate'),
        accessor: 'order_date' as const,
        Cell: IdDateCell,
      },
      {
        Header: t('list.tableHeaders.orderNumber'),
        accessor: 'external_order_id' as const,
        Cell: OrderNumberCell,
        hidden: isOrderNumberHidden,
      },
      {
        Header: t('list.tableHeaders.seller'),
        accessor: 'producer' as const,
        Cell: ProducerCell,
      },
      {
        Header: t('list.tableHeaders.distributor'),
        accessor: 'distributor' as const,
        Cell: DistributorCell,
      },
      {
        Header: t('list.tableHeaders.season'),
        accessor: 'season.title' as 'season',
      },
      {
        Header: t('list.tableHeaders.crops'),
        accessor: 'sku-items' as const,
        id: 'crops',
        Cell: Category,
      },
      {
        Header: t('list.tableHeaders.items'),
        accessor: 'sku-items' as const,
        id: 'items',
        Cell: ItemsCell,
      },
      {
        Header: t('list.tableHeaders.packaging'),
        accessor: 'sku_items' as const,
        id: 'packaging',
        Cell: Packaging,
      },
      {
        Header: t('list.tableHeaders.quantity'),
        accessor: 'sku-items' as const,
        id: 'qty',
        Cell: Qty,
      },
      {
        Header: t('list.tableHeaders.standardUnits'),
        accessor: 'sku-items' as const,
        id: 'standardUnits',
        Cell: StandardUnits,
      },
      {
        Header: t('list.tableHeaders.seedTreatment'),
        accessor: 'sku-items' as const,
        id: 'seed-treatment',
        Cell: SeedTreatmentCell,
        hidden: isSeedTreatmentHidden,
      },
      {
        accessor: 'region_id' as const,
        hidden: true,
      },
      {
        accessor: 'created_at' as const,
        hidden: true,
      },
      {
        accessor: 'currency' as const,
        hidden: true,
      },
      {
        accessor: 'slug' as const,
        hidden: true,
      },
    ],
    [t, isOrderNumberHidden, isSeedTreatmentHidden],
  )

  const hiddenColumns: (keyof DistributorOrderSku)[] = useMemo(
    () => ['currency', 'region_id', 'created_at', 'slug'],
    [],
  )
  const [progress, data = []] = useDistributorOrderSkuList()

  const { columns, rows, prepareRow } = useTable(
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
      columns: visibleColumns,
      data,
      initialState: { hiddenColumns },
    },
    useRowSelect,
  )

  const filterUpdated = useAction(DistributorOrderSkuActions.filterUpdated)
  const filterCleared = useAction(DistributorOrderSkuActions.filterHasBeenReset)
  const sortingUpdated = useAction(DistributorOrderSkuActions.sortingUpdated)
  const listRequested = useAction(DistributorOrderSkuActions.listRequested)
  const listUpdateRequested = useAction(DistributorOrderSkuActions.listUpdateRequested)
  const filterValue = useSelector(DistributorOrderSkuSelectors.filter)
  const page = useSelector(DistributorOrderSkuSelectors.page)
  const pageSize = useSelector(DistributorOrderSkuSelectors.pageSize)
  const pages = useSelector(DistributorOrderSkuSelectors.pages)
  const total = useSelector(DistributorOrderSkuSelectors.total)
  const { start_date, end_date } = filterValue
  const { sort_field, sort_reversed } = useSelector(DistributorOrderSkuSelectors.sorting)
  const updateTimeout = useRef<number | null>(null)

  const plantUpdateTimeout = useCallback(() => {
    updateTimeout.current = window.setTimeout(() => {
      listUpdateRequested()
      plantUpdateTimeout()
    }, LIST_UPDATE_INTERVAL)
  }, [listUpdateRequested])

  const clearUpdateTimeout = useCallback(() => {
    if (updateTimeout.current) {
      clearTimeout(updateTimeout.current)
      updateTimeout.current = null
    }
  }, [])

  usePageVisibilityChange(plantUpdateTimeout, clearUpdateTimeout)

  useEffect(() => {
    if (!updateTimeout.current) {
      plantUpdateTimeout()
    }
    return clearUpdateTimeout
  }, [clearUpdateTimeout, listUpdateRequested, plantUpdateTimeout])

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<DistributorOrderSkuListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const handleDateRangeChange = useCallback(
    ([start, end]) => {
      const parsedStartDate = parseISO(start)
      const parsedEndDate = parseISO(end)
      if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
        handleFilterChange({ start_date: start, end_date: end })
      }
    },
    [handleFilterChange],
  )

  const handleClearFilters = useCallback(() => {
    filterCleared()
  }, [filterCleared])

  const isFilterApplied = Object.values(filterValue).some(Boolean)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('distributorOrderSkuList')

  const handleStatusChange = useCallback(
    (status: DistributorOrderStatus) =>
      handleFilterChange({
        status,
      }),
    [handleFilterChange],
  )

  const [progressXLSX, downloadXLSX] = useDownload(endpoints.distributorOrderSku(`download/xlsx`), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  const [progressCSV, downloadCSV] = useDownload(endpoints.distributorOrderSku('download/csv'), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  const dateFormat = useDateFormat({ isYearShort: true })

  return (
    <Wrapper>
      <TComponents.Filters>
        <FiltersWrapper>
          <StyledFilter title={t('labels:period')}>
            <RangeDatePicker
              start={start_date || ''}
              end={end_date || ''}
              onChange={handleDateRangeChange}
              format={dateFormat}
              onClear={
                !!(start_date || end_date)
                  ? () => handleFilterChange({ start_date: undefined, end_date: undefined })
                  : undefined
              }
            />
          </StyledFilter>
          <StyledFilter title={t('labels:status')}>
            <DistributorOrderStatusSelect
              value={filterValue.status}
              onChange={handleStatusChange}
              placeholder={t('status.any')}
              customStyles={customSelectStyles}
            />
          </StyledFilter>
          <StyledFilter title={t('labels:retailer')}>
            <DistributorsMultiSelect
              onChange={val => handleFilterChange({ distributor_id: val })}
              selected={filterValue.distributor_id}
            />
          </StyledFilter>
          <StyledFilter title={t('labels:season')}>
            <SeasonSelect
              onChange={val => handleFilterChange({ season_id: val })}
              value={filterValue.season_id}
              variant="small"
              placeholder={t('status.any')}
              isClearable
            />
          </StyledFilter>
          <StyledFilter title={t('labels:category')}>
            <CategorySelect
              onChange={val => {
                const newValue = val ?? null
                handleFilterChange({ category_id: newValue as string })
              }}
              value={filterValue.category_id}
              variant="small"
              placeholder={t('status.any')}
              isClearable
            />
          </StyledFilter>
          <StyledFilter title={t('labels:subcategory')}>
            <SubcategorySelect
              onChange={val => {
                const newValue = val ?? null
                handleFilterChange({ subcategory_id: newValue as string })
              }}
              filter={{ parent_id: filterValue.category_id }}
              value={filterValue.subcategory_id}
              isDisabled={!filterValue.category_id}
              placeholder={t('status.any')}
              variant="small"
              isClearable
            />
          </StyledFilter>
          <StyledFilter title={t('labels:seller')}>
            <SellerFilterSelect
              value={filterValue.seller_id}
              onChange={val => handleFilterChange({ seller_id: val })}
              variant="small"
              isClearable
              placeholder={t('status.any')}
              required
            />
          </StyledFilter>
        </FiltersWrapper>
        <AlignWrapper verticalAlign="bottom" horizontalAlign="right" gap="8px">
          <Button onClick={downloadXLSX} intent="primary" filled progress={progressXLSX}>
            {t('labels:downloadXlsx')}
          </Button>
          <Button onClick={downloadCSV} intent="primary" filled progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </AlignWrapper>
      </TComponents.Filters>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              if (sortFields.includes(column.id))
                return (
                  <SortableHeadCell
                    key={column.getHeaderProps().key}
                    id={column.id as any}
                    sortable={column.sortable}
                    hidden={column.hidden}
                    sortField={sort_field}
                    sortDesc={sort_reversed}
                    onChange={sortingUpdated}
                    width={column.width}
                  >
                    {column.render('Header')}
                  </SortableHeadCell>
                )
              return (
                <TableHeadCell key={column.getHeaderProps().key} hidden={column.hidden} width={column.width}>
                  {column.render('Header')}
                </TableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                selected={row.isSelected}
                onClick={() => {
                  push({ route: Routes.Edit, params: { id: row.original.id.toString() } })
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()

                  if (cell.column.hidden) {
                    return null
                  }

                  return (
                    <TableBodyCell key={key} {...props} stopClickPropagation={cell.column.id === 'status'}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={handleClearFilters}>
                {t('list.resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </Wrapper>
  )
}

export default DistributorOrderSkuList
