import { BasicSelect, LocalizedValue } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import { TargetTypes } from 'modules/domain/target2/common/types'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'
import { createGetList } from './common'

export type TargetCategory = {
  title: LocalizedValue
  id: string
}

type TargetCategoryFilter = {
  manufacturer_id?: string
  title?: string
}

type TargetCategorySelectProps = EntitySelectProps<TargetCategory> & {
  manufacturerId?: string
  valueLabel?: string
  scope?: TargetTypes | 'default'
}

const getList = createGetList<TargetCategory, TargetCategoryFilter>(endpoints.target2('company_subcategories'))

const getListTerritoryFilter = createGetList<TargetCategory, TargetCategoryFilter>(
  endpoints.target2('filters/targets_territory/subcategories'),
)

const getListRetailerFilter = createGetList<TargetCategory, TargetCategoryFilter>(
  endpoints.target2('filters/targets_retailer/subcategories'),
)

const getListFarmerFilter = createGetList<TargetCategory, TargetCategoryFilter>(
  endpoints.target2('filters/targets_farmer/subcategories'),
)

const scopeManagerMap = {
  default: getList,
  [TargetTypes.Farmer]: getListFarmerFilter,
  [TargetTypes.Retailer]: getListRetailerFilter,
  [TargetTypes.Territory]: getListTerritoryFilter,
}

const getValue = (item: TargetCategory) => item.id

export const TargetSubcategorySelect: React.VFC<TargetCategorySelectProps> = ({
  manufacturerId,
  isDisabled,
  valueLabel,
  onChange,
  scope = 'default',
  ...props
}) => {
  const { pick } = useLangPicker()
  const filter = useMemo(() => ({ manufacturer_id: manufacturerId }), [manufacturerId])
  const fakeValue = useMemo(() => (valueLabel ? { title: { en: valueLabel }, id: 'fake' } : undefined), [valueLabel])

  const getLabel = (item: TargetCategory) => pick(item.title)

  return (
    <SelectTestWrapper data-test-id="target-subcategory-select">
      {isDisabled ? (
        <BasicSelect isDisabled getOptionLabel={getLabel} getOptionValue={getValue} {...props} value={fakeValue} />
      ) : (
        <EntitySelect
          filter={filter}
          getEntityList={scopeManagerMap[scope]}
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          onChange={onChange}
          searchParamKey="title"
          {...props}
        />
      )}
    </SelectTestWrapper>
  )
}
