import { EntityState } from 'modules/domain/types'
import { LocalizedValue, Status } from 'types/entities'
import { PackageTypes } from '../packageTypes/types'
import { Product } from '../product/types'
import { ProductOptionType } from '../productOptions/types'

export type StorefrontState = EntityState<StorefrontItem, StorefrontListRequestFilter, StorefrontListRequestSorting> & {
  sidebarFilter: StorefrontSidebarFilter
}

export type StorefrontListRequestFilter = {
  search?: string
  status?: Status | Status[]
  seller_id?: string
  category_id?: string
  product_id?: string
  product_ids?: string[]
  product_options_id?: string[]
  sku_type?: StorefrontItemType
}

export type CardSkusRequestFilter = Pick<StorefrontListRequestFilter, 'status'>

export type StorefrontSidebarFilter = {
  search?: string
  status?: Status | Status[]
  producer_id?: string
}

export type StorefrontListRequestParams = {
  filter?: StorefrontListRequestFilter
  sorting?: StorefrontListRequestSorting
  page?: number
  pageSize?: number
}

export type StorefrontListRequestSorting = {
  sort_field?: keyof StorefrontItem
  sort_reversed?: boolean
}

export type DragItem = {
  id: string
  type: DragItemType
}

export enum DragItemType {
  Sidebar = 'Sidebar',
  Product = 'Product',
  Bundle = 'Bundle',
  BundleItem = 'BundleItem',
}

export type DropItemType = {
  productId: string
}

export enum StorefrontItemType {
  Product = 'product',
  Bundle = 'bundle',
}

export enum AvailableFor {
  FarmerOrder = 'farmer_orders',
  DistributorOrder = 'distributor_orders',
}

export enum SkuPriceType {
  NotSpecified = 'not_specified',
  MSRP = 'msrp',
}

export type SkuOptionParams = {
  percentage?: number
}

export type SkuOption = {
  option_id: string
  title?: string
  title_i18n?: LocalizedValue
  type: ProductOptionType
  params: SkuOptionParams
  is_stackable?: boolean
}

export type StorefrontSku = {
  id: string
  type: StorefrontItemType.Product
  sku_id: string
  seller_id: string
  price?: string
  price_type: string
  special_prices: string[]
  min_qty?: string
  max_qty?: string
  default_qty?: string
  status: Status
  is_out_of_stock: boolean
  out_of_stock_date?: string
  available_for?: AvailableFor[]
  params: {
    type: 'product' | 'bundle'
    product_id?: string
    product?: Product
    package_id?: string
    package_title?: string
    package_capacity?: number
    options: SkuOption[]
    package?: PackageTypes
  }
  title?: string
  product?: Product
}

export type StorefrontSkuFilterSuggestItem = {
  id: string
  sku_id: string
  product_title: string
  category_title: string
  package_title: string
  options?: SkuOption[]
  skuOptions: SkuOption[]
  status: Status
}

export type StorefrontSkuDTO = Omit<Partial<StorefrontSku>, 'out_of_stock_date'> & { out_of_stock_date?: string | null }

export type StorefrontBundle = {
  id: string
  type: StorefrontItemType.Bundle
  sku_id: string
  price: string
  title: string
  status: Status
  available_for?: AvailableFor[]

  special_prices: string[]
  params: {
    type: string
    product_id: string
    product?: Product
    package_id: string | null
    package_capacity: number
    options: SkuOption[]
  }

  items: StorefrontSku[]
}

export type StorefrontItem = StorefrontSku | StorefrontBundle

export type StorefrontItemForFilter = Pick<StorefrontItem, 'id' | 'sku_id'> & {
  product_category_title?: string
  product_title?: string
}

export enum StorefrontModalMode {
  Create = 'Create',
  Edit = 'Edit',
}

export type MenuOption = {
  id: MenuOptionType
  title: string
}

export enum MenuOptionType {
  Edit = 'Edit',
  Remove = 'Remove',
  Restore = 'Restore',
}

export enum PackageType {
  Tote = 'Tote',
  Bag = 'Bag',
}
