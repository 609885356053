import {
  AdvancedHeadCell,
  Button,
  RangeDatePicker,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  useAction,
  useHistoryPush,
  usePageVisibilityChange,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import useDownload from 'hooks/useDownload'
import AuthSelectors from 'modules/domain/auth/selectors'
import FarmerOrderSkuActions from 'modules/domain/farmerOrderSku/duck'
import { useFarmerOrderSkuList } from 'modules/domain/farmerOrderSku/hooks'
import FarmerOrderSkuSelectors from 'modules/domain/farmerOrderSku/selectors'
import { FarmerOrderSkuListRequestFilter } from 'modules/domain/farmerOrderSku/types'
import SeasonSelectors from 'modules/domain/season/selectors'
import { endpoints } from 'modules/endpoints'
import { useOrderUpdatePermissions } from 'modules/permissions/permissions'
import { parseISO, isValid } from 'date-fns'
import { Progress } from 'modules/types'
import { generateCrmTableColumnAccessString, generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { CellProps, HeaderProps, useRowSelect, useTable } from 'react-table'
import styled, { StyledProps } from 'styled-components'
import {
  Columns,
  CompanyType,
  CustomFeatureName,
  FeatureFlagModifiers,
  isAgro,
  isDistributor,
  Sections,
  Tables,
} from 'types/entities'
import { FarmerOrderSku, FarmerOrderSkuItem } from 'types/farmerOrderSku'
import CategorySelect from 'views/components/CategorySelect/CategorySelect'
import SubcategorySelect from 'views/components/CategorySelect/SubcategorySelect'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import DistributorsMultiSelect from 'views/components/DistributorsMultiSelect/DistributorsMultiSelect'
import FarmerOrderSkuEnterQtyModal from 'views/components/FarmerOrderSkuEnterQtyModal/FarmerOrderSkuEnterQtyModal'
import FarmerOrderStatusColored from 'views/components/FarmerOrderStatusColored/FarmerOrderStatusColored'
import BusinessModelFilter from 'views/components/TableFilters/BusinessModelFilter'
import CountryFilter from 'views/components/TableFilters/CountryFilter'
import { Filter as FilterComponent } from 'views/components/TableFilters/TableFilters'
import Routes from './routes'
import useLangPicker from 'hooks/useLangPicker'
import { AlignWrapper } from 'views/components/AlignWrapper/AlignWrapper'
import useDateFormatFn from 'hooks/useDateFormatFn'
import useDateFormat from 'hooks/useDateFormat'
import { HouseholdSelect } from 'views/components/HouseholdSelect/HouseholdSelect'
import FarmerOrderStatusMultiSelect from 'views/components/FarmerOrderStatusMultiSelect/FarmerOrderStatusMultiSelect'
import { ProductsSelect } from 'views/components/ProductsSelect/ProductsSelect'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'
import { CellWrapper } from 'views/components/OrderProductItems/styles'
import { ProductOptionType } from 'modules/domain/productOptions/types'
import ModalActions from 'modules/domain/modal/duck'
import { numberToPrecision } from 'modules/utils/helpers'

const LIST_UPDATE_INTERVAL = 60000

const Wrapper = styled(TComponents.Wrapper)`
  overflow: auto;
  grid-template-rows: 'max-content max-content';
`

export const BodyCell = styled(TableBodyCell)<StyledProps<{ emphasis?: boolean }>>`
  background-color: ${props => (props.emphasis ? props.theme.color.primary25 : props.theme.color.onPrimaryLight)};
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 650px;
  max-width: 970px;
`

const Filter = styled(FilterComponent)`
  margin: 0 16px 16px 0 !important;
  width: 30%;
  max-width: 220px;
  flex-grow: 1;
  justify-content: space-between;
`

const StatusColumnHeaderCell: React.FC<HeaderProps<FarmerOrderSku>> = () => {
  const { t } = useTranslation('farmerOrder')
  return (
    <TComponents.CheckboxWrapper>
      <span>{t('list.tableHeaders.status')}</span>
    </TComponents.CheckboxWrapper>
  )
}

const StatusCell: React.FC<CellProps<FarmerOrderSku>> = ({ row }) => {
  const { t } = useTranslation('farmerOrder')
  return (
    <TComponents.CheckboxWrapper>
      <FarmerOrderStatusColored
        status={row.original.status}
        dangerouslySetInnerHTML={{ __html: t(`status.${row.values.status}`) }}
      />
    </TComponents.CheckboxWrapper>
  )
}

const SupplierCell: React.FC<CellProps<FarmerOrderSku>> = ({ row, column }) => {
  return (
    <TComponents.BoldText key={column.id}>
      {(row.original.producer?.internal_name ?? '').toUpperCase()}
    </TComponents.BoldText>
  )
}

const IdDateCell: React.FC<CellProps<FarmerOrderSku>> = ({ cell, row, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })

  return (
    <div key={column.id} style={{ whiteSpace: 'nowrap' }}>
      <div data-test-id="order-slug">{cell.value}</div>
      <div>{getFormatedDate(row.original.order_date)}</div>
    </div>
  )
}

const SeasonWrapper = styled.div`
  width: 100px;
`

const SeasonCell: React.FC<CellProps<FarmerOrderSku>> = ({ column, row }) => {
  return (
    <SeasonWrapper>
      <TComponents.RegularText key={column.id}>{row.original.season?.title ?? ''}</TComponents.RegularText>
    </SeasonWrapper>
  )
}

const DistributorCell: React.FC<CellProps<FarmerOrderSku>> = ({ row, column }) => {
  return <span key={column.id}>{row.original.distributor?.internal_name ?? ''}</span>
}

const CustomerCell: React.FC<CellProps<FarmerOrderSku>> = ({ row }) => {
  if (!row.original.customer) {
    return <span>{''}</span>
  }
  const name = `${row.original.customer.first_name || ''} ${row.original.customer.last_name || ''}`
  return (
    <div style={{ whiteSpace: 'nowrap', maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <div>{name.trim()}</div>
      <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{row.original.customer.farm_name}</div>
      <div>{row.original.customer.phone_number}</div>
    </div>
  )
}

const Category: React.FC<CellProps<FarmerOrderSku>> = ({ row }) => {
  const items = row.original.sku_items
  const { pick } = useLangPicker()
  if (!items || !items.length) return null
  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      {items.map(
        (item, i) =>
          item.sku?.product?.subcategory && (
            <div key={`${item.sku?.params.product_id}-${i}`}>{pick(item.sku?.product.subcategory.title_i18n)}</div>
          ),
      )}
    </div>
  )
}

const Packaging: React.FC<CellProps<FarmerOrderSku>> = ({ row }) => {
  return (
    <CellWrapper>
      {row.original.sku_items.map((item, i) => (
        <div key={i}>{item.sku?.params.package_title}</div>
      ))}
    </CellWrapper>
  )
}

const OrderQty: React.FC<CellProps<FarmerOrderSku>> = ({ row }) => {
  return (
    <div>
      {row.original.sku_items.map((item, i) => (
        <div key={i}>{numberToPrecision(item.quantity)}</div>
      ))}
    </div>
  )
}

const StandardUnits: React.FC<CellProps<FarmerOrderSku>> = ({ row }) => {
  return (
    <div>
      {row.original.sku_items.map((item, i) => (
        <div key={i}>
          {item.standard_qty ? (item.standard_qty === '0' ? '0' : numberToPrecision(item.standard_qty)) : '-'}
        </div>
      ))}
    </div>
  )
}

const QtyCellWrapper = styled.div<StyledProps<{ disabled?: boolean }>>`
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme, disabled }) => !disabled && { boxShadow: `0 0 10px ${theme.color.primary100}` }}
  background-color: ${({ theme }) => theme.color.onPrimaryLight};
  &:hover {
    background-color: ${({ theme, disabled }) => (!disabled ? theme.color.primary25 : theme.color.onPrimaryLight)};
  }
`

const FinalQty: React.FC<CellProps<FarmerOrderSku> & { isDistributor: boolean }> = ({ row, isDistributor }) => {
  const { t } = useTranslation('farmerOrder')
  const items = row.original.sku_items
  const updatePermissions = useOrderUpdatePermissions()
  const openModal = useAction(ModalActions.open)
  const closeModal = useAction(ModalActions.close)
  const isOrderConfirmedByDistributor = row.original?.interaction?.confirmed_by_distributor === 'confirmed' || false

  // distributors should confirm the order if they want to change its 'final qty'
  if (isDistributor && !isOrderConfirmedByDistributor) {
    return (
      <>
        {items.map((item, i) => (
          <div key={i} style={{ textAlign: 'center' }}>
            {item.final_qty ? numberToPrecision(item.final_qty) : '-'}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <QtyCellWrapper
        disabled={!updatePermissions.items}
        onClick={e => {
          // TODO opens popup even when disabled === true
          e.stopPropagation()
          updatePermissions.items &&
            openModal({
              heading: t('list.tableHeaders.finalQty'),
              content: (
                <FarmerOrderSkuEnterQtyModal
                  orderId={row.original.id}
                  qtyColumn={'final_qty'}
                  onClose={() => closeModal()}
                />
              ),
            })
        }}
      >
        {items.map((item, i) => (
          <div key={i}>{item.final_qty ? numberToPrecision(item.final_qty) : '-'}</div>
        ))}
      </QtyCellWrapper>
    </>
  )
}

const DeliveredQty: React.FC<CellProps<FarmerOrderSku> & { isDistributor: boolean }> = ({ row, isDistributor }) => {
  const { t } = useTranslation('farmerOrder')
  const items = row.original.sku_items
  const openModal = useAction(ModalActions.open)
  const closeModal = useAction(ModalActions.close)

  const updatePermissions = useOrderUpdatePermissions()
  const isOrderConfirmedByDistributor = row.original?.interaction?.confirmed_by_distributor === 'confirmed' || false

  // distributors should confirm the order if they want to change its 'delivered qty'
  if (isDistributor && !isOrderConfirmedByDistributor) {
    return (
      <>
        {items.map((item, i) => (
          <div key={i} style={{ textAlign: 'center' }}>
            {item.delivered_qty ? numberToPrecision(item.delivered_qty) : '-'}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <QtyCellWrapper
        disabled={!updatePermissions.items}
        onClick={e => {
          // TODO opens popup even when disabled === true
          e.stopPropagation()
          updatePermissions.items &&
            openModal({
              heading: t('list.tableHeaders.deliveredQty'),
              content: (
                <FarmerOrderSkuEnterQtyModal
                  orderId={row.original.id}
                  qtyColumn={'delivered_qty'}
                  onClose={() => closeModal()}
                />
              ),
            })
        }}
      >
        {items.map((item, i) => (
          <div key={i}>{item.delivered_qty ? numberToPrecision(item.delivered_qty) : '-'}</div>
        ))}
      </QtyCellWrapper>
    </>
  )
}

const PromocodesCell: React.FC<CellProps<FarmerOrderSku>> = ({ row }) => {
  return <div>{row.original.promocodes.map(({ code }) => code).join(', ')}</div>
}

const ItemsCell: React.FC<CellProps<FarmerOrderSku>> = ({ row }) => {
  if (!row.original.sku_items) {
    return <span>{''}</span>
  }

  return (
    <div>
      {row.original.sku_items.map((item: FarmerOrderSkuItem, i: number) => {
        return <div key={`${item.id}-${i}`} style={{ whiteSpace: 'nowrap' }}>{`${item.sku?.product?.title || ''}`}</div>
      })}
    </div>
  )
}

const SeedTreatment = styled.div`
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SeedTreatmentCell: React.FC<CellProps<FarmerOrderSku>> = ({ row }) => {
  const items = row.original.sku_items

  return (
    <div>
      {items.map((item: FarmerOrderSkuItem, i: number) => {
        const seedTreatment = item.sku?.params.options.find(o => o.type === ProductOptionType.SEED_TREATMENT)
        if (!seedTreatment) return <div key={`${item.sku_id}-${i}`}>-</div>

        return (
          <SeedTreatment key={`${item.sku_id}-${i}`} title={seedTreatment?.title || '-'}>
            {seedTreatment?.title || '-'}
          </SeedTreatment>
        )
      })}
    </div>
  )
}

const SortableHeadCell = AdvancedHeadCell<keyof FarmerOrderSku>()

const customCountryFilterStyles: React.CSSProperties = {
  margin: '0 16px 16px 0',
}

const FarmerOrderSkuList: React.FC = () => {
  const push = useHistoryPush()
  const { t } = useTranslation(['farmerOrder', 'labels', 'common'])
  const role = useSelector(AuthSelectors.role)
  const checkFeatureFlag = useFeatureFlags()
  const hasAccess = useCallback(flag => !checkFeatureFlag(flag), [checkFeatureFlag])

  const filterValue = useSelector(FarmerOrderSkuSelectors.filter)
  const selectedSeason = useSelector(SeasonSelectors.item(filterValue.season_id))

  const isSeedTreatmentHidden = !hasAccess(
    generateCrmTableColumnAccessString(Tables.FarmerOrders, Columns.SeedTreatment, FeatureFlagModifiers.Hidden),
  )

  const allColumns = React.useMemo(
    () => [
      {
        Header: StatusColumnHeaderCell,
        accessor: 'status' as const,
        Cell: StatusCell,
      },
      {
        Header: t('list.tableHeaders.idAndDate'),
        accessor: 'slug' as const,
        Cell: IdDateCell,
      },
      {
        Header: t('list.tableHeaders.supplier'),
        accessor: 'producer' as const,
        Cell: SupplierCell,
      },
      {
        Header: t('list.tableHeaders.distributor'),
        accessor: 'distributor' as const,
        Cell: DistributorCell,
      },
      {
        Header: t('list.tableHeaders.season'),
        accessor: 'season' as const,
        Cell: SeasonCell,
      },
      {
        Header: t('list.tableHeaders.client'),
        accessor: 'customer' as const,
        Cell: CustomerCell,
      },
      {
        Header: t('list.tableHeaders.subcategory'),
        accessor: 'sku_items' as const,
        id: 'crops',
        Cell: Category,
      },
      {
        Header: t('list.tableHeaders.items'),
        accessor: 'sku_items' as const,
        id: 'items',
        Cell: ItemsCell,
      },
      {
        Header: t('list.tableHeaders.seedTreatment'),
        accessor: 'sku_items' as const,
        id: 'seed-treatment',
        Cell: SeedTreatmentCell,
        hidden: isSeedTreatmentHidden,
      },
      {
        Header: t('list.tableHeaders.packaging'),
        accessor: 'sku_items' as const,
        id: 'packaging',
        Cell: Packaging,
      },
      {
        Header: t('list.tableHeaders.quantity'),
        accessor: 'sku_items' as const,
        id: 'qty',
        Cell: OrderQty,
      },
      {
        Header: t('list.tableHeaders.standardUnits'),
        accessor: 'sku_items' as const,
        id: 'standardUnits',
        Cell: StandardUnits,
      },
      {
        Header: t('list.tableHeaders.deliveredQty'),
        accessor: 'sku_items' as const,
        id: 'deliveredQty',
        Cell: props => <DeliveredQty isDistributor={isDistributor(role)} {...props} />,
        hidden: !selectedSeason?.enable_delivered_qty,
      },
      {
        Header: t('list.tableHeaders.finalQty'),
        accessor: 'sku_items' as const,
        id: 'finalQty',
        Cell: props => <FinalQty isDistributor={isDistributor(role)} {...props} />,
        hidden: !selectedSeason?.enable_final_qty,
      },
      {
        Header: t('list.tableHeaders.promocodes'),
        accessor: 'promocodes' as const,
        Cell: PromocodesCell,
      },
    ],
    [t, isSeedTreatmentHidden, selectedSeason?.enable_delivered_qty, selectedSeason?.enable_final_qty, role],
  )

  const [progress, data = []] = useFarmerOrderSkuList()

  const { columns, rows, prepareRow } = useTable<FarmerOrderSku>(
    {
      columns: allColumns,
      data,
    },
    useRowSelect,
  )

  const filterUpdated = useAction(FarmerOrderSkuActions.filterUpdated)
  const filterCleared = useAction(FarmerOrderSkuActions.filterHasBeenReset)
  const sortingUpdated = useAction(FarmerOrderSkuActions.sortingUpdated)
  const listRequested = useAction(FarmerOrderSkuActions.listRequested)
  const listUpdateRequested = useAction(FarmerOrderSkuActions.listUpdateRequested)

  const page = useSelector(FarmerOrderSkuSelectors.page)
  const pageSize = useSelector(FarmerOrderSkuSelectors.pageSize)
  const pages = useSelector(FarmerOrderSkuSelectors.pages)
  const total = useSelector(FarmerOrderSkuSelectors.total)
  const totalQty = useSelector(FarmerOrderSkuSelectors.totalQty)
  const totalDeliveredQty = useSelector(FarmerOrderSkuSelectors.totalDeliveredQty)
  const totalStandardQty = useSelector(FarmerOrderSkuSelectors.totalStandardQty)

  const totalFinalQty = useSelector(FarmerOrderSkuSelectors.totalFinalQty)
  const { country, business_model, start_date, end_date } = filterValue
  const { sort_field, sort_reversed } = useSelector(FarmerOrderSkuSelectors.sorting)
  const updateTimeout = useRef<number | null>(null)

  const isAdmin = useSelector(AuthSelectors.isAdmin)

  const displayCategoryFilter =
    isAdmin ||
    checkFeatureFlag([generateCustomFeatureFlag(Sections.FarmerOrders, CustomFeatureName.DisplayCategoryFilter)])

  const plantUpdateTimeout = useCallback(() => {
    updateTimeout.current = window.setTimeout(() => {
      listUpdateRequested()
      plantUpdateTimeout()
    }, LIST_UPDATE_INTERVAL)
  }, [listUpdateRequested])

  const clearUpdateTimeout = useCallback(() => {
    if (updateTimeout.current) {
      clearTimeout(updateTimeout.current)
      updateTimeout.current = null
    }
  }, [])

  usePageVisibilityChange(plantUpdateTimeout, clearUpdateTimeout)

  useEffect(() => {
    if (!updateTimeout.current) {
      plantUpdateTimeout()
    }
    return clearUpdateTimeout
  }, [clearUpdateTimeout, listUpdateRequested, plantUpdateTimeout])

  const handleFilterChange = React.useCallback(
    (newFilterValue: Partial<FarmerOrderSkuListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const handleDateRangeChange = React.useCallback(
    ([start, end]) => {
      const parsedStartDate = parseISO(start)
      const parsedEndDate = parseISO(end)
      if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
        handleFilterChange({ start_date: start, end_date: end })
      }
    },
    [handleFilterChange],
  )

  const handleClearFilters = React.useCallback(() => {
    filterCleared()
  }, [filterCleared])

  const isFilterApplied = Object.values(filterValue).some(Boolean)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('farmerOrderSkuList')

  const [progressCSV, downloadCSV] = useDownload(endpoints.farmerOrderSku('download/csv'), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  const dateFormat = useDateFormat({ isYearShort: true })

  return (
    <Wrapper>
      <TComponents.Filters>
        <FiltersWrapper>
          <Filter title={t('labels:period')}>
            <RangeDatePicker
              start={start_date || ''}
              end={end_date || ''}
              onChange={handleDateRangeChange}
              format={dateFormat}
            />
          </Filter>
          <BusinessModelFilter filterValue={{ business_model }} handleFilterChange={handleFilterChange} disableMulti />
          <Filter title={t('labels:status')}>
            <FarmerOrderStatusMultiSelect
              selected={filterValue.filter_by_status}
              onChange={val => handleFilterChange({ filter_by_status: val })}
            />
          </Filter>
          <Filter title={t('labels:retailer')}>
            <DistributorsMultiSelect
              onChange={val => handleFilterChange({ distributor_id: val })}
              selected={filterValue.distributor_id}
            />
          </Filter>
          {isAgro(role) && (
            <>
              <Filter title={t('labels:households')}>
                <HouseholdSelect
                  value={filterValue.household_id}
                  onChange={val => handleFilterChange({ household_id: val })}
                  placeholder={t('status.any')}
                  variant="small"
                />
              </Filter>
              <Filter title={t('labels:supplier')}>
                <CompanySelect
                  value={filterValue.producer_id}
                  onChange={val => handleFilterChange({ producer_id: val, season_id: undefined })}
                  variant="small"
                  filter={{ is_seller: true }}
                  isClearable
                  placeholder={t('status.any')}
                  companyType={CompanyType.Producer}
                  required
                />
              </Filter>
            </>
          )}
          {displayCategoryFilter && (
            <Filter title={t('labels:category')}>
              <CategorySelect
                filter={{ seller_ids: filterValue.producer_id }}
                onChange={val =>
                  handleFilterChange({ category_id: val, subcategory_id: undefined, product_id: undefined })
                }
                value={filterValue.category_id}
                variant="small"
                placeholder={t('common:any')}
                isClearable
              />
            </Filter>
          )}
          {displayCategoryFilter && (
            <Filter title={t('labels:subcategory')}>
              <SubcategorySelect
                filter={{ parent_id: filterValue.category_id, seller_ids: filterValue.producer_id }}
                onChange={val => handleFilterChange({ subcategory_id: val, product_id: undefined })}
                value={filterValue.subcategory_id}
                variant="small"
                placeholder={t('common:any')}
                isClearable
              />
            </Filter>
          )}
          <Filter title={t('labels:product')}>
            <ProductsSelect
              onChange={val => handleFilterChange({ product_id: val })}
              value={filterValue.product_id}
              variant="small"
              isFilterSuggest
              isClearable
              filter={{
                producer_id: filterValue.producer_id,
                category: filterValue.category_id,
                subcategory: filterValue.subcategory_id,
              }}
              scope="sku-orders"
            />
          </Filter>
          <Filter title={t('labels:season')}>
            <SeasonSelect
              variant="small"
              value={filterValue.season_id}
              onChange={val => handleFilterChange({ season_id: val })}
              isClearable
              companyId={filterValue.producer_id}
            />
          </Filter>
          {isAgro(role) && (
            <CountryFilter
              filterValue={{ country }}
              handleFilterChange={handleFilterChange}
              customStyles={customCountryFilterStyles}
            />
          )}
        </FiltersWrapper>
        <AlignWrapper verticalAlign="bottom" horizontalAlign="right">
          <Button onClick={downloadCSV} intent="primary" filled progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </AlignWrapper>
      </TComponents.Filters>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              let value
              if (column.id === 'qty') {
                value = totalQty
              } else if (column.id === 'finalQty') {
                value = totalFinalQty
              } else if (column.id === 'deliveredQty') {
                value = totalDeliveredQty
              } else if (column.id === 'standardUnits') {
                value = totalStandardQty
              }

              return (
                <SortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof FarmerOrderSku}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                  width={column.width}
                  emphatic={column.id === 'finalQty'}
                >
                  {value ? numberToPrecision(value) : ''}
                </SortableHeadCell>
              )
            })}
          </TableHeadRow>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <SortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof FarmerOrderSku}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                  width={column.width}
                  emphatic={column.id === 'finalQty'}
                >
                  {column.render('Header')}
                </SortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                selected={row.isSelected}
                onClick={() => {
                  push({
                    route: generatePath(Routes.Edit, { id: row.original.id.toString() }),
                  })
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()

                  if (cell.column.hidden) {
                    return null
                  }

                  return (
                    <BodyCell
                      key={key}
                      {...props}
                      stopClickPropagation={['status', 'finalQty', 'deliveredQty'].includes(cell.column.id)}
                      emphasis={cell.column.id === 'finalQty'}
                    >
                      {cell.render('Cell')}
                    </BodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={columns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={handleClearFilters}>
                {t('list.resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </Wrapper>
  )
}

export default FarmerOrderSkuList
