import {
  Button,
  DatePicker,
  Snackbar,
  FormComponents,
  Input,
  SectionBody,
  SectionContainer,
  SectionTitle,
  TextArea,
  useAction,
  WizardData,
} from '@agro-club/frontend-shared'
import { useFormik } from 'formik'
import { SingleValue } from 'react-select'
import checkCompanyFeatureFlags from 'helpers/checkCompanyFeatureFlags'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import useDateFormat from 'hooks/useDateFormat'
import useDownload from 'hooks/useDownload'
import useHasOutOfStockDateExpired from 'hooks/useHasOutOfStockDateExpired'
import useTotalSum from 'hooks/useTotalSum'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import RetailerAllocationActions from 'modules/domain/allocation/retailerAllocation/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { useCompanyById } from 'modules/domain/company/hooks'
import { Company } from 'modules/domain/company/types'
import { useCalculatedDiscounts } from 'modules/domain/discountRule/hooks'
import { DiscountRuleScope } from 'modules/domain/discountRule/types'
import ModalActions from 'modules/domain/modal/duck'
import { Product } from 'modules/domain/product/types'
import { useSeasonList } from 'modules/domain/season/hooks'
import UploadManagerSelectors from 'modules/domain/uploadManager/selectors'
import { endpoints } from 'modules/endpoints'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'
import { Progress } from 'modules/types'
import {
  generateCrmButtonAccessString,
  generateCrmFieldAccessString,
  generateCrmSectionAccessString,
  generateCustomFeatureFlag,
} from 'modules/utils/generateStringHelpers'
import { clone, remove } from 'ramda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'
import { DistributorOrder, DistributorOrderItem, DistributorOrderStatus } from 'types/distributorOrder'
import {
  Buttons,
  CompanyType,
  CustomFeatureName,
  FeatureFlagModifiers,
  isAgro,
  isDistributor,
  isProducer,
  isProducersManager,
  isUsersCompanyDistributor,
  isUsersCompanyHeadDistributor,
  isUsersCompanyProducer,
  ROLES,
  Sections,
} from 'types/entities'
import * as uuid from 'uuid'
import { OrderDiscounts } from 'views/components/FarmerOrderDetailsForm/OrderDiscountsBlock'
import OrderProductItems from 'views/components/OrderProductItems/OrderProductItems'
import OrderProductItemsReadOnly from 'views/components/OrderProductItems/OrderProductItemsReadOnly'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import CompanyRoutes from 'views/pages/Company/routes'
import * as Yup from 'yup'
import CompanyInfoPreview from '../CompanyInfoPreview/CompanyInfoPreview'
import { CompanySelect } from '../CompanySelect/CompanySelect'
import FileManager from '../FileManager/FileManager'
import { FileItem } from '../FileManager/types'
import StickyFooterBtn from '../StickyFooterBtn/StickyFooterBtn'
import StickyFooterDeleteBtn from '../StickyFormControls/StickyFooterDeleteBtn'
import OrderHistoryCard from '../OrderHistoryCard/OrderHistoryCard'
import { SeasonSelect } from '../SeasonSelect/SeasonSelect'
import PromocodeField from '../Promocode/PromocodeField'
import PromocodeList from '../Promocode/PromocodeList'
import {
  isPromocodeWithComment,
  isPromocodeWithLegalText,
  Promocode,
  PromocodeWithComment,
} from 'modules/domain/promocode/types'
import { ModalWithButtonContent } from '../ModalWithButtons/ModalWithButtonsContent'
import { PreviewContainer } from '../PreviewContainer/PreviewContainer'
import StickyFooterRestoreBtn from '../StickyFormControls/StickyFooterRestoreBtn'

const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 354px auto;
`

const Column = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
`

const DatePickerWrapper = styled.div`
  width: 220px;
`

const DownloadButtonWrapper = styled.div`
  display: flex;
  padding-right: 8px;
`

export type FormFields = {
  distributor_id: string
  producer_id?: string
  status: DistributorOrderStatus
  comment?: string
  items: DistributorOrderItem[]
  billing_company_id?: string
  purchase_number?: string
  interaction: {
    confirmed_by_producer: boolean
    canceled_by_role: ROLES | null
  }
  order_date?: string
  desired_delivery_date?: string
  delivery_date?: string
  shipping_date?: string
  external_order_id?: string
  season_id?: string
  files: FileItem[]
  promocodes: Promocode[]
}

type Props = {
  mode: 'create' | 'edit'
  onSubmit(values: FormFields, options: { dirty: boolean; duplicate: boolean }): void
  onCancel(): void
  onProducerConfirmation?: () => void
  onCancelOrder?: () => void
  onRemove?: () => void
  onRestore?: () => void
  onComplete?: () => void
  progress?: Progress
  removeProgress?: Progress
  initialValues: FormFields
  order?: DistributorOrder
  totalNet?: string
  onUncancelOrder?: () => void
}

const DistributorOrderDetailsForm: React.FC<Props> = ({
  mode,
  order,
  initialValues,
  onRemove,
  onRestore,
  onCancel,
  onSubmit,
  onCancelOrder,
  onProducerConfirmation,
  onComplete,
  removeProgress,
  progress,
  totalNet,
  onUncancelOrder,
}) => {
  const { t } = useTranslation(['distributorOrder', 'common', 'company', 'labels'])
  const [isRemovePermitted, isUpdatePermitted] = usePermissions([
    { capability: CAPABILITY.DISTRIBUTOR_ORDERS, permission: PERMISSION.D },
    { capability: CAPABILITY.DISTRIBUTOR_ORDERS, permission: PERMISSION.U },
  ])
  const role = useSelector(AuthSelectors.role)
  const isAdmin = useSelector(AuthSelectors.isAdmin)
  const userCompany = useSelector(AuthSelectors.userCompany)
  const headCompanyFetched = useRef(false)
  const [, seasons] = useSeasonList()
  const [optionsRequiredErrors, setOptionsRequiredErrors] = useState<string[][]>([])
  const isFilesUploading = useSelector(UploadManagerSelectors.isFilesUploading)

  const [selectedPromocode, setSelectedPromocode] = useState<string>()
  const isDistributorChangeAllowed = isAdmin || isProducer(role) || isUsersCompanyHeadDistributor(userCompany)

  // TODO fix this extra req for head company
  const [, headCompany] = useCompanyById(
    isDistributorChangeAllowed
      ? order?.distributor?.head_company_relation?.company_id
      : userCompany?.head_company_relation?.company_id,
  )
  const [selectedDistributor, setSelectedDistributor] = useState<Company | null>(
    order?.distributor || (isUsersCompanyDistributor(userCompany) && userCompany) || null,
  )
  const [headDistributor, setHeadDistributor] = useState<Company | null>(headCompany || null)

  const producerManagerCanEdit = isProducersManager(role)
    ? [DistributorOrderStatus.New, DistributorOrderStatus.Changed].includes(order?.status as DistributorOrderStatus)
    : true

  const [deliveryDatesEnabled, setDeliveryDatesEnabled] = useState(false)
  const [shippingDateEnabled, setShippingDateEnabled] = useState(false)
  const [orderNumberEnabled, setOrderNumberEnabled] = useState(false)

  const openModal = useAction(ModalActions.open)
  const [productQtyIsNullError, setProductQtyIsNullError] = useState(false)
  const clearProductsLimitsForRetailerOrders = useAction(RetailerAllocationActions.clearProductsLimitsForRetailerOrders)

  const checkFeatureFlag = useFeatureFlags()

  const isTimeHidden = checkFeatureFlag(generateCustomFeatureFlag(Sections.Orders, CustomFeatureName.WithoutTime))
  const dateFormat = useDateFormat({ withTime: !isTimeHidden })

  const isHistoryCardVisible = checkFeatureFlag(
    generateCustomFeatureFlag(Sections.RetailerOrders, 'historyCardVisible'),
  )

  const isUpdateAfterSaveRestricted = checkFeatureFlag(
    generateCustomFeatureFlag(Sections.RetailerOrders, 'restrictUpdateAfterSave'),
  )
  const isUpdateRestricted = checkFeatureFlag(
    generateCustomFeatureFlag(Sections.RetailerOrders, 'restrictUpdateRetailerOrders'),
  )
  const isUpdateAllowedForCertainCompanies = checkFeatureFlag(
    generateCustomFeatureFlag(Sections.RetailerOrders, 'allowUpdateRetailerOrdersForCertainCompanies'),
  )

  const isDownloadXlsxEnabled = useMemo(
    () =>
      checkCompanyFeatureFlags(
        order?.producer,
        generateCrmButtonAccessString(Sections.RetailerOrders, Buttons.DownloadXlsx, FeatureFlagModifiers.Enabled),
      ),
    [order?.producer],
  )

  const isCompletedButtonEnabled =
    checkFeatureFlag(
      generateCrmButtonAccessString(Sections.RetailerOrders, Buttons.Completed, FeatureFlagModifiers.Enabled),
    ) || isAdmin

  const isCompletedButtonDisabled = !isAdmin && !isProducer(role)

  const showComment =
    isAdmin ||
    checkFeatureFlag(
      generateCrmFieldAccessString(Sections.RetailerOrders, 'productComment', FeatureFlagModifiers.Enabled),
    )

  const checkProducerFeatureFlags = useCallback(
    (producer?: Company | null): void => {
      if (!producer) {
        return
      }
      const orderNumberEnabledFlag = generateCrmFieldAccessString(
        Sections.RetailerOrders,
        'orderNumber',
        FeatureFlagModifiers.Enabled,
      )

      const shippingDateEnabledFlag = generateCrmFieldAccessString(
        Sections.RetailerOrders,
        'shippingDate',
        FeatureFlagModifiers.Enabled,
      )

      const deliveryDatesEnabledFlag = generateCrmFieldAccessString(
        Sections.Orders,
        'desiredDeliveryDates',
        FeatureFlagModifiers.Enabled,
      )

      setOrderNumberEnabled(checkCompanyFeatureFlags(producer, orderNumberEnabledFlag, role || undefined))
      setShippingDateEnabled(checkCompanyFeatureFlags(producer, shippingDateEnabledFlag, role || undefined))
      setDeliveryDatesEnabled(checkCompanyFeatureFlags(producer, deliveryDatesEnabledFlag, role || undefined))
    },
    [role],
  )

  const isSeasonClosed = useMemo(() => seasons?.find(season => season.id === order?.season_id)?.is_season_closed, [
    order?.season_id,
    seasons,
  ])

  const seasonClosedEditable = isSeasonClosed ? isAdmin : true

  const isEditable =
    !order ||
    (seasonClosedEditable &&
      producerManagerCanEdit &&
      !isUpdateAfterSaveRestricted &&
      !isUpdateRestricted &&
      isUpdatePermitted)

  const isEditableForCertainCompanies =
    isUpdateAllowedForCertainCompanies &&
    [DistributorOrderStatus.New, DistributorOrderStatus.Changed, DistributorOrderStatus.Confirmed].includes(
      order?.status as DistributorOrderStatus,
    )

  useEffect(() => {
    if (!headCompanyFetched.current && headCompany) {
      setHeadDistributor(headCompany)
      headCompanyFetched.current = true
    }
  }, [headCompany])

  useEffect(() => {
    checkProducerFeatureFlags(order?.producer)
  }, [checkProducerFeatureFlags, order?.producer])

  const initialProducerId =
    initialValues.producer_id ||
    (isUsersCompanyProducer(userCompany) && userCompany?.id) ||
    (isUsersCompanyDistributor(userCompany) &&
      userCompany?.producers_relations?.length === 1 &&
      userCompany.producers_relations[0]) ||
    undefined

  const currentDate = useMemo(() => {
    return new Date().toISOString()
  }, [])

  const validationSchema = useMemo(() => {
    return Yup.object({
      distributor_id: Yup.string().required(t('validation:field_required')),
      producer_id: Yup.string().required(t('validation:field_required')),
      items: Yup.array().min(1, t('validation:oneProductMinRequired')),
      order_date: Yup.string().required(t('validation:field_required')),
      season_id: Yup.string().required(t('validation:field_required')),
      status: Yup.string(),
      comment: Yup.string(),
    })
  }, [t])

  const formik = useFormik<FormFields>({
    initialValues: {
      producer_id: initialProducerId,
      distributor_id: initialValues.distributor_id || (isUsersCompanyDistributor(userCompany) && userCompany?.id) || '',
      status: initialValues.status,
      comment: initialValues.comment || '',
      items: initialValues.items || [],
      billing_company_id: initialValues.billing_company_id || userCompany?.id,
      purchase_number: initialValues.purchase_number,
      interaction: initialValues.interaction || {
        confirmed_by_producer: false,
        canceled_by_role: null,
      },
      order_date: initialValues.order_date || currentDate,
      season_id: initialValues.season_id,
      desired_delivery_date: initialValues.desired_delivery_date,
      delivery_date: initialValues.delivery_date,
      shipping_date: initialValues.shipping_date,
      external_order_id: initialValues.external_order_id,

      files: initialValues.files,
      promocodes: initialValues.promocodes,
    },
    validationSchema,
    enableReinitialize: true,
    // used custom submit handler
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  const editingDateHasExpired = useHasOutOfStockDateExpired(formik.values.items)

  const handleProductsChange = useCallback(
    (index, qty, product?: Omit<Product, 'options'>, wizard_comment?: string, wizard_data?: WizardData<any>) => {
      const items = clone(formik.values.items)
      if (product) {
        const newItem: DistributorOrderItem = {
          product_id: product.id,
          quantity: qty,
          product: product as Product,
          key: uuid.v4(),
          wizard_comment,
          wizard_data,
        }
        const seedTreatment = product.seed_treatment ? product.seed_treatment.filter(s => s.is_active) : []
        if (seedTreatment && seedTreatment.length) {
          newItem.seed_treatment_id = seedTreatment[0].id
        }
        items.unshift(newItem)
      } else {
        items[index].quantity = qty
      }
      formik.setFieldValue(`items`, items)
      if (productQtyIsNullError && items.find(item => item.quantity)) {
        setProductQtyIsNullError(false)
      }
    },
    [formik, productQtyIsNullError],
  )

  const handleProductRemove = useCallback(
    (index: number) => {
      if (formik.values.items[index]) {
        formik.setFieldValue('items', remove(index, 1, formik.values.items))
      }
    },
    [formik],
  )

  const handleProductOptionsChange = useCallback(
    (idx, options) => {
      const items = clone(formik.values.items)
      if (idx !== -1) {
        items[idx].options = options
      }
      formik.setFieldValue(`items`, items)
    },
    [formik],
  )

  const handleSeedTreatmentChange = useCallback(
    (idx, value) => {
      const items = clone(formik.values.items)
      if (idx !== -1) {
        items[idx].seed_treatment_id = value
      }
      formik.setFieldValue(`items`, items)
    },
    [formik],
  )

  const handleAltPackagingChange = useCallback(
    (idx, value) => {
      const items = clone(formik.values.items)
      if (idx !== -1) {
        items[idx].packaging = value
      }
      formik.setFieldValue(`items`, items)
    },
    [formik],
  )

  const handleCommentChange = useCallback(
    (idx, value) => {
      const items = clone(formik.values.items)
      items[idx].comment = value
      formik.setFieldValue(`items`, items)
    },
    [formik],
  )

  const handleFilesChange = useCallback(
    (items: FileItem[]) => {
      formik.setFieldValue('files', items)
    },
    [formik],
  )

  const requiredOptionsValid = useMemo(() => {
    const items = clone(formik.values.items)
    const errors: string[][] = []
    let isValid = true
    items.forEach((item: DistributorOrderItem) => {
      const opts = item.product?.product_options
      const foundedErrors: string[] = []
      if (!opts || !opts.length) return
      const options = item.options || []

      opts.forEach(o => {
        const found = o.option_ids.filter(x => options.includes(x))
        if (o.required && !found.length) {
          foundedErrors.push(o.type)
          isValid = false
        }
      })

      errors.push(foundedErrors)
    })
    setOptionsRequiredErrors(errors)
    return isValid
  }, [formik.values.items])

  const submit = async (duplicate = false) => {
    try {
      await formik.submitForm()

      const formInvalid = !formik.isValid
      const requiredOptionsInvalid = !requiredOptionsValid

      if (formInvalid || requiredOptionsInvalid) {
        return
      }
      const values = { ...formik.values, items: formik.values.items.filter(item => item.quantity) }

      if (!values.items.length) {
        setProductQtyIsNullError(true)
        return
      }
      onSubmit(values, {
        dirty: formik.dirty,
        duplicate,
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const handleSubmit = () => {
    submit(false)
  }

  const isProducerChangeAllowed = !order

  const onProducerChange = useCallback(
    (id: string, producer?: SingleValue<Company>) => {
      formik.resetForm()
      const producerCurrentSeason = (seasons ?? []).filter(s => s.company_id === id).find(s => s.is_current)
      formik.setFieldValue('season_id', producerCurrentSeason?.id)
      checkProducerFeatureFlags(producer)
      formik.setFieldValue('producer_id', id)
    },
    [formik, seasons, checkProducerFeatureFlags],
  )
  //TODO need check again
  const [, initialProducer] = useCompanyById(initialProducerId)

  useEffect(() => {
    if (!order?.producer && initialProducerId && initialProducerId === formik.values.producer_id) {
      checkProducerFeatureFlags(initialProducer)
    }
  }, [order?.producer, initialProducerId, formik.values.producer_id, initialProducer, checkProducerFeatureFlags])

  const handleSeasonChange = (val: string) => {
    formik.setFieldValue('season_id', val)
    formik.setFieldValue('items', [])
    formik.setFieldTouched('items', false)
    clearProductsLimitsForRetailerOrders()
  }

  const isCancelOrderDisabled = useMemo(() => {
    return formik.values.status === DistributorOrderStatus.Canceled || editingDateHasExpired
  }, [editingDateHasExpired, formik.values.status])

  const onDistributorChange = useCallback(
    (value: string, distributor?: Company, headDistributor?: Company) => {
      formik.setFieldValue('distributor_id', value)
      setSelectedDistributor(distributor || null)
      setHeadDistributor(headDistributor || null)
      if (!formik.values.billing_company_id) {
        formik.setFieldValue('billing_company_id', value)
      }
    },
    [formik],
  )

  const shippingAddress = [
    t(`common:country.full.${selectedDistributor?.country}`),
    selectedDistributor?.city,
    selectedDistributor?.address,
    selectedDistributor?.postal_code,
  ]
    .filter(Boolean)
    .join(', ')

  const billingAddress = [
    headDistributor && t(`common:country.full.${headDistributor?.country}`),
    headDistributor?.city,
    headDistributor?.address,
    headDistributor?.postal_code,
  ]
    .filter(Boolean)
    .join(', ')

  const confirmationPermitted = (isProducer(role) || isAdmin) && !!order && !isUpdateAfterSaveRestricted
  const cancellationPermitted =
    !!order && !isUpdateAfterSaveRestricted && order.status !== DistributorOrderStatus.Canceled
  const uncancellationPermitted =
    (isAdmin ||
      checkFeatureFlag(
        generateCrmButtonAccessString(Sections.RetailerOrders, Buttons.Uncancel, FeatureFlagModifiers.Enabled),
      )) &&
    !!order &&
    order.status === DistributorOrderStatus.Canceled

  const canRemoveOrder = useMemo(() => {
    return isRemovePermitted && !editingDateHasExpired
  }, [isRemovePermitted, editingDateHasExpired])

  const removingPermitted = useMemo(
    () => !!order && canRemoveOrder && order.status !== DistributorOrderStatus.Deleted,
    [canRemoveOrder, order],
  )

  const restorePermitted = useMemo(() => isAdmin && !!order && order.status === DistributorOrderStatus.Deleted, [
    isAdmin,
    order,
  ])

  const entriesWithPrices = useMemo(() => {
    return formik.values.items.map(entry => {
      return { ...entry, price: entry?.product?.price }
    })
  }, [formik.values.items])
  const calculateParams = useMemo(
    () => ({
      producer_id: formik.values.producer_id || '',
      entries: entriesWithPrices,
      promocodes: formik.values.promocodes ? formik.values.promocodes.map(p => p.code) : [],
      at_date: formik.values.order_date,
      season_id: formik.values.season_id,
      scopes: [DiscountRuleScope.DistributorOrder],
    }),
    [
      entriesWithPrices,
      formik.values.order_date,
      formik.values.producer_id,
      formik.values.season_id,
      formik.values.promocodes,
    ],
  )
  const [calcProgress, discountInfo] = useCalculatedDiscounts(calculateParams)

  const { actualTotalNet, totalSum } = useTotalSum({
    orders: formik.values.items || [],
    producerId: formik.values.producer_id || '',
    totalNet,
    discountInfo,
  })

  const [downloadProgress, downloadXlsx] = useDownload(endpoints.distributorOrder('download/xlsx'), {
    order_id: order?.id,
  })

  const isDisabledField = !isEditableForCertainCompanies && !isEditable
  const isSaveBtnDisabled = !formik.dirty || isDisabledField || isFilesUploading || progress === Progress.WORK
  const canAddFiles = formik.values.files.length || isEditable || isEditableForCertainCompanies
  const isNotEditableCertainFields = !isEditable || isEditableForCertainCompanies

  const handlePromocodeApply = useCallback(
    (code: string, promocode: Promocode) => {
      const promocodes = clone(formik.values.promocodes)
      promocodes.push({ ...promocode, code })
      formik.setFieldValue('promocodes', promocodes)

      if (isPromocodeWithComment(promocode) || isPromocodeWithLegalText(promocode)) {
        setSelectedPromocode(code)
      }
    },
    [formik],
  )

  const handlePromocodeRemove = useCallback(
    code => {
      const promocodes = clone(formik.values.promocodes)
      const index = promocodes.findIndex(item => item.code === code)
      if (index !== -1) {
        promocodes.splice(index, 1)
      }
      formik.setFieldValue('promocodes', promocodes)
    },
    [formik],
  )

  const handlePromocodeCommentChange = useCallback(
    (code, comment) => {
      const promocodes = clone(formik.values.promocodes)
      const index = promocodes.findIndex(item => item.code === code)
      if (index !== -1) {
        const editedPromocode = promocodes[index] as PromocodeWithComment
        editedPromocode.comment = comment
      }
      formik.setFieldValue('promocodes', promocodes)
    },
    [formik],
  )

  const onSeasonChange = val => {
    if (!formik.values.season_id || !formik.values.items.length) {
      handleSeasonChange(val as string)
    } else {
      openModal({
        contentProps: {},
        content: (
          <ModalWithButtonContent
            modalContent={t('modalText')}
            allowButtonText={t('common:yes')}
            cancelButtonText={t('common:no')}
            handlleAllowButtonClick={() => handleSeasonChange(val as string)}
          />
        ),
      })
    }
  }

  useValidationErrorNotification(formik.submitCount, formik.isValid)

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <Wrapper>
          <Column>
            <SectionContainer>
              <SectionBody>
                <FormComponents.FormSection title={t('form.sectionHeaders.producer')}>
                  {isProducerChangeAllowed ? (
                    <CompanySelect
                      label={t('form.labels.producer')}
                      onChange={(value, company) => onProducerChange(value as string, company)}
                      value={formik.values.producer_id}
                      companyType={CompanyType.Producer}
                      isClearable
                      isSearchable
                      onMenuClose={() => formik.setFieldTouched('producer_id')}
                      invalid={!!formik.submitCount && !!formik.errors.producer_id}
                      errorText={formik.errors.producer_id}
                      required
                      isDisabled={!!userCompany && userCompany.company_type === CompanyType.Producer}
                      filter={{
                        feature_flags: generateCrmSectionAccessString(
                          Sections.RetailerOrders,
                          FeatureFlagModifiers.Enabled,
                        ),
                      }}
                    />
                  ) : (
                    <CompanyInfoPreview
                      company={order?.producer}
                      footer={`ID ${order?.producer?.id || ''}`}
                      link={
                        isAdmin && order && order.producer
                          ? generatePath(CompanyRoutes.Edit, { id: order.producer?.id })
                          : undefined
                      }
                    />
                  )}
                  <SeasonSelect
                    label={t('form.labels.season')}
                    placeholder={t('form.placeholders.season')}
                    value={formik.values.season_id}
                    companyId={formik.values.producer_id}
                    getMappedOption={season => ({
                      ...season,
                      isDisabled: !isAdmin && season.is_season_closed,
                    })}
                    onChange={onSeasonChange}
                    isDisabled={isEditableForCertainCompanies || !isEditable}
                    onMenuClose={() => {
                      formik.setFieldTouched('season_id')
                    }}
                    invalid={!!formik.submitCount && !!formik.errors.season_id}
                    errorText={formik.errors.season_id}
                    isClearable
                    required
                  />
                  {!!selectedDistributor && selectedDistributor.head_company_relation && (
                    <FormComponents.FormSection title={t('form.sectionHeaders.billingCompany')}>
                      <PreviewContainer.WrapperCompact>
                        <PreviewContainer.Header>{headDistributor?.internal_name || ''}</PreviewContainer.Header>
                        <PreviewContainer.Subheader>
                          {headDistributor?.short_description || ''}
                        </PreviewContainer.Subheader>
                        <PreviewContainer.Text>{billingAddress}</PreviewContainer.Text>
                      </PreviewContainer.WrapperCompact>
                    </FormComponents.FormSection>
                  )}
                  {isDistributorChangeAllowed ? (
                    <CompanySelect
                      label={t('form.sectionHeaders.distributor')}
                      placeholder={t(t('form.placeholders.distributor'))}
                      onChange={value => onDistributorChange(value as string)}
                      value={formik.values.distributor_id}
                      companyType={CompanyType.Distributor}
                      isClearable
                      isSearchable
                      onMenuClose={() => {
                        formik.setFieldTouched('distributor_id')
                      }}
                      invalid={!!formik.submitCount && !!formik.errors.distributor_id}
                      errorText={formik.errors.distributor_id}
                      required
                      isDisabled={!formik.values.producer_id || isEditableForCertainCompanies || !isEditable}
                      filter={{ for_producer_id: formik.values.producer_id }}
                    />
                  ) : (
                    <FormComponents.FormSection title={t('form.sectionHeaders.distributor')}>
                      <CompanyInfoPreview company={selectedDistributor} testId={'distributor-preview'} />
                    </FormComponents.FormSection>
                  )}
                  {!!selectedDistributor && selectedDistributor.head_company_relation && (
                    <FormComponents.FormSection title={t('form.sectionHeaders.shippingAddress')}>
                      <PreviewContainer.WrapperCompact>
                        <PreviewContainer.Subheader>{shippingAddress}</PreviewContainer.Subheader>
                      </PreviewContainer.WrapperCompact>
                    </FormComponents.FormSection>
                  )}
                  {!!selectedDistributor && !selectedDistributor.head_company_relation && (
                    <>
                      <FormComponents.FormSection title={t('form.sectionHeaders.billingAndShippingAddress')}>
                        <PreviewContainer.WrapperCompact>
                          <PreviewContainer.Subheader>{shippingAddress}</PreviewContainer.Subheader>
                        </PreviewContainer.WrapperCompact>
                      </FormComponents.FormSection>
                    </>
                  )}
                  <Input
                    label={t('form.sectionHeaders.purchaseNumber')}
                    {...formik.getFieldProps('purchase_number')}
                    disabled={isNotEditableCertainFields}
                  />
                  {orderNumberEnabled && (
                    <Input
                      label={t('form.sectionHeaders.orderNumber')}
                      {...formik.getFieldProps('external_order_id')}
                      disabled={isNotEditableCertainFields}
                    />
                  )}
                  <DatePickerWrapper data-test-id={'order-date'}>
                    <DatePicker
                      onChange={value => {
                        formik.setFieldValue('order_date', value)
                        formik.setFieldTouched('order_date')
                      }}
                      date={formik.getFieldProps('order_date').value}
                      invalid={!!formik.submitCount && !!formik.errors.order_date}
                      errorText={formik.errors.order_date}
                      label={t('form.labels.orderDate')}
                      disabled={!isAdmin}
                      format={dateFormat}
                      required
                    />
                  </DatePickerWrapper>
                  {deliveryDatesEnabled ? (
                    <>
                      <DatePickerWrapper data-test-id={'desired-delivery-date'}>
                        <DatePicker
                          onChange={value => {
                            formik.setFieldValue('desired_delivery_date', value)
                            formik.setFieldTouched('desired_delivery_date')
                          }}
                          date={formik.getFieldProps('desired_delivery_date').value}
                          invalid={!!formik.submitCount && !!formik.errors.order_date}
                          errorText={formik.errors.order_date}
                          label={t('form.labels.desiredDeliveryDate')}
                          disabled={isDisabledField}
                          format={dateFormat}
                        />
                      </DatePickerWrapper>
                      <DatePickerWrapper data-test-id={'delivery-date'}>
                        <DatePicker
                          onChange={value => {
                            formik.setFieldValue('delivery_date', value)
                            formik.setFieldTouched('delivery_date')
                          }}
                          date={formik.getFieldProps('delivery_date').value}
                          invalid={!!formik.submitCount && !!formik.errors.order_date}
                          errorText={formik.errors.order_date}
                          label={t('form.labels.deliveryDate')}
                          disabled={isDisabledField}
                          format={dateFormat}
                        />
                      </DatePickerWrapper>
                    </>
                  ) : null}
                  {shippingDateEnabled && (
                    <DatePickerWrapper data-test-id={'shipping-date'}>
                      <DatePicker
                        onChange={value => {
                          formik.setFieldValue('shipping_date', value)
                          formik.setFieldTouched('shipping_date')
                        }}
                        date={formik.getFieldProps('shipping_date').value}
                        invalid={!!formik.submitCount && !!formik.errors.shipping_date}
                        errorText={formik.errors.shipping_date}
                        label={t('form.labels.shippingDate')}
                        disabled={isDisabledField || isDistributor(role)}
                        format={dateFormat}
                      />
                    </DatePickerWrapper>
                  )}
                </FormComponents.FormSection>
              </SectionBody>
            </SectionContainer>
            {order && (isAgro(role) || isHistoryCardVisible) && (
              <OrderHistoryCard
                orderId={order.id}
                createdById={order.author_id}
                orderSource={order.order_source}
                createdDate={order.created_at}
                confirmedByProducerId={order.last_confirmed_id}
              />
            )}
          </Column>
          <Column>
            {isEditableForCertainCompanies || isEditable ? (
              <OrderProductItems
                context={'distributor'}
                onChange={handleProductsChange}
                onRemove={handleProductRemove}
                onMenuClose={() => formik.setFieldTouched('items')}
                isAllowed={!!formik.values.producer_id}
                items={formik.values.items}
                producerId={formik.values.producer_id || undefined}
                onSeedTreatmentChange={handleSeedTreatmentChange}
                onProductOptionsChange={handleProductOptionsChange}
                onAltPackagingChange={handleAltPackagingChange}
                onCommentChange={handleCommentChange}
                invalid={!!formik.submitCount && !!formik.errors.items}
                errorText={formik.errors.items as string}
                optionsRequiredErrors={optionsRequiredErrors}
                mode={mode}
                total={totalSum}
                netTotal={actualTotalNet}
                showComment={showComment}
                distributorId={formik.values.distributor_id as string}
                seasonId={formik.values.season_id}
                initialItems={formik.initialValues.items}
              />
            ) : (
              <OrderProductItemsReadOnly
                items={formik.values.items}
                total={totalSum}
                netTotal={actualTotalNet}
                mode={'edit'}
                context={'distributor'}
              />
            )}
            {formik.values.producer_id && formik.values.items && (
              <OrderDiscounts
                producerId={formik.values.producer_id}
                discountInfo={discountInfo}
                progress={calcProgress}
              />
            )}
            <SectionContainer>
              <SectionTitle>{t('form.sectionHeaders.promocodes')}</SectionTitle>
              <SectionBody>
                <PromocodeField
                  company_id={formik.values.producer_id}
                  onApply={handlePromocodeApply}
                  disabled={!isEditable}
                  promocodes={formik.values.promocodes}
                />
                <PromocodeList
                  promocodes={formik.values.promocodes}
                  onRemove={handlePromocodeRemove}
                  onCommentChange={handlePromocodeCommentChange}
                  selected={selectedPromocode}
                  onSelect={setSelectedPromocode}
                />
              </SectionBody>
            </SectionContainer>
            <SectionContainer>
              <SectionTitle>{t('form.sectionHeaders.comment')}</SectionTitle>
              <SectionBody>
                <TextArea
                  {...formik.getFieldProps('comment')}
                  placeholder={t('form.placeholders.comment')}
                  disabled={isDisabledField}
                  data-test-id={'comment-textarea'}
                />
              </SectionBody>
            </SectionContainer>
            {canAddFiles && (
              <SectionContainer>
                <SectionTitle>{t('form.sectionHeaders.attachedFiles')}</SectionTitle>
                <SectionBody>
                  <FileManager items={formik.values.files} onChange={handleFilesChange} disabled={isDisabledField} />
                </SectionBody>
              </SectionContainer>
            )}
          </Column>
        </Wrapper>
      </StickyFooterLayout.Body>
      <StickyFooterLayout.ButtonsFooter>
        <Button
          intent={'primary'}
          filled
          disabled={isSaveBtnDisabled}
          onClick={handleSubmit}
          progress={progress}
          data-test-id={'save-button'}
        >
          {t('common:save')}
        </Button>
        <StickyFooterBtn
          heading={t('common:cancelEditingHeader')}
          text={t('common:cancelEditingText')}
          onSubmit={onCancel}
          buttonText={t('common:dontSaveChanges')}
          intent={'cancel'}
          disabled={isDisabledField}
        />
        <StickyFooterLayout.ActionButtonsWrapper>
          {confirmationPermitted && (
            <Button
              intent={'action'}
              filled
              onClick={onProducerConfirmation}
              progress={progress}
              disabled={
                formik.values.interaction.confirmed_by_producer ||
                order?.status === DistributorOrderStatus.Canceled ||
                isDisabledField ||
                isFilesUploading
              }
            >
              {isAdmin ? t('confirmOrderForProducer') : t('confirmOrder')}
            </Button>
          )}
          {cancellationPermitted && (
            <StickyFooterBtn
              heading={t('form.cancelOrderHeader')}
              text={t('form.cancelOrderText')}
              onSubmit={onCancelOrder}
              buttonText={t('cancelOrder')}
              intent={'danger'}
              filled
              progress={progress}
              disabled={isCancelOrderDisabled || isDisabledField || isFilesUploading}
            />
          )}
          {uncancellationPermitted && (
            <StickyFooterBtn
              heading={t('form.uncancelOrderHeader')}
              text={t('form.uncancelOrderText')}
              onSubmit={onUncancelOrder}
              buttonText={t('uncancelOrder')}
              intent={'danger'}
              filled
              progress={progress}
              disabled={isDisabledField || isFilesUploading}
            />
          )}
        </StickyFooterLayout.ActionButtonsWrapper>
        {!!order && isDownloadXlsxEnabled && (
          <DownloadButtonWrapper>
            <Button intent={'primary'} onClick={downloadXlsx} progress={downloadProgress}>
              {t('labels:downloadXlsx')}
            </Button>
          </DownloadButtonWrapper>
        )}
        {isCompletedButtonEnabled && order?.status === DistributorOrderStatus.Confirmed && (
          <Button intent={'action'} onClick={onComplete} progress={progress} disabled={isCompletedButtonDisabled}>
            {t('labels:completed')}
          </Button>
        )}

        {productQtyIsNullError && <Snackbar>{t('productQtyIsNullError')}</Snackbar>}
        {removingPermitted && !!onRemove && (
          <StickyFooterDeleteBtn
            onRemove={onRemove}
            removeProgress={removeProgress}
            popoverText={t('form.removeText', { id: order?.slug })}
            disabled={isDisabledField}
          />
        )}
        {restorePermitted && !!onRestore && <StickyFooterRestoreBtn onRestore={onRestore} progress={progress} />}
      </StickyFooterLayout.ButtonsFooter>
    </StickyFooterLayout.Wrapper>
  )
}

export default DistributorOrderDetailsForm
