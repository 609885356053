import { ProcoteSku, ProcoteSkuProps, ProcoteWizardNode } from '@agro-club/frontend-shared'
import React from 'react'

type PropsType = {
  opened: boolean
  onClose: () => void
  wizardNode: ProcoteWizardNode | null
  changeOrderList: ProcoteSkuProps['handleCrm']
  onResultCardChange: ProcoteSkuProps['onResultCardChange']
}

export const CardWizardProcote = ({ changeOrderList, wizardNode, opened, onClose, onResultCardChange }: PropsType) => {
  return !!wizardNode ? (
    <ProcoteSku
      opened={opened}
      onClose={onClose}
      handleCrm={changeOrderList as any}
      productWizard={wizardNode}
      onResultCardChange={onResultCardChange}
    />
  ) : null
}
