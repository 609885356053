import React, { useCallback, useMemo } from 'react'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { useSelector } from 'react-redux'
import MailingListSelectors from 'modules/domain/mailingList/selectors'
import MailingListActions from 'modules/domain/mailingList/duck'
import { useTranslation } from 'react-i18next'
import { CellProps, useTable } from 'react-table'
import {
  MailingListEntry,
  MailingListEntryStatus,
  MailingListListRequestFilter,
} from 'modules/domain/mailingList/types'
import { useMailingListList } from 'modules/domain/mailingList/hooks'
import { TableFilter } from 'views/components/TableFilters/TableFilters'
import MailingListRoutes from 'views/pages/MailingList/routes'
import Status from '../Status'
import {
  Flag,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useHistoryPush,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import useDateFormatFn from 'hooks/useDateFormatFn'

const CreatedAtCell: React.FC<CellProps<MailingListEntry>> = ({ row, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })

  return (
    <div key={column.id}>
      <div>{getFormatedDate(row.values.created_at)}</div>
    </div>
  )
}

const UpdatedAtCell: React.FC<CellProps<MailingListEntry>> = ({ row, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })
  return (
    <div key={column.id}>
      <div>{getFormatedDate(row.values.updated_at)}</div>
    </div>
  )
}

const StatusCell: React.FC<CellProps<MailingListEntry>> = ({ row, column }) => {
  return (
    <div key={column.id}>
      <Status status={row.values.status} />
    </div>
  )
}

const RegionCell: React.FC<CellProps<MailingListEntry>> = ({ row, column }) => {
  if (!row.values.region) {
    return null
  }

  return (
    <div key={column.id}>
      <Flag code={row.values.region} />
    </div>
  )
}

const MailingList: React.FC = () => {
  const push = useHistoryPush()
  const { t } = useTranslation(['mailingList', 'labels'])
  const [progress, data = []] = useMailingListList()
  const total = useSelector(MailingListSelectors.total)
  const page = useSelector(MailingListSelectors.page)
  const pages = useSelector(MailingListSelectors.pages)
  const pageSize = useSelector(MailingListSelectors.pageSize)
  const filter = useSelector(MailingListSelectors.filter)

  const listRequested = useAction(MailingListActions.listRequested)
  const filterUpdated = useAction(MailingListActions.filterUpdated)
  const { scrollRef } = usePersistentScroll('mailingList')

  const allColumns = useMemo(
    () => [
      {
        Header: t('fields.status'),
        accessor: 'status' as const,
        sortable: false,
        Cell: StatusCell,
      },
      {
        Header: t('fields.email'),
        accessor: 'email' as const,
        sortable: false,
      },
      {
        Header: t('fields.region'),
        accessor: 'region' as const,
        sortable: false,
        Cell: RegionCell,
      },
      {
        Header: t('fields.createdAt'),
        accessor: 'created_at' as const,
        sortable: false,
        Cell: CreatedAtCell,
      },
      {
        Header: t('fields.updatedAt'),
        accessor: 'updated_at' as const,
        sortable: false,
        Cell: UpdatedAtCell,
      },
    ],
    [t],
  )

  const hiddenColumns = useMemo(() => ['id'], [])

  const { columns, rows, prepareRow } = useTable<MailingListEntry>({
    columns: allColumns,
    data,
    initialState: { hiddenColumns },
  })

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const statusFilterOptions = useMemo(
    () => [
      {
        value: MailingListEntryStatus.ACTIVE,
        title: t('status.active'),
        bullet: true,
        color: 'green' as const,
      },
      {
        value: MailingListEntryStatus.UNSUBSCRIBED,
        title: t('status.unsubscribed'),
        bullet: true,
        color: 'orange' as const,
      },
    ],
    [t],
  )

  const handleFilterChange = useCallback(
    (newValue: Partial<MailingListListRequestFilter>) => {
      filterUpdated({ ...filter, ...newValue })
    },
    [filter, filterUpdated],
  )

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <TableFilter
          handleFilterChange={handleFilterChange}
          title={t('labels:status')}
          filterValue={{ status: filter.status }}
          options={statusFilterOptions}
          disableMulti
        />
      </TComponents.Filters>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return <TableHeadCell key={column.id}>{column.render('Header')}</TableHeadCell>
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  push({ route: MailingListRoutes.Item, params: { id: row.original.id.toString() } })
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={allColumns.length}
            loading={<TComponents.Spinner />}
          />
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default MailingList
