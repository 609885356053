import React, { useCallback, useMemo } from 'react'
import { Filter as FilterComponent, TableFilter } from 'views/components/TableFilters/TableFilters'
import { useTranslation } from 'react-i18next'
import { CellProps, HeaderProps, useRowSelect, useTable } from 'react-table'
import ReturnDeclarationActions from 'modules/domain/returnDeclaration/duck'
import ReturnDeclarationSelectors from 'modules/domain/returnDeclaration/selectors'
import { useReturnDeclarationList } from 'modules/domain/returnDeclaration/hooks'
import { ReturnDeclarationListRequestFilter, ReturnDeclarationType } from 'modules/domain/returnDeclaration/types'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import Routes from '../routes'
import { ReturnDeclaration, ReturnDeclarationItem } from 'types/returnDeclaration'
import {
  RangeDatePicker,
  AdvancedHeadCell,
  Table,
  TableHead,
  TableHeadRow,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableNoData,
  useAction,
  usePersistentScroll,
  Button,
} from '@agro-club/frontend-shared'
import { parseISO, isValid } from 'date-fns'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Progress } from 'modules/types'
import ReturnDeclarationStatusSelect from 'views/components/ReturnDeclarationStatusSelect/ReturnDeclarationStatusSelect'
import useLangPicker from 'hooks/useLangPicker'
import { Columns, FeatureFlagModifiers, isUsersCompanyBranchDistributor, Tables } from 'types/entities'
import AuthSelectors from 'modules/domain/auth/selectors'
import styled from 'styled-components'
import DistributorsMultiSelect from 'views/components/DistributorsMultiSelect/DistributorsMultiSelect'
import ReturnDeclarationStatusColored from 'views/components/ReturnDeclarationStatusColored/ReturnDeclarationStatusColored'
import useDownload from 'hooks/useDownload'
import { endpoints } from 'modules/endpoints'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateCrmTableColumnAccessString } from 'modules/utils/generateStringHelpers'
import useDateFormatFn from 'hooks/useDateFormatFn'
import useDateFormat from 'hooks/useDateFormat'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'
import { AlignWrapper } from 'views/components/AlignWrapper/AlignWrapper'

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: max-content auto max-content;
  height: 100%;
`

const SeedTreatment = styled.div`
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 650px;
  max-width: 970px;
`

const Filter = styled(FilterComponent)`
  margin: 0 16px 16px 0 !important;
  width: 30%;
  max-width: 220px;
  flex-grow: 1;
  justify-content: space-between;
`

const StatusColumnHeaderCell: React.FC<HeaderProps<ReturnDeclaration>> = () => {
  const { t } = useTranslation('returnDeclaration')
  return (
    <TComponents.CheckboxWrapper>
      <span>{t('list.tableHeaders.status')}</span>
    </TComponents.CheckboxWrapper>
  )
}

const StatusCell: React.FC<CellProps<ReturnDeclaration>> = ({ row }) => {
  const { t } = useTranslation('returnDeclaration')
  return (
    <TComponents.CheckboxWrapper>
      <ReturnDeclarationStatusColored
        status={row.values.status}
        dangerouslySetInnerHTML={{ __html: t(`status.${row.values.status}`) }}
      />
    </TComponents.CheckboxWrapper>
  )
}

const ProducerCell: React.FC<CellProps<ReturnDeclaration>> = ({ cell, column }) => {
  return (
    <TComponents.BoldText key={column.id}>
      {((cell.value && cell.value.internal_name) || '').toUpperCase()}
    </TComponents.BoldText>
  )
}

const IdDateCell: React.FC<CellProps<ReturnDeclaration>> = ({ cell, row, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })
  return (
    <div key={column.id} style={{ whiteSpace: 'nowrap' }}>
      <div>{row.values.slug}</div>
      {!!cell.value && <div>{getFormatedDate(cell.value)}</div>}
    </div>
  )
}

const DistributorCell: React.FC<CellProps<ReturnDeclaration>> = ({ cell, column }) => {
  return <span key={column.id}>{(cell.value && cell.value.internal_name) || ''}</span>
}

const Crop: React.FC<CellProps<ReturnDeclaration>> = ({ row }) => {
  const items = row.values.items as ReturnDeclarationItem[]
  const { pick } = useLangPicker()

  return (
    <div>
      {items.map(
        (item, i) =>
          item.product &&
          item.product?.subcategory && (
            <div key={`${item.product_id}-${i}`}>{pick(item.product.subcategory.title_i18n)}</div>
          ),
      )}
    </div>
  )
}

const Qty: React.FC<CellProps<ReturnDeclaration>> = ({ row }) => {
  const items = row.values.items as ReturnDeclarationItem[]

  return (
    <div>
      {items.map((item, i) => (
        <div key={i}>{item.quantity}</div>
      ))}
    </div>
  )
}

const SeedTreatmentCell: React.FC<CellProps<ReturnDeclaration>> = ({ cell }) => {
  if (!cell.value) {
    return <span>{''}</span>
  }

  return (
    <div>
      {cell.value.map((item: ReturnDeclarationItem, i: number) => {
        const id = item.seed_treatment_id
        const seedTreatment = id ? item.product?.seed_treatment.find(el => el.id === id) : null
        return (
          <SeedTreatment key={`${item.product_id}-${i}`} title={seedTreatment?.title || '-'}>
            {seedTreatment?.title || '-'}
          </SeedTreatment>
        )
      })}
    </div>
  )
}

const ItemsCell: React.FC<CellProps<ReturnDeclaration>> = ({ cell }) => {
  if (!cell.value) {
    return <span>{''}</span>
  }

  return (
    <div>
      {cell.value.map((item: ReturnDeclarationItem, i: number) => {
        return (
          <div key={`${item.product_id}-${i}`} style={{ whiteSpace: 'nowrap' }}>{`${item.product?.title || ''}`}</div>
        )
      })}
    </div>
  )
}

const TypeCell: React.FC<CellProps<ReturnDeclaration>> = ({ cell, column }) => {
  const { t } = useTranslation('returnDeclaration')
  const translate = cell.value === 'in_season' ? 'type.inSeason' : 'type.endOfSeason'
  return <span key={column.id}>{t(translate)}</span>
}

const SortableHeadCell = AdvancedHeadCell<keyof ReturnDeclaration>()

const ReturnDeclarationList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation(['returnDeclaration', 'labels'])
  const userCompany = useSelector(AuthSelectors.userCompany)
  const dateFormat = useDateFormat({ isYearShort: true })
  const isBasicRetailer = isUsersCompanyBranchDistributor(userCompany) || isUsersCompanyBranchDistributor(userCompany)
  const checkFeatureFlag = useFeatureFlags()
  const hasAccess = useCallback(flag => !checkFeatureFlag(flag), [checkFeatureFlag])
  const isSeedTreatmentHidden = !hasAccess(
    generateCrmTableColumnAccessString(Tables.DistributorOrders, Columns.SeedTreatment, FeatureFlagModifiers.Hidden),
  )

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: StatusColumnHeaderCell,
        accessor: 'status' as const,
        Cell: StatusCell,
      },
      {
        Header: t('form.sectionHeaders.type'),
        accessor: 'type' as const,
        Cell: TypeCell,
      },
      {
        Header: t('list.tableHeaders.idAndDate'),
        accessor: 'declaration_date' as const,
        Cell: IdDateCell,
      },
      {
        Header: t('list.tableHeaders.producer'),
        accessor: 'producer' as const,
        Cell: ProducerCell,
      },
      {
        Header: t('list.tableHeaders.distributor'),
        accessor: 'distributor' as const,
        Cell: DistributorCell,
        hidden: isBasicRetailer,
      },
      {
        Header: t('list.tableHeaders.crops'),
        accessor: 'items' as const,
        id: 'crops',
        Cell: Crop,
      },
      {
        Header: t('list.tableHeaders.items'),
        accessor: 'items' as const,
        id: 'items',
        Cell: ItemsCell,
      },
      {
        Header: t('list.tableHeaders.quantity'),
        accessor: 'items' as const,
        id: 'qty',
        Cell: Qty,
      },
      {
        Header: t('list.tableHeaders.seedTreatment'),
        accessor: 'items' as const,
        id: 'seed-treatment',
        Cell: SeedTreatmentCell,
        hidden: isSeedTreatmentHidden,
      },
      {
        accessor: 'created_at' as const,
        hidden: true,
      },
      {
        accessor: 'slug' as const,
        hidden: true,
      },
    ],
    [t, isBasicRetailer, isSeedTreatmentHidden],
  )

  const hiddenColumns: (keyof ReturnDeclaration)[] = React.useMemo(
    () => ['currency', 'region_id', 'created_at', 'slug'],
    [],
  )
  const [progress, data = []] = useReturnDeclarationList()
  const { columns, rows, prepareRow } = useTable<ReturnDeclaration>(
    {
      columns: visibleColumns,
      data,
      initialState: { hiddenColumns },
    },
    useRowSelect,
  )

  const filterUpdated = useAction(ReturnDeclarationActions.filterUpdated)
  const filterCleared = useAction(ReturnDeclarationActions.filterHasBeenReset)
  const sortingUpdated = useAction(ReturnDeclarationActions.sortingUpdated)
  const listRequested = useAction(ReturnDeclarationActions.listRequested)
  const filterValue = useSelector(ReturnDeclarationSelectors.filter)
  const page = useSelector(ReturnDeclarationSelectors.page)
  const pageSize = useSelector(ReturnDeclarationSelectors.pageSize)
  const pages = useSelector(ReturnDeclarationSelectors.pages)
  const total = useSelector(ReturnDeclarationSelectors.total)
  const { start_date, end_date, type } = filterValue
  const { sort_field, sort_reversed } = useSelector(ReturnDeclarationSelectors.sorting)

  const typeDeclarationOptions = useMemo(
    () => [
      { value: ReturnDeclarationType.InSeason, title: t('type.inSeason'), bullet: true, color: 'green' as const },
      {
        value: ReturnDeclarationType.EndOfSeason,
        title: t('type.endOfSeason'),
        bullet: true,
        color: 'orange' as const,
      },
    ],
    [t],
  )

  const handleFilterChange = React.useCallback(
    (newFilterValue: Partial<ReturnDeclarationListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const handleDateRangeChange = React.useCallback(
    ([start, end]) => {
      const parsedStartDate = parseISO(start)
      const parsedEndDate = parseISO(end)
      if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
        handleFilterChange({ start_date: start, end_date: end })
      }
    },
    [handleFilterChange],
  )

  const handleClearFilters = React.useCallback(() => {
    filterCleared()
  }, [filterCleared])

  const isFilterApplied = Object.values(filterValue).some(Boolean)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('returnDeclarationList')

  const handleStatusChange = useCallback(
    (filter: Partial<ReturnDeclarationListRequestFilter>) => {
      const { status } = filter
      handleFilterChange({
        status,
      })
    },
    [handleFilterChange],
  )

  const [progressCSV, downloadCSV] = useDownload(endpoints.returnDeclaration('download/csv'), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  return (
    <Wrapper>
      <TComponents.Filters>
        <FiltersWrapper>
          <Filter title={t('labels:period')}>
            <RangeDatePicker
              start={start_date || ''}
              end={end_date || ''}
              onChange={handleDateRangeChange}
              format={dateFormat}
            />
          </Filter>
          <Filter title={t('labels:status')}>
            <ReturnDeclarationStatusSelect
              value={filterValue}
              onChange={handleStatusChange}
              placeholder={t('status.any')}
            />
          </Filter>
          {!isBasicRetailer && (
            <Filter title={t('labels:retailer')}>
              <DistributorsMultiSelect
                onChange={val => handleFilterChange({ distributor_id: val })}
                selected={filterValue.distributor_id}
              />
            </Filter>
          )}
          <Filter title={t('labels:season')}>
            <SeasonSelect
              onChange={value => handleFilterChange({ season_id: value })}
              variant="small"
              value={filterValue.season_id}
              isClearable
            />
          </Filter>
          <Filter title={t('labels:type')}>
            <div data-test-id={'filters-declaration-type'}>
              <TableFilter
                handleFilterChange={handleFilterChange}
                filterValue={{ type }}
                options={typeDeclarationOptions}
                title={''}
                disableMulti
              />
            </div>
          </Filter>
        </FiltersWrapper>
        <AlignWrapper verticalAlign="bottom" horizontalAlign="right">
          <Button filled intent="primary" onClick={downloadCSV} progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </AlignWrapper>
      </TComponents.Filters>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <SortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof ReturnDeclaration}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                  width={column.width}
                >
                  {column.render('Header')}
                </SortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                selected={row.isSelected}
                onClick={() => {
                  history.push(generatePath(Routes.Edit, { id: row.original.id.toString() }))
                }}
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()

                  if (cell.column.hidden) {
                    return null
                  }

                  return (
                    <TableBodyCell key={key} {...props} stopClickPropagation={cell.column.id === 'status'}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={handleClearFilters}>
                {t('list.resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </Wrapper>
  )
}

export default ReturnDeclarationList
