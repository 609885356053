import { useAction, useHistoryPush } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import StorefrontProductActions from 'modules/domain/storefrontProduct/duck'
import { useStorefrontProductById } from 'modules/domain/storefrontProduct/hooks'
import StorefrontProductSelectors from 'modules/domain/storefrontProduct/selectors'
import { StorefrontProductDTO } from 'modules/domain/storefrontProduct/types'
import { Progress } from 'modules/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import StorefrontRoutes from '../routes'
import StorefrontProductDetailsForm from './components/StorefrontProductDetailsForm'

const StorefrontProductEdit: React.FC = () => {
  const { t } = useTranslation('product')
  const { pick } = useLangPicker()
  const push = useHistoryPush()
  const params = useParams<{ id: string }>()
  const updateAction = useAction(StorefrontProductActions.updateRequested)
  const removeAction = useAction(StorefrontProductActions.removeRequested)
  const page = useSelector(StorefrontProductSelectors.page)
  const meta = useSelector(StorefrontProductSelectors.meta(params.id))
  const [fetchProgress, item] = useStorefrontProductById(params.id)

  const handleSubmit = (props: StorefrontProductDTO) => {
    updateAction(params.id, props)
  }

  const handleRemove = () => {
    removeAction(params.id)
  }

  const goBack = () => {
    push({ route: StorefrontRoutes.ProductsList, query: { page } })
  }

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !item) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={pick(item.title_i18n) || ''} />
          <Header.Id>{`ID ${item.id}`}</Header.Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <StorefrontProductDetailsForm
          progress={meta.updateProgress}
          mode={'edit'}
          onSubmit={handleSubmit}
          onRemove={handleRemove}
          onCancel={goBack}
          initialValues={item}
        />
      </Layout.Content>
    </>
  )
}

export default StorefrontProductEdit
