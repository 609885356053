import React, { useCallback } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import Routes from './routes'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReturnDeclarationDetailsForm, {
  FormFields,
} from 'views/components/ReturnDeclarationDetailsForm/ReturnDeclarationDetailsForm'
import ReturnDeclarationActions from 'modules/domain/returnDeclaration/duck'
import ReturnDeclarationSelectors from 'modules/domain/returnDeclaration/selectors'
import { ReturnDeclarationStatus } from 'types/returnDeclaration'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import { ReturnDeclarationType } from 'modules/domain/returnDeclaration/types'
import AuthSelectors from 'modules/domain/auth/selectors'
import { isDistributor } from 'types/entities'

const ReturnDeclarationAdd: React.FC<{
  initialState?: FormFields
  onUpdateInitialState(props: FormFields | undefined): void
}> = ({ initialState, onUpdateInitialState }) => {
  const { t } = useTranslation('returnDeclaration')
  const push = useHistoryPush()
  const page = useSelector(ReturnDeclarationSelectors.page)
  const addProgress = useSelector(ReturnDeclarationSelectors.addProgress)
  const goBack = useCallback(() => push({ route: Routes.List, query: page ? { page } : undefined }), [page, push])
  const addDeclaration = useAction(ReturnDeclarationActions.addRequested)
  const role = useSelector(AuthSelectors.role)

  useHelmet({ title: t('addPageTitle') })

  const handleSubmit = useCallback(
    (values: FormFields, options: { duplicate: boolean; dirty: boolean }) => {
      onUpdateInitialState(undefined)
      const { producer_id, ...params } = values
      const data = {
        producer_id: producer_id || null,
        ...params,
      }
      addDeclaration(data, options.duplicate)
    },
    [addDeclaration, onUpdateInitialState],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title compact size={'small'} title={t('addPageTitle')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ReturnDeclarationDetailsForm
          mode={'create'}
          onCancel={goBack}
          onSubmit={handleSubmit}
          progress={addProgress}
          initialValues={{
            status: initialState?.status || ReturnDeclarationStatus.New,
            pickup_note: initialState?.pickup_note,
            distributor_id: initialState?.distributor_id || '',
            producer_id: initialState?.producer_id || null,
            comment: initialState?.comment,
            items: initialState?.items || [],
            season_id: initialState?.season_id,
            type: isDistributor(role) ? ReturnDeclarationType.EndOfSeason : initialState?.type,
          }}
        />
      </Layout.Content>
    </>
  )
}

export default ReturnDeclarationAdd
