import React, { useMemo } from 'react'
import { getPrettyDiapason, getPrettyPrice } from 'modules/utils/helpers'
import { Currency, DiscountTypes, LocalizedValue } from 'types/entities'
import { Chip, SectionContainer, SectionTitle, SectionBody, FormComponents } from '@agro-club/frontend-shared'
import styled, { css } from 'styled-components'
import { Progress } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { clone } from 'ramda'
import { CalculatedDiscountDTO, CalculatedDiscountResponse, CalculatedTier } from 'modules/domain/discountRule/types'
import { useCompanyById } from 'modules/domain/company/hooks'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { Total, TotalValue } from 'views/components/OrderProductItems/styles'
import useLangPicker from 'hooks/useLangPicker'
import useDateFormatFn from 'hooks/useDateFormatFn'
import { HistoricQtyTierRule, SkuCalculatedTier } from 'modules/domain/incentiveProgram/types'
import { HistoricQtyRule } from '../OrderSkuDiscounts/OrderSkuMonetaryDiscount/HistoricQtyRule'

export const DiscountsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const DiscountRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`

export const DiscountLabel = styled.div`
  margin-right: 16px;
  color: ${({ theme }) => theme.color.secondary200};
  font-size: 14px;
`

export const DiscountItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const DiscountChip = styled(Chip)`
  height: 24px;
  font-size: 14px;
  margin-right: 8px;
  padding: 4px 8px;
`

const LearnMore = styled.a`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  margin-bottom: 12px;
`

export const Container = styled(SectionContainer)<{ isAllowed: boolean }>`
  transition: 0.3s all;
  position: relative;
  ${(props: { isAllowed: boolean }) =>
    props.isAllowed
      ? ''
      : css`
          opacity: 0.8;
          cursor: not-allowed;
        `}
`

export const Content = styled.div<{ isAllowed: boolean }>`
  pointer-events: ${(props: { isAllowed: boolean }) => (props.isAllowed ? 'auto' : 'none')};
`

const DiscountRow: React.FC<{
  tiers: CalculatedTier[]
  title: string
  currency: Currency
  linkTitle?: string
  linkUrl?: string
  measureText?: string
}> = ({ tiers, title, currency, linkTitle, linkUrl, measureText }) => {
  const { t } = useTranslation('discount')
  const getFormatedDate = useDateFormatFn()

  const sortedItems = useMemo(() => {
    return clone(tiers).sort(
      (prev, next) => parseFloat(prev.tier_rule.amount || '0') - parseFloat(next.tier_rule.amount || '0'),
    )
  }, [tiers])

  if (!tiers.length) return null

  return (
    <DiscountRowWrapper>
      <DiscountLabel>{title}</DiscountLabel>
      <DiscountItemsContainer>
        {sortedItems.map((tier, idx) => {
          if (tier.tier_rule.type === DiscountTypes.HistoryQuantity) {
            return (
              <HistoricQtyRule
                tier={(tier as unknown) as SkuCalculatedTier<HistoricQtyTierRule>}
                currency={currency}
                idx={idx}
              />
            )
          }
          if (tier.tier_rule.type === DiscountTypes.Percentage) {
            return (
              <DiscountChip key={idx} active={tier.is_applied} color={'orange'}>
                {`${tier.tier_rule.percent}% | ${getPrettyDiapason(
                  getFormatedDate(tier.tier_rule.start_date),
                  getFormatedDate(tier.tier_rule.end_date),
                )} | ${getPrettyDiapason(tier.tier_rule.min_qty, tier.tier_rule.max_qty)}`}
              </DiscountChip>
            )
          }

          const discount = getPrettyPrice(parseFloat(tier.tier_rule.amount || '0'), currency)
          const amount = `${tier.tier_rule.min_qty} ${tier.tier_rule.max_qty ? `- ${tier.tier_rule.max_qty}` : '+'}`
          const measure = measureText || t('bag')

          return (
            <DiscountChip key={idx} active={tier.is_applied} color={'orange'}>
              {t('tierRuleTemplate', { discount, amount, measure })}
            </DiscountChip>
          )
        })}
        {linkUrl && linkTitle && (
          <LearnMore href={linkUrl} target={'blank'}>
            {linkTitle || t('common:learnMore')}
          </LearnMore>
        )}
      </DiscountItemsContainer>
    </DiscountRowWrapper>
  )
}

const QuantityDiscount: React.FC<{ title: string; items: CalculatedDiscountDTO[]; currency: Currency }> = ({
  title,
  items,
  currency,
}) => {
  const { pick } = useLangPicker()

  return (
    <FormComponents.FormSection title={title}>
      {/* <EligibilityLabel>
        {t('subjectToEligibility')}
        <sup> * </sup>
      </EligibilityLabel> */}
      <DiscountsSectionWrapper>
        {items.map((discount, idx) => (
          <DiscountRow
            key={idx}
            tiers={discount.tiers}
            title={discount.rule.title}
            measureText={discount.rule.measure}
            currency={currency}
            linkUrl={pick(discount.rule.link_url_i18n as LocalizedValue<string>)}
            linkTitle={pick(discount.rule.link_label_i18n)}
          />
        ))}
      </DiscountsSectionWrapper>
    </FormComponents.FormSection>
  )
}
type PropsType = {
  producerId: string
  discountInfo?: CalculatedDiscountResponse
  progress: Progress
}
export const OrderDiscounts: React.FC<PropsType> = ({ producerId, discountInfo, progress }: PropsType) => {
  const { t } = useTranslation('discount')
  const [, producer] = useCompanyById(producerId || '')

  const monetaryDiscounts = discountInfo?.discounts?.filter(d => d.rule.rule_type !== DiscountTypes.Gift)

  const campaigns = useMemo(
    () =>
      monetaryDiscounts
        ? monetaryDiscounts.reduce(
            (acc: Set<string>, discount: CalculatedDiscountDTO) => acc.add(discount.rule.campaign_name),
            new Set(),
          )
        : [],
    [monetaryDiscounts],
  )

  if (progress === Progress.WORK && !discountInfo) {
    return <SpinnerLayout />
  }

  if (!monetaryDiscounts?.length) {
    return null
  }

  let discountTotal: number = parseFloat(String(discountInfo?.total)) || 0
  discountTotal = Number.isInteger(discountTotal) ? Number(discountTotal) : Number(discountTotal.toFixed(2))

  return (
    <Container isAllowed={true}>
      <Content isAllowed={true}>
        <SectionTitle>{t('discounts')}</SectionTitle>
        <SectionBody data-test-id={'order-discounts-container'}>
          {[...campaigns].map(campaign => {
            return (
              <QuantityDiscount
                key={campaign}
                items={monetaryDiscounts.filter(d => d.rule.campaign_name === campaign)}
                title={campaign}
                currency={producer?.currency || Currency.CAD}
              />
            )
          })}
          {discountInfo?.total && (
            <Total>
              {t('totalSavings')}: <TotalValue>{getPrettyPrice(discountTotal, producer?.currency)}</TotalValue>
            </Total>
          )}
        </SectionBody>
      </Content>
    </Container>
  )
}
