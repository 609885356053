import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import DistributorOrderList from './DistributorOrderList/DistributorOrderList'
import DistributorOrderActions from 'modules/domain/distributorOrder/duck'
import DistributorOrderSelectors from 'modules/domain/distributorOrder/selectors'
import { useSelector } from 'react-redux'
import DistributorOrderRoutes from 'views/pages/DistributorOrder/routes'
import { AddButton, SearchInput, useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import ReturnDeclarationRoutes from '../ReturnDeclaration/routes'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateCrmSectionAccessString } from 'modules/utils/generateStringHelpers'
import { useSeasonList } from 'modules/domain/season/hooks'
import { Progress } from 'modules/types'
import { SpinnerWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import { FeatureFlagModifiers, Sections, Actions, isAgro } from 'types/entities'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { generateActionAccessString } from 'modules/utils/generateStringHelpers'
import AuthSelectors from 'modules/domain/auth/selectors'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'

const SearchInputWrapper = styled.div`
  padding: 18px 24px;
  width: 50%;
`

const DistributorOrderTable: React.FC = () => {
  const checkFeatureFlag = useFeatureFlags()
  const hasAccess = useCallback(flag => checkFeatureFlag(flag), [checkFeatureFlag])
  const role = useSelector(AuthSelectors.role)

  const filter = useSelector(DistributorOrderSelectors.filter)
  const push = useHistoryPush()
  const filterUpdated = useAction(DistributorOrderActions.filterUpdated)
  const { t } = useTranslation(['distributorOrder', 'returnDeclaration', 'common'])

  const isCreateRestricted = checkFeatureFlag(
    generateActionAccessString(Sections.ReturnDeclarations, Actions.Create, FeatureFlagModifiers.Restricted),
  )

  const [seasonsProgress] = useSeasonList()

  const handleSearchChange = React.useCallback(
    (search?: string) => {
      filterUpdated({ ...filter, ...{ search } })
    },
    [filterUpdated, filter],
  )

  useHelmet({ title: t('ordersMetaTitle') })

  const isReturnDeclarationEnabled =
    isAgro(role) || hasAccess(generateCrmSectionAccessString(Sections.ReturnDeclarations, FeatureFlagModifiers.Enabled))

  const isCreatePermitted = usePermissions({ capability: CAPABILITY.DISTRIBUTOR_ORDERS, permission: PERMISSION.C })

  return (
    <>
      <Layout.Header>
        <Header.TabsRoot>
          <Header.Tab title={t('list.heading')} isActive onClick={() => push({ route: DistributorOrderRoutes.List })}>
            {isCreatePermitted && <AddButton to={DistributorOrderRoutes.Add} />}
          </Header.Tab>
          {isReturnDeclarationEnabled && (
            <Header.Tab
              title={t('returnDeclaration:list.heading')}
              onClick={() => push({ route: ReturnDeclarationRoutes.List })}
            >
              {!isCreateRestricted && <AddButton to={ReturnDeclarationRoutes.Add} />}
            </Header.Tab>
          )}
        </Header.TabsRoot>
        <SearchInputWrapper>
          <SearchInput onChange={handleSearchChange} value={filter.search} placeholder={t('list.searchPlaceholder')} />
        </SearchInputWrapper>
      </Layout.Header>
      <Layout.Content>
        {seasonsProgress === Progress.SUCCESS ? (
          <DistributorOrderList />
        ) : (
          <SpinnerWrapper>
            <SpinnerLayout />
          </SpinnerWrapper>
        )}
      </Layout.Content>
    </>
  )
}

export default DistributorOrderTable
