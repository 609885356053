import React, { useMemo, useCallback, useState } from 'react'
import { Button, Modal, Checkbox, useBoolean, Input, Tooltip } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { FieldMetaProps } from 'formik/dist/types'
import { FeatureFlagInput } from 'views/pages/Company/CompanyDetailsForm/CompanyConfigForm'
import { remove } from 'ramda'
import * as Yup from 'yup'
import { FEATURE_FLAGS_ROLES, FeatureFlagPermission } from 'types/entities'
import * as Styled from './styled'
import i18n from 'i18n'

const rolesValues = Object.values(FEATURE_FLAGS_ROLES)

const NotSelectedRoles: React.FC = () => {
  return (
    <Styled.TooltipWrapper>
      <Tooltip
        visibleOnHover={true}
        temporary={true}
        tooltipContent={<Styled.TooltipContent>{i18n.t('company:form.noRolesSelected')}</Styled.TooltipContent>}
        position="top"
      >
        <Styled.IconWarningStyled />
      </Tooltip>
    </Styled.TooltipWrapper>
  )
}

export const rolesMap = rolesValues.reduce(
  (acc, obj) => ({
    ...acc,
    [obj]: false,
  }),
  {},
)

export type FeatureFlagsFormFields = {
  items: FeatureFlagInput[]
}

const FeatureFlagsForm: React.FC<{
  items: FeatureFlagInput[]
  onSubmit: (items: FeatureFlagInput[]) => void
}> = ({ items, onSubmit }) => {
  const [isOpen, openModal, closeModal] = useBoolean(false)
  const [showedRowIndex, setShowedRowIndex] = useState<number | null | 'all'>(null)
  const { t } = useTranslation(['company'])

  const validationSchema = useMemo(() => {
    return Yup.object({
      items: Yup.array(
        Yup.object({
          name: Yup.string().required(t('validation:field_required')),
          value: Yup.object(),
        }),
      ),
    })
  }, [t])

  const formik = useFormik<FeatureFlagsFormFields>({
    validationSchema,
    initialValues: {
      items,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    enableReinitialize: true,
  })

  const getFieldMeta = useCallback(
    (field: string, idx: number): FieldMetaProps<FeatureFlagsFormFields> =>
      formik.getFieldMeta(`items[${idx}].${field}`),
    [formik],
  )

  const handleAddFlag = useCallback(() => {
    formik.setFieldValue('items', [
      ...formik.values.items,
      {
        name: '',
        value: rolesMap,
      },
    ])
  }, [formik])

  const handleRemoveFlag = useCallback(
    (index: number) => {
      formik.setFieldValue('items', remove(index, 1, formik.values.items))
    },
    [formik],
  )

  const handleInputFocus = useCallback(
    idx => {
      showedRowIndex !== 'all' && setShowedRowIndex(idx)
    },
    [showedRowIndex],
  )

  const handleExpand = useCallback(() => {
    showedRowIndex === 'all' ? setShowedRowIndex(null) : setShowedRowIndex('all')
  }, [showedRowIndex])

  const handleSubmit = useCallback(() => {
    if (!formik.isValid) return

    onSubmit(formik.values.items)
    closeModal()
  }, [formik.isValid, formik.values.items, closeModal, onSubmit])

  const handleCancel = useCallback(() => {
    setShowedRowIndex(null)
    formik.resetForm()
    closeModal()
  }, [closeModal, formik])

  const hasFlags = useMemo(() => {
    return !!formik.values.items.length
  }, [formik.values.items.length])

  const permissionsChange = useCallback(
    (isChecked, role, valueKey) => {
      const currentRoles = formik.getFieldProps(valueKey).value
      let nextRoles: FeatureFlagPermission

      if (role == FEATURE_FLAGS_ROLES.ALL) {
        nextRoles = {
          ...Object.keys(rolesMap).reduce((acc, key) => ({ ...acc, [key]: isChecked }), {}),
        }
        formik.setFieldValue(valueKey, nextRoles)
      } else {
        nextRoles = { ...currentRoles, [role]: isChecked }
      }

      formik.setFieldValue(valueKey, nextRoles)
    },
    [formik],
  )

  return (
    <>
      <Styled.ConfigureFeatureFlagsButton onClick={() => openModal()} data-test-id="configure-feature-flag-button">
        <Styled.IconSettingsStyled />
        {t('form.configure')}
      </Styled.ConfigureFeatureFlagsButton>
      {isOpen ? (
        <Modal
          isOpen={isOpen}
          onClose={handleCancel}
          heading={t('form.featureFlagsConfiguration')}
          shouldCloseOnEsc={true}
          size="large"
        >
          <Styled.ContentWrapper>
            {hasFlags && (
              <Styled.StyledButton onClick={handleExpand} data-test-id={'expand-collapse-button'}>
                {showedRowIndex === 'all' ? t('form.collapseAll') : t('form.expandAll')}
              </Styled.StyledButton>
            )}
            {hasFlags ? (
              formik.values.items.map((item: any, idx: number) => {
                const nameKey = `items[${idx}].name`
                const valueKey = `items[${idx}].value`
                const rolesNotSelected =
                  !!item.name.trim() && Object.values(formik.getFieldProps(valueKey).value).every(val => !val)

                return (
                  <Styled.FeatureFlagRow key={idx}>
                    <Styled.RemoveButton
                      data-test-id={'remove-feature-flag-button'}
                      onClick={() => handleRemoveFlag(idx)}
                    />
                    <Styled.InputWrapper style={{ paddingRight: rolesNotSelected ? '40px' : 0 }}>
                      <Input
                        {...formik.getFieldProps(nameKey)}
                        label={t('form.featureFlagName')}
                        invalid={getFieldMeta('name', idx).touched && !!getFieldMeta('name', idx).error}
                        errorText={getFieldMeta('name', idx).error}
                        value={item.name.trim()}
                        onFocus={() => handleInputFocus(idx)}
                        data-test-id={`flag-${idx}-name`}
                        required
                      />
                      {rolesNotSelected && <NotSelectedRoles />}
                    </Styled.InputWrapper>
                    {(showedRowIndex === idx || showedRowIndex === 'all') && (
                      <Styled.FeatureFlagPermissions data-test-id={`flag-${idx}-permissions`}>
                        {rolesValues.map((role: string, key: number) => (
                          <Checkbox
                            key={key}
                            label={<Styled.Label>{role}</Styled.Label>}
                            isChecked={
                              !!formik.getFieldProps(valueKey).value[FEATURE_FLAGS_ROLES.ALL] ||
                              !!formik.getFieldProps(valueKey).value[role]
                            }
                            onChange={(_, isChecked) => permissionsChange(isChecked, role, valueKey)}
                            disabled={
                              role !== FEATURE_FLAGS_ROLES.ALL &&
                              !!formik.getFieldProps(valueKey).value[FEATURE_FLAGS_ROLES.ALL]
                            }
                            testId={`flag-${idx}-${role}-permission`}
                          />
                        ))}
                      </Styled.FeatureFlagPermissions>
                    )}
                  </Styled.FeatureFlagRow>
                )
              })
            ) : (
              <div data-test-id="no-feature-flags">{t('form.noFeatureFlags')}</div>
            )}
            <Styled.StyledButton onClick={handleAddFlag} data-test-id={'add-feature-flag-button'}>
              {t('form.addFeatureFlag')}
            </Styled.StyledButton>
          </Styled.ContentWrapper>
          <Styled.ModalFooter>
            <Button
              disabled={!formik.dirty}
              intent={'primary'}
              filled
              type={'submit'}
              onClick={handleSubmit}
              data-test-id="submit-feature-flags-form"
            >
              {t('Save')}
            </Button>
            <Button onClick={handleCancel} intent={'cancel'}>
              {t('Cancel')}
            </Button>
          </Styled.ModalFooter>
        </Modal>
      ) : null}
    </>
  )
}

export default FeatureFlagsForm
