import React from 'react'
import ProductDetailsForm, { FormProps } from 'views/pages/Product/ProductDetailsForm/ProductDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import ProductActions from 'modules/domain/product/duck'
import ProductSelectors from 'modules/domain/product/selectors'
import { useSelector } from 'react-redux'
import ProductRoutes from 'views/pages/Product/routes'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

export const ProductAdd: React.FC<{ initialState?: FormProps; onUpdateInitialState(props: FormProps): void }> = ({
  initialState,
  onUpdateInitialState,
}) => {
  const push = useHistoryPush()
  const { t } = useTranslation('product')
  const addProgress = useSelector(ProductSelectors.addProgress)
  const addAction = useAction(ProductActions.addRequested)
  const page = useSelector(ProductSelectors.page)
  const goBack = () => push({ route: ProductRoutes.List, query: { page } })
  useHelmet({ title: t('addProductMetaTitle') })
  const handleSubmit = (form: FormProps, options: { duplicate: boolean; dirty: boolean }) => {
    onUpdateInitialState(form)
    addAction(form, options.duplicate)
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ProductDetailsForm
          mode={'create'}
          onSubmit={handleSubmit}
          onCancel={goBack}
          progress={addProgress}
          initialValues={initialState}
        />
      </Layout.Content>
    </>
  )
}

export default ProductAdd
