import { Currency } from 'types/entities'
import env from 'env'
import { CountryCode } from 'libphonenumber-js'
import { FarmerOrderFilterStatus } from 'types/farmerOrder'
import { EntityState } from './domain/types'
import { Progress } from '@agro-club/frontend-shared'
import { Country } from './domain/collection/types'

export const DADATA_COMPANY_API_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party'
export const DADATA_ADDRESS_API_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
export const DADATA_API_KEY = 'e9640d8a3572c018cadaafb2aaf42d8338476edc'

export const apiDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS'

export const API_BASE_URL = 'https://api-company-filters.stage.agro.club/api/v1'
// export const AUTH_API_BASE_URL = 'https://api-auth.stage.agro.club/v1'
export const AUTH_API_BASE_URL = 'https://api-company-filters.stage.agro.club/api/v1'

export const AVAILABLE_CURRENCY: Currency[] = [Currency.CAD]

export const FARMER_ORDER_STATUS_LIST: FarmerOrderFilterStatus[] = []

export const DATE_FORMAT = 'dd.MM.yy'

export const LIST_PAGE_SIZE = 50

export const COUNTERS_FETCH_DELAY_MS = env.DEVELOPMENT ? 1000 * 256 : 1000 * 5

export const SKUS_LIMIT_FOR_CARD = 100

// TODO: get default country from config
export const DEFAULT_COUNTRY: CountryCode = 'CA'
export const DEFAULT_LANGUANGE = 'en'

export enum STORAGE_KEYS {
  FILTERS = 'global_filters',
}

export const TWO_ROWS_TABLE_HEAD_HEIGHT = 80

export enum ISOLanguageCode {
  CA = 'en-CA',
  US = 'en-US',
  RU = 'ru-RU',
  UA = 'uk-UA',
  ES = 'es-ES',
}

export enum CountryDateFormats {
  CA = 'MM/dd/yyyy',
  US = 'MM/dd/yyyy',
  RU = 'dd.MM.yyyy',
  UA = 'dd.MM.yyyy',
  ES = 'dd/MM/yyyy',
}

export const COUNTRIES: Country[] = [
  {
    id: 'RU',
    title: 'Russia',
    phone_prefix: '+7',
    features: ['original'],
  },
  {
    id: 'CA',
    title: 'Canada',
    phone_prefix: '+1',
    features: ['enterprise'],
  },
  {
    id: 'US',
    title: 'USA',
    phone_prefix: '+1',
    features: ['enterprise'],
  },
  {
    id: 'ES',
    title: 'Spain',
    phone_prefix: '+34',
    features: ['enterprise'],
  },
]

export const BasicEntityState: EntityState<any, any, any> = {
  items: {},
  meta: {},
  ids: [],

  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,
  listFetchNextErrorDetail: null,
  itemFetchErrorDetail: null,
  removeErrorDetail: null,
  listFetchErrorDetail: null,
  addErrorDetail: null,
  updateErrorDetail: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: LIST_PAGE_SIZE,
}

export const RETURN_DECLARATION_DATE = {
  'canterra-seeds': 'FRIDAY, JUNE 16, 2023',
}
