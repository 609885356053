import { BasicSelect } from '@agro-club/frontend-shared'
import { TargetTypes } from 'modules/domain/target2/common/types'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'
import { createGetList } from './common'

export type TargetTerritory = {
  id: string
  title: string
}

type TerritoryFilter = {
  manufacturer_id?: string
  title?: string
}

type RetailerTerritoryFilter = {
  retailer_id?: string
  title?: string
}

const getRetailerFormList = createGetList<TargetTerritory, RetailerTerritoryFilter>(
  endpoints.target2('territories_retailer'),
)

const getListForm = createGetList<TargetTerritory, TerritoryFilter>(endpoints.target2('territories_manufacturer'))

const getListTerritoryFilter = createGetList<TargetTerritory, TerritoryFilter>(
  endpoints.target2('filters/targets_territory/territories'),
)

const getListRetailerFilter = createGetList<TargetTerritory, TerritoryFilter>(endpoints.target2('retailer/territories'))

const getListFarmerFilter = createGetList<TargetTerritory, TerritoryFilter>(endpoints.target2('farmer/territories'))

type TargetsTerritorySelectProps = EntitySelectProps<TargetTerritory> & {
  manufacturerId?: string
  retailerId?: string
  valueLabel?: string
  scope?: TargetTypes | 'default' | 'retailer'
}
const scopeManagerMap = {
  [TargetTypes.Farmer]: getListFarmerFilter,
  [TargetTypes.Retailer]: getListRetailerFilter,
  [TargetTypes.Territory]: getListTerritoryFilter,
  retailer: getRetailerFormList,
  default: getListForm,
}

const getTerritoryLabel = (item: TargetTerritory) => item.title

const getTerritoryValue = (item: TargetTerritory) => item.id

export const TargetTerritorySelect: React.VFC<TargetsTerritorySelectProps> = ({
  manufacturerId,
  isDisabled,
  valueLabel,
  onChange,
  retailerId,
  scope = 'default',
  ...props
}) => {
  const filter = useMemo(() => ({ manufacturer_id: manufacturerId, retailer_id: retailerId }), [
    manufacturerId,
    retailerId,
  ])
  const fakeValue = useMemo(() => (valueLabel ? { title: valueLabel, id: 'fake' } : undefined), [valueLabel])

  return (
    <SelectTestWrapper data-test-id="target-territory-select">
      {isDisabled ? (
        <BasicSelect
          isDisabled
          getOptionLabel={getTerritoryLabel}
          getOptionValue={getTerritoryValue}
          {...props}
          value={fakeValue}
        />
      ) : (
        <EntitySelect
          filter={filter}
          getEntityList={scopeManagerMap[scope]}
          getOptionLabel={getTerritoryLabel}
          getOptionValue={getTerritoryValue}
          onChange={onChange}
          searchParamKey="title"
          {...props}
        />
      )}
    </SelectTestWrapper>
  )
}
