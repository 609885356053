import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import FarmerOrderActions from './duck'
import FarmerOrderSelectors from './selectors'
import { FarmerOrder } from 'types/farmerOrder'
import { useEffect, useState } from 'react'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useFarmerOrderList: ResourceHook<FarmerOrder[], void[]> = () => {
  const progress = useSelector(FarmerOrderSelectors.listFetchProgress)
  const list = useSelector(FarmerOrderSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(FarmerOrderActions.listRequested, page ? { page } : {})

  useDidMount(() => {
    fetchAction()
  })

  return [progress, list]
}

export const useFarmerOrder: ResourceHook<FarmerOrder, [string]> = (id: string) => {
  const [mut, setMut] = useState(true)
  const progress = useSelector(FarmerOrderSelectors.itemFetchProgress)
  const order = useSelector(state => FarmerOrderSelectors.item(state, id))
  const fetchAction = useAction(FarmerOrderActions.itemRequested, id)

  useDidMount(() => {
    // temp hack till order documents will be fixed
    fetchAction()
    setMut(false)
  })

  if (mut) {
    return [Progress.WORK, undefined]
  }

  return [progress, order]
}

export const useFarmerOrderById: ResourceHook<FarmerOrder, [string]> = (id: string) => {
  const meta = useSelector(state => FarmerOrderSelectors.meta(state, id))
  const item = useSelector(state => FarmerOrderSelectors.item(state, id))
  const fetchAction = useAction(FarmerOrderActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}
