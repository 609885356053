import React from 'react'
import { useSelector } from 'react-redux'
import ProductOptionsSelectors from 'modules/domain/productOptions/selectors'
import ProductOptionsActions from 'modules/domain/productOptions/duck'
import * as Header from 'views/ui/Header/Header'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import ProductOptionsDetailsForm, { FormProps } from 'views/pages/Product/ProductOptions/ProductOptionsDetailsForm'
import { useTranslation } from 'react-i18next'
import ProductRoutes from '../routes'
import { useAction, useHistoryPush } from '@agro-club/frontend-shared'

const ProductOptionsAdd: React.VFC = () => {
  const { t } = useTranslation('productOptions')
  const push = useHistoryPush()
  const addAction = useAction(ProductOptionsActions.addRequested)
  const addProgress = useSelector(ProductOptionsSelectors.addProgress)
  const page = useSelector(ProductOptionsSelectors.page)

  const handleSubmit = (form: Required<FormProps>) => {
    addAction(form)
  }

  const goBack = () => {
    push({ route: ProductRoutes.OptionsList, query: { page } })
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ProductOptionsDetailsForm progress={addProgress} mode={'create'} onSubmit={handleSubmit} onCancel={goBack} />
      </Layout.Content>
    </>
  )
}

export default ProductOptionsAdd
