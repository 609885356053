import { useSelector } from 'react-redux'
import { Progress, ResourceHook } from 'modules/types'
import CallBackRequestActions from './duck'
import CallBackRequestSelectors from './selectors'
import { CallBackRequest } from './types'
import { useEffect } from 'react'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useCallBackRequestList: ResourceHook<CallBackRequest[], void[]> = () => {
  const progress = useSelector(CallBackRequestSelectors.listFetchProgress)
  const list = useSelector(CallBackRequestSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(CallBackRequestActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useCallBackRequest: ResourceHook<CallBackRequest, [string]> = (id: string) => {
  const progress = useSelector(CallBackRequestSelectors.itemFetchProgress)
  const item = useSelector(state => CallBackRequestSelectors.item(state, id))
  const fetchAction = useAction(CallBackRequestActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [progress, item]
}

export const useCallBackRequestById: ResourceHook<CallBackRequest, [string]> = (id: string) => {
  const meta = useSelector(state => CallBackRequestSelectors.meta(state, id))
  const item = useSelector(state => CallBackRequestSelectors.item(state, id))
  const fetchAction = useAction(CallBackRequestActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}

export const usePendingCallBackRequestCount: ResourceHook<number, void[]> = () => {
  const progress = useSelector(CallBackRequestSelectors.pendingFetchProgress)
  const count = useSelector(CallBackRequestSelectors.pendingCount)
  const fetchAction = useAction(CallBackRequestActions.pendingCallCountRequested)

  useDidMount(() => {
    if (progress === Progress.IDLE) {
      fetchAction()
    }
  })
  return [progress, count]
}
