import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import DistributorOrderActions from 'modules/domain/distributorOrder/duck'
import DistributorOrderSelectors from 'modules/domain/distributorOrder/selectors'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { DistributorOrderStatus } from 'types/distributorOrder'
import DistributorOrderDetailsForm, {
  FormFields,
} from 'views/components/DistributorOrderDetailsForm/DistributorOrderDetailsForm'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import Routes from './routes'

const DistributorOrderAdd: React.FC<{
  initialState?: FormFields
  onUpdateInitialState(props: FormFields | undefined): void
}> = ({ initialState, onUpdateInitialState }) => {
  const { t } = useTranslation('distributorOrder')
  const history = useHistory()
  const push = useHistoryPush()
  const page = useSelector(DistributorOrderSelectors.page)
  const goBack = useCallback(
    () => (history.length > 2 ? history.goBack() : push({ route: Routes.List, query: { page } })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history.length, page, push],
  )
  const addProgress = useSelector(DistributorOrderSelectors.addProgress)

  const addOrder = useAction(DistributorOrderActions.addRequested)

  useHelmet({ title: t('addPageTitle') })

  const handleSubmit = useCallback(
    (values: FormFields, options: { duplicate: boolean; dirty: boolean }) => {
      onUpdateInitialState(undefined)
      const { producer_id, ...params } = values
      const data = {
        producer_id: producer_id,
        season_id: values.season_id || '',
        ...params,
      }
      addOrder(data, options.duplicate)
    },
    [addOrder, onUpdateInitialState],
  )

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title compact size={'small'} title={t('addPageTitle')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <DistributorOrderDetailsForm
          mode={'create'}
          onCancel={goBack}
          onSubmit={handleSubmit}
          progress={addProgress}
          initialValues={{
            ...initialState,
            status: initialState?.status || DistributorOrderStatus.New,
            purchase_number: initialState?.purchase_number,
            distributor_id: initialState?.distributor_id || '',
            billing_company_id: initialState?.billing_company_id,
            producer_id: initialState?.producer_id,
            comment: initialState?.comment,
            items: initialState?.items || [],
            interaction: {
              canceled_by_role: initialState?.interaction.canceled_by_role || null,
              confirmed_by_producer: !!initialState?.interaction.confirmed_by_producer,
            },
            season_id: initialState?.season_id || '',
            files: initialState?.files || [],
            promocodes: initialState?.promocodes || [],
          }}
        />
      </Layout.Content>
    </>
  )
}

export default DistributorOrderAdd
