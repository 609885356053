import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { Button, Progress, RangeDatePicker, SearchInput, useAction, useHelmet } from '@agro-club/frontend-shared'
import FccOrderSkuActions from 'modules/domain/fccOrderSku/duck'
import FccOrderSkuSelectors from 'modules/domain/fccOrderSku/selectors'
import { useSelector } from 'react-redux'
import FccOrderSkuTable from './FccOrderSkuTable'
import { parseISO, isValid } from 'date-fns'
import useDateFormat from 'hooks/useDateFormat'
import useDownload from 'hooks/useDownload'
import AuthSelectors from 'modules/domain/auth/selectors'
import { FccOrderSkuListRequestFilter } from 'modules/domain/fccOrderSku/types'
import { endpoints } from 'modules/endpoints'
import { isAgro, isUsersCompanyProducer, isUsersCompanyHeadDistributor } from 'types/entities'
import DistributorsMultiSelect from 'views/components/DistributorsMultiSelect/DistributorsMultiSelect'
import PreventNavigationModal from 'views/components/PreventNavigationModal/PreventNavigationModal'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'
import BusinessModelFilter from 'views/components/TableFilters/BusinessModelFilter'
import { Filter } from 'views/components/TableFilters/TableFilters'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import useErrorNotification from 'hooks/useErrorNotification'
import { getPersistentFilter } from 'modules/utils/helpers'

const SubtitleWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
  white-space: pre-wrap;
  max-width: 1000px;
  font-size: 16px;
  line-height: 24px;
`

const SearchInputWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 4px;
  width: 50%;
`

const Buttons = styled.div`
  justify-self: flex-end;
  margin: auto 0 0 auto;
  display: flex;
  gap: 16px;
`

const Filters = styled(TComponents.Filters)`
  align-items: center;
`

const FccOrderSkuList: React.FC = () => {
  const filter = useSelector(FccOrderSkuSelectors.filter)
  const filterUpdated = useAction(FccOrderSkuActions.filterUpdated)
  const { t } = useTranslation(['fccOrderSku', 'common'])
  const handleSearchChange = React.useCallback(
    (search?: string) => {
      filterUpdated({ ...filter, ...{ search } })
    },
    [filterUpdated, filter],
  )
  const userCompany = useSelector(AuthSelectors.userCompany)

  const updateRequested = useAction(FccOrderSkuActions.updateItemsRequested)
  const updateErrorDetail = useSelector(FccOrderSkuSelectors.updateErrorDetail)

  const filterValue = useSelector(FccOrderSkuSelectors.filter)
  const itemsForUpdate = useSelector(FccOrderSkuSelectors.itemsForUpdate)

  useHelmet({ title: t('fccMetaTitle') })

  const role = useSelector(AuthSelectors.role)

  const dateFormat = useDateFormat({ isYearShort: true })

  const clearIdsForUpdate = useAction(FccOrderSkuActions.clearItemsForUpdate)

  const isChanged = useSelector(FccOrderSkuSelectors.isChanged)
  const updateProgress = useSelector(FccOrderSkuSelectors.updateProgress)
  const { business_model, start_date, end_date } = filterValue
  const { sort_field, sort_reversed } = useSelector(FccOrderSkuSelectors.sorting)
  const onSave = useCallback(() => {
    updateRequested(itemsForUpdate)
  }, [itemsForUpdate, updateRequested])

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<FccOrderSkuListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const handleDateRangeChange = useCallback(
    ([start, end]) => {
      const parsedStartDate = parseISO(start)
      const parsedEndDate = parseISO(end)
      if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
        handleFilterChange({ start_date: start, end_date: end })
      } else {
        handleFilterChange({ start_date: undefined, end_date: undefined })
      }
    },
    [handleFilterChange],
  )

  const showDistributorsFilter =
    isAgro(role) || isUsersCompanyProducer(userCompany) || isUsersCompanyHeadDistributor(userCompany)

  const [progressCSV, downloadCSV] = useDownload(endpoints.farmerOrderSku('fcc/download/csv'), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  useErrorNotification(updateProgress === Progress.ERROR ? updateErrorDetail : '')

  const persistentFilters = getPersistentFilter('season_id')

  const agro = isAgro(role)

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')} />
          <SubtitleWrapper>{t('subtitle')}</SubtitleWrapper>
          <SearchInputWrapper>
            <SearchInput
              onChange={handleSearchChange}
              value={filter.search}
              placeholder={t('list.searchPlaceholder')}
            />
          </SearchInputWrapper>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <TComponents.Wrapper>
          <PreventNavigationModal
            when={isChanged}
            header={t('fccOrder:navigationModal.title')}
            message={t('fccOrder:navigationModal.message')}
            allowButtonContent={t('common:yes')}
            cancelButtonContent={t('common:no')}
            onAllowTransition={clearIdsForUpdate}
          />
          <Filters>
            <Filter title={t('labels:period')}>
              <RangeDatePicker
                start={start_date || ''}
                end={end_date || ''}
                onChange={handleDateRangeChange}
                format={dateFormat}
                isClearable
              />
            </Filter>
            <BusinessModelFilter
              filterValue={{ business_model }}
              handleFilterChange={handleFilterChange}
              disableMulti
            />
            {showDistributorsFilter && (
              <Filter title={t('labels:retailer')}>
                <DistributorsMultiSelect
                  onChange={val => handleFilterChange({ distributor_id: val })}
                  selected={filterValue.distributor_id}
                />
              </Filter>
            )}
            <Filter title={t('labels:season')}>
              <SeasonSelect
                onChange={val => handleFilterChange({ season_id: val as string })}
                value={filterValue.season_id}
                variant="small"
                width="200px"
                // this is because filterValue is updated with persistent filters
                // after some time but we need to prevent current season from being fetched
                useDefaultCurrentSeason={!!persistentFilters?.season_id ? false : true}
                placeholder={t('common:any')}
                isClearable={!!agro}
              />
            </Filter>
            <Buttons>
              <Button
                disabled={!isChanged || updateProgress === Progress.WORK}
                progress={updateProgress}
                onClick={onSave}
                intent={'primary'}
              >
                {t('common:save')}
              </Button>
              <Button filled intent="primary" onClick={downloadCSV} progress={progressCSV}>
                {t('labels:downloadCsv')}
              </Button>
            </Buttons>
          </Filters>
          <FccOrderSkuTable />
        </TComponents.Wrapper>
      </Layout.Content>
    </>
  )
}

export default FccOrderSkuList
