import {
  Button,
  Checkbox,
  CurrencySelect,
  FormComponents,
  helpersSlug,
  Input,
  SectionBody,
  SectionContainer,
  Segment,
  SimpleSelect,
  Switch,
  SwitchButton,
  Tabs,
  TextArea,
  useFormManager,
} from '@agro-club/frontend-shared'
import { FormikContext, useFormik } from 'formik'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import parsePhoneNumberFromString, { CountryCode, isValidPhoneNumber } from 'libphonenumber-js'
import { COUNTRIES, DEFAULT_COUNTRY } from 'modules/constants'
import { Region } from 'modules/domain/collection/types'
import { useCompanyById } from 'modules/domain/company/hooks'
import { Company, CompanyConfig, CompanyRelation, SellerRelatedContacts } from 'modules/domain/company/types'
import UploadManagerSelectors from 'modules/domain/uploadManager/selectors'
import { Progress } from 'modules/types'
import { clone, equals, remove } from 'ramda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Address } from 'types/address'
import { BusinessModel, CompanyType, Currency } from 'types/entities'
import { Dict } from 'types/generics'
import AddressForm from 'views/components/AddressForm/AddressForm'
import { CompanyMultiSelect } from 'views/components/CompanySelect/CompanyMultiSelect'
import ImageForm, { ImageFormItem, ImageFormProps } from 'views/components/ImageForm/ImageForm'
import PhoneInput from 'views/components/PhoneInput/PhoneInput'
import { PreviewContainer } from 'views/components/PreviewContainer/PreviewContainer'
import StickyFooterDefaultControls from 'views/components/StickyFormControls/StickyFooterDefaultControls'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import CompanyConfigForm, {
  FormManagerProps as ConfigFormFields,
} from 'views/pages/Company/CompanyDetailsForm/CompanyConfigForm'
import CompanyTerritoryForm, {
  CompanyTerritoryFormProps,
} from 'views/pages/Company/CompanyDetailsForm/CompanyTerritoryForm'
import Branches from 'views/pages/Company/CompanyDetailsForm/components/Branches'
import * as Styled from 'views/pages/Company/CompanyDetailsForm/styled'
import CompanyRoutes from 'views/pages/Company/routes'
import * as Yup from 'yup'
import CompanyContactForm, { CompanyContactFormProps } from './CompanyContactForm'
import { AddWrapper } from './components/BranchesStyles'
import RegionsMultiSelect from 'views/components/RegionsMultiSelect/RegionsMultiSelect'

const InputWrapper = styled.div`
  max-width: 350px;
`

const StyledButton = styled(Button)`
  width: max-content;
`

const useCompanyConfigList = () => {
  const { t } = useTranslation('company')
  return useMemo(
    () => [
      {
        title: t('tabs.main'),
        value: 'main',
      },
      {
        title: t('tabs.config'),
        value: 'config',
      },
    ],
    [t],
  )
}

const useCompanyTypesList = () => {
  const { t } = useTranslation('company')
  return useMemo(
    () => [
      {
        id: CompanyType.Producer,
        title: t('type.producer'),
      },
      {
        id: CompanyType.Distributor,
        title: t('type.distributor'),
      },
    ],
    [t],
  )
}

const useBusinessModelList = () => {
  const { t } = useTranslation('company')
  return useMemo(
    () => [
      {
        id: BusinessModel.Full,
        title: t('businessModel.full'),
      },
      {
        id: BusinessModel.Lite,
        title: t('businessModel.lite'),
      },
    ],
    [t],
  )
}

type MainFormProps = {
  companyType: CompanyType
  businessModel: BusinessModel
  rank?: string
  description: string | null
  officialName: string
  internalName: string
  accountNumber?: string
  slug?: string
  shortDescription: string | null
  address: string
  city: string
  postal_code: string
  region_id: string
  iban: string
  swift: string
  currency: Currency
  country?: CountryCode
  currentImage?: string
  regions: Region[]
  regions_ids: string[]
  is_active?: boolean
  is_seller?: boolean
  is_visible?: boolean
  is_supplier?: boolean
  sku_orders?: boolean
  creditOffer?: boolean
  comment?: string | null
  legal_address: Address
  physical_address: Address

  email?: string | null
  secondary_email?: string | null
  phone_number?: string | null

  contacts: SellerRelatedContacts[]

  branchCompanies?: Dict<Company>
  branchCompaniesIds?: string[]

  producers_relations: string[]
  territories: string[]
  taurus_account_number: null | string
  yara_account_number: null | string
}

export type FormFields = MainFormProps & {
  images: ImageFormItem[]
}

type FormManagerProps = {
  main: FormFields
  images?: ImageFormProps
  branches: CompanyRelation[]
  phoneNumberFormik: { code?: string; number: string }
  'contacts:0': SellerRelatedContacts
  'territory:0': CompanyTerritoryFormProps
  'territory:1': CompanyTerritoryFormProps
  // and so on, there is few of them, I don't know how to make it "array like"
}

const emptyArr = []
// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunc = () => {}

const CompanyDetailsForm: React.FC<{
  initialValues?: FormFields
  onRemove?(): void
  onSubmit(values: FormFields, branches?: CompanyRelation[]): void
  onSubmitConfig?(values: ConfigFormFields): void
  onCancel(): void
  progress?: Progress
  removeProgress?: Progress
  editing?: boolean
  headCompanyRelation?: CompanyRelation | null
  branchCompanies: CompanyRelation[]
  config?: CompanyConfig | null
}> = ({
  onCancel,
  onRemove,
  onSubmit,
  onSubmitConfig,
  progress,
  removeProgress,
  editing = false,
  headCompanyRelation,
  branchCompanies,
  initialValues: {
    address = '',
    city = '',
    region_id = '',
    businessModel = BusinessModel.Full,
    companyType = CompanyType.Producer,
    currency = Currency.CAD,
    description = '',
    internalName = '',
    officialName = '',
    accountNumber = '',
    slug = '',
    postal_code = '',
    swift = '',
    iban = '',
    shortDescription = '',
    images = [],
    creditOffer,
    country,
    regions = [],
    regions_ids = [],
    is_active = true,
    rank,
    is_visible = true,
    is_seller = true,
    is_supplier = false,
    sku_orders = false,
    producers_relations = [],
    territories = [],
    email,
    secondary_email,
    phone_number,
    taurus_account_number,
    yara_account_number,
    comment = '',
    legal_address,
    physical_address,
    contacts = [],
  } = {},
  config,
}) => {
  const [tab, setTab] = useState('main')
  const { t } = useTranslation(['company', 'common', 'validation'])
  const { register, dirty, bind, submitAll } = useFormManager<FormManagerProps>()
  const params = useParams<{ id: string }>()
  const [, headCompany] = useCompanyById(headCompanyRelation?.company_id)
  const isFilesUploading = useSelector(UploadManagerSelectors.isFilesUploading)

  const validationSchema = useMemo(() => {
    const addressValidationSchema = Yup.object({
      address: Yup.string().nullable(),
      city: Yup.string().nullable(),
      region_id: Yup.string().nullable(),
      country: Yup.string().nullable(),
      postal_code: Yup.string().nullable(),
    })

    return Yup.object({
      companyType: Yup.mixed().oneOf([CompanyType.Producer, CompanyType.Distributor]),
      businessModel: Yup.mixed().oneOf([BusinessModel.Lite, BusinessModel.Full]),
      rank: Yup.string().nullable(),
      description: Yup.string().nullable(),
      officialName: Yup.string().required(t('validation:field_required')),
      internalName: Yup.string().required(t('validation:field_required')),
      accountNumber: Yup.string().nullable(),
      slug: Yup.string().required(t('validation:field_required')),
      shortDescription: Yup.string().nullable(),
      address: Yup.string().nullable(),
      city: Yup.string().nullable(),
      postal_code: Yup.string().nullable(),
      iban: Yup.string().nullable(),
      swift: Yup.string().nullable(),
      currency: Yup.mixed<Currency>().oneOf(Object.values(Currency)),
      sin: Yup.string().nullable(),
      country: Yup.mixed().oneOf(COUNTRIES.map(c => c.id)),
      currentImage: Yup.string(),
      is_active: Yup.boolean(),
      is_visible: Yup.boolean(),
      is_seller: Yup.boolean(),
      is_supplier: Yup.boolean(),
      sku_orders: Yup.boolean(),
      creditOffer: Yup.boolean(),
      email: Yup.string()
        .email(t('validation:email_invalid'))
        .nullable(),
      secondary_email: Yup.string()
        .email(t('validation:email_invalid'))
        .nullable(),
      region_id: Yup.string().nullable(),
      regions_ids: Yup.array(Yup.string()),
      legal_address: addressValidationSchema,
      physical_address: addressValidationSchema,
    })
  }, [t])

  const branchesFormik = useFormik<{ branches: CompanyRelation[] }>({
    initialValues: {
      branches: branchCompanies,
    },
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })
  register('branches', branchesFormik)

  const getAddressValues = (address?: Address) => {
    if (address) {
      return {
        ...address,
        country: address.country?.length ? address.country : DEFAULT_COUNTRY,
      }
    } else {
      return {
        address: '',
        city: '',
        postal_code: '',
        country: DEFAULT_COUNTRY,
      }
    }
  }

  const formik = useFormik<MainFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      address,
      businessModel,
      companyType,
      currency,
      description: description || '',
      internalName,
      officialName,
      accountNumber,
      slug,
      postal_code,
      swift,
      iban,
      shortDescription: shortDescription || '',
      rank,
      country: country || DEFAULT_COUNTRY,
      city,
      regions,
      regions_ids,
      is_active,
      is_visible,
      is_seller,
      is_supplier,
      sku_orders,
      creditOffer,
      producers_relations,
      territories,
      email,
      secondary_email,
      region_id,
      taurus_account_number: taurus_account_number || '',
      yara_account_number: yara_account_number || '',
      comment,
      contacts,
      legal_address: getAddressValues(legal_address),
      physical_address: getAddressValues(physical_address),
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
  })
  register('main', formik)

  useEffect(() => {
    // React to validationSchema change
    formik.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationSchema])

  useEffect(() => {
    // TODO Migrated to legal_address, but didn't support it yet
    formik.setValues({
      ...formik.values,
      address: formik.values.legal_address.address || '',
      city: formik.values.legal_address.city || '',
      postal_code: formik.values.legal_address.postal_code || '',
      country: formik.values.legal_address.country,
    })
    if (physicalAddressSameAsLegal) {
      formik.setFieldValue('physical_address', formik.values.legal_address)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.legal_address])

  const parsedNumber = useMemo(() => parsePhoneNumberFromString(phone_number || ''), [phone_number])
  const phoneNumberFormik = useFormik<{ code?: string; number?: string }>({
    initialValues: {
      code: `+${(parsedNumber?.countryCallingCode as string) || ''}`,
      number: (parsedNumber?.nationalNumber as string) || '',
    },
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema: Yup.object({
      code: Yup.string(),
      number: Yup.string()
        .nullable()
        .test('is-valid-number', t('validation:invalid_phone_number'), value => {
          if (!value || formik.values.companyType === CompanyType.Producer) {
            return true
          }
          return isValidPhoneNumber(`${phoneNumberFormik.values.code}${value || ''}`)
        }),
    }),
  })
  register('phoneNumberFormik', phoneNumberFormik)

  const companyTypes = useCompanyTypesList()
  const businessModels = useBusinessModelList()
  const configTabs = useCompanyConfigList()

  const submit = async () => {
    try {
      const [valid, forms] = await submitAll()
      if (!valid) {
        return
      }

      let terrIds: string[] = []
      let contacts: SellerRelatedContacts[] = []

      if (forms.main.companyType === CompanyType.Distributor && forms.main.producers_relations.length) {
        const entries = Object.entries(forms)
        terrIds = entries
          .filter((entry): entry is [string, CompanyTerritoryFormProps] => entry[0].startsWith('territory'))
          .reduce((buf, [, list]) => buf.concat(list.territories), [] as string[])
        contacts = entries
          .filter((entry): entry is [string, CompanyContactFormProps] => entry[0].startsWith('contacts'))
          .map(([, list]) => list)
      }

      const { code, number } = phoneNumberFormik.values
      onSubmit(
        {
          ...forms.main,
          phone_number: code && number ? `${code}${number}` : '',
          images: forms.images?.files || [],
          producers_relations:
            formik.values.companyType === CompanyType.Distributor ? formik.values.producers_relations : [],
          territories: terrIds,
          contacts,
        },
        formik.values.companyType === CompanyType.Distributor ? branchesFormik.values.branches : undefined,
      )
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const handleBranchCompanyChange = useCallback(
    (id: string, relation: CompanyRelation) => {
      const index = branchesFormik.values.branches.findIndex(item => item.company_id === id)
      const newBranches = clone(branchesFormik.values.branches)
      if (index === -1) {
        newBranches.push(relation)
      } else {
        newBranches[index] = relation
      }
      branchesFormik.setFieldValue('branches', newBranches)
    },
    [branchesFormik],
  )

  const handleBranchCompanyRemove = useCallback(
    (id: string) => {
      const index = branchesFormik.values.branches.findIndex(item => item.company_id === id)
      const newBranches = clone(branchesFormik.values.branches)
      newBranches.splice(index, 1)
      branchesFormik.setFieldValue('branches', newBranches)
    },
    [branchesFormik],
  )

  const [relatedManufacturers, setRelatedManufacturers] = useState<Company[]>()

  const handleProducersRelationChange = useCallback(
    (ids, values: readonly Company[]) => {
      formik.setFieldValue('producers_relations', ids)
      setRelatedManufacturers([...values])
    },
    [formik],
  )

  const alreadyPickedManufacturers = useMemo(() => {
    if (!relatedManufacturers?.length) {
      return {}
    }
    const alreadyPicked = {}
    formik.values.contacts.forEach(item => {
      if (item.seller_id) alreadyPicked[item.seller_id] = true
    })
    return alreadyPicked
  }, [relatedManufacturers, formik.values.contacts])

  useValidationErrorNotification(formik.submitCount, formik.isValid)

  const [physicalAddressSameAsLegal, setPhysicalAddressSameAsLegal] = useState(
    equals(formik.values.physical_address, formik.values.legal_address),
  )
  const deliveryAddressSameAsMailingChange = useCallback(() => {
    const newPhysicalAddressSameAsLegal = !physicalAddressSameAsLegal
    if (newPhysicalAddressSameAsLegal) {
      formik.setFieldValue('physical_address', formik.values.legal_address)
    }
    setPhysicalAddressSameAsLegal(newPhysicalAddressSameAsLegal)
  }, [physicalAddressSameAsLegal, formik])

  useEffect(() => {
    setTab('main')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.companyType === CompanyType.Distributor])

  return (
    <>
      {tab === 'main' && (
        <StickyFooterLayout.Wrapper>
          <StickyFooterLayout.Body>
            <SectionContainer>
              <SectionBody>
                <FormComponents.FormSection title={t('form.companyType')}>
                  <SwitchButton
                    options={companyTypes}
                    value={formik.values.companyType}
                    onChange={v => formik.setFieldValue('companyType', v)}
                    testId={'company-type-switch'}
                  />
                </FormComponents.FormSection>
                {formik.values.companyType === CompanyType.Producer && (
                  <Styled.ConfigTabsWrapper data-test-id="company-config-tabs">
                    <Tabs options={configTabs} active={tab} onChange={value => setTab(value)} />
                  </Styled.ConfigTabsWrapper>
                )}
                <FormComponents.FormSection title={''}>
                  <Segment
                    items={businessModels}
                    value={formik.values.businessModel}
                    onChange={v => formik.setFieldValue('businessModel', v)}
                    label={t('form.businessModel')}
                  />
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.description')}>
                  <Styled.DescriptionSection>
                    <Styled.DescriptionBlock>
                      <Input
                        {...formik.getFieldProps('internalName')}
                        invalid={formik.touched.internalName && !!formik.errors.internalName}
                        errorText={formik.errors.internalName}
                        label={t('form.internalName')}
                        data-test-id={'internal-name'}
                        required
                      />
                      <Input
                        {...formik.getFieldProps('officialName')}
                        invalid={formik.touched.officialName && !!formik.errors.officialName}
                        errorText={formik.errors.officialName}
                        label={t('form.officialName')}
                        onChange={e => {
                          formik.setFieldValue('officialName', e.target.value)
                          !editing && formik.setFieldValue('slug', helpersSlug.slugify(e.target.value))
                        }}
                        data-test-id={'official-name'}
                        required
                      />
                      <Styled.HorizontalRow>
                        <Input
                          {...formik.getFieldProps('accountNumber')}
                          invalid={formik.touched.accountNumber && !!formik.errors.accountNumber}
                          errorText={formik.errors.accountNumber}
                          label={t('form.accountNumber')}
                          data-test-id={'account-number'}
                        />
                        <Input
                          {...formik.getFieldProps('rank')}
                          invalid={formik.touched.rank && !!formik.errors.rank}
                          errorText={formik.errors.rank}
                          label={t('form.rank')}
                          data-test-id={'rank'}
                        />
                      </Styled.HorizontalRow>
                      <Input
                        {...formik.getFieldProps('taurus_account_number')}
                        invalid={formik.touched.taurus_account_number && !!formik.errors.taurus_account_number}
                        errorText={formik.errors.taurus_account_number}
                        label={t('form.taurus_account_number')}
                        data-test-id={'taurus_account_number'}
                      />
                      <Input
                        {...formik.getFieldProps('yara_account_number')}
                        invalid={formik.touched.yara_account_number && !!formik.errors.yara_account_number}
                        errorText={formik.errors.yara_account_number}
                        label={t('form.yara_account_number')}
                        data-test-id={'yara_account_number'}
                      />
                      {formik.values.companyType === CompanyType.Producer && (
                        <Checkbox
                          isChecked={formik.values.creditOffer}
                          onChange={(_, isChecked) => {
                            formik.setFieldValue('creditOffer', isChecked)
                          }}
                          label={t('form.creditOffer')}
                          testId={'credit-offer'}
                        />
                      )}
                      <Input
                        {...formik.getFieldProps('slug')}
                        invalid={formik.touched.slug && !!formik.errors.slug}
                        errorText={formik.errors.slug}
                        label={t('form.slug')}
                        disabled={editing}
                        data-test-id={'slug'}
                        required
                      />
                      <TextArea
                        {...formik.getFieldProps('description')}
                        invalid={formik.touched.description && !!formik.errors.description}
                        errorText={formik.errors.description}
                        label={t('form.description')}
                        data-test-id={'description'}
                        limit={1000}
                      />
                      <TextArea
                        {...formik.getFieldProps('shortDescription')}
                        invalid={formik.touched.shortDescription && !!formik.errors.shortDescription}
                        errorText={formik.errors.shortDescription}
                        label={t('form.shortDescription')}
                        data-test-id={'short-description'}
                        limit={500}
                      />
                    </Styled.DescriptionBlock>
                    {formik.values.companyType === CompanyType.Producer && (
                      <ImageForm limit={1} useFormik={bind('images')} files={images} />
                    )}
                  </Styled.DescriptionSection>
                  {formik.values.companyType === CompanyType.Distributor && (
                    <Styled.RegionSection>
                      <RegionsMultiSelect
                        testId={'add-region'}
                        label={t('form.region')}
                        values={formik.values.regions_ids}
                        onChange={value => formik.setFieldValue('regions_ids', value)}
                      />
                    </Styled.RegionSection>
                  )}
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.legalAddress')}>
                  <FormikContext.Provider value={formik}>
                    <AddressForm field={'legal_address'} />
                  </FormikContext.Provider>
                </FormComponents.FormSection>
                <Styled.Checkbox
                  onChange={deliveryAddressSameAsMailingChange}
                  isChecked={physicalAddressSameAsLegal}
                  label={t('form.sameAsLegal')}
                />
                <FormComponents.FormSection title={t('form.physicalAddress')}>
                  <FormikContext.Provider value={formik}>
                    <AddressForm field={'physical_address'} disabled={physicalAddressSameAsLegal} />
                  </FormikContext.Provider>
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.contacts')}>
                  <Styled.AddressSection>
                    <Input
                      {...formik.getFieldProps('email')}
                      invalid={formik.touched.email && !!formik.errors.email}
                      label={t('form.email')}
                      errorText={formik.errors.email}
                      data-test-id={'email'}
                    />
                    <Input
                      {...formik.getFieldProps('secondary_email')}
                      invalid={formik.touched.secondary_email && !!formik.errors.secondary_email}
                      label={t('form.secondary_email')}
                      errorText={formik.errors.secondary_email}
                      data-test-id={'secondary-email'}
                    />
                    {formik.values.companyType === CompanyType.Distributor && (
                      <>
                        <PhoneInput
                          label={t('common:phone')}
                          name={'phone'}
                          invalid={phoneNumberFormik.touched.number && !!phoneNumberFormik.errors.number}
                          errorText={phoneNumberFormik.errors.number}
                          phoneNumber={
                            phoneNumberFormik.values.code
                              ? `${phoneNumberFormik.values.code}${phoneNumberFormik.values.number}`
                              : undefined
                          }
                          onChange={(_, code, number) => {
                            phoneNumberFormik.setFieldValue('code', code)
                            phoneNumberFormik.setFieldValue('number', number)
                          }}
                          onBlur={() => {
                            phoneNumberFormik.setFieldTouched('number', true)
                          }}
                          testId={'phone'}
                        />
                        <SimpleSelect
                          options={emptyArr}
                          onChange={emptyFunc}
                          label={t('form.manufacturer')}
                          placeholder={t('common:all')}
                          isDisabled
                        />
                      </>
                    )}
                  </Styled.AddressSection>
                  {formik.values.companyType === CompanyType.Distributor && (
                    <>
                      {formik.values.contacts.map((contacts, index) => (
                        <CompanyContactForm
                          key={index}
                          onRemove={() =>
                            formik.values.contacts &&
                            formik.setFieldValue('contacts', remove(index, 1, formik.values.contacts))
                          }
                          relatedManufacturers={relatedManufacturers}
                          alreadyPicked={alreadyPickedManufacturers}
                          index={index}
                          initialValues={contacts}
                          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                          /* @ts-ignore */
                          useFormik={bind(`contacts:${index}`)}
                        />
                      ))}
                      {formik.values.producers_relations.length > 1 &&
                        formik.values.contacts.length < formik.values.producers_relations.length && (
                          <StyledButton
                            intent="primary"
                            variant={'text'}
                            onClick={() => formik.setFieldValue('contacts', [...(formik.values.contacts || []), {}])}
                          >
                            Add company related contacts
                          </StyledButton>
                        )}
                    </>
                  )}
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.requisites')}>
                  <Styled.RequisitesBlock>
                    <Styled.GridArea area={'iban'}>
                      <Input
                        {...formik.getFieldProps('iban')}
                        invalid={formik.touched.iban && !!formik.errors.iban}
                        errorText={formik.errors.iban}
                        label={t('form.iban')}
                        data-test-id={'iban'}
                      />
                    </Styled.GridArea>
                    <Styled.GridArea area={'swift'}>
                      <Input
                        {...formik.getFieldProps('swift')}
                        invalid={formik.touched.swift && !!formik.errors.iban}
                        errorText={formik.errors.iban}
                        label={t('form.swift')}
                        data-test-id={'swift'}
                      />
                    </Styled.GridArea>
                    <Styled.GridArea area={'currency'}>
                      <CurrencySelect
                        value={formik.values.currency}
                        onChange={v => formik.setFieldValue('currency', v)}
                        invalid={formik.touched.currency && !!formik.errors.currency}
                        errorText={formik.errors.currency}
                        label={t('form.currency')}
                      />
                    </Styled.GridArea>
                  </Styled.RequisitesBlock>
                </FormComponents.FormSection>
                {formik.values.companyType === CompanyType.Distributor && !headCompanyRelation && (
                  <FormComponents.FormSection title={t('form.branches')}>
                    <Branches
                      branches={branchesFormik.values.branches}
                      onChange={handleBranchCompanyChange}
                      onRemove={handleBranchCompanyRemove}
                      headCompanyId={params.id}
                    />
                  </FormComponents.FormSection>
                )}
                {formik.values.companyType === CompanyType.Distributor && (
                  <FormComponents.FormSection title={t('form.producersRelation')}>
                    <AddWrapper>
                      <CompanyMultiSelect
                        isSearchable
                        isClearable
                        companyType={CompanyType.Producer}
                        values={formik.values.producers_relations}
                        onChange={handleProducersRelationChange}
                        onLoadDefaults={items => setRelatedManufacturers(items)}
                      />
                    </AddWrapper>
                  </FormComponents.FormSection>
                )}

                {formik.values.companyType === CompanyType.Distributor && formik.values.producers_relations.length ? (
                  <FormComponents.FormSection title={t('form.territory')}>
                    {formik.values.producers_relations.map((id, i) => {
                      return (
                        <CompanyTerritoryForm
                          key={id}
                          companyId={id}
                          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                          /* @ts-ignore */
                          useFormik={bind(`territory:${i}`)}
                          territories={formik.values.territories}
                        />
                      )
                    })}
                  </FormComponents.FormSection>
                ) : null}
                {!!headCompanyRelation && !!headCompany && (
                  <FormComponents.FormSection title={t('form.headCompany')}>
                    <PreviewContainer.Container
                      footer={`ID ${headCompany.id || ''}`}
                      to={headCompany.id ? generatePath(CompanyRoutes.Edit, { id: headCompany.id }) : undefined}
                    >
                      <PreviewContainer.Header>{headCompany.internal_name}</PreviewContainer.Header>
                      <PreviewContainer.Text style={{ marginTop: '6px' }}>
                        {headCompany.description || headCompany.short_description}
                      </PreviewContainer.Text>
                    </PreviewContainer.Container>
                  </FormComponents.FormSection>
                )}
                <FormComponents.FormSection title={t('form.comment')}>
                  <InputWrapper>
                    <TextArea {...formik.getFieldProps('comment')} limit={1000} />
                  </InputWrapper>
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.controls')}>
                  <Styled.ControlsSection>
                    <Switch
                      on={formik.getFieldProps('is_active').value}
                      onClick={value => {
                        formik.setFieldValue('is_active', value)
                      }}
                      label={t('form.isActive')}
                      testId={'is-active'}
                    />
                    <Switch
                      on={formik.getFieldProps('is_supplier').value}
                      onClick={value => {
                        formik.setFieldValue('is_supplier', value)
                      }}
                      label={t('form.isSupplier')}
                    />
                    <Switch
                      on={formik.getFieldProps('sku_orders').value}
                      onClick={value => {
                        formik.setFieldValue('sku_orders', value)
                      }}
                      label={t('form.skuOrders')}
                    />
                    {formik.values.companyType === CompanyType.Distributor && (
                      <Switch
                        on={formik.getFieldProps('is_visible').value}
                        onClick={value => {
                          formik.setFieldValue('is_visible', value)
                        }}
                        label={t('form.isVisible')}
                        testId={'is-visible'}
                      />
                    )}
                    {formik.values.companyType === CompanyType.Producer && (
                      <Switch
                        on={formik.getFieldProps('is_seller').value}
                        onClick={value => {
                          formik.setFieldValue('is_seller', value)
                        }}
                        label={t('form.isSeller')}
                      />
                    )}
                  </Styled.ControlsSection>
                </FormComponents.FormSection>
              </SectionBody>
            </SectionContainer>
          </StickyFooterLayout.Body>
          <StickyFooterDefaultControls
            onRemove={onRemove}
            onSave={submit}
            onCancel={onCancel}
            saveProgress={progress}
            isSaveDisabled={!dirty || isFilesUploading || progress === Progress.WORK}
            removeProgress={removeProgress}
            popoverText={t('form.removeText', { title: internalName })}
          />
        </StickyFooterLayout.Wrapper>
      )}
      {tab === 'config' && (
        <CompanyConfigForm
          onSubmit={onSubmitConfig}
          onCancel={onCancel}
          companyType={formik.values.companyType}
          config={config}
          progress={progress}
          companyTypes={companyTypes}
          configTabs={configTabs}
          tab={tab}
          setTab={setTab}
          setCompanyType={v => formik.setFieldValue('companyType', v)}
        />
      )}
    </>
  )
}

export default CompanyDetailsForm
