import { SearchInput, useAction, useBoolean, useHelmet } from '@agro-club/frontend-shared'
import StoreFrontActions from 'modules/domain/storefront/duck'
import StorefrontSelectors from 'modules/domain/storefront/selectors'
import { StorefrontListRequestFilter, StorefrontModalMode } from 'modules/domain/storefront/types'
import React, { useCallback, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { ProductOptionsMultiSelect } from 'views/components/ProductOptionsSelect/ProductOptionsMultiSelect'
import StatusFilter from 'views/components/TableFilters/StatusFilter'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import StorefrontItemModal, {
  StorefrontItemModalData,
} from 'views/pages/Storefront/Storefront/StorefrontItemModal/StorefrontItemModal'
import StorefrontSidebar from 'views/pages/Storefront/Storefront/StorefrontSidebar/StorefrontSideBar'
import { StorefrontHeader } from '../../components/StorefrontHeader'
import StorefrontList from './StorefrontList'
import * as Styled from './styled'

const StorefrontRoot: React.FC = () => {
  const { t } = useTranslation(['storefront', 'cards', 'packageTypes', 'SellerSelect', 'labels'])
  const filter = useSelector(StorefrontSelectors.filter)
  const filterUpdated = useAction(StoreFrontActions.filterUpdated)
  const { search, status, seller_id } = filter
  const [isSkuModalOpen, openSkuModal, closeSkuModal] = useBoolean(false)
  const [skuModalData, setSkuModalData] = useState<StorefrontItemModalData | undefined>(undefined)
  useHelmet({ title: t('storefront:metaTitle') })
  const [noSellerAnimation, setNoSellerAnimation] = useState<boolean>(false)

  const handleFilterChange = (newFilterValue: Partial<StorefrontListRequestFilter>) => {
    filterUpdated({ ...filter, ...newFilterValue })
  }

  const handleSkuAdd = useCallback(
    (productId: string) => {
      if (!seller_id) {
        setNoSellerAnimation(true)
        return
      }
      setSkuModalData({ mode: StorefrontModalMode.Create, productId })
      openSkuModal()
    },
    [openSkuModal, seller_id],
  )

  const handleSkuEdit = useCallback(
    (id: string) => {
      setSkuModalData({ mode: StorefrontModalMode.Edit, id })
      openSkuModal()
    },
    [openSkuModal],
  )

  const handleSkuModalClose = useCallback(() => {
    setSkuModalData(undefined)
    closeSkuModal()
  }, [closeSkuModal])

  useEffect(() => {
    const timerId = setTimeout(() => {
      setNoSellerAnimation(false)
    }, 1500)

    return () => {
      clearTimeout(timerId)
    }
  }, [noSellerAnimation])

  const handleProductFilter = productId => {
    if (!seller_id) {
      setNoSellerAnimation(true)
      return
    }
    handleFilterChange({ product_id: productId })
  }

  return (
    <>
      <Layout.Header>
        <StorefrontHeader tab={'storefront'} />
        <Styled.SearchInputWrapper>
          <SearchInput
            onChange={value => handleFilterChange({ ...filter, ...{ search: value } })}
            value={search}
            placeholder={t('list.searchPlaceholder')}
          />
        </Styled.SearchInputWrapper>
      </Layout.Header>
      <Layout.Content>
        <Styled.Wrapper>
          <Styled.StorefrontFilters>
            <StatusFilter filterValue={{ status }} handleFilterChange={handleFilterChange} />
            <Styled.AnimatedFilter animated={noSellerAnimation}>
              <CompanySelect
                filter={{ is_seller: true }}
                onChange={val => handleFilterChange({ seller_id: val, product_ids: [] })}
                value={seller_id}
                data-test-id="storefront-seller-select"
                isClearable
                isSearchable
                variant="small"
                width="200px"
                label={t('SellerSelect:label')}
                placeholder={t('SellerSelect:placeholder')}
              />
            </Styled.AnimatedFilter>
            <ProductOptionsMultiSelect
              onChange={val => handleFilterChange({ product_options_id: val })}
              values={filter.product_options_id}
              data-test-id="storefront-product-options-select"
              isClearable
              isSearchable
              showLabel
              variant="small"
              width="270px"
              disableTags
              isDisabled={!seller_id}
            />
          </Styled.StorefrontFilters>
          <Styled.StorefrontContentWrapper>
            <DndProvider backend={HTML5Backend}>
              <StorefrontSidebar onSkuAdd={handleSkuAdd} onProductFilter={handleProductFilter} />
              <StorefrontList onSkuAdd={handleSkuAdd} onSkuEdit={handleSkuEdit} />
              <StorefrontItemModal
                heading={
                  skuModalData?.mode === StorefrontModalMode.Create
                    ? t('storefront:modal.createItemHeading')
                    : t('storefront:modal.editItemHeading')
                }
                isOpen={isSkuModalOpen}
                modalData={skuModalData}
                onClose={handleSkuModalClose}
              />
            </DndProvider>
          </Styled.StorefrontContentWrapper>
        </Styled.Wrapper>
      </Layout.Content>
    </>
  )
}

export default StorefrontRoot
