import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'

import { Filter } from 'views/components/TableFilters/TableFilters'
import { useSignedReportLink } from 'modules/domain/report/hooks'
import { Progress, ResourceHook } from 'modules/types'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { getMyReports } from 'modules/domain/report/managers'
import { Report, ReportListRequestFilter } from 'modules/domain/report/types'
import { Button, IconCross, IconRefresh, Tabs, useHelmet, useWindowResize } from '@agro-club/frontend-shared'
import { useConfig } from 'modules/domain/config/hooks'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { CompanyType, CustomFeatureName, isAgro, isDistributor, Sections } from 'types/entities'
import useLangPicker from 'hooks/useLangPicker'
import { useLocalStorage } from 'modules/utils/helpers'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { useHotkeys } from 'hooks/useHotkeys'
import { refreshSchedule, RefreshSchedule, checkSchedule } from './helpers'
import useWLFeatureFlags from 'hooks/featureFlags/useWLFeatureFlags'
import { generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'

const SubtitleWrapper = styled.div`
  margin-top: 16px;
  white-space: pre-wrap;
  & > a {
    color: ${({ theme }) => theme.color.primary600};
    text-decoration: none;
  }
`

const SpinnerCentered = styled(SpinnerLayout)`
  margin: 40px auto;
`

const ErrorMessage = styled.div`
  font-size: 24px;
  margin: 40px auto;
  width: 700px;
  white-space: pre-wrap;
  text-align: center;
`

const TempMessage = styled.div`
  font-size: 20px;
  margin: 40px auto;
  width: 500px;
  white-space: pre-wrap;
  text-align: center;
  color: ${props => props.theme.color.secondary300};
`

const FiltersWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
`

const IframeWrapper = styled.div<{ fullScreen?: boolean }>`
  height: 100%;
  ${({ fullScreen }) =>
    fullScreen &&
    css`
      background-color: white;
      position: fixed;
      z-index: 1000000;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
    `}
`

const Iframe = styled.iframe`
  padding: 24px;
`

const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  overflow: hidden;
  max-width: 100%;
`

const ControlWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding-right: 24px;
`

const FullScreenControl = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.outlineMid};

  & > div {
    padding: 0;
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`

const StyledCross = styled(IconCross)`
  padding: 4px;
`

const StyledRefresh = styled(IconRefresh)`
  padding: 1px;
`

const useAvailableReports: ResourceHook<Report[], [ReportListRequestFilter]> = (filter: ReportListRequestFilter) => {
  const [reports, setReports] = useState<Report[]>([])
  const [progress, setProgress] = useState(Progress.IDLE)

  useEffect(() => {
    const fetch = async () => {
      try {
        setProgress(Progress.WORK)
        const response = await getMyReports(filter)
        setReports(response)
        setProgress(Progress.SUCCESS)
      } catch (e) {
        setProgress(Progress.ERROR)
      }
    }

    fetch()
  }, [filter])

  return [progress, reports]
}

const Dashboard: React.FC = () => {
  const [fullScreenMode, setFullScreenMode] = useState(false)
  const { t } = useTranslation(['dashboards', 'errors', 'support'])
  const { pick } = useLangPicker()
  const [, config] = useConfig()
  useHelmet({ title: t('dashboardsMetaTitle') })
  useWindowResize()
  const [storedValue, setValue] = useLocalStorage('producer_id', '')
  const profile = useSelector(AuthSelectors.profile)
  const role = useSelector(AuthSelectors.role)
  const [listProgress, reports = []] = useAvailableReports(storedValue)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const reportsTabs: { title: string; value: string }[] = useMemo(() => {
    return reports.length
      ? reports
          .sort((a, b) => a.order - b.order)
          .map(r => ({
            title: pick(r.title_i18n),
            value: r.id,
            type: r.is_warning && 'warning',
          }))
      : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pick, profile, reports])

  const contentWrapperRef = useRef<HTMLDivElement>(null)
  const [reportId, setReportId] = useState(reportsTabs.length && reportsTabs[0].value)
  const [refresh, setRefresh] = useState(0)
  const [refreshAllowed, setRefreshAllowed] = useState(false)
  const iframe = useRef<HTMLIFrameElement>(null)
  const selectedReport = useMemo(() => reports.find(r => r.id === reportId), [reports, reportId])
  const [progress, reportUrl] = useSignedReportLink(selectedReport, refresh)

  const checkWLFeatureFlag = useWLFeatureFlags()
  const autoRefreshBySchedule = checkWLFeatureFlag(
    generateCustomFeatureFlag(Sections.Reports, CustomFeatureName.AutoRefreshBySchedule),
  )

  const email = config?.contacts?.email?.value
  const phone = config?.contacts?.phone?.value

  const subtitleText = t('subtitle', {
    emailLink: `<a href="mailto:${email}" target=_blank>${email}</a>`,
    phoneLink: `<a href="tel:${phone}" target=_blank>${phone}</a>`,
  })

  const getProducerRelationsCount = () => {
    return profile?.company?.producers_relations.length || 0
  }
  const showSellerFilter = isAgro(role) || (isDistributor(role) && getProducerRelationsCount() > 1)

  const handleFilterChange = (newFilterValue: Partial<ReportListRequestFilter>) => {
    setValue(newFilterValue)
  }

  const isRefreshAllowed = useCallback(() => {
    // todo get schedule from report after ent-4195
    const schedule: RefreshSchedule = refreshSchedule
    return autoRefreshBySchedule ? checkSchedule(schedule) : true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  useEffect(() => {
    reportsTabs.length && setReportId(reportsTabs[0].value)
  }, [reportsTabs])

  useHotkeys(
    'Escape',
    () => {
      setFullScreenMode(false)
    },
    false,
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (isRefreshAllowed()) {
        setRefreshAllowed(true)
      }
    }, 60 * 1000)

    if (refreshAllowed) clearInterval(interval)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (fullScreenMode && selectedReport?.auto_refresh_time && isRefreshAllowed()) {
      const timeout = setTimeout(() => {
        setRefresh(prev => ++prev)
      }, selectedReport.auto_refresh_time * 1000)

      return () => clearTimeout(timeout)
    }

    if (fullScreenMode && !refreshAllowed) {
      setRefreshAllowed(false)
    }
  }, [fullScreenMode, isRefreshAllowed, refresh, refreshAllowed, selectedReport?.auto_refresh_time])

  const tabs = <Tabs options={reportsTabs} active={reportId || ''} onChange={val => setReportId(val)} />

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('title')} />
          <SubtitleWrapper dangerouslySetInnerHTML={{ __html: subtitleText }} />
          {showSellerFilter && (
            <FiltersWrapper data-test-id={'dashboard'}>
              <Filter title={t('labels:producer')}>
                <CompanySelect
                  width="220px"
                  filter={{ is_seller: true }}
                  companyType={CompanyType.Producer}
                  onChange={val => {
                    handleFilterChange({ producer_id: val })
                  }}
                  value={storedValue.producer_id}
                  isClearable
                />
              </Filter>
            </FiltersWrapper>
          )}
        </Header.Root>
        <ControlWrapper>
          <TabsWrapper>{tabs}</TabsWrapper>
          {!(listProgress === Progress.SUCCESS && !reports.length) && !!reportsTabs.length && (
            <Button intent="primary" variant="text" onClick={() => setFullScreenMode(true)}>
              Fullscreen
            </Button>
          )}
        </ControlWrapper>
      </Layout.Header>
      <Layout.Content ref={contentWrapperRef}>
        <IframeWrapper fullScreen={fullScreenMode}>
          {fullScreenMode && (
            <FullScreenControl>
              {tabs}
              <Buttons>
                <Button
                  intent="primary"
                  variant="text"
                  onClick={() => {
                    setRefresh(prev => ++prev)
                  }}
                >
                  <StyledRefresh />
                </Button>
                <Button intent="primary" variant="text" onClick={() => setFullScreenMode(false)}>
                  <StyledCross />
                </Button>
              </Buttons>
            </FullScreenControl>
          )}
          {listProgress === Progress.SUCCESS && !reports.length ? (
            <TempMessage>{t('noReportsFound')}</TempMessage>
          ) : progress === Progress.SUCCESS && reportUrl ? (
            <Iframe
              ref={iframe}
              title={'Mode report'}
              src={reportUrl}
              width="100%"
              height={
                fullScreenMode
                  ? '100%'
                  : contentWrapperRef
                  ? (contentWrapperRef.current?.clientHeight || 0) - 52
                  : '1px'
              } // TODO Handle resizing
              frameBorder="0"
            />
          ) : progress === Progress.SUCCESS && selectedReport?.message ? (
            <TempMessage>{selectedReport.message}</TempMessage>
          ) : progress === Progress.ERROR ? (
            <ErrorMessage>{t('errors:unknown_error')}</ErrorMessage>
          ) : (
            <SpinnerCentered size={'big'} />
          )}
        </IframeWrapper>
      </Layout.Content>
    </>
  )
}

export default Dashboard
