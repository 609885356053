import * as managers from 'modules/domain/company/managers'
import { Company } from 'modules/domain/company/types'
import React from 'react'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'

export type CompanySelectProps = Omit<EntitySelectProps<Company>, 'onChange'> & {
  onChange?: (newValue: string | undefined) => void
}

export const getCompanyLabel = (company: Company) => company.official_name
export const getCompanyValue = (company: Company) => company.id

export const SellerFilterSelect: React.FC<CompanySelectProps> = ({ onChange, ...props }) => {
  const handleChange = (newValue: string | undefined) => onChange?.(newValue)

  return (
    <SelectTestWrapper data-test-id="seller-filter-select">
      <EntitySelect
        getEntityList={managers.getSellersListForDistributorOrderSku}
        getEntityById={managers.getCompany}
        getOptionLabel={getCompanyLabel}
        getOptionValue={getCompanyValue}
        onChange={handleChange}
        {...props}
      />
    </SelectTestWrapper>
  )
}
