import { useAction, usePersistentScroll } from '@agro-club/frontend-shared'
import useLangPicker from 'hooks/useLangPicker'
import AuthSelectors from 'modules/domain/auth/selectors'
import TerritoryTargetSkuActions from 'modules/domain/target2sku/territoryTarget/duck'
import { useTerritoryTargetSkuList } from 'modules/domain/target2sku/territoryTarget/hooks'
import TerritoryTargetSkuSelectors from 'modules/domain/target2sku/territoryTarget/selectors'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isProducer } from 'types/entities'
import { CompoundedTableEditable } from 'views/components/CompoundedTable/CompoundedTableEditable/CompoundedTableEditable'
import { TerritoryTargetSkuForm } from '../forms/TerritoryTargetSkuForm'
import { BolderCell, TertiaryCell, TitleCell, TitleI18nCell } from './common'
import { TextTertiary } from '../styles'
import useCompareStrings from 'hooks/useCompareStrings'

export const TerritoryTargetSkuTable: React.VFC = () => {
  const { t } = useTranslation('target2')
  const { pick } = useLangPicker()

  const [progress, data = []] = useTerritoryTargetSkuList()

  const total = useSelector(TerritoryTargetSkuSelectors.total)
  const page = useSelector(TerritoryTargetSkuSelectors.page)
  const pages = useSelector(TerritoryTargetSkuSelectors.pages)
  const pageSize = useSelector(TerritoryTargetSkuSelectors.pageSize)
  const targetsTotal = useSelector(TerritoryTargetSkuSelectors.targetsTotal)
  const retailerTargetsTotal = useSelector(TerritoryTargetSkuSelectors.retailerTargetsTotal)
  const farmerOrdersTotal = useSelector(TerritoryTargetSkuSelectors.farmerOrdersTotal)
  const previousYear = useSelector(TerritoryTargetSkuSelectors.previousYear)

  const listRequested = useAction(TerritoryTargetSkuActions.listRequested)
  const { scrollRef } = usePersistentScroll('territoryTargetSkuList')

  const role = useSelector(AuthSelectors.role)
  const producer = isProducer(role)
  const compareStrings = useCompareStrings()

  const columns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.manufacturer'),
        accessor: 'manufacturer' as const,
        width: 193,
        hidden: producer,
        Cell: TitleCell,
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.territory'),
        accessor: 'territory',
        width: 193,
        Cell: TitleCell,
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.season'),
        accessor: 'season',
        width: 193,
        Cell: TitleCell,
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.subcategory'),
        accessor: `subcategory` as const,
        width: 160,
        Cell: TitleI18nCell(pick),
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.product'),
        accessor: 'product' as const,
        width: 217,
        Cell: TitleI18nCell(pick),
        sortType: compareStrings,
      },
      {
        Header: t('list.tableHeaders.territoryTarget'),
        accessor: 'target_value' as const,
        width: 80,
        headerAlign: 'right',
        cellAlign: 'right',
      },
      {
        Header: t('list.tableHeaders.retailerTarget'),
        accessor: 'retailer_target' as const,
        width: 80,
        headerAlign: 'right',
        cellAlign: 'right',
        Cell: BolderCell,
      },
      {
        Header: t('list.tableHeaders.farmerOrders'),
        accessor: 'farmer_orders_sku' as const,
        width: 80,
        headerAlign: 'right',
        cellAlign: 'right',
      },
      {
        Header: <TextTertiary>{t('list.tableHeaders.farmerPreviousYear')}</TextTertiary>,
        accessor: 'farmer_orders_sku_previous_year' as const,
        width: 76,
        Cell: TertiaryCell,
        headerAlign: 'right',
        cellAlign: 'right',
      },
    ],
    [t, producer, pick, compareStrings],
  )

  const metaColumns = useMemo(
    () => ({
      farmer_orders_sku: farmerOrdersTotal,
      target_value: targetsTotal,
      retailer_target: retailerTargetsTotal,
      farmer_orders_sku_previous_year: <TextTertiary>{previousYear}</TextTertiary>,
    }),
    [targetsTotal, retailerTargetsTotal, farmerOrdersTotal, previousYear],
  )

  const canEdit = usePermissions({ capability: CAPABILITY.TERRITORY_TARGETS, permission: PERMISSION.CRU })

  return (
    <CompoundedTableEditable
      FormComponent={TerritoryTargetSkuForm}
      columns={columns}
      metaColumns={metaColumns}
      data={data}
      ref={scrollRef}
      progress={progress}
      total={total}
      currentPage={page}
      pageSize={pageSize}
      onSetPage={num => {
        listRequested({ page: num })
      }}
      pages={pages}
      readonly={!canEdit}
      newTitle={t('form.new')}
      isSortable
      defaultSortField="territory"
    />
  )
}

export default TerritoryTargetSkuTable
