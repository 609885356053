import React, { useCallback, useEffect, useRef, useState } from 'react'
import CountryFilter from 'views/components/TableFilters/CountryFilter'
import { Filter as FilterComponent } from 'views/components/TableFilters/TableFilters'
import { useTranslation } from 'react-i18next'
import { CellProps, HeaderProps, useRowSelect, useTable } from 'react-table'
import FarmerOrderActions from 'modules/domain/farmerOrder/duck'
import FarmerOrderSelectors from 'modules/domain/farmerOrder/selectors'
import { useFarmerOrderList } from 'modules/domain/farmerOrder/hooks'
import { useSelector } from 'react-redux'
import { FarmerOrderListRequestFilter } from 'modules/domain/farmerOrder/types'
import { generatePath, useHistory } from 'react-router-dom'
import Routes from '../routes'
import { TWO_ROWS_TABLE_HEAD_HEIGHT } from 'modules/constants'
import { FarmerOrder, FarmerOrderItem } from 'types/farmerOrder'
import FarmerOrderStatusColored from 'views/components/FarmerOrderStatusColored/FarmerOrderStatusColored'
import {
  RangeDatePicker,
  controlStyle,
  menuListStyle,
  menuStyle,
  optionStyle,
  AdvancedHeadCell,
  Table,
  TableHead,
  TableHeadRow,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableNoData,
  useAction,
  usePageVisibilityChange,
  usePersistentScroll,
  CustomSelectStyles,
  Button,
} from '@agro-club/frontend-shared'
import { parseISO, isValid } from 'date-fns'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Progress } from 'modules/types'
import useLangPicker from 'hooks/useLangPicker'
import {
  Columns,
  CustomFeatureName,
  FeatureFlagModifiers,
  isAgro,
  isDistributor,
  isUsersCompanyBasicDistributor,
  isUsersCompanyHeadDistributor,
  isUsersCompanyProducer,
  Sections,
  Tables,
} from 'types/entities'
import AuthSelectors from 'modules/domain/auth/selectors'
import { endpoints } from 'modules/endpoints'
import useDownload from 'hooks/useDownload'
import styled, { StyledProps } from 'styled-components'
import DistributorsMultiSelect from 'views/components/DistributorsMultiSelect/DistributorsMultiSelect'
import { HouseholdSelect } from 'views/components/HouseholdSelect/HouseholdSelect'
import FarmerOrderEnterQtyModal from 'views/components/FarmerOrderEnterQtyModal/FarmerOrderEnterQtyModal'
import { useOrderUpdatePermissions } from 'modules/permissions/permissions'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import SeasonSelectors from 'modules/domain/season/selectors'
import CategorySelect from 'views/components/CategorySelect/CategorySelect'
import SubcategorySelect from 'views/components/CategorySelect/SubcategorySelect'
import FarmerOrderStatusMultiSelect from 'views/components/FarmerOrderStatusMultiSelect/FarmerOrderStatusMultiSelect'
import { AlignWrapper } from 'views/components/AlignWrapper/AlignWrapper'
import BusinessModelFilter from 'views/components/TableFilters/BusinessModelFilter'
import useDateFormatFn from 'hooks/useDateFormatFn'
import useDateFormat from 'hooks/useDateFormat'
import { generateCrmTableColumnAccessString, generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { CompanyType } from 'types/entities'
import { ProductsSelect } from 'views/components/ProductsSelect/ProductsSelect'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'

const LIST_UPDATE_INTERVAL = 60000

const SeedTreatment = styled.div`
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SeasonWrapper = styled.div`
  width: 100px;
`

const Wrapper = styled(TComponents.Wrapper)`
  overflow: auto;
  grid-template-rows: 'max-content max-content';
`

export const BodyCell = styled(TableBodyCell)<StyledProps<{ emphasis?: boolean }>>`
  background-color: ${props => (props.emphasis ? props.theme.color.primary25 : props.theme.color.onPrimaryLight)};
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 650px;
  max-width: 970px;
`

const Filter = styled(FilterComponent)`
  margin: 0 16px 16px 0 !important;
  width: 30%;
  max-width: 220px;
  flex-grow: 1;
  justify-content: space-between;
`

const TableWrapper = styled.div`
  max-height: calc(100vh - 388px);
  display: flex;
  overflow: auto;

  @media screen and (max-width: 1226px) {
    max-height: 'calc(100vh - 392px';
  }

  @media screen and (min-width: 1280px) and (max-width: 1392px) {
    max-height: 'calc(100vh - 392px';
  }

  @media screen and (min-width: 1393px) and (max-width: 1452px) {
    max-height: 578px;
  }
`

const StatusColumnHeaderCell: React.FC<HeaderProps<FarmerOrder>> = () =>
  // {
  // getToggleAllRowsSelectedProps,
  // toggleAllRowsSelected,
  // column,
  // },
  {
    // TODO Uncomment when API will support bunch update
    // const props = getToggleAllRowsSelectedProps()
    const { t } = useTranslation('farmerOrder')
    return (
      <TComponents.CheckboxWrapper>
        {/* <Checkbox
        key={column.id}
        isChecked={props.checked || props.indeterminate}
        isPartlyChecked={props.indeterminate}
        onChange={() => toggleAllRowsSelected()}
      /> */}
        <span>{t('list.tableHeaders.status')}</span>
      </TComponents.CheckboxWrapper>
    )
  }

const StatusCell: React.FC<CellProps<FarmerOrder>> = ({ row }) => {
  const { t } = useTranslation('farmerOrder')
  // TODO Uncomment when API will support bunch update
  // const onChange = useCallback(
  //   (value, isChecked, e) => {
  //     e.preventDefault()
  //     e.stopPropagation()
  //     row.toggleRowSelected()
  //   },
  //   [row],
  // )
  return (
    <TComponents.CheckboxWrapper>
      {/* <Checkbox key={column.id} isChecked={row.isSelected} onChange={onChange} /> */}
      <FarmerOrderStatusColored
        status={row.original.status}
        dangerouslySetInnerHTML={{ __html: t(`status.${row.original.status}`) }}
      />
    </TComponents.CheckboxWrapper>
  )
}

const ProducerCell: React.FC<CellProps<FarmerOrder>> = ({ cell, column }) => {
  return (
    <TComponents.BoldText key={column.id}>
      {((cell.value && cell.value.internal_name) || '').toUpperCase()}
    </TComponents.BoldText>
  )
}

const SeasonCell: React.FC<CellProps<FarmerOrder>> = ({ column, row }) => {
  return (
    <SeasonWrapper>
      <TComponents.RegularText key={column.id}>{row.original.season?.title ?? ''}</TComponents.RegularText>
    </SeasonWrapper>
  )
}

const IdDateCell: React.FC<CellProps<FarmerOrder> & { isTimeHidden: boolean }> = ({
  cell,
  row,
  column,
  isTimeHidden,
}) => {
  const getFormatedDate = useDateFormatFn({ withTime: !isTimeHidden })

  return (
    <div key={column.id} style={{ whiteSpace: 'nowrap' }}>
      <div>{cell.value}</div>
      <div>{getFormatedDate(row.original.order_date)}</div>
    </div>
  )
}

const DistributorCell: React.FC<CellProps<FarmerOrder>> = ({ column, row }) => {
  return <span key={column.id}>{row.original.distributor?.internal_name ?? ''}</span>
}

const CustomerCell: React.FC<CellProps<FarmerOrder>> = ({ row }) => {
  if (!row.original.customer) {
    return <span>{''}</span>
  }
  const name = `${row.original.customer.first_name || ''} ${row.original.customer.last_name || ''}`
  return (
    <div style={{ whiteSpace: 'nowrap', maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <div>{name.trim()}</div>
      <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{row.original.customer.farm_name}</div>
      <div>{row.original.customer.phone_number}</div>
    </div>
  )
}

const Crop: React.FC<CellProps<FarmerOrder>> = ({ row }) => {
  const items = row.original.items as FarmerOrderItem[]
  const { pick } = useLangPicker()

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      {items.map(
        (item, i) =>
          item.product &&
          item.product?.subcategory && (
            <div key={`${item.product_id}-${i}`}>{pick(item.product.subcategory.title_i18n)}</div>
          ),
      )}
    </div>
  )
}

const OrderQty: React.FC<CellProps<FarmerOrder>> = ({ row }) => {
  const items = row.original.items as FarmerOrderItem[]

  return (
    <div>
      {items.map((item, i) => (
        <div key={i}>{item.quantity}</div>
      ))}
    </div>
  )
}

const QtyCellWrapper = styled.div<StyledProps<{ disabled?: boolean }>>`
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ theme, disabled }) => !disabled && { boxShadow: `0 0 10px ${theme.color.primary100}` }}
  background-color: ${({ theme }) => theme.color.onPrimaryLight};
  &:hover {
    background-color: ${({ theme, disabled }) => (!disabled ? theme.color.primary25 : theme.color.onPrimaryLight)};
  }
`

const FinalQty: React.FC<CellProps<FarmerOrder> & { isDistributor: boolean }> = ({ row, isDistributor }) => {
  const { t } = useTranslation('farmerOrder')
  const items = row.original.items as FarmerOrderItem[]
  const [isModalOpen, setIsModalOpen] = useState(false)
  const updatePermissions = useOrderUpdatePermissions()
  const isOrderConfirmedByDistributor = row.original?.interaction?.confirmed_by_distributor === 'confirmed' || false

  // distributors should confirm the order if they want to change its 'final qty'
  if (isDistributor && !isOrderConfirmedByDistributor) {
    return (
      <>
        {items.map((item, i) => (
          <div key={i} style={{ textAlign: 'center' }}>
            {item.final_qty ? item.final_qty : item.final_qty === 0 ? '0' : '-'}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <QtyCellWrapper
        disabled={!updatePermissions.items}
        onClick={e => {
          // TODO opens popup even when disabled === true
          e.stopPropagation()
          updatePermissions.items && setIsModalOpen(true)
        }}
      >
        {items.map((item, i) => (
          <div key={i}>{item.final_qty ? item.final_qty : item.final_qty === 0 ? '0' : '-'}</div>
        ))}
      </QtyCellWrapper>
      {isModalOpen && (
        <FarmerOrderEnterQtyModal
          orderId={row.original.id}
          qtyColumn={'final_qty'}
          heading={t('list.tableHeaders.finalQty')}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
}

const DeliveredQty: React.FC<CellProps<FarmerOrder> & { isDistributor: boolean }> = ({ row, isDistributor }) => {
  const { t } = useTranslation('farmerOrder')
  const items = row.original.items as FarmerOrderItem[]
  const [isModalOpen, setIsModalOpen] = useState(false)
  const updatePermissions = useOrderUpdatePermissions()
  const isOrderConfirmedByDistributor = row.original?.interaction?.confirmed_by_distributor === 'confirmed' || false

  // distributors should confirm the order if they want to change its 'delivered qty'
  if (isDistributor && !isOrderConfirmedByDistributor) {
    return (
      <>
        {items.map((item, i) => (
          <div key={i} style={{ textAlign: 'center' }}>
            {item.delivered_qty ? item.delivered_qty : item.delivered_qty === 0 ? '0' : '-'}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <QtyCellWrapper
        disabled={!updatePermissions.items}
        onClick={e => {
          // TODO opens popup even when disabled === true
          e.stopPropagation()
          updatePermissions.items && setIsModalOpen(true)
        }}
      >
        {items.map((item, i) => (
          <div key={i}>{item.delivered_qty ? item.delivered_qty : item.delivered_qty === 0 ? '0' : '-'}</div>
        ))}
      </QtyCellWrapper>
      {isModalOpen && (
        <FarmerOrderEnterQtyModal
          orderId={row.original.id}
          qtyColumn={'delivered_qty'}
          heading={t('list.tableHeaders.deliveredQty')}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
}

const PromocodesCell: React.FC<CellProps<FarmerOrder>> = ({ cell }) => {
  const items = cell.value as { code: string }[]

  return <div>{items.map(({ code }) => code).join(', ')}</div>
}

const ItemsCell: React.FC<CellProps<FarmerOrder>> = ({ cell }) => {
  if (!cell.value) {
    return <span>{''}</span>
  }

  return (
    <div>
      {cell.value.map((item: FarmerOrderItem, i: number) => {
        return (
          <div key={`${item.product_id}-${i}`} style={{ whiteSpace: 'nowrap' }}>{`${item.product?.title || ''}`}</div>
        )
      })}
    </div>
  )
}

const SeedTreatmentCell: React.FC<CellProps<FarmerOrder>> = ({ cell }) => {
  if (!cell.value) {
    return <span>{''}</span>
  }

  return (
    <div>
      {cell.value.map((item: FarmerOrderItem, i: number) => {
        const id = item.seed_treatment_id
        const seedTreatment = id ? item.product?.seed_treatment.find(el => el.id === id) : null
        return (
          <SeedTreatment key={`${item.product_id}-${i}`} title={seedTreatment?.title || '-'}>
            {seedTreatment?.title || '-'}
          </SeedTreatment>
        )
      })}
    </div>
  )
}

const SortableHeadCell = AdvancedHeadCell<keyof FarmerOrder>()

const customSelectStyles: CustomSelectStyles = {
  control: (...args) => ({
    ...controlStyle(...args),
    height: '32px',
    minHeight: '32px',
    minWidth: '200px',
  }),
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
    width: 'auto',
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  }),
}

const customCountryFilterStyles: React.CSSProperties = {
  margin: '0 16px 16px 0',
}

const FarmerOrderList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation(['farmerOrder', 'labels', 'common'])
  const role = useSelector(AuthSelectors.role)
  const userCompany = useSelector(AuthSelectors.userCompany)
  const checkFeatureFlag = useFeatureFlags()
  const hasAccess = useCallback(flag => !checkFeatureFlag(flag), [checkFeatureFlag])

  const isBasicRetailer = isUsersCompanyBasicDistributor(userCompany)

  const filterValue = useSelector(FarmerOrderSelectors.filter)
  const selectedSeason = useSelector(SeasonSelectors.item(filterValue.season_id))

  const isSeedTreatmentHidden = !hasAccess(
    generateCrmTableColumnAccessString(Tables.FarmerOrders, Columns.SeedTreatment, FeatureFlagModifiers.Hidden),
  )
  const isTimeHidden = !hasAccess(generateCustomFeatureFlag(Sections.Orders, CustomFeatureName.WithoutTime))

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: StatusColumnHeaderCell,
        accessor: 'status' as const,
        Cell: StatusCell,
      },
      {
        Header: t('list.tableHeaders.idAndDate'),
        accessor: 'slug' as const,
        Cell: props => <IdDateCell isTimeHidden={isTimeHidden} {...props} />,
      },
      {
        Header: t('list.tableHeaders.producer'),
        accessor: 'producer' as const,
        Cell: ProducerCell,
      },
      {
        Header: t('list.tableHeaders.distributor'),
        accessor: 'distributor' as const,
        Cell: DistributorCell,
        hidden: isBasicRetailer,
      },
      {
        Header: t('list.tableHeaders.season'),
        accessor: 'season' as const,
        Cell: SeasonCell,
      },
      {
        Header: t('list.tableHeaders.client'),
        accessor: 'customer' as const,
        Cell: CustomerCell,
      },
      {
        Header: t('list.tableHeaders.subcategory'),
        accessor: 'items' as const,
        id: 'crops',
        Cell: Crop,
      },
      {
        Header: t('list.tableHeaders.items'),
        accessor: 'items' as const,
        id: 'items',
        Cell: ItemsCell,
      },
      {
        Header: t('list.tableHeaders.seedTreatment'),
        accessor: 'items' as const,
        id: 'seed-treatment',
        Cell: SeedTreatmentCell,
        hidden: isSeedTreatmentHidden,
      },
      {
        Header: t('list.tableHeaders.quantity'),
        accessor: 'items' as const,
        id: 'qty',
        Cell: OrderQty,
      },
      {
        Header: t('list.tableHeaders.deliveredQty'),
        accessor: 'items' as const,
        id: 'deliveredQty',
        Cell: props => <DeliveredQty isDistributor={isDistributor(role)} {...props} />,
        hidden: !selectedSeason?.enable_delivered_qty,
      },
      {
        Header: t('list.tableHeaders.finalQty'),
        accessor: 'items' as const,
        id: 'finalQty',
        Cell: props => <FinalQty isDistributor={isDistributor(role)} {...props} />,
        hidden: !selectedSeason?.enable_final_qty,
      },
      {
        Header: t('list.tableHeaders.promocodes'),
        accessor: 'promocodes' as const,
        Cell: PromocodesCell,
      },
    ],
    [t, role, isBasicRetailer, selectedSeason, isSeedTreatmentHidden],
  )

  const hiddenColumns: (keyof FarmerOrder)[] = React.useMemo(
    () => ['currency', 'region_id', 'price', 'total', 'created_at', 'order_date', 'id', 'interaction'],
    [],
  )

  const columnsAll = React.useMemo(() => {
    return [
      ...visibleColumns,
      ...hiddenColumns.map(col => ({
        Header: col,
        accessor: col,
        hidden: true,
      })),
    ]
  }, [hiddenColumns, visibleColumns])

  const [progress, data = []] = useFarmerOrderList()

  const { columns, rows, prepareRow } = useTable<FarmerOrder>(
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
      columns: columnsAll,
      data,
      initialState: {
        hiddenColumns,
      },
    },
    useRowSelect,
  )

  const filterUpdated = useAction(FarmerOrderActions.filterUpdated)
  const filterCleared = useAction(FarmerOrderActions.filterHasBeenReset)
  const sortingUpdated = useAction(FarmerOrderActions.sortingUpdated)
  const listRequested = useAction(FarmerOrderActions.listRequested)
  const listUpdateRequested = useAction(FarmerOrderActions.listUpdateRequested)

  const page = useSelector(FarmerOrderSelectors.page)
  const pageSize = useSelector(FarmerOrderSelectors.pageSize)
  const pages = useSelector(FarmerOrderSelectors.pages)
  const total = useSelector(FarmerOrderSelectors.total)
  const totalQty = useSelector(FarmerOrderSelectors.totalQty)
  const totalDeliveredQty = useSelector(FarmerOrderSelectors.totalDeliveredQty)
  const totalFinalQty = useSelector(FarmerOrderSelectors.totalFinalQty)
  const { country, business_model, start_date, end_date } = filterValue
  const { sort_field, sort_reversed } = useSelector(FarmerOrderSelectors.sorting)
  const updateTimeout = useRef<number | null>(null)

  const isAdmin = useSelector(AuthSelectors.isAdmin)

  const displayCategoryFilter =
    isAdmin ||
    checkFeatureFlag([generateCustomFeatureFlag(Sections.FarmerOrders, CustomFeatureName.DisplayCategoryFilter)])

  const plantUpdateTimeout = useCallback(() => {
    updateTimeout.current = window.setTimeout(() => {
      listUpdateRequested()
      plantUpdateTimeout()
    }, LIST_UPDATE_INTERVAL)
  }, [listUpdateRequested])

  const clearUpdateTimeout = useCallback(() => {
    if (updateTimeout.current) {
      clearTimeout(updateTimeout.current)
      updateTimeout.current = null
    }
  }, [])

  usePageVisibilityChange(plantUpdateTimeout, clearUpdateTimeout)

  useEffect(() => {
    if (!updateTimeout.current) {
      plantUpdateTimeout()
    }
    return clearUpdateTimeout
  }, [clearUpdateTimeout, listUpdateRequested, plantUpdateTimeout])

  const handleFilterChange = React.useCallback(
    (newFilterValue: Partial<FarmerOrderListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const handleDateRangeChange = React.useCallback(
    ([start, end]) => {
      const parsedStartDate = parseISO(start)
      const parsedEndDate = parseISO(end)
      if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
        handleFilterChange({ start_date: start, end_date: end })
      }
    },
    [handleFilterChange],
  )

  const handleClearFilters = React.useCallback(() => {
    filterCleared()
  }, [filterCleared])

  const isFilterApplied = Object.values(filterValue).some(Boolean)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('farmerOrderList')

  const [downloadProgress, downloadCSV] = useDownload(endpoints.farmerOrder('download/csv'), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  const showDistributorsFilter =
    isAgro(role) || isUsersCompanyProducer(userCompany) || isUsersCompanyHeadDistributor(userCompany)

  const dateFormat = useDateFormat({ isYearShort: true })

  return (
    <Wrapper>
      <TComponents.Filters>
        <FiltersWrapper>
          <Filter title={t('labels:period')}>
            <RangeDatePicker
              start={start_date || ''}
              end={end_date || ''}
              onChange={handleDateRangeChange}
              format={dateFormat}
            />
          </Filter>
          <BusinessModelFilter filterValue={{ business_model }} handleFilterChange={handleFilterChange} disableMulti />

          <Filter title={t('labels:status')}>
            <FarmerOrderStatusMultiSelect
              selected={filterValue.filter_by_status}
              onChange={val => handleFilterChange({ filter_by_status: val })}
            />
          </Filter>
          {showDistributorsFilter && (
            <Filter title={t('labels:retailer')}>
              <DistributorsMultiSelect
                onChange={val => handleFilterChange({ distributor_id: val })}
                selected={filterValue.distributor_id}
              />
            </Filter>
          )}
          {isAgro(role) && (
            <Filter title={t('labels:households')}>
              <HouseholdSelect
                value={filterValue.household_id}
                onChange={value => handleFilterChange({ household_id: value })}
                placeholder={t('status.any')}
                customStyles={customSelectStyles}
              />
            </Filter>
          )}
          {isAgro(role) && (
            <Filter title={t('labels:producer')}>
              <CompanySelect
                onChange={newValue => {
                  handleFilterChange({ producer_id: newValue, category_id: [], subcategory_id: [] })
                }}
                value={filterValue.producer_id}
                customStyles={customSelectStyles}
                isClearable
                companyType={CompanyType.Producer}
              />
            </Filter>
          )}
          {displayCategoryFilter && (
            <Filter title={t('labels:category')}>
              <CategorySelect
                filter={{ seller_ids: filterValue.producer_id }}
                onChange={val => handleFilterChange({ category_id: val ? [val] : [], subcategory_id: [] })}
                value={filterValue.category_id?.[0]}
                customStyles={customSelectStyles}
                placeholder={t('common:any')}
                isClearable
              />
            </Filter>
          )}
          {displayCategoryFilter && (
            <Filter title={t('labels:subcategory')}>
              <SubcategorySelect
                filter={{ parent_id: filterValue.category_id?.[0], seller_ids: filterValue.producer_id }}
                onChange={val => handleFilterChange({ subcategory_id: val ? [val] : [] })}
                value={filterValue.subcategory_id?.[0]}
                customStyles={customSelectStyles}
                placeholder={t('common:any')}
                isClearable
              />
            </Filter>
          )}
          <Filter title={t('labels:product')}>
            <ProductsSelect
              onChange={val => handleFilterChange({ product_id: val })}
              value={filterValue.product_id}
              variant="small"
              isFilterSuggest
            />
          </Filter>
          <Filter title={t('labels:season')}>
            <SeasonSelect
              variant="small"
              value={filterValue.season_id}
              onChange={val => handleFilterChange({ season_id: val })}
              isClearable
            />
          </Filter>
          {isAgro(role) && (
            <CountryFilter
              filterValue={{ country }}
              handleFilterChange={handleFilterChange}
              customStyles={customCountryFilterStyles}
            />
          )}
        </FiltersWrapper>
        <AlignWrapper verticalAlign="bottom" horizontalAlign="right">
          <Button onClick={downloadCSV} intent="primary" filled progress={downloadProgress}>
            {t('labels:downloadCsv')}
          </Button>
        </AlignWrapper>
      </TComponents.Filters>
      <TableWrapper>
        <Table
          total={total}
          pages={pages}
          pageSize={pageSize}
          currentPage={page}
          onSetPage={fetchNextItems}
          ref={scrollRef}
        >
          <TableHead height={TWO_ROWS_TABLE_HEAD_HEIGHT}>
            <TableHeadRow>
              {columns.map(column => {
                let value
                if (column.id === 'qty') {
                  value = totalQty
                } else if (column.id === 'finalQty') {
                  value = totalFinalQty
                } else if (column.id === 'deliveredQty') {
                  value = totalDeliveredQty
                }

                return (
                  <SortableHeadCell
                    key={column.getHeaderProps().key}
                    id={column.id as keyof FarmerOrder}
                    sortable={column.sortable}
                    hidden={column.hidden}
                    sortField={sort_field}
                    sortDesc={sort_reversed}
                    onChange={sortingUpdated}
                    width={column.width}
                    emphatic={column.id === 'finalQty'}
                  >
                    {value}
                  </SortableHeadCell>
                )
              })}
            </TableHeadRow>
            <TableHeadRow>
              {columns.map(column => {
                return (
                  <SortableHeadCell
                    key={column.getHeaderProps().key}
                    id={column.id as keyof FarmerOrder}
                    sortable={column.sortable}
                    hidden={column.hidden}
                    sortField={sort_field}
                    sortDesc={sort_reversed}
                    onChange={sortingUpdated}
                    width={column.width}
                    emphatic={column.id === 'finalQty'}
                  >
                    {column.render('Header')}
                  </SortableHeadCell>
                )
              })}
            </TableHeadRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              prepareRow(row)
              const { key, ...props } = row.getRowProps()
              return (
                <TableBodyRow
                  key={key}
                  {...props}
                  selected={row.isSelected}
                  onClick={() => history.push(generatePath(Routes.Edit, { id: row.original.id.toString() }))}
                >
                  {row.cells.map(cell => {
                    const { key, ...props } = cell.getCellProps()

                    if (cell.column.hidden) {
                      return null
                    }

                    return (
                      <BodyCell
                        key={key}
                        {...props}
                        stopClickPropagation={['status', 'finalQty', 'deliveredQty'].includes(cell.column.id)}
                        emphasis={cell.column.id === 'finalQty'}
                      >
                        {cell.render('Cell')}
                      </BodyCell>
                    )
                  })}
                </TableBodyRow>
              )
            })}
            <TableNoData
              progress={progress}
              isEmpty={!rows.length}
              colSpan={visibleColumns.length}
              loading={<TComponents.Spinner />}
            >
              <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
              {isFilterApplied && progress !== Progress.WORK && (
                <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={handleClearFilters}>
                  {t('list.resetAllFilters')}
                </TComponents.ClearButton>
              )}
            </TableNoData>
          </TableBody>
        </Table>
      </TableWrapper>
    </Wrapper>
  )
}

export default FarmerOrderList
