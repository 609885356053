import React from 'react'
import { isPromocodeWithComment, isPromocodeWithLegalText, PromocodeListProps } from 'modules/domain/promocode/types'
import { TextAreaWithTags } from '@agro-club/frontend-shared'

const PromocodeList: React.FC<PromocodeListProps> = ({ promocodes, onRemove, onCommentChange, selected, onSelect }) => {
  const handleRemove = (id: string) => {
    onRemove(id)
    if (id === selectedPromocode) {
      onSelect('')
    }
  }

  const items = promocodes.map(item => {
    let placeholder
    let value
    let readOnly = false
    let editable: boolean | undefined = false
    let selectable: boolean | undefined = false

    if (isPromocodeWithComment(item)) {
      placeholder = item.params.prompt || undefined
      editable = !!item.params.prompt
      value = item.comment || undefined
      selectable = true
    }

    if (isPromocodeWithLegalText(item)) {
      value = item.params.legal_text || undefined
      readOnly = true
      selectable = true
    }
    return {
      id: item.code,
      title: item.code,
      placeholder,
      editable,
      value,
      selectable,
      readonly: readOnly,
    }
  })

  // select first editable, readonly or selected
  const selectedPromocode =
    selected ||
    promocodes.find(item => {
      if (isPromocodeWithComment(item)) {
        return !!item.params.prompt
      }
      if (isPromocodeWithLegalText(item)) {
        return !!item.params.legal_text
      }
    })?.code

  return (
    <div data-test-id={'promocode-textarea'}>
      <TextAreaWithTags
        items={items}
        selected={selectedPromocode}
        onTextChange={onCommentChange}
        onSelectionChange={onSelect}
        onRemove={handleRemove}
      />
    </div>
  )
}

export default PromocodeList
