import { Checkbox, FormField, FormikHook, Input, SimpleSelect, useBoolean } from '@agro-club/frontend-shared'
import { FormikProvider, useFormikContext } from 'formik'
import { SKUS_LIMIT_FOR_CARD } from 'modules/constants'

import { useWizardOptions } from 'modules/domain/productWizard/hooks'
import StorefrontSelectors from 'modules/domain/storefront/selectors'
import { StorefrontItemType, StorefrontModalMode } from 'modules/domain/storefront/types'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CompanyType, Status } from 'types/entities'
import CategorySelect from 'views/components/CategorySelect/CategorySelect'
import SubcategorySelect from 'views/components/CategorySelect/SubcategorySelect'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import { ProductsSelect } from 'views/components/ProductsSelect/ProductsSelect'
import { SkuMultiSelect } from 'views/components/SkuSelect/SkuMultiSelect'
import * as Yup from 'yup'
import StorefrontItemModal, { StorefrontItemModalData } from '../../Storefront/StorefrontItemModal/StorefrontItemModal'
import { useSelector } from 'react-redux'
import * as Styled from '../styled'

export type StorefrontOriginFormProps = {
  skus?: string[]
  sellerId?: string
  productId?: string
  wizardId?: string
  categoryId?: string
  subcategoryId?: string
  rank?: number
  isNew?: boolean
  isFeatured?: boolean
  isLimited: boolean
  isExclusive: boolean
  hideFromCrm: boolean
  isOutOfStock: boolean
  outOfStockAllowFarmerOrders: boolean
  outOfStockAllowRetailOrders: boolean
  isGift: boolean
  producerProductId?: string
}

const WizardSelect: React.FC = () => {
  const { t } = useTranslation('product')
  const formik = useFormikContext<StorefrontOriginFormProps>()
  const [progress, data = []] = useWizardOptions()

  // temporary map to compatible options for SimpleSelect
  // fixme https://agroclub.atlassian.net/browse/ENT-1908
  const options = data.map(({ id, title }) => ({ id, title }))

  return (
    <div data-test-id={'wizard-select'}>
      <SimpleSelect
        progress={progress}
        value={formik.values.wizardId}
        onChange={v => formik.setFieldValue('wizardId', v)}
        options={options}
        label={t('form.wizard')}
        isSearchable={true}
        isClearable={true}
      />
    </div>
  )
}

const StorefrontOriginForm: React.FC<{
  useFormik: FormikHook
  skus?: string[]
  sellerId?: string
  productId?: string
  wizardId?: string
  categoryId?: string
  subcategoryId?: string
  rank?: number
  isNew?: boolean
  isFeatured?: boolean
  isLimited: boolean
  isExclusive?: boolean
  hideFromCrm?: boolean
  isOutOfStock: boolean
  outOfStockAllowFarmerOrders: boolean
  outOfStockAllowRetailOrders: boolean
  isGift?: boolean
  producerProductId?: string
}> = memo(
  ({
    useFormik,
    skus,
    sellerId,
    productId,
    wizardId,
    categoryId,
    subcategoryId,
    rank,
    isNew = true,
    isFeatured = false,
    isLimited,
    isExclusive = false,
    hideFromCrm = false,
    isOutOfStock = false,
    outOfStockAllowFarmerOrders = false,
    outOfStockAllowRetailOrders = false,
    isGift = false,
    producerProductId,
  }) => {
    const { t } = useTranslation(['product', 'cards', 'common', 'validation'])
    const [isSkuModalOpen, openSkuModal, closeSkuModal] = useBoolean(false)
    const [skuModalData, setSkuModalData] = useState<StorefrontItemModalData | undefined>(undefined)
    const addSkuProgress = useSelector(StorefrontSelectors.addProgress)

    const validationSchema = useMemo(() => {
      return Yup.object({
        skus: Yup.array(Yup.string())
          .test(
            'skus-limit',
            t('validation:skusLimitExceeded', { limit: SKUS_LIMIT_FOR_CARD }),
            skus => skus !== undefined && skus.length <= SKUS_LIMIT_FOR_CARD,
          )
          .nullable(),
        sellerId: Yup.string().required(t('validation:field_required')),
        productId: Yup.string().required(t('validation:field_required')),
        categoryId: Yup.string().required(t('validation:field_required')),
        subcategoryId: Yup.string().required(t('validation:field_required')),
        producerProductId: Yup.string().nullable(),
      })
    }, [t])

    const formik = useFormik<StorefrontOriginFormProps>({
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit: () => {},
      initialValues: {
        skus,
        sellerId,
        wizardId,
        categoryId,
        productId,
        subcategoryId,
        rank,
        isNew,
        isFeatured,
        isLimited,
        isExclusive,
        hideFromCrm,
        isOutOfStock,
        outOfStockAllowRetailOrders,
        outOfStockAllowFarmerOrders,
        isGift,
        producerProductId,
      },
      validationSchema,
      enableReinitialize: true,
    })

    const handleProductChange = productId => {
      formik.setFieldValue('productId', productId)
      formik.setFieldValue('skus', [])
    }

    const handleOutOfStockFlagChange = isChecked => {
      formik.setFieldValue('isOutOfStock', isChecked)
      if (!isChecked) {
        formik.setFieldValue('outOfStockAllowFarmerOrders', false)
        formik.setFieldValue('outOfStockAllowRetailOrders', false)
      }
    }

    const handleOutOfStockAllowFarmerOrdersChange = isChecked => {
      formik.setFieldValue('outOfStockAllowFarmerOrders', isChecked)
    }

    const handleOutOfStockAllowRetailOrdersChange = isChecked => {
      formik.setFieldValue('outOfStockAllowRetailOrders', isChecked)
    }

    const handleSkuOptionsChange = (ids: string[]) => {
      formik.setFieldValue('skus', ids)
    }

    const handleSellerIdChange = (id?: string) => {
      formik.setFieldValue('sellerId', id)
      formik.setFieldValue('productId', undefined)
      formik.setFieldValue('skus', [])
    }

    const handleSkuAdd = useCallback(() => {
      setSkuModalData({
        mode: StorefrontModalMode.Create,
        sellerId: formik.values.sellerId,
        productId: formik.values.productId,
      })
      openSkuModal()
    }, [formik.values.productId, formik.values.sellerId, openSkuModal])

    const handleSkuModalClose = useCallback(() => {
      setSkuModalData(undefined)
      closeSkuModal()
    }, [closeSkuModal])

    return (
      <FormikProvider value={formik}>
        <Styled.Grid2Col>
          <FormField
            label={t('cards:form.labels.sellerId')}
            render={() => (
              <CompanySelect
                companyType={CompanyType.Producer}
                filter={{ is_seller: true }}
                onChange={handleSellerIdChange}
                value={formik.values.sellerId}
                isClearable
                isSearchable
                placeholder={t('SellerSelect:placeholder')}
                pageSize={100}
                data-test-id="card-seller"
              />
            )}
          />
          <FormField
            testId="storefront-productId"
            label={t('cards:form.labels.productId')}
            render={() => (
              <ProductsSelect
                required
                onChange={handleProductChange}
                value={formik.values.productId || undefined}
                isDisabled={!formik.values.sellerId}
                placeholder={t('cards:form.placeholders.productId')}
                filter={{
                  seller_id: formik.values.sellerId,
                }}
                invalid={formik.touched.productId && !!formik.errors.productId}
                errorText={formik.errors.productId}
                data-test-id="card-product"
              />
            )}
          />
        </Styled.Grid2Col>
        <Styled.SkuSelectWrapper>
          <SkuMultiSelect
            label={t('cards:form.labels.skuList')}
            placeholder={t('cards:form.placeholders.skuList')}
            onChange={handleSkuOptionsChange}
            sellerId={sellerId}
            type={StorefrontItemType.Product}
            status={Status.Active}
            productId={formik.values.productId || undefined}
            invalid={formik.touched.skus && !!formik.errors.skus}
            errorText={formik.errors.skus}
            values={formik.values.skus}
            isDisabled={!formik.values.sellerId || !formik.values.productId}
            isClearable
            isSearchable
            limit={SKUS_LIMIT_FOR_CARD}
            data-test-id="card-skus"
            updateDeps={[addSkuProgress]}
          />
          <Styled.AddSkuButton
            intent="primary"
            data-test-id="add-sku-button"
            onClick={handleSkuAdd}
            disabled={!formik.values.sellerId || !formik.values.productId}
            progress={addSkuProgress}
          >
            {t('cards:form.addSku')}
          </Styled.AddSkuButton>
        </Styled.SkuSelectWrapper>
        <Styled.Grid2Col>
          <CategorySelect
            label={t('form.category')}
            onChange={v => {
              formik.setFieldValue('subcategoryId', null)
              formik.setFieldValue('categoryId', v)
            }}
            invalid={formik.touched.categoryId && !!formik.errors.categoryId}
            errorText={formik.errors.categoryId}
            value={formik.values.categoryId}
            filter={{ seller_ids: formik.values.sellerId }}
            data-test-id="card-category"
          />
          <SubcategorySelect
            label={t('form.subcategory')}
            onChange={v => {
              formik.setFieldValue('subcategoryId', v)
            }}
            invalid={formik.touched.subcategoryId && !!formik.errors.subcategoryId}
            errorText={formik.errors.subcategoryId}
            value={formik.values.subcategoryId}
            isDisabled={!formik.values.categoryId}
            filter={{ parent_id: formik.values.categoryId, seller_ids: formik.values.sellerId }}
            data-test-id="card-subcategory"
          />
          <WizardSelect />
        </Styled.Grid2Col>
        <Styled.Grid3Col>
          <Input
            {...formik.getFieldProps('rank')}
            invalid={formik.touched.rank && !!formik.errors.rank}
            errorText={formik.errors.rank}
            label={t('form.rank')}
            type={'number'}
          />
          <Input
            {...formik.getFieldProps('producerProductId')}
            invalid={formik.touched.producerProductId && !!formik.errors.producerProductId}
            errorText={formik.errors.producerProductId}
            label={t('form.productId')}
          />
        </Styled.Grid3Col>
        <Styled.Grid3Col>
          <FormField
            label={t('form.badgeNewTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.isNew}
                onChange={(_, isChecked) => {
                  formik.setFieldValue('isNew', isChecked)
                }}
                label={'Badge is shown'}
                data-test-id="is-new-flag"
              />
            )}
          />
          <FormField
            label={t('form.isFeaturedTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.isFeatured}
                onChange={(_, isChecked) => {
                  formik.setFieldValue('isFeatured', isChecked)
                }}
                label={t('form.isFeatured')}
                data-test-id="is-featured-flag"
              />
            )}
          />
          <FormField
            label={t('form.isLimitedTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.isLimited}
                onChange={(_, isChecked) => {
                  formik.setFieldValue('isLimited', isChecked)
                }}
                label={t('form.isLimited')}
                data-test-id="is-limited-flag"
              />
            )}
          />
          <FormField
            label={t('form.isExclusiveTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.isExclusive}
                onChange={(_, isChecked) => {
                  formik.setFieldValue('isExclusive', isChecked)
                }}
                label={t('form.isExclusive')}
                data-test-id="is-exclusive-flag"
              />
            )}
          />
          <FormField
            label={t('form.isGiftTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.isGift}
                onChange={(_, isChecked) => {
                  formik.setFieldValue('isGift', isChecked)
                }}
                label={t('form.isGift')}
                data-test-id="is-gift-flag"
              />
            )}
          />
          <FormField
            label={t('form.hideFromCrmTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.hideFromCrm}
                onChange={(_, isChecked) => {
                  formik.setFieldValue('hideFromCrm', isChecked)
                }}
                label={t('form.hideFromCrm')}
                data-test-id="hide-from-crm-flag"
              />
            )}
          />
          <FormField
            label={t('form.isOutOfStockTitle')}
            render={({ id }) => (
              <Checkbox
                id={id}
                isChecked={formik.values.isOutOfStock}
                onChange={(_, isChecked) => handleOutOfStockFlagChange(isChecked)}
                label={t('form.isOutOfStock')}
                data-test-id="is-out-of-stock-flag"
              />
            )}
          />
        </Styled.Grid3Col>
        {formik.values.isOutOfStock && (
          <Styled.Grid3Col>
            <FormField
              render={({ id }) => (
                <Checkbox
                  id={id}
                  isChecked={formik.values.outOfStockAllowFarmerOrders}
                  onChange={(_, isChecked) => handleOutOfStockAllowFarmerOrdersChange(isChecked)}
                  label={t('cards:form.labels.outOfStockAllowFarmerOrders')}
                  data-test-id="out-of-stock-allow-farmer-orders"
                />
              )}
            />
            <FormField
              render={({ id }) => (
                <Checkbox
                  id={id}
                  isChecked={formik.values.outOfStockAllowRetailOrders}
                  onChange={(_, isChecked) => handleOutOfStockAllowRetailOrdersChange(isChecked)}
                  label={t('cards:form.labels.outOfStockAllowRetailOrders')}
                  data-test-id="out-of-stock-allow-retail-orders"
                />
              )}
            />
          </Styled.Grid3Col>
        )}
        <StorefrontItemModal
          heading={t('storefront:modal.createItemHeading')}
          isOpen={isSkuModalOpen}
          modalData={skuModalData}
          onClose={handleSkuModalClose}
        />
      </FormikProvider>
    )
  },
)
StorefrontOriginForm.displayName = 'StorefrontOriginForm'

export default StorefrontOriginForm
