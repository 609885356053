import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ProductOptionsSelectors from 'modules/domain/productOptions/selectors'
import ProductOptionsActions from 'modules/domain/productOptions/duck'
import styled from 'styled-components'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useProductOptionsById } from 'modules/domain/productOptions/hooks'
import { Progress } from 'modules/types'
import ProductOptionsDetailsForm, { FormProps } from 'views/pages/Product/ProductOptions/ProductOptionsDetailsForm'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { useTranslation } from 'react-i18next'
import { useAction, useHistoryPush } from '@agro-club/frontend-shared'
import ProductRoutes from '../routes'
import useLangPicker from 'hooks/useLangPicker'

const Id = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.onPrimaryDark};
  opacity: 0.48;
  margin-top: 4px;
`

const ProductOptionsEdit: React.VFC = () => {
  const { t } = useTranslation('productOptions')
  const { pick } = useLangPicker()
  const push = useHistoryPush()
  const params = useParams<{ id: string }>()
  const updateAction = useAction(ProductOptionsActions.updateRequested)
  const removeAction = useAction(ProductOptionsActions.removeRequested)
  const page = useSelector(ProductOptionsSelectors.page)
  const meta = useSelector(state => ProductOptionsSelectors.meta(state, params.id))
  const [fetchProgress, item] = useProductOptionsById(params.id)

  const handleSubmit = (props: FormProps) => {
    updateAction(params.id, props)
  }
  const handleRemove = () => {
    removeAction(params.id)
  }

  const data = useMemo(() => {
    if (!item) {
      return null
    }
    return {
      title_i18n: item?.title_i18n,
      type: item?.type || '',
      is_stackable: item.is_stackable || false,
    }
  }, [item])

  const goBack = () => {
    push({ route: ProductRoutes.OptionsList, query: { page } })
  }

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (fetchProgress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (fetchProgress === Progress.WORK || !data || !item) {
    return loading()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={pick(item.title_i18n) || ''} />
          <Id>{`ID ${item.id}`}</Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <ProductOptionsDetailsForm
          progress={meta.updateProgress}
          errorText={meta.updateErrorDetail}
          removeProgress={meta.removeProgress}
          mode={'edit'}
          onRemove={handleRemove}
          onSubmit={handleSubmit}
          onCancel={goBack}
          initialValues={data}
        />
      </Layout.Content>
    </>
  )
}

export default ProductOptionsEdit
