import React, { memo, useCallback, useState } from 'react'
import { AvailableLanguages, LocalizedValue } from 'types/entities'
import { useTranslation } from 'react-i18next'
import * as Styled from 'views/pages/Product/ProductDetailsForm/styled'
import { FormikHook, Input, Label } from '@agro-club/frontend-shared'
import FileManager from 'views/components/FileManager/FileManager'
import { FileItem } from 'views/components/FileManager/types'
import { createLocalizedValue } from 'helpers/localization'
import styled from 'styled-components'
import { remove } from 'ramda'

export const AddButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.color.primary600};
  display: inline-flex;
  border: 0;
  background: none;
  width: auto;
  transition: opacity 0.2s ease-in;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

export type ProductInfoFilesFormProps = {
  files: {
    title_i18n: LocalizedValue
    url_i18n: LocalizedValue<FileItem[] | undefined>
  }[]
}

const ProductInfoFilesForm: React.FC<{
  testId?: string
  useFormik: FormikHook
  files: {
    title_i18n: LocalizedValue
    url_i18n: LocalizedValue<FileItem[] | undefined>
  }[]
  langList: AvailableLanguages[]
}> = memo(({ useFormik, files = [], langList, testId }) => {
  const { t } = useTranslation(['product', 'common', 'validation'])
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const formik = useFormik<ProductInfoFilesFormProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    initialValues: {
      files,
    },
    enableReinitialize: true,
  })

  const handleFilesChange = useCallback(
    (items: FileItem[], key: string) => {
      formik.setFieldValue(key, items)
    },
    [formik],
  )

  const handleAddClick = () => {
    formik.setFieldValue('files', [
      ...formik.values.files,
      {
        title_i18n: createLocalizedValue(() => ''),
      },
    ])
  }

  const handleRemoveClick = (idx: number) => {
    formik.setFieldValue('files', remove(idx, 1, formik.values.files))
  }

  return (
    <div data-test-id={testId}>
      <Styled.VerticalInputsWrapper>
        {formik.values.files.map((_, idx) => {
          return (
            <Styled.VerticalFormWrapper key={idx}>
              <Styled.RemoveIcon
                onMouseEnter={() => setHoveredIndex(idx)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => handleRemoveClick(idx)}
                data-test-id={'remove-button'}
              />
              {langList.map((lang, langIdx) => {
                const titleKey = `files[${idx}].title_i18n[${lang}]`
                return (
                  <Styled.VerticalFormContainer key={langIdx}>
                    <Input
                      {...formik.getFieldProps(titleKey)}
                      label={t('form.urlTitle', { idx: idx + 1, lang: `(${lang})` })}
                      limit={30}
                    />
                    <div data-test-id={`files[${idx}].url_i18n[${lang}]`}>
                      <Label>{t('form.urlLink', { idx: idx + 1, lang: `(${lang})` })}</Label>
                      <FileManager
                        items={formik.values.files?.[idx]?.url_i18n?.[lang] || []}
                        onChange={items => handleFilesChange(items, `files[${idx}].url_i18n[${lang}]`)}
                        limit={1}
                      />
                    </div>
                  </Styled.VerticalFormContainer>
                )
              })}
              {hoveredIndex !== null && hoveredIndex === idx ? <Styled.RemoveOverlay /> : null}
            </Styled.VerticalFormWrapper>
          )
        })}
      </Styled.VerticalInputsWrapper>
      <Styled.AddButton data-test-id={'add-product-info-file'} onClick={handleAddClick} type={'button'}>
        {t('form.addFileButton')}
      </Styled.AddButton>
    </div>
  )
})
ProductInfoFilesForm.displayName = 'ProductInfoFilesForm'

export default ProductInfoFilesForm
