import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { SearchInput, useAction, useHelmet } from '@agro-club/frontend-shared'
import FccOrderList from './FccOrderList/FccOrderList'
import FccOrderActions from 'modules/domain/fccOrder/duck'
import FccOrderSelectors from 'modules/domain/fccOrder/selectors'
import { useSelector } from 'react-redux'

const SubtitleWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
  white-space: pre-wrap;
  max-width: 1000px;
  font-size: 16px;
  line-height: 24px;
`

const SearchInputWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 4px;
  width: 50%;
`

const FccOrderTable: React.FC = () => {
  const filter = useSelector(FccOrderSelectors.filter)
  const filterUpdated = useAction(FccOrderActions.filterUpdated)
  const { t } = useTranslation(['fccOrder', 'common'])
  const handleSearchChange = React.useCallback(
    (search?: string) => {
      filterUpdated({ ...filter, ...{ search } })
    },
    [filterUpdated, filter],
  )

  useHelmet({ title: t('fccMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root>
          <Header.Title title={t('list.heading')} />
          <SubtitleWrapper>{t('subtitle')}</SubtitleWrapper>
          <SearchInputWrapper>
            <SearchInput
              onChange={handleSearchChange}
              value={filter.search}
              placeholder={t('list.searchPlaceholder')}
            />
          </SearchInputWrapper>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <FccOrderList />
      </Layout.Content>
    </>
  )
}

export default FccOrderTable
