import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useHistoryPush,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import ProductOptionsActions from 'modules/domain/productOptions/duck'
import { useProductOptionsList } from 'modules/domain/productOptions/hooks'
import ProductOptionsSelectors from 'modules/domain/productOptions/selectors'
import { ProductOptions } from 'modules/domain/productOptions/types'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CellProps, useTable } from 'react-table'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import ProductRoutes from 'views/pages/Product/routes'

const TypeCell: React.FC<CellProps<ProductOptions>> = ({ cell, column }) => {
  const { t } = useTranslation(['productOptions'])
  return <TComponents.RegularText key={column.id}>{t(`types.${cell.value}`)}</TComponents.RegularText>
}

const ProductOptionsTable: React.FC = () => {
  const { t } = useTranslation(['productOptions', 'labels'])
  const push = useHistoryPush()
  const [progress, data = []] = useProductOptionsList()
  const total = useSelector(ProductOptionsSelectors.total)
  const page = useSelector(ProductOptionsSelectors.page)
  const pages = useSelector(ProductOptionsSelectors.pages)
  const pageSize = useSelector(ProductOptionsSelectors.pageSize)

  const listRequested = useAction(ProductOptionsActions.listRequested)
  const { scrollRef } = usePersistentScroll('productOptionsTable')

  const allColumns = useMemo(
    () => [
      {
        Header: t('productOptions:fields.id'),
        accessor: 'id' as const,
        sortable: false,
      },
      {
        Header: t('productOptions:fields.title'),
        accessor: 'title' as const,
        sortable: false,
      },
      {
        Header: t('productOptions:fields.type'),
        accessor: 'type' as const,
        Cell: TypeCell,
        sortable: false,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<ProductOptions>({
    columns: allColumns,
    data,
  })

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  return (
    <>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
        data-test-id="product-options-table"
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return <TableHeadCell key={column.id}>{column.render('Header')}</TableHeadCell>
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow
                key={key}
                {...props}
                onClick={() => {
                  push({ route: ProductRoutes.OptionsEdit, params: { id: row.original.id } })
                }}
                data-test-id="product-option-row"
              >
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={allColumns.length}
            loading={<TComponents.Spinner />}
          />
        </TableBody>
      </Table>
    </>
  )
}

export default ProductOptionsTable
