import React, { useCallback, useMemo, useState } from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import { useTranslation } from 'react-i18next'
import { useTable } from 'react-table'
import { useCategoryList } from 'modules/domain/category/hooks'
import { generatePath, useHistory } from 'react-router-dom'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import CategorySelectors from 'modules/domain/category/selectors'
import { useSelector } from 'react-redux'
import CategoryActions from 'modules/domain/category/duck'
import {
  Button,
  controlStyle,
  CustomSelectStyles,
  menuListStyle,
  menuStyle,
  optionStyle,
  SearchInput,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  useHelmet,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import { endpoints } from 'modules/endpoints'
import { createLocalizedValue } from 'helpers/localization'
import { CountryCode } from 'libphonenumber-js'
import useLangPicker from 'hooks/useLangPicker'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import useDownload from 'hooks/useDownload'
import { AlignWrapper } from 'views/components/AlignWrapper/AlignWrapper'
import CategoryRoutes from './routes'
import { Filter } from 'views/components/TableFilters/TableFilters'
import { CategoryHeader } from '../components/CategoryHeader'
import styled from 'styled-components'
import useDebouncedEffect from 'hooks/useDebouncedEffect'

const customSelectStyles: CustomSelectStyles = {
  control: (...args) => ({
    ...controlStyle(...args),
    height: '32px',
    minHeight: '32px',
    minWidth: '200px',
  }),
  menu: base => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
    width: 'auto',
  }),
  menuList: base => ({
    ...base,
    ...menuListStyle,
  }),
  option: base => ({
    ...base,
    ...optionStyle,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  }),
}

const SearchInputWrapper = styled.div`
  padding: 18px 24px;
  width: 50%;
`

const CategoryList: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation(['category', 'labels', 'common'])
  const [progress, data = []] = useCategoryList()
  const { pick } = useLangPicker()

  const filterUpdated = useAction(CategoryActions.filterUpdated)
  const filterValue = useSelector(CategorySelectors.filter)
  const total = useSelector(CategorySelectors.total)
  const page = useSelector(CategorySelectors.page)
  const pages = useSelector(CategorySelectors.pages)
  const pageSize = useSelector(CategorySelectors.pageSize)

  const [search, setSearch] = useState(filterValue.search || '')
  useDebouncedEffect(() => filterUpdated({ ...filterValue, search }), 100, [search])

  const listRequested = useAction(CategoryActions.listRequested)

  const { scrollRef } = usePersistentScroll('categoryList')

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const mappedData = useMemo(
    () =>
      data.map(item => {
        return {
          id: item.id,
          slug: item.slug,
          ...createLocalizedValue(lang => item.title_i18n[lang]),
          country: item.country,
          parentCategory: pick(item.parent?.title_i18n) || '',
        }
      }),
    [data, pick],
  )

  const allColumns = useMemo(
    () => [
      {
        Header: t('list.tableHeaders.id'),
        accessor: 'id' as const,
      },
      {
        Header: t('list.tableHeaders.country'),
        accessor: 'country' as const,
      },
      {
        Header: t('list.tableHeaders.slug'),
        accessor: 'slug' as const,
      },
      {
        Header: t('list.tableHeaders.parentCategory'),
        accessor: 'parentCategory' as const,
      },
      {
        Header: 'EN',
        accessor: 'en' as const,
      },

      {
        Header: 'RU',
        accessor: 'ru' as const,
      },

      {
        Header: 'FR',
        accessor: 'fr' as const,
      },
      {
        Header: 'ES',
        accessor: 'es' as const,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<{
    id: string
    country: CountryCode
    slug?: string
    parentCategory?: string
    en?: string
    ru?: string
    fr?: string
    es?: string
  }>({
    columns: allColumns,
    data: mappedData,
  })

  useHelmet({ title: t('categoriesMetaTitle') })
  const [downloadProgress, downloadCSV] = useDownload(endpoints.category('download/csv'))

  return (
    <>
      <Layout.Header>
        <CategoryHeader tab="category" />
        <SearchInputWrapper>
          <SearchInput onChange={setSearch} value={filterValue.search} placeholder={t('list.searchPlaceholder')} />
        </SearchInputWrapper>
      </Layout.Header>
      <Layout.Content>
        <TComponents.Wrapper>
          <TComponents.Filters>
            <TComponents.FiltersWrapper>
              <Filter title={t('list.seller')}>
                <CompanySelect
                  isSearchable
                  isClearable
                  filter={{ is_seller: true }}
                  customStyles={customSelectStyles}
                  placeholder={t('common:any')}
                  value={filterValue.seller_id?.[0]}
                  onChange={seller => {
                    filterUpdated({ ...filterValue, seller_id: seller ? [seller] : [] })
                  }}
                />
              </Filter>
            </TComponents.FiltersWrapper>
            <AlignWrapper horizontalAlign="right" verticalAlign="bottom">
              <Button onClick={downloadCSV} intent={'primary'} filled progress={downloadProgress}>
                {t('labels:downloadCsv')}
              </Button>
            </AlignWrapper>
          </TComponents.Filters>
          <Table
            total={total}
            pages={pages}
            pageSize={pageSize}
            currentPage={page}
            onSetPage={fetchNextItems}
            ref={scrollRef}
          >
            <TableHead>
              <TableHeadRow>
                {columns.map(column => (
                  <TableHeadCell key={column.id}>{column.render('Header')}</TableHeadCell>
                ))}
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {rows.map(row => {
                prepareRow(row)
                const { key, ...props } = row.getRowProps()
                return (
                  <TableBodyRow
                    key={key}
                    {...props}
                    onClick={() => {
                      history.push(generatePath(CategoryRoutes.CategoryEdit, { id: row.original.id }))
                    }}
                  >
                    {row.cells.map(cell => {
                      const { key, ...props } = cell.getCellProps()
                      return (
                        <TableBodyCell key={key} {...props}>
                          {cell.render('Cell')}
                        </TableBodyCell>
                      )
                    })}
                  </TableBodyRow>
                )
              })}
              <TableNoData
                progress={progress}
                isEmpty={!rows.length}
                colSpan={allColumns.length}
                loading={<TComponents.Spinner />}
              />
            </TableBody>
          </Table>
        </TComponents.Wrapper>
      </Layout.Content>
    </>
  )
}

export default CategoryList
