import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DistributorOrderFilterStatus } from 'types/distributorOrder'
import { ROLES } from 'types/entities'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { CheckboxTreeSelect, CheckboxTreeSelectProps } from '@agro-club/frontend-shared'
import {
  agroStatuses,
  producerStatuses,
  distributorStatuses,
} from 'views/components/DistributorOrderStatusSelect/DistributorOrderStatusSelect'

const MultiSelectWrapper = styled.div`
  width: 100%;
  display: flex;
`

const DistributorOrderStatusMultiSelect: React.FC<Omit<CheckboxTreeSelectProps, 'options'> & {}> = ({
  ...selectProps
}) => {
  const { t } = useTranslation(['distributorOrder'])
  const role = useSelector(AuthSelectors.role)
  const optionsByRole: DistributorOrderFilterStatus[] = useMemo(() => {
    let result: DistributorOrderFilterStatus[] = []
    switch (role) {
      case ROLES.AGRO_ADMIN:
        result = agroStatuses
        break
      case ROLES.PRODUCER_HEAD:
      case ROLES.PRODUCER_MANAGER:
        result = producerStatuses
        break
      case ROLES.DISTRIBUTOR_HEAD:
      case ROLES.DISTRIBUTOR_MANAGER:
        result = distributorStatuses
    }
    return result
  }, [role])

  const options = useMemo(() => {
    return [...optionsByRole.map(item => ({ id: item, title: t(`status.full.${item}`), hasChildren: false }))]
  }, [optionsByRole, t])

  return (
    <MultiSelectWrapper data-test-id="distributor-order-status-multi-select">
      <CheckboxTreeSelect {...selectProps} options={options} placeholder={t('status.any')} />
    </MultiSelectWrapper>
  )
}

export default DistributorOrderStatusMultiSelect
