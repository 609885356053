import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { AddButton, SearchInput, useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import ReturnDeclarationSkuList from './ReturnDeclarationSkuList/ReturnDeclarationSkuList'
import ReturnDeclarationSkuActions from 'modules/domain/returnDeclarationSku/duck'
import ReturnDeclarationSkuSelectors from 'modules/domain/returnDeclarationSku/selectors'
import { useSelector } from 'react-redux'
import ReturnDeclarationSkuRoutes from './routes'
import { useSeasonList } from 'modules/domain/season/hooks'
import { Progress } from 'modules/types'
import { SpinnerWrapper } from 'views/components/CommonTableComponents/CommonTableComponents'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { Actions, FeatureFlagModifiers, Sections } from 'types/entities'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateActionAccessString, generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import DistributorOrderSkuRoutes from '../DistributorOrderSku/routes'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'

const SearchInputWrapper = styled.div`
  padding: 18px 24px;
  width: 50%;
`

const ReturnDeclarationSkuTable: React.FC = () => {
  const push = useHistoryPush()
  const filter = useSelector(ReturnDeclarationSkuSelectors.filter)
  const filterUpdated = useAction(ReturnDeclarationSkuActions.filterUpdated)
  const { t } = useTranslation(['returnDeclaration', 'distributorOrder', 'common'])
  const checkFeatureFlag = useFeatureFlags()
  const isDeclarationCreateRestricted = checkFeatureFlag(
    generateActionAccessString(Sections.ReturnDeclarations, Actions.Create, FeatureFlagModifiers.Restricted),
  )
  const isAddOrderRestricted = checkFeatureFlag(
    generateCustomFeatureFlag(Sections.RetailerSkuOrders, 'restrictAddRetailerOrders'),
  )
  const [seasonsProgress] = useSeasonList()

  const handleSearchChange = React.useCallback(
    (search?: string) => {
      filterUpdated({ ...filter, ...{ search } })
    },
    [filterUpdated, filter],
  )

  const isOrderCreatePermitted =
    usePermissions({
      capability: CAPABILITY.DISTRIBUTOR_ORDERS_SKU,
      permission: PERMISSION.C,
    }) && !isAddOrderRestricted

  useHelmet({ title: t('returnDeclarationMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.TabsRoot>
          <Header.Tab
            title={`${t('distributorOrder:list.heading')} SKU`}
            onClick={() => push({ route: DistributorOrderSkuRoutes.List })}
          >
            {isOrderCreatePermitted && (
              <AddButton data-test-id="distributor-sku-add" to={DistributorOrderSkuRoutes.Add} />
            )}
          </Header.Tab>
          <Header.Tab
            isActive
            title={`${t('returnDeclaration:list.heading')} SKU`}
            onClick={() => push({ route: ReturnDeclarationSkuRoutes.List })}
          >
            {!isDeclarationCreateRestricted && <AddButton to={ReturnDeclarationSkuRoutes.Add} />}
          </Header.Tab>
        </Header.TabsRoot>
        <SearchInputWrapper>
          <SearchInput onChange={handleSearchChange} value={filter.search} placeholder={t('list.searchPlaceholder')} />
        </SearchInputWrapper>
      </Layout.Header>
      <Layout.Content>
        {seasonsProgress === Progress.SUCCESS ? (
          <ReturnDeclarationSkuList />
        ) : (
          <SpinnerWrapper>
            <SpinnerLayout />
          </SpinnerWrapper>
        )}
      </Layout.Content>
    </>
  )
}

export default ReturnDeclarationSkuTable
