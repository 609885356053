import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { FormFields } from 'views/pages/Farmer/FarmerDetailsForm/types'
import { equals, clone } from 'ramda'
import { PaperAgreementStatus, Partner } from 'modules/domain/farmer/types'
import * as Styled from 'views/pages/Farmer/FarmerDetailsForm/styled'
import { DatePicker, Input, FormComponents, Switch, TextArea, useAction, Spinner } from '@agro-club/frontend-shared'
import { Status } from 'types/entities'
import { CountryCode } from 'libphonenumber-js'
import { useSelector } from 'react-redux'
import FarmerSelectors from 'modules/domain/farmer/selectors'
import { Progress } from 'modules/types'
import { DocumentItem, DocumentItemStatus } from 'modules/domain/document/types'
import FarmerDocumentItem from 'views/components/FarmerDocumentItem/FarmerDocumentItem'
import DocumentActions from 'modules/domain/document/duck'
import FarmerDocumentPreviewModal from 'views/components/FarmerDocumentPreviewModal/FarmerDocumentPreviewModal'
import FarmerDocumentUploadModal from 'views/components/FarmerDocumentUploadModal/FarmerDocumentUploadModal'
import PhoneInput from 'views/components/PhoneInput/PhoneInput'
import { CountrySelect } from 'views/components/CountrySelect/CountrySelect'
import AuthSelectors from 'modules/domain/auth/selectors'
import FarmerAddressForm from 'views/components/FarmerAddressForm/FarmerAddressForm'
import useDateFormat from 'hooks/useDateFormat'
import { TimezoneSelect } from 'views/components/TimezoneSelect/TimezoneSelect'
import { DocumentSelect } from 'views/components/DocumentSelect/DocumentSelect'
import DocumentSelectors from 'modules/domain/document/selectors'
import { useUserDocumentsNoCache } from 'modules/domain/document/hooks'
import FarmStatusSelect from 'views/components/FarmStatusSelect/FarmStatusSelect'

const DocumentsForm: React.FC<{ userId: string }> = ({ userId }) => {
  const { t } = useTranslation('farmer')
  const formik = useFormikContext<FormFields>()

  const [previewModalState, setPreviewModalState] = useState<{
    isOpen: boolean
    documentId: string | null
    status: DocumentItemStatus
  }>({
    isOpen: false,
    documentId: null,
    status: null,
  })

  const [uploadModalState, setUploadModalState] = useState<{ isOpen: boolean; documentId: string | null }>({
    documentId: null,
    isOpen: false,
  })
  const [documentId, setDocumentId] = useState<string>()
  const printAction = useAction(DocumentActions.documentPrintRequested)
  const downloadAction = useAction(DocumentActions.documentDownloadRequested)
  const sendEmailAction = useAction(DocumentActions.signRequestToUserEmailRequested)

  const onUploadClick = (documentId: string) => {
    setUploadModalState({ isOpen: true, documentId })
  }

  const onPreviewClick = (documentId: string, status: DocumentItemStatus) => {
    setPreviewModalState({ isOpen: true, status, documentId })
  }

  const onDownloadClick = (documentId: string, documentStatus: DocumentItemStatus, fileName?: string) => {
    if (documentStatus === 'signed') {
      downloadAction({ documentId, userId, fileName })
    } else {
      downloadAction({ documentId, fileName })
    }
  }

  const onPrintClick = (documentId: string, documentStatus: DocumentItemStatus) => {
    if (documentStatus === 'signed') {
      printAction({ documentId, userId })
    } else {
      printAction({ documentId })
    }
  }

  const onSendEmailClick = (documentId: string) => {
    sendEmailAction(userId, documentId)
  }
  const [progress, documents, refetch] = useUserDocumentsNoCache(userId)

  const meta = useSelector(state => DocumentSelectors.meta(state, documentId || '', userId))

  useEffect(() => {
    if (meta.needSignRequestProgress === Progress.SUCCESS) refetch()
  }, [meta.needSignRequestProgress, refetch])

  if (meta.needSignRequestProgress === Progress.WORK || progress === Progress.WORK) {
    return <Spinner size={'small'} />
  }

  if (!documents?.length) {
    return (
      <Styled.DocumentsBlock>
        <DocumentSelect
          label={t('documents.emptyDocumentsList')}
          onChange={id => {
            formik.setFieldValue('document_id', id)
            setDocumentId(id)
          }}
        />
      </Styled.DocumentsBlock>
    )
  }

  return (
    <Styled.DocumentsBlock>
      {documents.map(doc => (
        <FarmerDocumentItem
          key={doc.id}
          documentId={doc.id}
          userId={userId}
          onUploadClick={onUploadClick}
          onSendEmailClick={onSendEmailClick}
          onDownloadClick={onDownloadClick}
          onPreviewClick={onPreviewClick}
          onPrintClick={onPrintClick}
        />
      ))}
      {previewModalState.documentId && previewModalState.isOpen && (
        <FarmerDocumentPreviewModal
          userId={userId}
          documentId={previewModalState.documentId}
          isOpen={previewModalState.isOpen}
          status={previewModalState.status}
          onClose={() => setPreviewModalState({ ...previewModalState, isOpen: false })}
        />
      )}
      {uploadModalState.documentId && uploadModalState.isOpen && (
        <FarmerDocumentUploadModal
          userId={userId}
          documentId={uploadModalState.documentId}
          isOpen={uploadModalState.isOpen}
          onClose={() => setUploadModalState({ ...uploadModalState, isOpen: false })}
        />
      )}
    </Styled.DocumentsBlock>
  )
}

type PropsType = {
  inOrderCreationMode?: boolean
  mode: 'edit' | 'create'
  userId?: string
  documents?: DocumentItem[]
  countryCodeFromPhone?: CountryCode
  isEmailOptional?: boolean
  handleChangeEmailOptional?: (value: boolean) => void
  onCountryCodeChange?: (value: CountryCode) => void
}

const FarmerForm: React.FC<PropsType> = ({
  mode,
  userId,
  inOrderCreationMode = false,
  countryCodeFromPhone,
  isEmailOptional,
  handleChangeEmailOptional,
  onCountryCodeChange,
}: PropsType) => {
  const { t } = useTranslation(['farmer', 'common', 'validation', 'farmerOrder'])
  const formik = useFormikContext<FormFields>()
  const [deliveryAddressSameAsMailing, setDeliveryAddressSameAsMailing] = useState(
    equals(formik.values.delivery_address, formik.values.legal_address),
  )

  const isAdmin = useSelector(AuthSelectors.isAdmin)

  const addErrorDetail = useSelector(FarmerSelectors.addErrorDetail)
  const updateErrorDetail = useSelector(FarmerSelectors.updateErrorDetail)
  const addProgress = useSelector(FarmerSelectors.addProgress)
  const updateProgress = useSelector(FarmerSelectors.updateProgress)

  useEffect(() => {
    if ([addProgress, updateProgress].includes(Progress.ERROR)) {
      if ([addErrorDetail, updateErrorDetail].includes('email_exists')) {
        formik.setFieldError('email', t('errors.emailTaken'))
      } else if ([addErrorDetail, updateErrorDetail].includes('phone_exists')) {
        formik.setFieldError('phone', t('errors.phoneTaken'))
      } else if ([addErrorDetail, updateErrorDetail].includes('additional_phone_exists')) {
        formik.setFieldError('additionalPhone', t('errors.phoneTaken'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addErrorDetail, updateErrorDetail, addProgress, updateProgress])

  const partners = formik.values?.partnership?.partners

  const partnersInitialStateItem = {
    first_name: '',
    last_name: '',
    phone_number: '',
    farm_name: '',
  }
  const handleAddPartnershipPerson = () => {
    formik.setFieldValue(
      'partnership.partners',
      partners ? [...partners, { ...partnersInitialStateItem }] : [{ ...partnersInitialStateItem }],
    )
  }

  const handleRemovePartnershipPerson = (index: number) => () => {
    if (!partners) return
    const newPartners = clone(partners)
    newPartners.splice(index, 1)
    if (!newPartners.length) {
      formik.setFieldValue('partnership.partners', undefined)
      return
    }
    formik.setFieldValue('partnership.partners', newPartners)
  }

  const handleChequePayeeNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('partnership.cheque_payee_name', e.target.value)
  }

  const handlePartnerInfoChange = (index: number, field: keyof Partner) => (value: string) => {
    let newPartners: Partner[] = []
    if (partners && partners.length) {
      newPartners = clone(partners)
    }

    if (newPartners[index]) {
      newPartners[index][field] = value
    } else {
      newPartners[index] = {
        [field]: value,
      }
    }

    formik.setFieldValue('partnership.partners', newPartners)
  }

  const handlePartnerTextInputChange = (index: number, field: keyof Partner) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    handlePartnerInfoChange(index, field)(e.target.value)
  }

  const handlePartnerPhoneInputChange = (index: number, field: keyof Partner) => (phone: string) => {
    handlePartnerInfoChange(index, field)(phone)
  }

  const deliveryAddressSameAsMailingChange = useCallback(() => {
    const newDeliveryAddressSameAsMailing = !deliveryAddressSameAsMailing
    if (newDeliveryAddressSameAsMailing) {
      formik.setFieldValue('delivery_address', formik.values.legal_address)
    }
    setDeliveryAddressSameAsMailing(newDeliveryAddressSameAsMailing)
  }, [deliveryAddressSameAsMailing, formik])

  const handlePaperAgreementChange = useCallback(
    value => {
      if (value) {
        formik.setFieldValue('paper_agreement', PaperAgreementStatus.Sent)
      } else {
        formik.setFieldValue('paper_agreement', PaperAgreementStatus.Needed)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const checkInValidPartnerItem = (item: string, i: number): boolean => {
    if (!formik.values.partnership?.['partners']?.length) {
      return true
    }

    if (formik.touched?.partnership?.['partners']?.[i]?.[item]) {
      return (
        formik.touched?.partnership?.['partners']?.[i]?.[item] && !!formik.errors.partnership?.['partners']?.[i]?.[item]
      )
    }
    return false
  }
  const dateFormat = useDateFormat({ isYearShort: true })

  const partnersJSX = partners?.length ? (
    <div data-test-id="farmer-partner">
      {partners.map((partner, i) => {
        return (
          <Styled.PartnerContainer key={i} data-test-id={`farmer-partner-${i + 1}`}>
            <Styled.PartnerBlock>
              <Input
                label={t('common:firstName')}
                name={'partnership-firstName'}
                value={partner.first_name || ''}
                onChange={handlePartnerTextInputChange(i, 'first_name')}
                onBlur={() => {
                  formik.setFieldTouched(`partnership.['partners'][${i}].first_name`, true)
                }}
                errorText={
                  formik.errors.partnership?.['partners']?.length &&
                  formik.errors.partnership?.['partners'][i]?.first_name
                }
                invalid={checkInValidPartnerItem('first_name', i)}
                required
              />
              <Input
                label={t('common:lastName')}
                name={'partnership-lastName'}
                value={partner.last_name || ''}
                onChange={handlePartnerTextInputChange(i, 'last_name')}
                onBlur={() => {
                  formik.setFieldTouched(`partnership.['partners'][${i}].last_name`, true)
                }}
                errorText={
                  formik.errors.partnership?.['partners']?.length &&
                  formik.errors.partnership?.['partners'][i]?.last_name
                }
                invalid={checkInValidPartnerItem('last_name', i)}
                required
              />
              <PhoneInput
                defaultCountryCode={countryCodeFromPhone}
                name={'partnership-phone'}
                label={t('common:phone')}
                onChange={handlePartnerPhoneInputChange(i, 'phone_number')}
                phoneNumber={partner.phone_number || ''}
                testId={`partner-phone-number-${i + 1}`}
                onBlur={() => {
                  formik.setFieldTouched(`partnership.['partners'][${i}].phone_number`, true)
                }}
                errorText={
                  formik.errors.partnership?.['partners']?.length &&
                  formik.errors.partnership?.['partners'][i]?.phone_number
                }
                invalid={checkInValidPartnerItem('phone_number', i)}
              />
              <Input
                label={t('form.labels.theirFarmName')}
                name={'partnership-farmName'}
                value={partner.farm_name || ''}
                onChange={handlePartnerTextInputChange(i, 'farm_name')}
              />
            </Styled.PartnerBlock>
            <Styled.RemoveBtn onClick={handleRemovePartnershipPerson(i)} data-test-id={`btn-remove-partner-${i + 1}`} />
          </Styled.PartnerContainer>
        )
      })}
    </div>
  ) : null

  const addPartnerJSX = partners?.length ? (
    <Styled.PartnershipButton type={'button'} onClick={handleAddPartnershipPerson} data-test-id="add-another-partner">
      {t('form.addPartner')}
    </Styled.PartnershipButton>
  ) : (
    <Styled.PartnershipButton type={'button'} onClick={handleAddPartnershipPerson} data-test-id="add-first-partner">
      {t('form.addFirstPartner')}
    </Styled.PartnershipButton>
  )
  return (
    <>
      <FormComponents.FormSection title={t('form.personaData')}>
        <Styled.FarmerPersonalDataWrapper>
          <Styled.CountryArea>
            <CountrySelect
              value={formik.values.countryCode}
              onChange={countryCode => formik.setFieldValue('countryCode', countryCode)}
              label={t('form.country')}
              invalid={formik.touched.countryCode && !!formik.errors.countryCode}
              errorText={formik.errors.countryCode}
            />
          </Styled.CountryArea>
          <Styled.NameArea>
            <Input
              label={t('common:firstName')}
              {...formik.getFieldProps('firstName')}
              invalid={formik.touched.firstName && !!formik.errors.firstName}
              errorText={formik.errors.firstName}
              required
            />
          </Styled.NameArea>
          <Styled.LastNameArea>
            <Input
              label={t('common:lastName')}
              {...formik.getFieldProps('lastName')}
              invalid={formik.touched.lastName && !!formik.errors.lastName}
              errorText={formik.errors.lastName}
              required
            />
          </Styled.LastNameArea>
          <Styled.MiddleNameArea>
            <Input label={t('common:farmName')} {...formik.getFieldProps('farmName')} />
          </Styled.MiddleNameArea>
          <Styled.PhoneNumberArea>
            <PhoneInput
              name={'phone'}
              label={t('common:phone')}
              invalid={formik.touched.phone && !!formik.errors.phone}
              errorText={formik.errors.phone}
              phoneNumber={formik.values.phone}
              onChange={(phone, _, __, ___, countryCode: CountryCode) => {
                onCountryCodeChange?.(countryCode)
                formik.setFieldValue('phone', phone)
              }}
              onBlur={() => {
                formik.setFieldTouched('phone', true)
              }}
              disabled={inOrderCreationMode}
              testId={'farmer-phone-number'}
              required
            />
          </Styled.PhoneNumberArea>
          <Styled.AdditionalPhoneNumberArea>
            <PhoneInput
              name={'additionalPhone'}
              label={t('common:additionalPhone')}
              invalid={formik.touched.additionalPhone && !!formik.errors.additionalPhone}
              errorText={formik.errors.additionalPhone}
              phoneNumber={formik.values.additionalPhone || ''}
              onChange={additionalPhone => {
                formik.setFieldValue('additionalPhone', additionalPhone)
              }}
              onBlur={() => {
                formik.setFieldTouched('additionalPhone', true)
              }}
            />
          </Styled.AdditionalPhoneNumberArea>
          {inOrderCreationMode ? (
            <Styled.EmailArea2>
              <Input
                label={t('common:email')}
                {...formik.getFieldProps('email')}
                invalid={formik.touched.email && !!formik.errors.email}
                required={!isEmailOptional}
                errorText={formik.errors.email}
              />
              <Styled.Hint data-test-id={'email-hint'}>{t('farmerOrder:form.emailHint')}</Styled.Hint>
              {isAdmin && handleChangeEmailOptional && (
                <Styled.EmailSwitchArea>
                  <Switch
                    on={!!formik.values.does_not_have_email}
                    onClick={handleChangeEmailOptional}
                    label={t('farmerOrder:form.labels.dontHaveEmail')}
                  />
                </Styled.EmailSwitchArea>
              )}
            </Styled.EmailArea2>
          ) : (
            <Styled.EmailArea>
              <Input
                label={t('common:email')}
                {...formik.getFieldProps('email')}
                invalid={formik.touched.email && !!formik.errors.email}
                errorText={formik.errors.email}
              />
            </Styled.EmailArea>
          )}
          {!inOrderCreationMode && (
            <Styled.CheckPayeeNameArea>
              <Input
                label={t('form.labels.chequePayeeName')}
                value={formik.values.partnership?.cheque_payee_name || ''}
                onChange={handleChequePayeeNameChange}
                data-test-id={'cheque-payee-name'}
              />
            </Styled.CheckPayeeNameArea>
          )}
          {!inOrderCreationMode && (
            <Styled.CanterraIdArea>
              <Input
                label={t('form.labels.canterraId')}
                {...formik.getFieldProps('canterraId')}
                invalid={formik.touched.canterraId && !!formik.errors.canterraId}
                errorText={formik.errors.canterraId}
                data-test-id={'canterra-id'}
              />
            </Styled.CanterraIdArea>
          )}
          <TimezoneSelect
            label={t('form.labels.timezone')}
            placeholder={''}
            value={formik.values.timezone || ''}
            onChange={newValue => formik.setFieldValue('timezone', newValue)}
          />
        </Styled.FarmerPersonalDataWrapper>
      </FormComponents.FormSection>
      <FormComponents.FormSection title={t('form.mailingAddress')}>
        <FarmerAddressForm field={'legal_address'} spy={deliveryAddressSameAsMailing} />
      </FormComponents.FormSection>
      <FormComponents.FormSection title={t('form.deliveryAddress')}>
        <Switch
          on={!!formik.getFieldProps('selfPickup').value}
          name={'self-pickup-switch'}
          onClick={value => {
            formik.setFieldValue('selfPickup', value)
          }}
          label={t('form.selfPickup')}
        />
        <Styled.Checkbox
          onChange={deliveryAddressSameAsMailingChange}
          isChecked={deliveryAddressSameAsMailing}
          label={t('form.labels.sameAsMailing')}
        />
        <FarmerAddressForm field={'delivery_address'} disabled={deliveryAddressSameAsMailing} />
      </FormComponents.FormSection>
      <FormComponents.FormSection title={t('form.partnership')}>
        <Styled.PartnershipBlock>
          {partnersJSX}
          {addPartnerJSX}
        </Styled.PartnershipBlock>
      </FormComponents.FormSection>
      {!inOrderCreationMode ? (
        <FormComponents.FormSection title={t('form.CSUA')}>
          <Styled.CSUAContainer>
            <Input {...formik.getFieldProps('csua_info.csua')} label={t('form.labels.CSUA.number')} />
            <Input {...formik.getFieldProps('csua_info.form')} label={t('form.labels.CSUA.formNumber')} />
            <DatePicker
              {...formik.getFieldProps('csua_info.date')}
              date={formik.values.csua_info?.date}
              onChange={(_, date) => {
                if (date) {
                  const fix = (v: number) => (v < 10 ? `0${v}` : v)
                  const y = date.getFullYear()
                  const m = date.getMonth() + 1
                  const d = date.getDate()
                  const hh = date.getHours()
                  const mm = date.getMinutes()
                  const ss = date.getSeconds()
                  const str = `${y}-${fix(m)}-${fix(d)}T${fix(hh)}:${fix(mm)}:${fix(ss)}.000Z`
                  formik.setFieldValue('csua_info.date', str)
                } else {
                  formik.setFieldValue('csua_info.date', '')
                }
              }}
              label={t('form.labels.CSUA.date')}
              format={dateFormat}
              testId={'csua-date'}
            />
            <Input {...formik.getFieldProps('csua_info.batch')} label={t('form.labels.CSUA.batchNumber')} />
          </Styled.CSUAContainer>
        </FormComponents.FormSection>
      ) : null}
      {mode === 'edit' && userId && (
        <FormComponents.FormSection title={t('form.documents')}>
          <DocumentsForm userId={userId} />
        </FormComponents.FormSection>
      )}
      {isAdmin && !inOrderCreationMode && (
        <FormComponents.FormSection title={t('form.comment')}>
          <Styled.CommentBlock>
            <TextArea {...formik.getFieldProps('comment')} limit={1000} />
          </Styled.CommentBlock>
        </FormComponents.FormSection>
      )}
      {!inOrderCreationMode ? (
        <FormComponents.FormSection title={t('form.other')}>
          <FarmStatusSelect
            {...formik.getFieldProps('farm_status')}
            onChange={e => formik.setFieldValue('farm_status', e)}
            placeholder={t('form.placeholders.farmStatusSelect')}
            label={t('form.labels.farmStatusSelect')}
          />
          <Styled.FarmerOtherDataWrapper>
            {formik.values.paper_agreement !== PaperAgreementStatus.NotNeeded ? (
              <Styled.Switch
                on={formik.values.paper_agreement === PaperAgreementStatus.Sent}
                onClick={handlePaperAgreementChange}
                label={t('form.labels.argeementSent')}
              />
            ) : null}
            <Switch
              on={formik.getFieldProps('status').value === Status.Active}
              name={'status-switch'}
              onClick={value => {
                formik.setFieldValue('status', value ? Status.Active : Status.Inactive)
              }}
              label={t('form.status')}
            />
          </Styled.FarmerOtherDataWrapper>
        </FormComponents.FormSection>
      ) : null}
    </>
  )
}

export default FarmerForm
