import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { StyledProps } from 'styled-components'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { useTranslation } from 'react-i18next'
import {
  Button,
  DatePicker,
  FormComponents,
  helpersObject,
  SectionBody,
  SectionContainer,
  SectionTitle,
  TextArea,
  TextAreaWithTags,
  Radio,
  RadioItem,
  Checkbox,
  Snackbar,
  useAction,
} from '@agro-club/frontend-shared'
import { FarmerOrder, FarmerOrderItem, FarmerOrderStatus } from 'types/farmerOrder'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Progress } from 'modules/types'
import { clone, remove } from 'ramda'
import { FormFields } from './types'
import { CAPABILITY, PERMISSION, useOrderUpdatePermissions, usePermissions } from 'modules/permissions/permissions'
import OrderProductItems from 'views/components/OrderProductItems/OrderProductItems'
import { Product } from 'modules/domain/product/types'
import CustomerInfo from '../CustomerModal/CustomerInfo'
import { generatePath } from 'react-router-dom'
import OrderStatusBlock from 'views/components/FarmerOrderDetailsForm/OrderStatusBlock'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import { DistributorConfirmationStatus } from 'modules/domain/farmerOrder/types'
import PromocodeField from 'views/components/Promocode/PromocodeField'
import { OrderDiscounts } from './OrderDiscountsBlock'
import { Company } from 'modules/domain/company/types'
import OrderProductItemsReadOnly from 'views/components/OrderProductItems/OrderProductItemsReadOnly'
import ControlButtons from './ControlButtons'
import CompanyInfoPreview from '../CompanyInfoPreview/CompanyInfoPreview'
import { CompanyType, FeatureFlagModifiers, isAgro, CustomFeatureName, Sections, ROLES, Actions } from 'types/entities'
import FarmerAddressForm from './FarmerAddressForm'
import CustomerModal from '../CustomerModal/CustomerModal'
import { FarmerData } from 'modules/domain/farmer/types'
import { UpdateError } from 'modules/domain/types'
import {
  isPromocodeWithComment,
  isPromocodeWithLegalText,
  Promocode,
  PromocodeWithComment,
} from 'modules/domain/promocode/types'
import FarmerRoutes from 'views/pages/Farmer/routes'
import CompanyRoutes from 'views/pages/Company/routes'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import OrderDocuments from './OrderDocuments/OrderDocuments'
import { useUserDocumentsNoCache } from 'modules/domain/document/hooks'
import * as uuid from 'uuid'
import { DEFAULT_COUNTRY } from 'modules/constants'
import CancelSwitch from 'views/components/FarmerOrderDetailsForm/CancelSwitch'
import { useSeasonList } from 'modules/domain/season/hooks'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import {
  generateActionAccessString,
  generateCrmFieldAccessString,
  generateCrmSectionAccessString,
} from 'modules/utils/generateStringHelpers'
import { FarmersSelect } from 'views/components/FarmersSelect/FarmersSelect'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import { getProducersList } from 'modules/domain/company/managers'
import FileManager from 'views/components/FileManager/FileManager'
import { FileItem } from 'views/components/FileManager/types'
import { generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import useHasOutOfStockDateExpired from 'hooks/useHasOutOfStockDateExpired'
import checkCompanyFeatureFlags from 'helpers/checkCompanyFeatureFlags'
import { DiscountRuleScope } from 'modules/domain/discountRule/types'
import useTotalSum from 'hooks/useTotalSum'
import { useCalculatedDiscounts } from 'modules/domain/discountRule/hooks'
import OrderHistoryCard from '../OrderHistoryCard/OrderHistoryCard'
import useDateFormat from 'hooks/useDateFormat'
import { CompanySelect } from '../CompanySelect/CompanySelect'
import RetailerAllocationActions from 'modules/domain/allocation/retailerAllocation/duck'
import ModalActions from 'modules/domain/modal/duck'
import { ModalWithButtonContent } from '../ModalWithButtons/ModalWithButtonsContent'
import { SeasonSelect } from '../SeasonSelect/SeasonSelect'
import { WizardData } from '@agro-club/frontend-shared'

const useProducersOptions = makeCancellableResourceListHook(makeCancelable(getProducersList))

const Wrapper = styled.div<StyledProps<{ language?: string }>>`
  position: relative;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto auto;
`

const Overlay = styled.div<StyledProps<{ isUpdatePermitted?: boolean }>>`
  ${({ isUpdatePermitted }) => {
    return (
      !isUpdatePermitted && {
        width: '100%',
        height: '100%',
        cursor: 'not-allowed',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
      }
    )
  }}
`

const Column = styled.div`
  min-width: 354px;
  width: fit-content;
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
`

const ErrorStyled = styled.div<StyledProps<{}>>`
  color: ${props => props.theme.color.accentDestructive};
  margin-top: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  bottom: -20px;
`

const FarmerBlock = styled.div`
  position: relative;
`

const DatePickerWrapper = styled.div`
  width: 220px;
`

const Label = styled.span`
  font-size: 14px;
  flex-grow: 1;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
`

const getDesiredDeliveryDateEnabled = (producer?: Company | null): boolean => {
  if (!producer) {
    return false
  }
  const deliveryDatesEnabledFlag = generateCrmFieldAccessString(
    Sections.Orders,
    'desiredDeliveryDates',
    FeatureFlagModifiers.Enabled,
  )
  return checkCompanyFeatureFlags(producer, deliveryDatesEnabledFlag)
}

type PromocodeListProps = {
  promocodes: Promocode[]
  onRemove: (id: string) => void
  onCommentChange: (code: string, comment: string) => void
  selected?: string
  onSelect: (code: string) => void
}

const PromocodeList: React.FC<PromocodeListProps> = ({ promocodes, onRemove, onCommentChange, selected, onSelect }) => {
  const handleRemove = (id: string) => {
    onRemove(id)
    if (id === selectedPromocode) {
      onSelect('')
    }
  }

  const items = promocodes.map(item => {
    let placeholder
    let value
    let readOnly = false
    let editable: boolean | undefined = false
    let selectable: boolean | undefined = false

    if (isPromocodeWithComment(item)) {
      placeholder = item.params.prompt || undefined
      editable = !!item.params.prompt
      value = item.comment || undefined
      selectable = true
    }

    if (isPromocodeWithLegalText(item)) {
      value = item.params.legal_text || undefined
      readOnly = true
      selectable = true
    }
    return {
      id: item.code,
      title: item.code,
      placeholder,
      editable,
      value,
      selectable,
      readonly: readOnly,
    }
  })

  // select first editable, readonly or selected
  const selectedPromocode =
    selected ||
    promocodes.find(item => {
      if (isPromocodeWithComment(item)) {
        return !!item.params.prompt
      }
      if (isPromocodeWithLegalText(item)) {
        return !!item.params.legal_text
      }
    })?.code

  return (
    <div data-test-id={'promocode-textarea'}>
      <TextAreaWithTags
        items={items}
        selected={selectedPromocode}
        onTextChange={onCommentChange}
        onSelectionChange={onSelect}
        onRemove={handleRemove}
      />
    </div>
  )
}

type Props = {
  mode: 'create' | 'edit'
  initialValues: FormFields
  order?: FarmerOrder
  onSubmit(
    values: FormFields,
    options: { dirty: boolean; duplicate: boolean; isAddRetailerOrderSuggestionEnabled: boolean },
  ): void
  onCancel(): void
  onDistributorConfirmation?: (
    status: DistributorConfirmationStatus,
    isAddRetailerOrderSuggestionEnabled: boolean,
  ) => void
  onDistributorCancellation?: (role: ROLES) => void
  onRemove?: () => void
  onRestore?: () => void
  onRefresh?: (isAddRetailerOrderSuggestionEnabled: boolean) => void
  progress?: Progress
  removeProgress?: Progress
  updateError?: UpdateError | 'unknown' | null
  totalNet?: string
}

enum FarmerSelectType {
  Modal = 'modal',
  Select = 'select',
}

const FarmerOrderDetailsForm: React.FC<Props> = ({
  mode,
  order,
  initialValues,
  onRemove,
  onRestore,
  onDistributorConfirmation,
  onDistributorCancellation,
  onCancel,
  onSubmit,
  onRefresh,
  removeProgress,
  progress,
  updateError,
  totalNet,
}) => {
  const { t } = useTranslation(['farmerOrder', 'common', 'company', 'labels'])
  const [usersPermitted] = usePermissions([{ capability: CAPABILITY.USERS, permission: PERMISSION.R }])
  const updatePermissions = useOrderUpdatePermissions()
  const userCompany = useSelector(AuthSelectors.userCompany)
  const role = useSelector(AuthSelectors.role)
  const checkFeatureFlag = useFeatureFlags()

  const initialRender = useRef(true)
  const [customer, setCustomer] = useState<FarmerData | null | undefined>(order?.customer)
  const [showCustomerModal, setShowCustomerModal] = useState(false)
  const [selectedPromocode, setSelectedPromocode] = useState<string>()
  const [selectedDocument, setSelectedDocument] = useState<string>()
  const profile = useSelector(AuthSelectors.profile)
  const country = profile?.country || DEFAULT_COUNTRY
  const [farmerSelectType, setFarmerSelectType] = useState<FarmerSelectType>(FarmerSelectType.Select)
  const [, producers = []] = useProducersOptions()
  const [optionsRequiredErrors, setOptionsRequiredErrors] = useState<string[][]>([])

  const [isProductQtyMoreThanLimitAfterDuplicate, setIsProductQtyMoreThanLimitAfterDuplicate] = useState(false)
  const [isProductQtyMoreThanLimit, setIsProductQtyMoreThanLimit] = useState(false)
  const clearProductsLimitsForFarmerOrders = useAction(RetailerAllocationActions.clearProductsLimitsForFarmerOrders)

  const isAdmin = !!role && [ROLES.AGRO_ADMIN, ROLES.AGRO_MANAGER].includes(role)
  const isProducer = !!role && [ROLES.PRODUCER_HEAD, ROLES.PRODUCER_MANAGER].includes(role)
  const isDistributor = !!role && [ROLES.DISTRIBUTOR_HEAD, ROLES.DISTRIBUTOR_MANAGER].includes(role)
  const isOrderConfirmedByDistributor = order?.interaction.confirmed_by_distributor === 'confirmed'

  const isPromocodeDisabled =
    mode === 'edit' && (!updatePermissions.promocode || (isDistributor && !isOrderConfirmedByDistributor))

  const isCommentDisabled =
    mode === 'edit' && (!updatePermissions.comment || (isDistributor && !isOrderConfirmedByDistributor))

  const isTimeHidden = checkFeatureFlag(generateCustomFeatureFlag(Sections.Orders, CustomFeatureName.WithoutTime))
  const dateFormat = useDateFormat({ withTime: !isTimeHidden })

  const producerConfirmationEnabledFeatureFlag = generateCrmFieldAccessString(
    'farmerOrders',
    'confirmedByManufacturer',
    FeatureFlagModifiers.Enabled,
  )
  const isHistoryCardVisible = checkFeatureFlag(generateCustomFeatureFlag(Sections.FarmerOrders, 'historyCardVisible'))
  const isManufacturerConfirmationEnabled = checkFeatureFlag(producerConfirmationEnabledFeatureFlag)

  const farmerConfirmRequired = generateCustomFeatureFlag(Sections.Orders, CustomFeatureName.FarmerConfirmRequired)
  const isFarmerConfirmationEnabled = checkFeatureFlag(farmerConfirmRequired)

  const [, seasons] = useSeasonList()

  const openModal = useAction(ModalActions.open)

  const isUpdatePermitted = !checkFeatureFlag(
    generateActionAccessString(Sections.FarmerOrders, Actions.Update, FeatureFlagModifiers.Restricted),
  )

  const showComment =
    isAdmin ||
    checkFeatureFlag(
      generateCrmFieldAccessString(Sections.FarmerOrders, 'productComment', FeatureFlagModifiers.Enabled),
    )

  const isSeasonClosed = useMemo(() => seasons?.find(season => season.id === order?.season_id)?.is_season_closed, [
    order?.season_id,
    seasons,
  ])

  const isEditable = !order || (isSeasonClosed ? isAdmin : true)

  const hideFarmerInfoChecking = useMemo(() => {
    if (userCompany?.company_type !== CompanyType.Distributor) {
      return false
    }

    const flag = generateCrmFieldAccessString(
      Sections.FarmerOrders,
      'farmerInfoForRetailer',
      FeatureFlagModifiers.Hidden,
    )

    return producers.some(producer => checkCompanyFeatureFlags(producer, flag, role || undefined))
  }, [producers, userCompany, role])

  const currentDate = useMemo(() => {
    return new Date().toISOString()
  }, [])

  const formInitialValues = {
    producer_id: initialValues.producer_id || undefined,
    distributor_id: initialValues.distributor_id || undefined,
    status: initialValues.status,
    delivery_date: initialValues.delivery_date || '',
    desired_delivery_date: initialValues.desired_delivery_date || '',
    address: initialValues.address || '',
    city: initialValues.city || '',
    region_id: initialValues.region_id || '',
    country: initialValues.country || country,
    postal_code: initialValues.postal_code || '',
    comment: initialValues.comment || '',
    items: initialValues.items || [],
    owner_id: initialValues.owner_id || '',
    interaction: {
      confirmed_by_agroclub: initialValues.interaction.confirmed_by_agroclub || false,
      confirmed_by_producer: initialValues.interaction.confirmed_by_producer || false,
      confirmed_by_distributor: initialValues.interaction.confirmed_by_distributor || 'unset',
      confirmed_by_farmer: initialValues.interaction.confirmed_by_farmer || false,
      canceled_by_role: initialValues.interaction.canceled_by_role || null,
      credit_offer_accepted: initialValues.interaction.credit_offer_accepted || false,
    },
    promocodes: initialValues.promocodes,
    revision: initialValues.revision,
    order_date: initialValues.order_date || currentDate,
    season_id: initialValues.season_id,
    files: initialValues.files,
    farmer_comment: initialValues.farmer_comment,
  }

  const validationSchema = useMemo(() => {
    return Yup.object({
      producer_id: Yup.string().required(t('validation:field_required')),
      owner_id: Yup.string().required(t('validation:farmerRequired')),
      status: Yup.string().required(t('validation:field_required')),
      items: Yup.array().min(1, t('validation:oneProductMinRequired')),
      order_date: Yup.string().required(t('validation:field_required')),
      season_id: Yup.string().required(),
    })
  }, [t])

  const formik = useFormik<FormFields>({
    initialValues: { ...formInitialValues },
    validationSchema,
    enableReinitialize: true,
    // used custom submit handler
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  const selectedProducer = useMemo(() => producers?.find(p => p.id === formik.values.producer_id), [
    formik.values.producer_id,
    producers,
  ])

  const isDesiredDeliveryDateEnabled = useMemo(() => {
    return selectedProducer ? getDesiredDeliveryDateEnabled(selectedProducer) : false
  }, [selectedProducer])

  const isAddRetailerOrderSuggestionEnabled = useMemo(() => {
    const selectedSeason = (seasons ?? []).find(s => s.id === formik.values.season_id)
    return !!selectedSeason?.show_retailer_order_suggestion
  }, [formik.values.season_id, seasons])

  const isRetailerInteractionByProducerEnabled = useMemo(() => {
    return checkCompanyFeatureFlags(
      selectedProducer,
      generateCustomFeatureFlag(Sections.FarmerOrders, CustomFeatureName.RetailerInteractionByProducer),
      role || undefined,
    )
  }, [selectedProducer, role])

  const [, userDocuments = []] = useUserDocumentsNoCache(formik.values.owner_id)
  const editingDateHasExpired = useHasOutOfStockDateExpired(formik.values.items)

  const [productQtyIsNullError, setProductQtyIsNullError] = useState(false)

  useEffect(() => {
    if (userCompany && userCompany.company_type === CompanyType.Distributor && !formik.values.distributor_id) {
      formik.setFieldValue('distributor_id', userCompany.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, userCompany])

  useEffect(() => {
    if (
      userCompany &&
      userCompany.company_type === CompanyType.Distributor &&
      userCompany.producers_relations.length === 1 &&
      !formik.values.producer_id
    ) {
      formik.setFieldValue('producer_id', userCompany.producers_relations[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, userCompany])

  const handleProductsChange = useCallback(
    (index, qty, product?: Omit<Product, 'options'>, wizard_comment?: string, wizard_data?: WizardData<any>) => {
      const items = clone(formik.values.items)
      if (product) {
        const newItem: FarmerOrderItem = {
          product_id: product.id,
          quantity: qty,
          product: product as Product,
          key: uuid.v4(),
          wizard_comment,
          wizard_data,
        }
        const seedTreatment = product.seed_treatment ? product.seed_treatment.filter(s => s.is_active) : []
        if (seedTreatment && seedTreatment.length) {
          newItem.seed_treatment_id = seedTreatment[0].id
        }
        items.unshift(newItem)
      } else {
        items[index].quantity = qty
      }
      formik.setFieldValue(`items`, items)
      if (productQtyIsNullError && items.find(item => item.quantity)) {
        setProductQtyIsNullError(false)
      }
    },
    [formik, productQtyIsNullError],
  )

  const handleProductRemove = useCallback(
    (index: number) => {
      if (formik.values.items[index]) {
        formik.setFieldValue('items', remove(index, 1, formik.values.items))
      }
    },
    [formik],
  )

  const handleSeedTreatmentChange = useCallback(
    (idx, value) => {
      const items = clone(formik.values.items)
      if (idx !== -1) {
        items[idx].seed_treatment_id = value
      }
      formik.setFieldValue(`items`, items)
    },
    [formik],
  )

  const handleProductOptionsChange = useCallback(
    (idx, options) => {
      const items = clone(formik.values.items)
      if (idx !== -1) {
        items[idx].options = options
      }
      formik.setFieldValue(`items`, items)
    },
    [formik],
  )

  const handleFinalQtyChange = useCallback(
    (idx, value) => {
      const items = clone(formik.values.items)
      if (idx !== -1) {
        items[idx].final_qty = value
      }
      formik.setFieldValue(`items`, items)
    },
    [formik],
  )

  const handleDeliveredQtyChange = useCallback(
    (idx, value) => {
      const items = clone(formik.values.items)
      if (idx !== -1) {
        items[idx].delivered_qty = value
      }
      formik.setFieldValue(`items`, items)
    },
    [formik],
  )

  const handleAltPackagingChange = useCallback(
    (idx, value) => {
      const items = clone(formik.values.items)
      if (idx !== -1) {
        items[idx].packaging = value
      }
      formik.setFieldValue(`items`, items)
    },
    [formik],
  )

  const handleCommentChange = useCallback(
    (idx, value) => {
      const items = clone(formik.values.items)
      if (idx !== -1) {
        items[idx].comment = value
      }
      formik.setFieldValue(`items`, items)
    },
    [formik],
  )

  const handleCreditOfferAcceptedChange = useCallback(
    isAccepted => {
      formik.setFieldValue('interaction.credit_offer_accepted', isAccepted)
    },
    [formik],
  )

  const handlePromocodeApply = useCallback(
    (code: string, promocode: Promocode) => {
      const promocodes = clone(formik.values.promocodes)
      promocodes.push({ ...promocode, code })
      formik.setFieldValue('promocodes', promocodes)

      if (isPromocodeWithComment(promocode) || isPromocodeWithLegalText(promocode)) {
        setSelectedPromocode(code)
      }
    },
    [formik],
  )

  const handlePromocodeRemove = useCallback(
    code => {
      const promocodes = clone(formik.values.promocodes)
      const index = promocodes.findIndex(item => item.code === code)
      if (index !== -1) {
        promocodes.splice(index, 1)
      }
      formik.setFieldValue('promocodes', promocodes)
    },
    [formik],
  )

  const handlePromocodeCommentChange = useCallback(
    (code, comment) => {
      const promocodes = clone(formik.values.promocodes)
      const index = promocodes.findIndex(item => item.code === code)
      if (index !== -1) {
        const editedPromocode = promocodes[index] as PromocodeWithComment
        editedPromocode.comment = comment
      }
      formik.setFieldValue('promocodes', promocodes)
    },
    [formik],
  )

  const onProducerChange = useCallback(
    id => {
      const distributorId = formik.values.distributor_id
      formik.resetForm()
      if (!updatePermissions.distributorSelection) {
        formik.setFieldValue('distributor_id', distributorId)
      }
      if (formik.values.producer_id !== id) {
        formik.setFieldValue('items', [])
        formik.setFieldTouched('items', false)
      }

      formik.setFieldValue('producer_id', id)
      const producerCurrentSeason = (seasons ?? []).filter(s => s.company_id === id).find(s => s.is_current)
      formik.setFieldValue('season_id', producerCurrentSeason?.id)
      if (!initialRender.current) {
        formik.setFieldValue('promocode', undefined)
      } else {
        initialRender.current = false
      }
    },
    [formik, seasons, updatePermissions.distributorSelection],
  )

  const handleSeasonChange = (val: string) => {
    formik.setFieldValue('season_id', val)
    formik.setFieldValue('items', [])
    formik.setFieldTouched('items', false)
    clearProductsLimitsForFarmerOrders()
  }

  const handleRefresh = useCallback(() => {
    formik.resetForm()
    onRefresh && onRefresh(isAddRetailerOrderSuggestionEnabled)
  }, [formik, onRefresh, isAddRetailerOrderSuggestionEnabled])

  const onCustomerChange = useCallback(
    (id, customer) => {
      if (customer) {
        formik.setValues({
          ...formik.values,
          owner_id: id,
          delivery_date: customer.delivery_address?.delivery_date || customer.legal_address?.delivery_date || '',
          desired_delivery_date:
            customer.desired_delivery_date?.desired_delivery_date ||
            customer.legal_address?.desired_delivery_date ||
            '',
          address: customer.delivery_address?.address || customer.legal_address?.address || '',
          country: customer.delivery_address?.country || customer.legal_address?.country || country,
          city: customer.delivery_address?.city || customer.legal_address?.city || '',
          region_id: customer.delivery_address?.region_id || customer.legal_address?.region_id || '',
          postal_code: customer.delivery_address?.postal_code || customer.legal_address?.postal_code || '',
        })
        setCustomer(customer)
      } else {
        formik.setFieldValue('owner_id', id)
      }
    },
    [formik, country],
  )

  const requiredOptionsValid = useMemo(() => {
    const items = clone(formik.values.items)
    const errors: string[][] = []
    let isValid = true

    items.forEach((item: FarmerOrderItem) => {
      const opts = item.product?.options
      const foundedErrors: string[] = []
      if (!opts || !opts.length) return
      const options = item.options || []

      opts.forEach(o => {
        const found = o.option_ids.filter(x => options.includes(x))
        if (o.required && !found.length) {
          foundedErrors.push(o.type)
          isValid = false
        }
      })

      errors.push(foundedErrors)
    })

    setOptionsRequiredErrors(errors)
    return isValid
  }, [formik.values.items])

  const submit = async (duplicate = false) => {
    try {
      await formik.submitForm()

      const formInvalid = !formik.isValid
      const requiredOptionsInvalid = !requiredOptionsValid

      if (formInvalid || requiredOptionsInvalid) {
        return
      }
      // API doesn't accept empty date so we cut it
      const values = { ...formik.values, items: formik.values.items.filter(item => item.quantity) }

      if (!values.items.length) {
        setProductQtyIsNullError(true)
        return
      }

      if (!values.delivery_date) {
        delete values.delivery_date
      }
      if (!values.desired_delivery_date) {
        delete values.desired_delivery_date
      }
      onSubmit(values, {
        dirty: formik.dirty,
        duplicate,
        isAddRetailerOrderSuggestionEnabled,
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const handleSubmit = () => {
    submit(false)
  }

  const handleDuplicate = () => {
    submit(true)
  }

  const handleRemove = useCallback(() => {
    onRemove?.()
  }, [onRemove])

  const handleRestore = useCallback(() => {
    onRestore?.()
  }, [onRestore])

  const handleRequiredDocumentClick = (id: string) => {
    setSelectedDocument(id)
    setTimeout(() => {
      setSelectedDocument('')
    }, 3000)
  }

  const farmerLink =
    usersPermitted && order?.customer ? generatePath(FarmerRoutes.Edit, { id: order.customer.id }) : undefined

  const handleStatusChange = useCallback(
    (field: string, value: unknown) => {
      if (field === 'canceled_by_role') {
        if (value !== null) {
          formik.setFieldValue('status', FarmerOrderStatus.Canceled)
        } else if (
          order?.interaction.confirmed_by_agroclub &&
          order?.interaction.confirmed_by_producer &&
          order?.interaction.confirmed_by_distributor === FarmerOrderStatus.Confirmed
        ) {
          formik.setFieldValue('status', FarmerOrderStatus.Confirmed)
        } else {
          formik.setFieldValue('status', FarmerOrderStatus.New)
        }
      }
      formik.setFieldValue(`interaction.${field}`, value)
    },
    [formik, order?.interaction],
  )

  const handleConfirmedByFarmerChange = useCallback(
    (_, isChecked: boolean) => {
      handleStatusChange('confirmed_by_farmer', isChecked)
    },
    [handleStatusChange],
  )

  const handleFilesChange = useCallback(
    (items: FileItem[]) => {
      formik.setFieldValue('files', items)
    },
    [formik],
  )

  const handleDistributorConfirmation = useCallback(
    (status: DistributorConfirmationStatus) => {
      if (status === 'rejected') {
        onDistributorCancellation?.(role as ROLES)
        return
      }

      return onDistributorConfirmation?.(status, isAddRetailerOrderSuggestionEnabled)
    },
    [isAddRetailerOrderSuggestionEnabled, onDistributorCancellation, onDistributorConfirmation, role],
  )

  const revisionMismatch = progress === Progress.ERROR && updateError === 'conflict_error'
  useValidationErrorNotification(formik.submitCount, formik.isValid)

  const docIds = useMemo(() => {
    const ids = formik.values.items.map(item => item.product?.docusign_document_id).filter(helpersObject.nonNullable)
    return [...new Set(ids)]
  }, [formik.values.items])

  const onRadioChange = (value: FarmerSelectType) => {
    setFarmerSelectType(value)
    formik.setFieldValue('farmer_id', '')
  }

  const isCreditOfferProvided = useMemo(() => {
    const producersList = producers as Company[]
    const foundedProducer: Company | undefined = producersList.find(
      (producer: Company) => producer?.id === formik.values.producer_id,
    )
    return !!foundedProducer?.credit_offer
  }, [formik.values.producer_id, producers])

  const isCancelOrderDisabled = useMemo(() => {
    return order?.status === FarmerOrderStatus.Canceled || editingDateHasExpired
  }, [editingDateHasExpired, order?.status])

  const entriesWithPrices = useMemo(() => {
    return formik.values.items.map(entry => {
      return { ...entry, price: entry?.product?.price }
    })
  }, [formik.values.items])
  const calculateParams = useMemo(
    () => ({
      producer_id: formik.values.producer_id || '',
      season_id: formik.values.season_id,
      entries: entriesWithPrices,
      promocodes: formik.values.promocodes ? formik.values.promocodes.map(p => p.code) : [],
      at_date: formik.values.order_date,
      owner_id: formik.values.owner_id || null,
      scopes: [DiscountRuleScope.FarmerOrders],
    }),
    [
      entriesWithPrices,
      formik.values.order_date,
      formik.values.producer_id,
      formik.values.promocodes,
      formik.values.season_id,
      formik.values.owner_id,
    ],
  )
  const [calcProgress, discountInfo] = useCalculatedDiscounts(calculateParams)

  const { actualTotalNet, totalSum } = useTotalSum({
    orders: formik.values.items || [],
    producerId: formik.values.producer_id || '',
    totalNet,
    discountInfo,
  })

  const onSeasonChange = val => {
    if (!formik.values.season_id || !formik.values.items.length) {
      handleSeasonChange(val as string)
    } else {
      openModal({
        contentProps: {},
        content: (
          <ModalWithButtonContent
            modalContent={t('modalText')}
            allowButtonText={t('common:yes')}
            cancelButtonText={t('common:no')}
            handlleAllowButtonClick={() => handleSeasonChange(val as string)}
          />
        ),
      })
    }
  }

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <Wrapper>
          <Column>
            <SectionContainer>
              <SectionBody>
                <FormComponents.FormSection title={t('form.sectionHeaders.producer')}>
                  {order ? (
                    <CompanyInfoPreview
                      company={order.producer}
                      footer={`ID ${order.producer?.id || ''}`}
                      link={
                        isAdmin && order && order.producer
                          ? generatePath(CompanyRoutes.Edit, { id: order.producer?.id })
                          : undefined
                      }
                    />
                  ) : (
                    <CompanySelect
                      name={'producer_id'}
                      label={t('form.labels.producer')}
                      onChange={onProducerChange}
                      value={formik.values.producer_id}
                      companyType={CompanyType.Producer}
                      isClearable
                      isSearchable
                      invalid={formik.touched.producer_id && !!formik.errors.producer_id}
                      errorText={formik.errors.producer_id}
                      required
                      isDisabled={!isEditable}
                      filter={{
                        feature_flags: generateCrmSectionAccessString(
                          Sections.FarmerOrders,
                          FeatureFlagModifiers.Enabled,
                        ),
                      }}
                    />
                  )}
                  <SeasonSelect
                    companyId={formik.values.producer_id}
                    label={t('form.labels.season')}
                    placeholder={t('form.placeholders.season')}
                    value={formik.values.season_id}
                    getMappedOption={season => ({ ...season, isDisabled: !isAdmin && season.is_season_closed })}
                    onChange={onSeasonChange}
                    isDisabled={!isEditable}
                    required
                  />
                  <CompanySelect
                    label={t('form.labels.distributor')}
                    placeholder={t('form.placeholders.distributor')}
                    onChange={value => {
                      formik.setFieldValue('distributor_id', value)
                      isAgro(role) && formik.setFieldValue('interaction.confirmed_by_distributor', 'unset')
                    }}
                    value={formik.values.distributor_id || ''}
                    companyType={CompanyType.Distributor}
                    isClearable
                    isSearchable
                    onMenuClose={() => {
                      formik.setFieldTouched('distributor_id')
                    }}
                    isDisabled={!updatePermissions.distributorSelection || !formik.values.producer_id || !isEditable}
                    filter={{ for_producer_id: formik.values.producer_id }}
                  />
                  <OrderStatusBlock
                    values={formik.values.interaction}
                    onChange={handleStatusChange}
                    hasDistributor={!!formik.values.distributor_id}
                    showCancellation={!!order && isAdmin}
                    isConfirmedByAgroVisible={isAdmin}
                    isConfirmedByManufacturerVisible={isManufacturerConfirmationEnabled || isAdmin}
                    isRetailerInteractionVisible={isRetailerInteractionByProducerEnabled || isAdmin}
                    isEditable={isEditable}
                  />
                  {(isFarmerConfirmationEnabled || isAdmin) && (
                    <div data-test-id="farmer-confirmation-status">
                      <FormComponents.FormSection title={t('form.sectionHeaders.farmersInteraction')}>
                        <Checkbox
                          label={<Label>{t('status.confirmed_by_farmer')}</Label>}
                          isChecked={formik.values.interaction.confirmed_by_farmer}
                          disabled={!isAdmin || !isEditable}
                          onChange={handleConfirmedByFarmerChange}
                        />
                      </FormComponents.FormSection>
                    </div>
                  )}
                  {isProducer && (
                    <CancelSwitch
                      canceledByRole={formik.values.interaction.canceled_by_role}
                      canceledByRoleDefault={role}
                      disabled={isCancelOrderDisabled || !isEditable}
                      onChange={handleStatusChange}
                    />
                  )}
                  <DatePickerWrapper data-test-id={'order-date'}>
                    <DatePicker
                      onChange={value => {
                        formik.setFieldValue('order_date', value)
                        formik.setFieldTouched('order_date')
                      }}
                      date={formik.getFieldProps('order_date').value}
                      invalid={formik.touched.order_date && !!formik.errors.order_date}
                      errorText={formik.errors.order_date}
                      label={t('form.labels.orderDate')}
                      disabled={!updatePermissions.date || !isEditable}
                      required
                      format={dateFormat}
                    />
                  </DatePickerWrapper>
                </FormComponents.FormSection>
              </SectionBody>
            </SectionContainer>
            <SectionContainer>
              <SectionBody data-test-id={'farmer-section'}>
                <FormComponents.FormSection title={t('form.sectionHeaders.farmer')}>
                  {!order && (
                    <>
                      <div data-test-id={'select-farmer'}>
                        <Radio value={farmerSelectType} onChange={onRadioChange} direction="column">
                          <RadioItem
                            value={FarmerSelectType.Select}
                            label={<Label>{t('form.labels.farmerSelect')}</Label>}
                          />
                          <div data-test-id={'find_by_phone'}>
                            <RadioItem
                              value={FarmerSelectType.Modal}
                              label={<Label>{t('form.labels.farmerModal')}</Label>}
                            />
                          </div>
                        </Radio>
                      </div>
                      {farmerSelectType === FarmerSelectType.Select && (
                        <FarmersSelect
                          onChange={onCustomerChange}
                          onMenuClose={() => formik.setFieldTouched('owner_id')}
                          value={formik.values.owner_id}
                          isDisabled={!formik.values.distributor_id}
                          invalid={formik.touched.owner_id && !!formik.errors.owner_id}
                          errorText={formik.errors.owner_id}
                          filter={{ distributor_id: formik.values.distributor_id as string }}
                        />
                      )}
                    </>
                  )}
                  {!order && farmerSelectType === FarmerSelectType.Modal && (
                    <FarmerBlock>
                      <Button
                        style={{ maxWidth: '200px' }}
                        intent={formik.touched.owner_id && !!formik.errors.owner_id ? 'danger' : 'primary'}
                        filled={false}
                        onClick={() => {
                          setShowCustomerModal(true)
                        }}
                        data-test-id={'select-customer-button'}
                      >
                        {customer ? t('form.changeCustomer') : t('form.selectCustomer')}
                      </Button>
                      {formik.values.distributor_id && !!formik.errors.owner_id && formik.touched.owner_id && (
                        <ErrorStyled>{formik.errors.owner_id}</ErrorStyled>
                      )}
                      <CustomerModal
                        isOpen={showCustomerModal}
                        onClose={() => {
                          setShowCustomerModal(false)
                          if (!formik.values.owner_id) {
                            formik.setFieldTouched('owner_id')
                          }
                        }}
                        onCustomerSelect={onCustomerChange}
                        hideFarmerInfoChecking={hideFarmerInfoChecking}
                      />
                      <ErrorStyled>
                        {formik.touched.owner_id && !!formik.errors.owner_id && formik.errors.owner_id}
                      </ErrorStyled>
                    </FarmerBlock>
                  )}
                  {!!formik.values.owner_id && (
                    <CustomerInfo
                      customer={customer}
                      link={isAdmin ? farmerLink : undefined}
                      creditOfferProvided={isCreditOfferProvided}
                      creditOfferAccepted={formik.values.interaction.credit_offer_accepted}
                      onCreditOfferAcceptedChange={
                        updatePermissions.creditOfferAccept ? handleCreditOfferAcceptedChange : undefined
                      }
                      hideFarmerInfoChecking={hideFarmerInfoChecking}
                      isEditable={isEditable}
                    />
                  )}
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.sectionHeaders.address')}>
                  <FarmerAddressForm
                    formik={formik}
                    isDesiredDeliveryDateEnabled={isDesiredDeliveryDateEnabled}
                    isEditable={isEditable}
                  />
                </FormComponents.FormSection>
              </SectionBody>
            </SectionContainer>
            {order && (isAgro(role) || isHistoryCardVisible) && (
              <OrderHistoryCard
                orderId={order.id}
                createdById={order.author_id}
                orderSource={order.order_source}
                createdDate={order.created_at}
                confirmedByProducerId={order.interaction.user_id_confirmed_by_producer}
                confirmedByDistributorId={order.interaction.user_id_confirmed_by_distributor}
                confirmedByAgroClubId={order.interaction.user_id_confirmed_by_agroclub}
              />
            )}
          </Column>
          <Column>
            {((isAdmin || isProducer || (isDistributor && isOrderConfirmedByDistributor)) &&
              updatePermissions.items &&
              isEditable) ||
            !order ? (
              <OrderProductItems
                context={'farmer'}
                farmerOrder={order}
                onChange={handleProductsChange}
                onRemove={handleProductRemove}
                onRequiredDocumentClick={handleRequiredDocumentClick}
                onMenuClose={() => formik.setFieldTouched('items')}
                isAllowed={!!formik.values.producer_id}
                items={formik.values.items}
                documents={userDocuments}
                producerId={formik.values.producer_id || undefined}
                onFinalQtyChange={handleFinalQtyChange}
                onDeliveredQtyChange={handleDeliveredQtyChange}
                onSeedTreatmentChange={handleSeedTreatmentChange}
                onProductOptionsChange={handleProductOptionsChange}
                onAltPackagingChange={handleAltPackagingChange}
                onCommentChange={handleCommentChange}
                invalid={formik.touched.items && !!formik.errors.items}
                errorText={formik.errors.items as string}
                optionsRequiredErrors={optionsRequiredErrors}
                mode={mode}
                total={totalSum}
                netTotal={actualTotalNet}
                showComment={showComment}
                distributorId={formik.values.distributor_id as string}
                seasonId={formik.values.season_id}
                setIsProductQtyMoreThanLimitAfterDuplicate={setIsProductQtyMoreThanLimitAfterDuplicate}
                setIsProductQtyMoreThanLimit={setIsProductQtyMoreThanLimit}
                initialItems={formik.initialValues.items}
              >
                {!!docIds.length && (
                  <OrderDocuments
                    documentIds={docIds}
                    userId={formik.values.owner_id}
                    selectedDocument={selectedDocument}
                  />
                )}
              </OrderProductItems>
            ) : (
              <OrderProductItemsReadOnly
                items={order?.items || []}
                showFinalQty={true}
                currency={order?.currency}
                orderTotal={Number(order?.total)}
                distributorId={formik.values.distributor_id as string}
                seasonId={formik.values.season_id}
                setIsProductQtyMoreThanLimit={setIsProductQtyMoreThanLimit}
                mode={'edit'}
                context={'farmer'}
              >
                {!!docIds.length && (
                  <OrderDocuments
                    documentIds={docIds}
                    userId={formik.values.owner_id}
                    selectedDocument={selectedDocument}
                  />
                )}
              </OrderProductItemsReadOnly>
            )}
            {formik.values.producer_id && formik.values.items && (
              <OrderDiscounts
                producerId={formik.values.producer_id}
                discountInfo={discountInfo}
                progress={calcProgress}
              />
            )}
            <SectionContainer>
              <SectionTitle>{t('form.sectionHeaders.promocodes')}</SectionTitle>
              <SectionBody>
                <PromocodeField
                  company_id={formik.values.producer_id}
                  onApply={handlePromocodeApply}
                  disabled={isPromocodeDisabled || !isEditable}
                  promocodes={formik.values.promocodes}
                />
                <PromocodeList
                  promocodes={formik.values.promocodes}
                  onRemove={handlePromocodeRemove}
                  onCommentChange={handlePromocodeCommentChange}
                  selected={selectedPromocode}
                  onSelect={setSelectedPromocode}
                />
              </SectionBody>
            </SectionContainer>
            <SectionContainer>
              <SectionTitle>{t('form.sectionHeaders.farmerComment')}</SectionTitle>
              <SectionBody>
                <TextArea
                  {...formik.getFieldProps('farmer_comment')}
                  placeholder={t('form.placeholders.farmerComment')}
                  disabled={!isAdmin}
                  data-test-id={'farmer-comment-textarea'}
                />
              </SectionBody>
            </SectionContainer>
            <SectionContainer>
              <SectionTitle>{t('form.sectionHeaders.comment')}</SectionTitle>
              <SectionBody>
                <TextArea
                  {...formik.getFieldProps('comment')}
                  placeholder={t('form.placeholders.comment')}
                  disabled={isCommentDisabled || !isEditable}
                  data-test-id={'comment-textarea'}
                />
              </SectionBody>
            </SectionContainer>
            <SectionContainer>
              <SectionTitle>{t('form.sectionHeaders.attachedFiles')}</SectionTitle>
              <SectionBody>
                <FileManager items={formik.values.files} onChange={handleFilesChange} disabled={!isEditable} />
              </SectionBody>
            </SectionContainer>
          </Column>
          <Overlay isUpdatePermitted={isUpdatePermitted} />
        </Wrapper>
      </StickyFooterLayout.Body>
      <StickyFooterLayout.ButtonsFooter>
        <ControlButtons
          onCancel={onCancel}
          onSave={handleSubmit}
          onDuplicate={handleDuplicate}
          onRemove={handleRemove}
          onRestore={handleRestore}
          distributorConfirmButtonDisabled={isDistributor && isProductQtyMoreThanLimit}
          onDistributorConfirmation={handleDistributorConfirmation}
          progress={progress}
          removeProgress={removeProgress}
          isFormDirty={formik.dirty}
          isFormValid={formik.isValid}
          interaction={formik.values.interaction}
          revisionMismatch={revisionMismatch}
          orderId={order?.id}
          orderStatus={order?.status}
          isRestorePermitted={isAdmin}
          orderSlug={order?.slug}
          isUpdatePermitted={isUpdatePermitted}
          editingDateHasExpired={editingDateHasExpired}
          isEditable={isEditable}
          duplicateButtonDisabled={isProductQtyMoreThanLimitAfterDuplicate && !isAdmin}
        />
      </StickyFooterLayout.ButtonsFooter>

      {productQtyIsNullError && <Snackbar>{t('productQtyIsNullError')}</Snackbar>}
      {revisionMismatch && (
        <StickyFooterLayout.NotificationWarning>
          <div>{t('form.errors.revisionMismatch')}</div>
          <Button intent={'warning'} filled progress={progress} onClick={handleRefresh} disabled={!isEditable}>
            {t('form.refreshOrder')}
          </Button>
        </StickyFooterLayout.NotificationWarning>
      )}
    </StickyFooterLayout.Wrapper>
  )
}

export default FarmerOrderDetailsForm
