import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FarmerOrder, FarmerOrderItem } from 'types/farmerOrder'
import {
  isAgro,
  LocalizedValue,
  ProductEntry,
  Status,
  ROLES,
  Sections,
  isDistributor as isDistributorRole,
  isProducersManager,
  CustomFeatureName,
  FieldLocation,
  FieldNames,
  FeatureFlagModifiers,
} from 'types/entities'
import { Product } from 'modules/domain/product/types'
import useLangPicker from 'hooks/useLangPicker'
import * as Styled from 'views/components/OrderProductItems/styles'
import { DocumentItem } from 'modules/domain/document/types'
import {
  SectionBody,
  SectionFooter,
  SectionTableHeadRow,
  SectionTable,
  SectionTitle,
  SectionTableHeadCell,
  SectionTableHead,
  SectionTableBody,
  Switch,
  WizardData,
  Dict,
} from '@agro-club/frontend-shared'
import { useAllProductOptionsList } from 'modules/domain/productOptions/hooks'
import { useRole } from 'modules/permissions/permissions'
import ProductWizardContainer from 'views/components/ProductWizards/ProductWizardContainer'
import { generateCustomFeatureFlag, generateFieldModifierString } from 'modules/utils/generateStringHelpers'
import checkCompanyFeatureFlags from 'helpers/checkCompanyFeatureFlags'
import { useCompanyById } from 'modules/domain/company/hooks'
import { isNil } from 'ramda'
import { getIniqItemsWithInitialQty, getUniqItemInitialQty, getUniqItemQty, getUniqItems } from './helpers'
import OrderProductItem from './OrderProductItem'
import { useProductsLimit } from 'modules/domain/allocation/hooks'
import { ProductsSelect } from '../ProductsSelect/ProductsSelect'
import { getProductMinQty } from 'modules/domain/allocation/helpers'

export const AltPackagingInput: React.FC<{
  productId: string
  value?: number | null
  altPackagingTitle: string
  editable: boolean
  onPackagingChange: (productId: string, val: number) => void
}> = ({ productId, value, altPackagingTitle, editable, onPackagingChange }) => {
  const { t } = useTranslation('farmerOrder')
  const [applyAltPackaging, setApplyCustomPackaging] = useState(!!value)
  const onChange = useCallback(
    val => {
      onPackagingChange(productId, val)
    },
    [onPackagingChange, productId],
  )

  const handleInputChange = useCallback(
    e => {
      const value = e.target.value.replace(/\D/g, '')
      const parsedValue = parseInt(value)
      onChange(applyAltPackaging && !Number.isNaN(parsedValue) ? parsedValue : 0)
    },
    [applyAltPackaging, onChange],
  )

  const handleSwitchChange = useCallback(
    value => {
      setApplyCustomPackaging(value)
      if (!value) {
        onPackagingChange(productId, 0)
      }
    },
    [onPackagingChange, productId],
  )

  return (
    <Styled.PackagingWrapper data-test-id={'alt-packaging'}>
      <Styled.PackagingSwitchContainer>
        <Styled.PackagingSwitchWrapper>
          <span>{t('enableAltPackaging')}</span>
          <Switch on={applyAltPackaging} onClick={handleSwitchChange} disabled={!editable} size={'small'} />
        </Styled.PackagingSwitchWrapper>
        <Styled.PackagingHint>{t('altPackagingHint')}</Styled.PackagingHint>
      </Styled.PackagingSwitchContainer>
      <Styled.PackagingInputWrapper show={applyAltPackaging}>
        <Styled.PackagingLabel>{altPackagingTitle}</Styled.PackagingLabel>
        <Styled.PackagingInput value={value || ''} onChange={handleInputChange} readOnly={!editable} type={'text'} />
      </Styled.PackagingInputWrapper>
    </Styled.PackagingWrapper>
  )
}

export const SeedTreatment: React.FC<{
  item: ProductEntry
  onChange?: (productId: string, seedTreatmentId: string) => void
}> = ({ item, onChange }) => {
  const { t } = useTranslation('farmerOrder')
  const { pick } = useLangPicker()
  const { product } = item
  const seedTreatment = !!product && product?.seed_treatment

  const treatmentOptions = useMemo(() => {
    if (seedTreatment) {
      return seedTreatment
        .filter(s => s.is_active || s.id === item.seed_treatment_id)
        .map((st: { title_i18n: LocalizedValue; id: string }) => ({
          title: pick(st.title_i18n),
          id: st.id,
        }))
    }
    return []
  }, [seedTreatment, pick, item.seed_treatment_id])

  const handleChange = useCallback(
    value => {
      onChange && onChange(item.product_id, value)
    },
    [item.product_id, onChange],
  )

  return !!treatmentOptions.length ? (
    <Styled.SeedTreatment data-test-id={'seed-treatment'}>
      <Styled.Label>{t('seedTreatment')}</Styled.Label>
      <Styled.SeedTreatmentSelect
        variant="small"
        minWidth="150px"
        options={treatmentOptions}
        value={item.seed_treatment_id}
        onChange={handleChange}
        isDisabled={!onChange}
        menuWidth="300px"
        menuMaxWidth="300px"
        wrapOptionsText
      />
    </Styled.SeedTreatment>
  ) : null
}

export const AltPackagingCell: React.FC<{
  item: ProductEntry
  onChange: (productId: string, qty: number) => void
}> = ({ item, onChange }) => {
  const { t } = useTranslation('farmerOrder')
  const { pick } = useLangPicker()
  const { product } = item

  if (!product?.alt_packaging) {
    return <Styled.Unavailable>{t('altPackageUnavailable')}</Styled.Unavailable>
  }

  return (
    <AltPackagingInput
      altPackagingTitle={pick(product?.alt_packaging_i18n)}
      value={item.packaging}
      productId={item.product_id}
      onPackagingChange={onChange}
      editable
    />
  )
}

const OrderProductItems: React.FC<{
  context: 'farmer' | 'distributor'
  onChange: (
    idx: number,
    qty: number,
    product?: Omit<Product, 'options'>,
    wizard_comment?: string,
    wizard_data?: WizardData<any>,
  ) => void
  onRemove: (idx: number) => void
  onMenuClose?: () => void
  onSeedTreatmentChange: (productId: string, seedTreatmentId: string) => void
  onProductOptionsChange: (idx: number, options: string[]) => void
  onAltPackagingChange: (idx: number, qty: number) => void
  onRequiredDocumentClick?: (id: string) => void
  onFinalQtyChange?: (idx: number, qty: number) => void
  onDeliveredQtyChange?: (idx: number, qty: number) => void
  onCommentChange?: (idx: number, comment: string) => void
  farmerOrder?: FarmerOrder
  isAllowed: boolean
  items?: FarmerOrderItem[]
  documents?: DocumentItem[]
  producerId?: string
  totalSavings?: number
  invalid?: boolean
  errorText?: string
  optionsRequiredErrors: string[][]
  mode: 'create' | 'edit'
  total?: string
  netTotal?: string
  showComment: boolean
  distributorId?: string
  seasonId?: string
  setIsProductQtyMoreThanLimitAfterDuplicate?: (isQtyMoreThanLimit: boolean) => void
  setIsProductQtyMoreThanLimit?: (isQtyMoreThanLimit: boolean) => void
  initialItems?: FarmerOrderItem[]
}> = ({
  context,
  isAllowed,
  farmerOrder,
  onChange,
  onRemove,
  items = [],
  documents = [],
  producerId,
  onFinalQtyChange,
  onDeliveredQtyChange,
  onSeedTreatmentChange,
  onProductOptionsChange,
  onAltPackagingChange,
  onRequiredDocumentClick,
  onMenuClose,
  onCommentChange,
  invalid,
  errorText,
  children,
  optionsRequiredErrors,
  mode,
  total,
  netTotal,
  showComment,
  distributorId,
  seasonId,
  setIsProductQtyMoreThanLimitAfterDuplicate,
  setIsProductQtyMoreThanLimit,
  initialItems = [],
}) => {
  const { t } = useTranslation(['farmerOrder', 'common', 'allocation'])
  const { pick } = useLangPicker()
  const role = useRole()
  const isAdmin = isAgro(role)
  const isDistributor = isDistributorRole(role)
  const isProducerManager = isProducersManager(role)
  const [, company] = useCompanyById(producerId)
  const filterByRetailerFlag = generateCustomFeatureFlag(Sections.Orders, 'filterByRetailer')
  const availableByInventory = useMemo(
    () => (isDistributor || isProducerManager) && checkCompanyFeatureFlags(company, filterByRetailerFlag),
    [company, filterByRetailerFlag, isDistributor, isProducerManager],
  )
  const uniqItems = getUniqItems(items)
  const uniqItemsWithInitialQty = getIniqItemsWithInitialQty(initialItems)

  const productsFromItems = useMemo(() => {
    return items.map(item => ({
      product_id: item.product_id,
      seed_treatment_id: item.seed_treatment_id || null,
    }))
  }, [items])

  const params = useMemo(
    () => ({
      distributorId: distributorId || '',
      seasonId: seasonId || '',
      products: productsFromItems,
    }),
    [distributorId, seasonId, productsFromItems],
  )

  const [, productsLimit = []] = useProductsLimit(context, params)

  useEffect(() => {
    if (productsLimit) {
      const productQtyMoreThanLimit = productsLimit.find(
        item => !isNil(item.allowed_quantity) && getUniqItemQty(uniqItems, item.product_id) > item.allowed_quantity,
      )
      setIsProductQtyMoreThanLimit?.((mode === 'edit' && productQtyMoreThanLimit?.is_limit_exceeded) || false)

      setIsProductQtyMoreThanLimitAfterDuplicate?.(
        !!productsLimit.find(
          item =>
            !isNil(item.allowed_quantity) && getUniqItemQty(uniqItems, item.product_id) * 2 > item.allowed_quantity,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsLimit])

  const showUnits = useMemo(
    () =>
      checkCompanyFeatureFlags(
        company,
        generateFieldModifierString(FieldLocation.OrderList, FieldNames.Units, FeatureFlagModifiers.Enabled),
        role,
      ),
    [company, role],
  )

  const hideWizardsForRetailer = generateCustomFeatureFlag(Sections.RetailerOrders, CustomFeatureName.HideWizards)
  const hideWizardsForFarmer = generateCustomFeatureFlag(Sections.FarmerOrders, CustomFeatureName.HideWizards)

  const [products, setProducts] = useState<Dict<Omit<Product, 'options'>>>({})

  const [productAddError, setProductAddError] = useState('')
  const itemsQty = items.length

  const isEditingExpired = useCallback(
    (product: Omit<Product, 'options'>) => {
      const expirationDate = product?.out_of_stock_date
      const isEditingExpired = !!expirationDate && new Date() >= new Date(expirationDate)
      const notPermittedForRole =
        !!role && [ROLES.DISTRIBUTOR_HEAD, ROLES.DISTRIBUTOR_MANAGER, ROLES.PRODUCER_MANAGER].includes(role)

      return isEditingExpired && notPermittedForRole
    },
    [role],
  )

  const handleProductAdd = useCallback(
    (
      product?: Omit<Product, 'options'> | null,
      qty?: string | number,
      wizard_comment?: string,
      wizard_data?: WizardData<any>,
    ) => {
      if (!product) return

      if (isEditingExpired(product)) {
        setProductAddError(pick(product?.title_i18n))
        return
      }

      const isProductQtyMoreThanLimit = productsLimit.find(
        item =>
          !isNil(item.allowed_quantity) &&
          ((product && getUniqItemQty(uniqItems, product.id) + product?.min_qty > item.allowed_quantity) ||
            getUniqItemQty(uniqItems, item.product_id) > item.allowed_quantity),
      )

      const productAllocationLimit = productsLimit.find(
        item => !isNil(item.allowed_quantity) && item.product_id === product?.id,
      )

      const productCurrentQty = product && getUniqItemQty(uniqItems, product.id)
      const productIntitialQty = product && getUniqItemInitialQty(uniqItemsWithInitialQty, product.id)

      const productMinQty = getProductMinQty(
        productCurrentQty,
        productIntitialQty,
        isAdmin,
        mode,
        product.id,
        productAllocationLimit,
        product?.min_qty,
      )

      setIsProductQtyMoreThanLimit?.(!!isProductQtyMoreThanLimit)
      setIsProductQtyMoreThanLimitAfterDuplicate?.(
        !!productsLimit.find(
          item =>
            !isNil(item.allowed_quantity) &&
            ((productCurrentQty + product?.min_qty) * 2 > item.allowed_quantity ||
              getUniqItemQty(uniqItems, item.product_id) * 2 > item.allowed_quantity),
        ),
      )
      setProductAddError('')

      onChange(
        itemsQty || 0,
        Number(qty) || (!isNil(productMinQty) ? productMinQty : 1),
        product,
        wizard_comment,
        wizard_data,
      )
    },
    [
      isAdmin,
      isEditingExpired,
      itemsQty,
      mode,
      onChange,
      pick,
      productsLimit,
      setIsProductQtyMoreThanLimit,
      setIsProductQtyMoreThanLimitAfterDuplicate,
      uniqItems,
      uniqItemsWithInitialQty,
    ],
  )

  const handleWizardChange = useCallback(
    (id: string, qty?: string | number, wizard_comment?: string, wizard_data?: WizardData<any>) => {
      const product = products[id]

      handleProductAdd(product, qty, wizard_comment, wizard_data)
    },
    [products, handleProductAdd],
  )

  const [, productOptions = []] = useAllProductOptionsList()

  const handleQtyInputChange = (idx: number, value: number) => {
    const currentProduct = items[idx]
    const currentProductCommonQty = getUniqItemQty(uniqItems, currentProduct.product_id)

    uniqItems[currentProduct.product_id].quantity =
      currentProduct.quantity - value > 0
        ? currentProductCommonQty - (currentProduct.quantity - value)
        : currentProductCommonQty + (value - currentProduct.quantity)

    setIsProductQtyMoreThanLimitAfterDuplicate?.(
      !!productsLimit.find(
        product =>
          !isNil(product.allowed_quantity) &&
          getUniqItemQty(uniqItems, product.product_id) * 2 > product.allowed_quantity,
      ),
    )

    if (!Number.isNaN(value)) {
      onChange(idx, value)
    } else {
      onChange(idx, 0)
    }
  }

  const handleRemove = useCallback(
    idx => () => {
      onRemove(idx)
      const currentProduct = items[idx]
      const currentProductCommonQty = getUniqItemQty(uniqItems, currentProduct.product_id)

      setIsProductQtyMoreThanLimitAfterDuplicate?.(
        !!productsLimit.find(
          item =>
            !isNil(item.allowed_quantity) &&
            ((currentProductCommonQty - currentProduct.quantity) * 2 > item.allowed_quantity ||
              (item.product_id !== currentProduct.product_id &&
                getUniqItemQty(uniqItems, item.product_id) * 2 > item.allowed_quantity)),
        ),
      )
    },
    [onRemove, items, setIsProductQtyMoreThanLimitAfterDuplicate, productsLimit, uniqItems],
  )

  const handleSeedTreatmentChange = useCallback(
    idx => (_productId: string, value: string) => {
      const currentProduct = items[idx]
      const currentProductCommonQty = getUniqItemQty(uniqItems, currentProduct.product_id)

      setIsProductQtyMoreThanLimitAfterDuplicate?.(
        !!productsLimit.find(
          item =>
            !isNil(item.allowed_quantity) &&
            ((currentProductCommonQty + currentProduct.quantity) * 2 > item.allowed_quantity ||
              getUniqItemQty(uniqItems, item.product_id) * 2 > item.allowed_quantity),
        ),
      )
      onSeedTreatmentChange(idx, value)
    },
    [onSeedTreatmentChange, items, setIsProductQtyMoreThanLimitAfterDuplicate, productsLimit, uniqItems],
  )

  const handleProductOptionsChange = useCallback(idx => (options: string[]) => onProductOptionsChange(idx, options), [
    onProductOptionsChange,
  ])

  const showPrices = useMemo(() => !!items?.length && items.some(item => !!item.product?.price), [items])

  const showDeliveredQty = farmerOrder?.season?.enable_delivered_qty
  const showFinalQty = !!farmerOrder?.season?.enable_final_qty

  const showAltPackage = useMemo(() => {
    let show = false
    items.forEach(item => {
      if (item.product?.alt_packaging) {
        show = true
      }
    })
    return show
  }, [items])

  const netTotalJSX = netTotal && (
    <Styled.Total>
      {t('form.netTotal')} <Styled.TotalValue>{netTotal}</Styled.TotalValue>
    </Styled.Total>
  )

  const isWizardVisible =
    producerId &&
    ((context === 'farmer' && !checkCompanyFeatureFlags(company, hideWizardsForFarmer, role)) ||
      (context === 'distributor' && !checkCompanyFeatureFlags(company, hideWizardsForRetailer, role)))

  return (
    <Styled.Container isAllowed={isAllowed}>
      <Styled.Content isAllowed={isAllowed}>
        <SectionTitle>{t('farmerOrder:form.sectionHeaders.orders')}</SectionTitle>
        {isWizardVisible && (
          <ProductWizardContainer producerId={producerId ?? ''} changeOrderList={handleWizardChange} />
        )}
        <SectionBody>
          <ProductsSelect
            onChange={(_, product) => handleProductAdd(product)}
            onMenuClose={onMenuClose}
            label={t('form.labels.product')}
            placeholder={t('form.placeholders.addProduct')}
            filter={{
              producer_id: producerId,
              status: isAdmin ? [Status.Active, Status.Inactive] : undefined,
              hide_from_crm: false,
              retailer_id: distributorId,
            }}
            invalid={invalid}
            errorText={errorText}
            withCategories
            isSearchable
            required
            isAvailableByInventory={availableByInventory}
            onLoadList={(_, products) => setProducts(products)}
            controlShouldRenderValue={false}
          />
          {!!productAddError && (
            <Styled.AddProductError data-test-id="add-product-error">
              {t('productAddError', { title: productAddError })}
            </Styled.AddProductError>
          )}
          {!!items?.length && (
            <SectionTable data-test-id={'product-items'}>
              <SectionTableHead>
                <SectionTableHeadRow>
                  <SectionTableHeadCell>{t('form.tableHeaders.product')}</SectionTableHeadCell>
                  {showPrices && <SectionTableHeadCell>{t('form.tableHeaders.price')}</SectionTableHeadCell>}
                  <SectionTableHeadCell>{t('form.tableHeaders.package')}</SectionTableHeadCell>
                  <SectionTableHeadCell textAlign={'center'}>{t('form.tableHeaders.quantity')}</SectionTableHeadCell>
                  {showDeliveredQty && onDeliveredQtyChange && (
                    <SectionTableHeadCell textAlign={'center'}>
                      {t('form.tableHeaders.deliveredQty')}
                    </SectionTableHeadCell>
                  )}
                  {showFinalQty && onFinalQtyChange && (
                    <SectionTableHeadCell textAlign={'center'}>{t('form.tableHeaders.finalQty')}</SectionTableHeadCell>
                  )}
                  {showAltPackage && <SectionTableHeadCell>{t('form.tableHeaders.altPackaging')}</SectionTableHeadCell>}
                  {showComment && (
                    <SectionTableHeadCell textAlign={'center'}>{t('form.tableHeaders.comment')}</SectionTableHeadCell>
                  )}
                  <SectionTableHeadCell />
                </SectionTableHeadRow>
              </SectionTableHead>
              <SectionTableBody>
                {items.map((item, idx) => {
                  const itemCommonQty = getUniqItemQty(uniqItems, item.product_id)
                  const itemCommonInitialQty = getUniqItemInitialQty(uniqItemsWithInitialQty, item.product_id)

                  return (
                    <OrderProductItem
                      key={item.product_id + idx}
                      item={item}
                      context={context}
                      documents={documents}
                      mode={mode}
                      onQtyInputChange={handleQtyInputChange}
                      idx={idx}
                      onRequiredDocumentClick={onRequiredDocumentClick}
                      onSeedTreatmentChange={handleSeedTreatmentChange}
                      onProductOptionsChange={handleProductOptionsChange}
                      optionsRequiredErrors={optionsRequiredErrors}
                      visibilityConfig={{
                        showPrices,
                        showAltPackage,
                        showComment,
                        showUnits,
                        showDeliveredQty,
                        showFinalQty,
                      }}
                      isEditingExpired={isEditingExpired}
                      onDeliveredQtyChange={onDeliveredQtyChange}
                      onFinalQtyChange={onFinalQtyChange}
                      onAltPackagingChange={onAltPackagingChange}
                      onCommentChange={onCommentChange}
                      onRemove={handleRemove}
                      itemCommonQty={itemCommonQty}
                      itemCommonInitialQty={itemCommonInitialQty}
                      productOptions={productOptions}
                      isAdmin={isAdmin}
                      productsLimit={productsLimit}
                    />
                  )
                })}
              </SectionTableBody>
            </SectionTable>
          )}
        </SectionBody>

        {showPrices && !!total && (
          <SectionFooter>
            <Styled.TotalWrap>
              <Styled.Total>
                {t('form.total')} <Styled.TotalValue>{total}</Styled.TotalValue>
              </Styled.Total>
              {netTotalJSX}
            </Styled.TotalWrap>
          </SectionFooter>
        )}
      </Styled.Content>
      {children}
      {!isAllowed && <Styled.Overlay>{t('form.addProductsOverlayText')}</Styled.Overlay>}
    </Styled.Container>
  )
}

export default OrderProductItems
