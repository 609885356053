import React, { FC, useEffect, useMemo, useState } from 'react'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import * as managers from 'modules/domain/product/managers'
import { WizardData } from '@agro-club/frontend-shared'
import { ProductWizardItem } from './ProductWizardItem'

type PropsType = {
  producerId: string
  changeOrderList: (id: string, qty: number, wizardComment?: string, wizard_data?: WizardData<any>) => void
}

const productsList = makeCancellableResourceListHook(makeCancelable(managers.getList))

const ProductWizardContainer: FC<PropsType> = ({ producerId, changeOrderList }: PropsType) => {
  const [wizardIds, setWizardIds] = useState<string[] | []>([])

  const optionsFilter = useMemo(() => ({ producer_id: producerId }), [producerId])
  const [, products = []] = productsList(optionsFilter)

  useEffect(() => {
    if (products.length === 0) {
      if (!wizardIds.length) return
      setWizardIds([])
      return
    }
    const arrWizardIds: string[] = []
    products.forEach(product => {
      if (producerId === product.producer_id && product.wizard_id) {
        arrWizardIds.push(product.wizard_id)
      }
    })
    const newArrWizardIds = [...new Set(arrWizardIds)]
    setWizardIds(newArrWizardIds)
  }, [producerId, products, wizardIds.length])

  return (
    <div>{!!wizardIds.length && <ProductWizardItem wizardIds={wizardIds} changeOrderList={changeOrderList} />}</div>
  )
}

export default React.memo(ProductWizardContainer)
