import React, { useCallback, useMemo } from 'react'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { useSelector } from 'react-redux'
import CallBackRequestSelectors from 'modules/domain/callBackRequest/selectors'
import CallBackRequestActions from 'modules/domain/callBackRequest/duck'
import {
  CallBackRequestListRequestFilter,
  CallBackRequestStatus,
  CallBackRequest,
} from 'modules/domain/callBackRequest/types'
import { useCallBackRequestList } from 'modules/domain/callBackRequest/hooks'
import { TableFilter } from 'views/components/TableFilters/TableFilters'
import { useTranslation } from 'react-i18next'
import { CellProps, useTable } from 'react-table'
import Status from '../Status'
import {
  Button,
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableNoData,
  useAction,
  usePersistentScroll,
} from '@agro-club/frontend-shared'
import useDateFormatFn from 'hooks/useDateFormatFn'

const CreatedAtCell: React.FC<CellProps<CallBackRequest>> = ({ row, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })

  return (
    <div key={column.id}>
      <div>{getFormatedDate(row.values.created_at)}</div>
    </div>
  )
}

const UpdatedAtCell: React.FC<CellProps<CallBackRequest>> = ({ row, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })

  return (
    <div key={column.id}>
      <div>{getFormatedDate(row.values.created_at)}</div>
    </div>
  )
}

const StatusCell: React.FC<CellProps<CallBackRequest>> = ({ row, column }) => {
  return (
    <div key={column.id}>
      <Status status={row.values.status} />
    </div>
  )
}

const RecordActions: React.FC<CellProps<CallBackRequest>> = ({ row, column }) => {
  const { t } = useTranslation('callBackRequest')
  const approveAction = useAction(CallBackRequestActions.updateRequested, row.values.id, {
    status: CallBackRequestStatus.DONE,
    phone: row.values.phone,
  })
  const meta = useSelector(state => CallBackRequestSelectors.meta(state, row.values.id))
  if (row.values.status !== CallBackRequestStatus.NEW) {
    return null
  }

  return (
    <div key={column.id}>
      <Button
        size={'small'}
        progress={meta.updateProgress}
        intent={'action'}
        filled={false}
        onClick={() => approveAction()}
      >
        {t('approve')}
      </Button>
    </div>
  )
}

const PhoneCell: React.FC<CellProps<CallBackRequest>> = ({ row, column }) => {
  return (
    <div key={column.id}>
      <a href={`tel:${row.values.phone}`}>{row.values.phone}</a>
    </div>
  )
}

const CallBackTable: React.FC = () => {
  const { t } = useTranslation(['callBackRequest', 'labels'])
  const [progress, data = []] = useCallBackRequestList()
  const total = useSelector(CallBackRequestSelectors.total)
  const page = useSelector(CallBackRequestSelectors.page)
  const pages = useSelector(CallBackRequestSelectors.pages)
  const pageSize = useSelector(CallBackRequestSelectors.pageSize)
  const filter = useSelector(CallBackRequestSelectors.filter)

  const listRequested = useAction(CallBackRequestActions.listRequested)
  const filterUpdated = useAction(CallBackRequestActions.filterUpdated)

  const { scrollRef } = usePersistentScroll('callBackTable')

  const statusFilterOptions = useMemo(
    () => [
      {
        value: CallBackRequestStatus.NEW,
        title: t('status.new'),
        bullet: true,
        color: 'orange' as const,
      },
      {
        value: CallBackRequestStatus.DONE,
        title: t('status.done'),
        bullet: true,
        color: 'green' as const,
      },
    ],
    [t],
  )

  const allColumns = useMemo(
    () => [
      {
        Header: t('fields.actions'),
        accessor: 'id' as const,
        sortable: false,
        Cell: RecordActions,
      },
      {
        Header: t('fields.status'),
        accessor: 'status' as const,
        sortable: false,
        Cell: StatusCell,
      },
      {
        Header: t('fields.phone'),
        accessor: 'phone' as const,
        sortable: false,
        Cell: PhoneCell,
      },
      {
        Header: t('fields.createdAt'),
        accessor: 'created_at' as const,
        sortable: false,
        Cell: CreatedAtCell,
      },
      {
        Header: t('fields.updatedAt'),
        accessor: 'updated_at' as const,
        sortable: false,
        Cell: UpdatedAtCell,
      },
    ],
    [t],
  )

  const { columns, rows, prepareRow } = useTable<CallBackRequest>({
    columns: allColumns,
    data,
  })

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const handleFilterChange = useCallback(
    (newValue: Partial<CallBackRequestListRequestFilter>) => {
      filterUpdated({ ...filter, ...newValue })
    },
    [filter, filterUpdated],
  )

  return (
    <TComponents.Wrapper>
      <TComponents.Filters>
        <TableFilter
          handleFilterChange={handleFilterChange}
          title={t('labels:status')}
          filterValue={{ status: filter.status }}
          options={statusFilterOptions}
          disableMulti
        />
      </TComponents.Filters>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>
            {columns.map(column => {
              return <TableHeadCell key={column.id}>{column.render('Header')}</TableHeadCell>
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow key={key} {...props}>
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()
                  return (
                    <TableBodyCell key={key} {...props}>
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={allColumns.length}
            loading={<TComponents.Spinner />}
          />
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default CallBackTable
