import React, { memo, useCallback, useMemo } from 'react'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
import { CheckboxTreeSelectProps } from '@agro-club/frontend-shared'
import { ProductOptionsData, ProductOptionType } from 'modules/domain/productOptions/types'
import { useAllProductOptionsList } from 'modules/domain/productOptions/hooks'
import useLangPicker from 'hooks/useLangPicker'

const ActiveIngredientsForm: React.FC<Omit<CheckboxTreeSelectProps, 'options' | 'filter' | 'onChange'> & {
  options: ProductOptionsData
  touched: boolean
  onChange: (type: ProductOptionType, option_ids: string[], required: boolean) => void
}> = memo(({ options, touched, onChange, ...selectProps }) => {
  const { t } = useTranslation(['productOptions'])
  const { pick } = useLangPicker()
  const [, productOptions] = useAllProductOptionsList()
  const { type, option_ids, required } = options

  const selectOptions = useMemo(
    () =>
      productOptions
        ?.filter(option => option.type === type)
        .map(option => ({
          id: option.id,
          title: option.title,
          title_i18n: option.title_i18n,
          hasChildren: false,
        })) || [],
    [productOptions, type],
  )

  const handleOptionsChange = useCallback(
    (id: string) => {
      const selectedIds = option_ids || []
      const set = new Set(selectedIds)
      set.has(id) ? set.delete(id) : set.add(id)
      onChange(type, [...set], required)
    },
    [onChange, required, option_ids, type],
  )

  const handleRequiredChange = useCallback(
    (_, isChecked) => {
      onChange(type, option_ids, isChecked)
    },
    [onChange, option_ids, type],
  )

  return (
    <Styled.FormWrapper data-test-id={'active-ingredients-form'}>
      <Styled.MultiSelectOptions
        {...selectProps}
        options={selectOptions}
        selected={option_ids || []}
        onChange={handleOptionsChange}
        invalid={touched && !option_ids.length}
        errorText={t('productOptions:errors.oneOrMore')}
        noneSelected={t('productOptions:labels.searchPlaceholder')}
        isSearchable
      />
      <Styled.RequiredCheckbox label={t('labels.required')} isChecked={required} onChange={handleRequiredChange} />
      {!!option_ids.length && (
        <Styled.PreviewBlock data-test-id="preview">
          <Styled.PreviewBlockTitle>{t('labels.selectedItems')}</Styled.PreviewBlockTitle>
          {selectOptions
            .filter(item => option_ids.includes(item.id))
            .map(item => (
              <Styled.PreviewBlockItem key={item.id}>{pick(item.title_i18n)}</Styled.PreviewBlockItem>
            ))}
        </Styled.PreviewBlock>
      )}
    </Styled.FormWrapper>
  )
})

ActiveIngredientsForm.displayName = 'ActiveIngredientsForm'

export default ActiveIngredientsForm
