import React, { FC, useMemo } from 'react'
import { useWizardsList } from 'modules/domain/productWizard/hooks'
import { ProductWizardManager } from 'views/components/ProductWizards/ProductWizardManager'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { WizardData } from '@agro-club/frontend-shared'

type PropsType = {
  wizardIds: string[]
  changeOrderList: (id: string, qty: number, wizardComment?: string, wizard_data?: WizardData<any>) => void
}

const ContainerWrapper = styled.div`
  padding: 16px 24px 0;
`

const Title = styled.div`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.48;
`

export const ProductWizardItem: FC<PropsType> = ({ wizardIds, changeOrderList }: PropsType) => {
  const { t } = useTranslation('product')
  const params = useMemo(() => {
    return { wizard_id: wizardIds, full_data: true }
  }, [wizardIds])
  const [progress, wizards] = useWizardsList(params)

  const renderWizardItem = wizards?.map(wizard => {
    return (
      <>
        <ProductWizardManager product={wizard} key={wizard.id} progress={progress} changeOrderList={changeOrderList} />
      </>
    )
  })

  return (
    <>
      {wizards?.length ? (
        <ContainerWrapper>
          <Title>{t('product:form.wizard')}</Title>
          {renderWizardItem}
        </ContainerWrapper>
      ) : null}
    </>
  )
}
