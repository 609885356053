import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, FormComponents, useFormManager, OptionalString, OptionalNumber } from '@agro-club/frontend-shared'
import { Progress } from 'modules/types'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { StorefrontItemType, StorefrontSku } from 'modules/domain/storefront/types'
import StatusForm, { StatusFormProps } from 'views/pages/Product/ProductDetailsForm/StatusForm'
import { Status } from 'types/entities'
import * as Styled from './styled'
import StorefrontSkuForm, {
  SkuFormProps,
} from 'views/pages/Storefront/Storefront/StorefrontItemModal/StorefrontSkuForm'
import { StorefrontItemModalData } from 'views/pages/Storefront/Storefront/StorefrontItemModal/StorefrontItemModal'
import StorefrontOptionsForm, {
  OptionsFormProps,
} from 'views/pages/Storefront/Storefront/StorefrontItemModal/StorefrontOptionsForm'
import StorefrontSelectors from 'modules/domain/storefront/selectors'
import { useSelector } from 'react-redux'
import useLangPicker from 'hooks/useLangPicker'

export type FormProps = StorefrontSku

type FormikManagerData = {
  skuForm: SkuFormProps
  status: StatusFormProps
  optionsForm: OptionsFormProps
}

export type StorefrontItemFormInitialValues = Partial<FormProps> & StorefrontItemModalData

type StorefrontItemFormProps = {
  initialValues?: StorefrontItemFormInitialValues
  progress?: Progress
  onSubmit: (form: Partial<FormProps>) => void
  onCancel(): void
}

const StorefrontItemDetailForm: React.FC<StorefrontItemFormProps> = ({
  initialValues = {},
  progress,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation(['storefront', 'common'])
  const { bind, submitAll, dirty, valid, reset } = useFormManager<FormikManagerData>()
  const [submitCount, setSubmitCount] = useState(0)
  const { updateProgress, updateErrorDetail } = useSelector(StorefrontSelectors.meta(initialValues.id))
  const addProgress = useSelector(StorefrontSelectors.addProgress)
  const addErrorDetail = useSelector(StorefrontSelectors.addErrorDetail)
  const { pick } = useLangPicker()

  const serverError = useMemo(() => {
    if (submitCount === 0) return null
    return updateProgress === Progress.ERROR
      ? updateErrorDetail
      : addProgress === Progress.ERROR
      ? addErrorDetail
      : null
  }, [addErrorDetail, addProgress, submitCount, updateErrorDetail, updateProgress])

  const handleSubmit = async () => {
    try {
      const [valid, forms] = await submitAll()
      setSubmitCount(submitCount + 1)

      if (!valid) {
        return
      }

      onSubmit({
        id: initialValues.id || '',
        type: StorefrontItemType.Product,
        sku_id: forms.skuForm.sku_id,
        price: forms.skuForm.price,
        price_type: forms.skuForm.price_type,
        special_prices: forms.skuForm.special_prices,
        min_qty: OptionalString(forms.skuForm.min_qty),
        max_qty: OptionalString(forms.skuForm.max_qty),
        default_qty: OptionalString(forms.skuForm.default_qty),
        status: forms.status.active ? Status.Active : Status.Inactive,
        is_out_of_stock: forms.skuForm.is_out_of_stock,
        out_of_stock_date: forms.skuForm.out_of_stock_date,
        available_for: forms.skuForm.available_for,
        params: {
          type: 'product',
          product_id: initialValues.params?.product_id,
          package_id: forms.skuForm.package_id,
          package_capacity: forms.skuForm.package_capacity,
          options: forms.optionsForm.options || [],
        },
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  useValidationErrorNotification(submitCount, valid)

  const handleCancel = () => {
    reset()
    onCancel()
  }

  return (
    <>
      <Styled.Content>
        <Styled.ContentBody>
          <Styled.ProductInfo>
            <Styled.ProductTitle>
              {t('skuForm.labels.product')}: {pick(initialValues?.params?.product?.title_i18n)}
            </Styled.ProductTitle>
            <Styled.Supplier>
              {t('skuForm.labels.supplier')}: {initialValues?.params?.product?.producer?.official_name}
            </Styled.Supplier>
          </Styled.ProductInfo>
          <FormComponents.FormSection title={t('optionsForm.title')}>
            <StorefrontOptionsForm useFormik={bind('optionsForm')} options={initialValues.params?.options || []} />
          </FormComponents.FormSection>
          <StorefrontSkuForm
            useFormik={bind('skuForm')}
            initialValues={{
              sku_id: initialValues.sku_id,
              price: initialValues.price,
              price_type: initialValues.price_type,
              special_prices: initialValues.special_prices,
              package_id: initialValues.params?.package_id,
              package_capacity: initialValues.params?.package_capacity,
              min_qty: OptionalNumber(initialValues.min_qty),
              max_qty: OptionalNumber(initialValues.max_qty),
              default_qty: OptionalNumber(initialValues.default_qty),
              is_out_of_stock: !!initialValues.is_out_of_stock,
              out_of_stock_date: initialValues.out_of_stock_date,
              available_for: initialValues.available_for || [],
            }}
          />
          <StatusForm
            useFormik={bind('status')}
            active={initialValues.status ? initialValues.status === Status.Active : true}
          />
          <Styled.ServerErrorMessage>{serverError}</Styled.ServerErrorMessage>
        </Styled.ContentBody>
      </Styled.Content>
      <Styled.Footer>
        <Button
          data-test-id={'submit'}
          filled
          intent={'primary'}
          type={'submit'}
          progress={progress}
          onClick={handleSubmit}
          disabled={!dirty || progress === Progress.WORK}
        >
          {t('common:save')}
        </Button>
        <Button onClick={handleCancel} intent={'cancel'}>
          {t('common:cancel')}
        </Button>
      </Styled.Footer>
    </>
  )
}

export default StorefrontItemDetailForm
