import * as managers from 'modules/domain/productOptions/managers'
import { ProductOptions, ProductOptionType } from 'modules/domain/productOptions/types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EntityMultiSelect, EntityMultiSelectProps } from '../EntitySelect/EntityMultiSelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'
import { getProductOptionLabel, getProductOptionValue } from './ProductOptionsSelect'

export type ProductOptionsMultiSelectProps = EntityMultiSelectProps<ProductOptions> & {
  type?: ProductOptionType
}

export const ProductOptionsMultiSelect: React.VFC<ProductOptionsMultiSelectProps> = ({ showLabel, type, ...props }) => {
  const filter = useMemo(() => ({ type }), [type])
  const { t } = useTranslation('ProductOptionMultiSelect')

  return (
    <SelectTestWrapper data-test-id="product-option-multi-select">
      <EntityMultiSelect
        filter={filter}
        getEntityByIds={managers.getItemsByIds}
        getEntityList={managers.getList}
        getOptionLabel={getProductOptionLabel}
        getOptionValue={getProductOptionValue}
        searchParamKey="title"
        placeholder={t('placeholder')}
        label={showLabel ? t('label') : undefined}
        {...props}
      />
    </SelectTestWrapper>
  )
}
