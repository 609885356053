import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import FarmerOrderRoutes from './routes'
import FarmerOrderTable from 'views/pages/FarmerOrder/FarmerOrderTable'
import FarmerOrderAdd from './FarmerOrderAdd'
import FarmerOrderEdit from './FarmerOrderEdit'
import { FormFields } from 'views/components/FarmerOrderDetailsForm/types'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import { generateActionAccessString } from 'modules/utils/generateStringHelpers'
import { Actions, FeatureFlagModifiers, Sections } from 'types/entities'

const FarmerOrderPage: React.FC = () => {
  const [initialState, setInitialState] = useState<FormFields | undefined>()
  const checkFeatureFlag = useFeatureFlags()
  const isCreatePermitted = !checkFeatureFlag(
    generateActionAccessString(Sections.FarmerOrders, Actions.Create, FeatureFlagModifiers.Restricted),
  )

  return (
    <Switch>
      <Route path={FarmerOrderRoutes.List} exact>
        <FarmerOrderTable />
      </Route>
      <Route path={FarmerOrderRoutes.Edit} exact>
        <FarmerOrderEdit onUpdateInitialState={setInitialState} />
      </Route>
      {isCreatePermitted && (
        <Route path={FarmerOrderRoutes.Add} exact>
          <FarmerOrderAdd onUpdateInitialState={setInitialState} initialState={initialState} />
        </Route>
      )}
      <Redirect to={FarmerOrderRoutes.List} />
    </Switch>
  )
}

export default FarmerOrderPage
