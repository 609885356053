import React, { memo, useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { DatePicker, FormikHook, Input, SimpleSelect, Switch } from '@agro-club/frontend-shared'
import * as Styled from './styled'
import { FormikProvider, useFormikContext } from 'formik'
import { PriceType } from 'types/entities'
import { AvailableFor } from 'modules/domain/storefront/types'
import { clone } from 'ramda'
import useDateFormat from 'hooks/useDateFormat'
import { PackageTypeSelect } from 'views/components/PackageTypeSelect/PackageTypeSelect'

export type SkuFormProps = {
  sku_id?: string
  price?: string
  price_type?: string
  special_prices?: string[]
  package_id?: string
  package_capacity?: number
  min_qty?: number
  max_qty?: number
  default_qty?: number
  is_out_of_stock: boolean
  out_of_stock_date?: string
  available_for: AvailableFor[]
}

const PriceTypeSelect: React.FC = () => {
  const { t } = useTranslation('product')
  const formik = useFormikContext<SkuFormProps>()
  const options = [
    { id: PriceType.Msrp, title: t('form.msrp') },
    { id: PriceType.None, title: t('form.notSpecified') },
  ]

  return (
    <SimpleSelect
      value={formik.values.price_type}
      onChange={v => {
        formik.setFieldValue('price_type', v)
      }}
      options={options}
      label={t('form.priceType')}
      isSearchable
    />
  )
}

const StorefrontSkuForm: React.FC<{
  useFormik: FormikHook
  initialValues: SkuFormProps
}> = memo(({ useFormik, initialValues }) => {
  const { t } = useTranslation(['storefront'])
  const dateFormat = useDateFormat({ isYearShort: true })

  const validationSchema = Yup.object({
    sku_id: Yup.string().required(t('validation:field_required')),
    price: Yup.string().nullable(),
    price_type: Yup.string(),
    min_qty: Yup.number()
      .min(1)
      .required(t('validation:field_required')),
    max_qty: Yup.number().nullable(),
    default_qty: Yup.mixed()
      .when('min_qty', (minQty, schema: any) => {
        return schema.test({
          test: defaultQty => {
            if (!defaultQty || !minQty) return true
            return defaultQty >= minQty
          },
          message: t('validation:default_qty'),
        })
      })
      .when('max_qty', (maxQty, schema: any) => {
        return schema.test({
          test: defaultQty => {
            if (!defaultQty || !maxQty) return true
            return defaultQty <= maxQty
          },
          message: t('validation:default_qty'),
        })
      }),
    package_id: Yup.string().required(t('validation:field_required')),
    availableFor: Yup.array(Yup.string().oneOf(Object.values(AvailableFor))),
    is_out_of_stock: Yup.boolean(),
  })

  const availableForInitialValue = useMemo(() => {
    if (initialValues?.available_for && !!initialValues.available_for.length) {
      return clone(initialValues.available_for).sort()
    }
    return []
  }, [initialValues.available_for])

  const formik = useFormik<Partial<SkuFormProps>>({
    initialValues: {
      ...initialValues,
      available_for: availableForInitialValue,
    },
    validationSchema,
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  const handleAvailableForChange = useCallback(
    (value: AvailableFor) => {
      let updated = clone(formik.values.available_for)

      if (updated?.includes(value)) {
        updated = updated.filter(el => el !== value)
      } else {
        updated?.push(value)
      }

      formik.setFieldValue('available_for', updated?.sort())
    },
    [formik],
  )

  const handleOutOfStockDateChange = useCallback(
    value => {
      if (formik.values.out_of_stock_date === null && value === '') {
        return
      }
      const updatedValue = !value ? undefined : value
      formik.setFieldValue('out_of_stock_date', updatedValue)
    },
    [formik],
  )

  const handlePackageTypeChange = (value?: string | number) => {
    formik.setFieldValue('package_id', value)
  }

  return (
    <FormikProvider value={formik}>
      <Styled.Grid3Col>
        <Input
          {...formik.getFieldProps('sku_id')}
          invalid={formik.touched.sku_id && !!formik.errors.sku_id}
          label={t('skuForm.labels.sku_id')}
          errorText={formik.errors.sku_id}
          required
          data-test-id="sku-id"
        />
        <Input
          {...formik.getFieldProps('price')}
          invalid={formik.touched.price && !!formik.errors.price}
          errorText={formik.errors.price}
          label={t('skuForm.labels.price')}
          type="number"
          data-test-id="price"
          min={0}
        />
        {!!formik.values.price && <PriceTypeSelect />}
      </Styled.Grid3Col>
      <Styled.Grid3Col>
        <Input<'number'>
          {...formik.getFieldProps('min_qty')}
          invalid={formik.touched.min_qty && !!formik.errors.min_qty}
          errorText={formik.errors.min_qty}
          onChange={(_, value) => formik.setFieldValue('min_qty', value)}
          label={t('skuForm.labels.minQty')}
          type="number"
          data-test-id="min-qty"
          min={0}
          step={0.1}
          enableStrictStep
          required
        />
        <Input<'number'>
          {...formik.getFieldProps('max_qty')}
          invalid={formik.touched.max_qty && !!formik.errors.max_qty}
          errorText={formik.errors.max_qty}
          onChange={(_, value) => formik.setFieldValue('max_qty', value)}
          label={t('skuForm.labels.maxQty')}
          type="number"
          data-test-id="max-qty"
          min={0}
          step={0.1}
          enableStrictStep
        />
        <Input<'number'>
          {...formik.getFieldProps('default_qty')}
          invalid={formik.touched.default_qty && !!formik.errors.default_qty}
          errorText={formik.errors.default_qty}
          label={t('skuForm.labels.defaultQty')}
          onChange={(_, value) => formik.setFieldValue('default_qty', value)}
          data-test-id="default-qty"
          type="number"
          min={1}
          step={0.1}
          enableStrictStep
        />
      </Styled.Grid3Col>
      <Styled.Grid3Col>
        <PackageTypeSelect
          value={formik.values.package_id}
          placeholder={t('skuForm.placeholders.select')}
          invalid={formik.touched.package_id && !!formik.errors.package_id}
          onChange={handlePackageTypeChange}
          label={t('skuForm.labels.packageType')}
          errorText={t('validation:field_required')}
          required
          menuMaxHeight="250px"
        />
      </Styled.Grid3Col>
      <Styled.AvailableForBox>
        <Styled.MediumCheckbox
          onChange={() => handleAvailableForChange(AvailableFor.FarmerOrder)}
          label={t('skuForm.labels.farmerOrder')}
          isChecked={formik.values.available_for?.includes(AvailableFor.FarmerOrder)}
        />
        <Styled.MediumCheckbox
          onChange={() => handleAvailableForChange(AvailableFor.DistributorOrder)}
          label={t('skuForm.labels.retailerOrder')}
          isChecked={formik.values.available_for?.includes(AvailableFor.DistributorOrder)}
        />
      </Styled.AvailableForBox>
      <DatePicker
        onChange={handleOutOfStockDateChange}
        date={formik.values.out_of_stock_date}
        invalid={formik.touched.out_of_stock_date && !!formik.errors.out_of_stock_date}
        errorText={formik.errors.out_of_stock_date}
        label={t('skuForm.labels.outOfStockDate')}
        format={dateFormat}
      />
      <Styled.SwitchWrapper>
        <Switch
          label={t('skuForm.labels.inStock')}
          on={!formik.values.is_out_of_stock}
          onClick={value => {
            formik.setFieldValue('is_out_of_stock', !value)
          }}
          testId="in-stock-switch"
        >
          {formik.values.is_out_of_stock ? t('skuForm.labels.outOfStock') : t('skuForm.labels.inStock')}
        </Switch>
      </Styled.SwitchWrapper>
    </FormikProvider>
  )
})

StorefrontSkuForm.displayName = 'StorefrontSkuForm'

export default StorefrontSkuForm
