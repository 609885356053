import React, { VFC } from 'react'
import * as CommonStyled from 'views/components/ProductWizards/styled'
import { Progress } from 'modules/types'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import { Procote, ProcoteWizardNode, WizardData } from '@agro-club/frontend-shared'

type PropsType = {
  opened: boolean
  onClose: () => void
  productWizard: ProcoteWizardNode | null
  progress: Progress
  changeOrderList: (id: string, qty: number, wizardComment?: string, wizard_data?: WizardData<any>) => void
}

export const ProductWizardProcote: VFC<PropsType> = ({
  changeOrderList,
  progress,
  productWizard,
  opened,
  onClose,
}: PropsType) => {
  if ((progress === Progress.SUCCESS || opened) && productWizard) {
    return <Procote opened={opened} onClose={onClose} handleCrm={changeOrderList} productWizard={productWizard} />
  } else {
    return (
      <CommonStyled.SpinnerWrap>
        <SpinnerLayout />
      </CommonStyled.SpinnerWrap>
    )
  }
}
