import React from 'react'

import { Redirect, Route, Switch } from 'react-router-dom'
import TargetRoutes from './routes'
import TargetList from './TargetList'
import { TargetTypes } from 'modules/domain/target2/common/types'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'

const TargetPage: React.FC = () => {
  const [showTerritoryTab, showRetailerTab, showFarmerTab] = usePermissions([
    { capability: CAPABILITY.TERRITORY_TARGETS, permission: PERMISSION.R },
    { capability: CAPABILITY.RETAILER_TARGETS, permission: PERMISSION.R },
    { capability: CAPABILITY.FARMER_TARGETS, permission: PERMISSION.R },
  ])
  return (
    <Switch>
      {showTerritoryTab && (
        <Route path={[TargetRoutes.TerritoryList, TargetRoutes.DefaultList]} exact={true}>
          <Redirect to={TargetRoutes.TerritoryList} />
          <TargetList targetType={TargetTypes.Territory} />
        </Route>
      )}

      {showRetailerTab && (
        <Route path={[TargetRoutes.TerritoryList, TargetRoutes.RetailerList, TargetRoutes.DefaultList]} exact={true}>
          <Redirect to={TargetRoutes.RetailerList} />
          <TargetList targetType={TargetTypes.Retailer} />
        </Route>
      )}

      {showFarmerTab && (
        <Route
          path={[
            TargetRoutes.TerritoryList,
            TargetRoutes.RetailerList,
            TargetRoutes.FarmerList,
            TargetRoutes.DefaultList,
          ]}
          exact={true}
        >
          <Redirect to={TargetRoutes.FarmerList} />
          <TargetList targetType={TargetTypes.Farmer} />
        </Route>
      )}
    </Switch>
  )
}

export default TargetPage
