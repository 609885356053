import React from 'react'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import MailingListRoutes from 'views/pages/MailingList/routes'
import { useTranslation } from 'react-i18next'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { useParams } from 'react-router-dom'
import { useMailingListItem } from 'modules/domain/mailingList/hooks'
import { Progress } from 'modules/types'
import Status from './Status'
import {
  Flag,
  FormText,
  SectionBody,
  SectionContainer,
  useAction,
  useHistoryPush,
  FormComponents,
} from '@agro-club/frontend-shared'
import MailingListActions from 'modules/domain/mailingList/duck'
import MailingListSelectors from 'modules/domain/mailingList/selectors'
import { useSelector } from 'react-redux'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { RemoveButton } from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import useDateFormatFn from 'hooks/useDateFormatFn'

const MailingListItem: React.FC = () => {
  const params = useParams<{ id: string }>()
  const { t } = useTranslation(['mailingList', 'common'])
  const [progress, item] = useMailingListItem(params.id)
  const meta = useSelector(state => MailingListSelectors.meta(state, params.id))
  const push = useHistoryPush()
  const page = useSelector(MailingListSelectors.page)
  const goBack = () => push({ route: MailingListRoutes.List, query: { page } })
  const removeAction = useAction(MailingListActions.removeRequested, params.id)
  const getFormatedDate = useDateFormatFn({ withTime: true })

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  if (progress === Progress.WORK) {
    return loading()
  }

  if (progress === Progress.ERROR || !item) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'large'} title={t('item.heading')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <StickyFooterLayout.Wrapper>
          <StickyFooterLayout.Body>
            <SectionContainer>
              <SectionBody>
                <FormComponents.FormSection title={'info'}>
                  <FormText label={'id'}>{item.id}</FormText>
                  <FormText label={t('fields.status')}>
                    <Status status={item.status} />
                  </FormText>
                  <FormText label={t('fields.email')}>{item.email}</FormText>
                  <FormText label={t('fields.region')}>{item.region ? <Flag code={item.region} /> : null}</FormText>
                  <FormText label={t('fields.createdAt')}>{getFormatedDate(item.created_at)}</FormText>
                  <FormText label={t('fields.updatedAt')}>{getFormatedDate(item.updated_at)}</FormText>
                </FormComponents.FormSection>
              </SectionBody>
            </SectionContainer>
          </StickyFooterLayout.Body>
          <StickyFooterLayout.ButtonsFooter>
            <RemoveButton intent={'danger'} onClick={() => removeAction()} progress={meta.removeProgress}>
              {t('common:delete')}
            </RemoveButton>
          </StickyFooterLayout.ButtonsFooter>
        </StickyFooterLayout.Wrapper>
      </Layout.Content>
    </>
  )
}

export default MailingListItem
