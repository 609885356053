import { TargetTypes } from 'modules/domain/target2/common/types'
import FarmerTargetActions from 'modules/domain/target2/farmerTarget/duck'
import FarmerTargetSelectors from 'modules/domain/target2/farmerTarget/selectors'
import RetailerTargetActions from 'modules/domain/target2/retailerTarget/duck'
import RetailerTargetSelectors from 'modules/domain/target2/retailerTarget/selectors'
import TerritoryTargetActions from 'modules/domain/target2/territoryTarget/duck'
import TerritoryTargetSelectors from 'modules/domain/target2/territoryTarget/selectors'
import { endpoints } from 'modules/endpoints'

export const mappedActions = {
  [TargetTypes.Territory]: TerritoryTargetActions,
  [TargetTypes.Retailer]: RetailerTargetActions,
  [TargetTypes.Farmer]: FarmerTargetActions,
}

export const mappedSelectors = {
  [TargetTypes.Territory]: TerritoryTargetSelectors,
  [TargetTypes.Retailer]: RetailerTargetSelectors,
  [TargetTypes.Farmer]: FarmerTargetSelectors,
}

export const mappedDownloadCSVEndpoint = {
  [TargetTypes.Territory]: endpoints.territoryTarget('download/csv'),
  [TargetTypes.Retailer]: endpoints.retailerTarget('download/csv'),
  [TargetTypes.Farmer]: endpoints.farmerTarget('download/csv'),
}

export const PersistentFilterStateKey = 'new-targets-show-filter'
