import React from 'react'
import DiscountRuleActions from 'modules/domain/discountRule/duck'
import { useSelector } from 'react-redux'
import DiscountRuleSelectors from 'modules/domain/category/selectors'
import DiscountRuleDetailsForm, {
  FormProps,
} from 'views/pages/DiscountRule/DiscountRuleDetailsForm/DiscountRuleDetailsForm'
import DiscountRuleRoutes from 'views/pages/DiscountRule/routes'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'

const DiscountRuleAdd: React.FC = () => {
  const { t } = useTranslation('discountRules')
  const submitAction = useAction(DiscountRuleActions.addRequested)
  const addProgress = useSelector(DiscountRuleSelectors.addProgress)

  const handleFormSubmit = (props: FormProps) => {
    submitAction(props)
  }

  const page = useSelector(DiscountRuleSelectors.page)
  const push = useHistoryPush()

  const goBack = () => {
    push({ query: { page }, route: DiscountRuleRoutes.List })
  }

  useHelmet({ title: t('addDiscountRuleMetaTitle') })

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title title={t('form.add')} />
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <DiscountRuleDetailsForm progress={addProgress} onCancel={goBack} onSubmit={handleFormSubmit} />
      </Layout.Content>
    </>
  )
}

export default DiscountRuleAdd
