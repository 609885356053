import React, { useCallback, useMemo } from 'react'
import FarmerOrderDetailsForm from 'views/components/FarmerOrderDetailsForm/FarmerOrderDetailsForm'
import { FormFields } from 'views/components/FarmerOrderDetailsForm/types'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useParams } from 'react-router-dom'
import Routes from './routes'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FarmerOrderSelectors from 'modules/domain/farmerOrder/selectors'
import FarmerOrderActions from 'modules/domain/farmerOrder/duck'
import { useFarmerOrder } from 'modules/domain/farmerOrder/hooks'
import { Progress } from 'modules/types'
import FarmerOrderStatusColored from 'views/components/FarmerOrderStatusColored/FarmerOrderStatusColored'
import { DistributorConfirmationStatus, FarmerOrderDTO } from 'modules/domain/farmerOrder/types'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import Item404 from 'views/layouts/Item404/Item404'
import ItemGenericError from 'views/layouts/ItemGenericError/ItemGenericError'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import * as uuid from 'uuid'
import styled from 'styled-components'
import { FileData } from 'views/components/FileManager/types'
import { FarmerOrderDeliveryAddress, FarmerOrderStatus } from 'types/farmerOrder'
import AuthSelectors from 'modules/domain/auth/selectors'
import { isAgro, ROLES } from 'types/entities'

const Subtitle = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.secondary200};
`

const FarmerOrderEdit: React.FC<{ onUpdateInitialState(props: FormFields): void }> = ({ onUpdateInitialState }) => {
  const { t } = useTranslation('farmerOrder')
  const params = useParams<{ id: string }>()
  const push = useHistoryPush()
  const page = useSelector(FarmerOrderSelectors.page)
  const meta = useSelector(state => FarmerOrderSelectors.meta(state, params.id))
  const role = useSelector(AuthSelectors.role)
  const isAdmin = isAgro(role)

  const goBack = useCallback(() => push({ route: Routes.List, query: page ? { page } : undefined }), [page, push])
  const updateOrder = useAction(FarmerOrderActions.updateRequested)
  const removeOrder = useAction(FarmerOrderActions.removeRequested, params.id)

  const [progress, order] = useFarmerOrder(params.id)

  useHelmet({ title: t('editPageTitle', { id: order ? order.slug : '' }) })

  const justRedirect = useCallback(() => {
    push({ route: Routes.Add })
  }, [push])

  const handleSubmit = useCallback(
    (
      values: FormFields,
      options: { duplicate: boolean; dirty: boolean; isAddRetailerOrderSuggestionEnabled: boolean },
    ) => {
      const { delivery_date, desired_delivery_date, address, city, country, postal_code, region_id, ...rest } = values
      const update = (duplicate: boolean) => {
        if (order) {
          const deliveryAddress: FarmerOrderDeliveryAddress = {
            desired_delivery_date,
            delivery_date,
            address,
            city,
            country,
            postal_code,
            region_id,
          }

          let key: keyof typeof deliveryAddress
          for (key in deliveryAddress) {
            if (!deliveryAddress[key]) {
              delete deliveryAddress[key]
            }
          }
          updateOrder(
            order.id,
            {
              ...rest,
              producer_id: order.producer_id,
              delivery_addresses: [deliveryAddress],
              revision: order.revision,
            },
            duplicate,
            options.isAddRetailerOrderSuggestionEnabled,
          )
        }
      }

      if (!options.duplicate) {
        update(false)
      } else {
        onUpdateInitialState(values)
        if (options.dirty) {
          update(true)
        } else {
          justRedirect()
        }
      }
    },
    [justRedirect, onUpdateInitialState, order, updateOrder],
  )

  const refreshOrder = useCallback(
    (isAddRetailerOrderSuggestionEnabled: boolean) => {
      if (order) {
        updateOrder(order.id, {}, false, isAddRetailerOrderSuggestionEnabled)
      }
    },
    [order, updateOrder],
  )

  const handleDistributorConfirm = useCallback(
    (status: DistributorConfirmationStatus, isAddRetailerOrderSuggestionEnabled: boolean) => {
      if (order) {
        updateOrder(
          order.id,
          {
            interaction: {
              ...order.interaction,
              confirmed_by_distributor: status,
            },
            revision: order.revision,
          },
          false,
          isAddRetailerOrderSuggestionEnabled,
        )
      }
    },
    [order, updateOrder],
  )

  const handleDistributorCancel = useCallback(
    (role: ROLES) => {
      if (order) {
        const payload: Partial<FarmerOrderDTO> = {
          status: FarmerOrderStatus.Canceled,
          interaction: {
            ...order.interaction,
            confirmed_by_distributor: 'rejected',
            canceled_by_role: role,
          },
          revision: order.revision,
        }

        updateOrder(order.id, payload)
      }
    },
    [order, updateOrder],
  )

  const handleRestore = useCallback(() => {
    if (order) {
      updateOrder(
        order.id,
        {
          status: FarmerOrderStatus.Changed,
        },
        false,
      )
    }
  }, [order, updateOrder])

  const loading = () => <ItemLoadingLayout id={params.id} onBack={goBack} />
  const error404 = () => <Item404 id={params.id} onBack={goBack} title={t('errors.notFoundTitle')} />
  const errorUnknown = () => <ItemGenericError id={params.id} onBack={goBack} title={t('errors.unknownErrorTitle')} />

  const files = useMemo(() => {
    if (!order || !order?.files?.length) return []
    return order.files.map((item: FileData) => ({
      file: item.url,
      kind: 'current' as const,
      id: uuid.v4(),
      error: null,
    }))
  }, [order])

  if (progress === Progress.ERROR) {
    if (meta.fetchError === 'not_found') {
      return error404()
    }
    return errorUnknown()
  }

  if (progress === Progress.WORK || !order) {
    return loading()
  }

  const initialValues: FormFields = {
    status: order.status,
    producer_id: order.producer_id,
    owner_id: order.owner_id,
    distributor_id: order.distributor_id,
    items: order.items,
    interaction: order.interaction,
    comment: order.comment,
    promocodes: order.promocodes,
    revision: order.revision,
    delivery_date: order.delivery_addresses[0]?.delivery_date,
    desired_delivery_date: order.delivery_addresses[0]?.desired_delivery_date,
    address: order.delivery_addresses[0]?.address,
    city: order.delivery_addresses[0]?.city,
    region_id: order.delivery_addresses[0]?.region_id || '',
    postal_code: order.delivery_addresses[0]?.postal_code,
    country: order.delivery_addresses[0]?.country,
    order_date: order.order_date,
    season_id: order.season_id,
    files,
    farmer_comment: order.farmer_comment,
  }

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title compact size={'small'} title={t('editPageTitle', { id: order.slug })} />
          <FarmerOrderStatusColored
            status={order.status}
            dangerouslySetInnerHTML={{ __html: t(`status.${order.status}`) }}
          />
          <Subtitle>{`${t('editPageSeasonSubtitle')}: ${order?.season?.title}`}</Subtitle>
          {isAdmin && <Subtitle>ID: {order.id}</Subtitle>}
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <FarmerOrderDetailsForm
          mode={'edit'}
          onCancel={goBack}
          onSubmit={handleSubmit}
          onDistributorConfirmation={handleDistributorConfirm}
          onDistributorCancellation={handleDistributorCancel}
          onRemove={removeOrder}
          onRestore={handleRestore}
          onRefresh={refreshOrder}
          initialValues={initialValues}
          order={order}
          removeProgress={meta.removeProgress}
          progress={meta.updateProgress}
          updateError={meta.updateError}
          totalNet={order.total_net}
        />
      </Layout.Content>
    </>
  )
}

export default FarmerOrderEdit
