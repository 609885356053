import {
  Button,
  Checkbox,
  DatePicker,
  FormComponents,
  helpersObject,
  Radio,
  RadioItem,
  SectionBody,
  SectionContainer,
  SectionTitle,
  TextArea,
  useAction,
  WizardData,
} from '@agro-club/frontend-shared'
import { useFormik } from 'formik'
import checkCompanyFeatureFlags from 'helpers/checkCompanyFeatureFlags'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import useDateFormat from 'hooks/useDateFormat'
import useHasOutOfStockDateExpired from 'hooks/useHasOutOfStockDateExpired'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { DEFAULT_COUNTRY } from 'modules/constants'
import AuthSelectors from 'modules/domain/auth/selectors'
import { getProducersList } from 'modules/domain/company/managers'
import { Company } from 'modules/domain/company/types'
import { useUserDocumentsNoCache } from 'modules/domain/document/hooks'
import { FarmerData } from 'modules/domain/farmer/types'
import { DistributorConfirmationStatus } from 'modules/domain/farmerOrder/types'
import { IncentiveProgramScope } from 'modules/domain/incentiveProgram/types'
import { Promocode } from 'modules/domain/promocode/types'
import { useSeasonList } from 'modules/domain/season/hooks'
import { SkuOption, StorefrontSku } from 'modules/domain/storefront/types'
import { UpdateError } from 'modules/domain/types'
import { CAPABILITY, PERMISSION, useOrderUpdatePermissions, usePermissions } from 'modules/permissions/permissions'
import { Progress } from 'modules/types'
import {
  generateActionAccessString,
  generateCrmFieldAccessString,
  generateCustomFeatureFlag,
  generateFieldModifierString,
} from 'modules/utils/generateStringHelpers'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import { clone, remove } from 'ramda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import {
  Actions,
  CompanyType,
  CustomFeatureName,
  FeatureFlagModifiers,
  FieldLocation,
  FieldNames,
  isAgro,
  isProducersHead,
  ROLES,
  Sections,
} from 'types/entities'
import { FarmerOrderDeliveryAddress, FarmerOrderStatus } from 'types/farmerOrder'
import { FarmerOrderSku, FarmerOrderSkuItem } from 'types/farmerOrderSku'
import * as uuid from 'uuid'
import CancelSwitch from 'views/components/FarmerOrderDetailsForm/CancelSwitch'
import ControlButtons from 'views/components/FarmerOrderDetailsForm/ControlButtons'
import FarmerAddressForm from 'views/components/FarmerOrderDetailsForm/FarmerAddressForm'
import OrderDocuments from 'views/components/FarmerOrderDetailsForm/OrderDocuments/OrderDocuments'
import OrderStatusBlock from 'views/components/FarmerOrderDetailsForm/OrderStatusBlock'
import { FarmersSelect } from 'views/components/FarmersSelect/FarmersSelect'
import FileManager from 'views/components/FileManager/FileManager'
import { FileItem } from 'views/components/FileManager/types'
import OrderSkuProductItems from 'views/components/OrderSkuCardItems/OrderSkuCardItems'
import OrderSkuProductItemsReadOnly from 'views/components/OrderSkuCardItems/OrderSkuCardItemsReadOnly'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import CompanyRoutes from 'views/pages/Company/routes'
import FarmerRoutes from 'views/pages/Farmer/routes'
import * as Yup from 'yup'
import CompanyInfoPreview from 'views/components/CompanyInfoPreview/CompanyInfoPreview'
import { CompanySelect } from 'views/components/CompanySelect/CompanySelect'
import CustomerInfo from 'views/components/CustomerModal/CustomerInfo'
import CustomerModal from 'views/components/CustomerModal/CustomerModal'
import { OrderSkuDiscounts } from 'views/components/OrderSkuDiscounts/OrderSkuDiscounts'
import styled, { StyledProps } from 'styled-components'
import { StorefrontCardForSelect } from 'modules/domain/storefrontCard/types'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'
import RetailerAllocationActions from 'modules/domain/allocation/retailerAllocation/duck'
import ModalActions from 'modules/domain/modal/duck'
import { ModalWithButtonContent } from 'views/components/ModalWithButtons/ModalWithButtonsContent'
import OrderHistoryCard from 'views/components/OrderHistoryCard/OrderHistoryCard'
import CommentsBlock from 'views/components/FarmerOrderDetailsForm/CommentsBlock'
import { Collections, CommentEntry } from 'modules/domain/comments/types'
import { fakeSku } from 'modules/domain/storefront/helpers'
import OrderCancellationReasonModal from 'views/components/OrderCancellationReasonModal/OrderCancellationReasonModal'

export const Wrapper = styled.div<StyledProps<{ language?: string }>>`
  position: relative;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto auto;
`

export const Overlay = styled.div<StyledProps<{ isUpdatePermitted?: boolean }>>`
  ${({ isUpdatePermitted }) => {
    return (
      !isUpdatePermitted && {
        width: '100%',
        height: '100%',
        cursor: 'not-allowed',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
      }
    )
  }}
`

export const Column = styled.div`
  min-width: 354px;
  width: fit-content;
  display: grid;
  grid-gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
`

export const ErrorStyled = styled.div<StyledProps<{}>>`
  color: ${props => props.theme.color.accentDestructive};
  margin-top: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  bottom: -20px;
`

export const FarmerBlock = styled.div`
  position: relative;
`

export const DatePickerWrapper = styled.div`
  width: 220px;
`

export const Label = styled.span`
  font-size: 14px;
  flex-grow: 1;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
`

export const CommentFormSection = styled(FormComponents.FormSection)`
  padding: 24px 0 6px 24px;
  margin: 0;
  border-bottom: none !important;

  & > h3 {
    margin-bottom: 0;
  }
`

export type FormFields = FarmerOrderDeliveryAddress &
  Partial<Pick<FarmerOrderSku, 'producer_id' | 'seller_id' | 'owner_id'>> &
  Pick<
    FarmerOrderSku,
    | 'distributor_id'
    | 'comment'
    | 'status'
    | 'sku_items'
    | 'interaction'
    | 'revision'
    | 'promocodes'
    | 'order_date'
    | 'season_id'
    | 'farmer_comment'
    | 'admin_comment'
  > & {
    files: FileItem[]
    comments: CommentEntry[]
  }

const useProducersOptions = makeCancellableResourceListHook(makeCancelable(getProducersList))

const getDesiredDeliveryDateEnabled = (producer?: Company | null): boolean => {
  if (!producer) {
    return false
  }
  const deliveryDatesEnabledFlag = generateCrmFieldAccessString(
    Sections.Orders,
    'desiredDeliveryDates',
    FeatureFlagModifiers.Enabled,
  )
  return checkCompanyFeatureFlags(producer, deliveryDatesEnabledFlag)
}

type Props = {
  mode: 'create' | 'edit'
  initialValues: FormFields
  order?: FarmerOrderSku
  onSubmit(
    values: FormFields,
    options: { dirty: boolean; duplicate: boolean; isAddRetailerOrderSuggestionEnabled: boolean },
  ): void
  onCancel(): void
  onDistributorConfirmation?: (
    status: DistributorConfirmationStatus,
    isAddRetailerOrderSuggestionEnabled: boolean,
    updatedItems?: FarmerOrderSkuItem[],
  ) => void
  onDistributorCancellation?: (role: ROLES, comment: string) => void
  onRemove?: () => void
  onRestore?: () => void
  onRefresh?: (isAddRetailerOrderSuggestionEnabled: boolean) => void
  progress?: Progress
  removeProgress?: Progress
  updateError?: UpdateError | 'unknown' | null
  totalNet?: string
}

enum FarmerSelectType {
  Modal = 'modal',
  Select = 'select',
}

const FarmerOrderSkuDetailsForm: React.FC<Props> = ({
  mode,
  order,
  initialValues,
  onRemove,
  onRestore,
  onDistributorConfirmation,
  onDistributorCancellation,
  onCancel,
  onSubmit,
  onRefresh,
  removeProgress,
  progress,
  updateError,
}) => {
  const { t } = useTranslation(['farmerOrder', 'common', 'company', 'labels'])
  const [usersPermitted] = usePermissions([{ capability: CAPABILITY.USERS, permission: PERMISSION.R }])
  const updatePermissions = useOrderUpdatePermissions()
  const userCompany = useSelector(AuthSelectors.userCompany)
  const role = useSelector(AuthSelectors.role)
  const initialRender = useRef(true)
  const [customer, setCustomer] = useState<FarmerData | null | undefined>(order?.customer)
  const [showCustomerModal, setShowCustomerModal] = useState(false)
  const [selectedPromocode, setSelectedPromocode] = useState<string>()
  const [selectedDocument, setSelectedDocument] = useState<string>()
  const profile = useSelector(AuthSelectors.profile)
  const country = profile?.country || DEFAULT_COUNTRY
  const [farmerSelectType, setFarmerSelectType] = useState<FarmerSelectType>(FarmerSelectType.Select)
  const [, producers = []] = useProducersOptions()
  const checkFeatureFlag = useFeatureFlags()
  const [skuMatchingErrors, setSkuMatchingErrors] = useState<boolean[]>([])
  const dateFormat = useDateFormat({ withTime: true })

  const [isProductQtyMoreThanLimitAfterDuplicate, setIsProductQtyMoreThanLimitAfterDuplicate] = useState(false)
  const [isProductQtyMoreThanLimit, setIsProductQtyMoreThanLimit] = useState(false)
  const clearProductsLimitsForFarmerOrders = useAction(RetailerAllocationActions.clearProductsLimitsForFarmerOrders)

  const isAdmin = isAgro(role)
  const isProducerHead = isProducersHead(role)
  const isProducer = !!role && [ROLES.PRODUCER_HEAD, ROLES.PRODUCER_MANAGER].includes(role)
  const isDistributor = !!role && [ROLES.DISTRIBUTOR_HEAD, ROLES.DISTRIBUTOR_MANAGER].includes(role)
  const isOrderConfirmedByDistributor = order?.interaction.confirmed_by_distributor === 'confirmed'

  const isPromocodeDisabled =
    mode === 'edit' && (!updatePermissions.promocode || (isDistributor && !isOrderConfirmedByDistributor))

  const isCommentDisabled =
    mode === 'edit' && (!updatePermissions.comment || (isDistributor && !isOrderConfirmedByDistributor))

  const isSeasonDisabled =
    mode === 'edit' && (!updatePermissions.distributorSelection || (isDistributor && !isOrderConfirmedByDistributor))

  const producerConfirmationEnabledFeatureFlag = generateCrmFieldAccessString(
    'farmerOrders',
    'confirmedByManufacturer',
    FeatureFlagModifiers.Enabled,
  )
  const isHistoryCardVisible = checkFeatureFlag(generateCustomFeatureFlag(Sections.FarmerOrders, 'historyCardVisible'))
  const isManufacturerConfirmationEnabled = checkFeatureFlag(producerConfirmationEnabledFeatureFlag)

  const farmerConfirmRequired = generateCustomFeatureFlag(Sections.Orders, CustomFeatureName.FarmerConfirmRequired)
  const isFarmerConfirmationEnabled = checkFeatureFlag(farmerConfirmRequired)

  const [, seasons] = useSeasonList()

  const openModal = useAction(ModalActions.open)

  const isUpdatePermitted = !checkFeatureFlag(
    generateActionAccessString(Sections.FarmerOrders, Actions.Update, FeatureFlagModifiers.Restricted),
  )

  const showComment =
    isAdmin ||
    checkFeatureFlag(
      generateCrmFieldAccessString(Sections.FarmerOrders, 'productComment', FeatureFlagModifiers.Enabled),
    )

  const isAttachmentsVisible = !checkFeatureFlag(
    generateFieldModifierString(FieldLocation.SkuOrders, FieldNames.Attachments, FeatureFlagModifiers.Hidden),
  )

  const isCommentsOfRejectReasonsVisible =
    isAdmin ||
    checkFeatureFlag(generateCustomFeatureFlag(Sections.FarmerOrders, CustomFeatureName.CommentsOfRejectReasonsVisible))

  const isSeasonClosed = useMemo(() => seasons?.find(season => season.id === order?.season_id)?.is_season_closed, [
    order?.season_id,
    seasons,
  ])

  const isEditable = !order || (isSeasonClosed ? isAdmin : true)

  const hideFarmerInfoChecking = useMemo(() => {
    if (userCompany?.company_type !== CompanyType.Distributor) {
      return false
    }

    const flag = generateCrmFieldAccessString(
      Sections.FarmerOrders,
      'farmerInfoForRetailer',
      FeatureFlagModifiers.Hidden,
    )

    return producers.some(producer => checkCompanyFeatureFlags(producer, flag, role))
  }, [producers, userCompany, role])

  const currentDate = useMemo(() => {
    return new Date().toISOString()
  }, [])

  const formInitialValues = {
    producer_id: initialValues.producer_id || undefined,
    distributor_id: initialValues.distributor_id || undefined,
    status: initialValues.status,
    delivery_date: initialValues.delivery_date || '',
    desired_delivery_date: initialValues.desired_delivery_date || '',
    address: initialValues.address || '',
    city: initialValues.city || '',
    region_id: initialValues.region_id || '',
    country: initialValues.country,
    postal_code: initialValues.postal_code || '',
    comment: initialValues.comment || '',
    sku_items: initialValues.sku_items || [],
    owner_id: initialValues.owner_id || '',
    interaction: {
      confirmed_by_agroclub: initialValues.interaction.confirmed_by_agroclub || false,
      confirmed_by_producer: initialValues.interaction.confirmed_by_producer || false,
      confirmed_by_distributor: initialValues.interaction.confirmed_by_distributor || 'unset',
      confirmed_by_farmer: initialValues.interaction.confirmed_by_farmer || false,
      canceled_by_role: initialValues.interaction.canceled_by_role || null,
      credit_offer_accepted: initialValues.interaction.credit_offer_accepted || false,
    },
    promocodes: initialValues.promocodes,
    revision: initialValues.revision,
    order_date: initialValues.order_date || currentDate,
    season_id: initialValues.season_id,
    files: initialValues.files,
    farmer_comment: initialValues.farmer_comment,
    comments: initialValues.comments,
    admin_comment: initialValues.admin_comment,
  }

  const validationSchema = useMemo(() => {
    return Yup.object({
      producer_id: Yup.string().required(t('validation:field_required')),
      owner_id: Yup.string().required(t('validation:farmerRequired')),
      status: Yup.string().required(t('validation:field_required')),
      order_date: Yup.string().required(t('validation:field_required')),
      season_id: Yup.string().required(),
      sku_items: Yup.array().min(1, t('validation:oneSkuMinRequired')),
    })
  }, [t])

  const formik = useFormik<FormFields>({
    initialValues: { ...formInitialValues },
    validationSchema,
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  const selectedProducer = useMemo(() => producers?.find(p => p.id === formik.values.producer_id), [
    formik.values.producer_id,
    producers,
  ])

  const isDesiredDeliveryDateEnabled = useMemo(() => {
    return selectedProducer ? getDesiredDeliveryDateEnabled(selectedProducer) : false
  }, [selectedProducer])

  const isAddRetailerOrderSuggestionEnabled = useMemo(() => {
    const selectedSeason = (seasons ?? []).find(s => s.id === formik.values.season_id)
    return !!selectedSeason?.show_retailer_order_suggestion
  }, [formik.values.season_id, seasons])

  const isRetailerInteractionByProducerEnabled = useMemo(() => {
    return checkCompanyFeatureFlags(
      selectedProducer,
      generateCustomFeatureFlag(Sections.FarmerOrders, CustomFeatureName.RetailerInteractionByProducer),
      role,
    )
  }, [selectedProducer, role])

  const distributorRelatedCompanies = useMemo(() => {
    let ids = [formik.values.distributor_id as string]

    if (isDistributor && profile?.related_companies?.length) {
      const relatedCompanyIds = profile.related_companies.map(data => data.company_id)
      ids = ids.concat(relatedCompanyIds)
    }

    return ids
  }, [formik.values.distributor_id, isDistributor, profile])

  const [currentProducer, setCurrentProducer] = useState<Company>()
  const [, userDocuments = []] = useUserDocumentsNoCache(formik.values.owner_id)
  const editingDateHasExpired = useHasOutOfStockDateExpired(formik.values.sku_items)

  useMemo(() => {
    if (!formik.values.season_id) {
      const producerCurrentSeason = (seasons ?? [])
        .filter(s => s.company_id === currentProducer?.id)
        .find(s => s.is_current)
      formik.setFieldValue('season_id', producerCurrentSeason?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasons, currentProducer])

  useEffect(() => {
    if (userCompany && userCompany.company_type === CompanyType.Distributor && !formik.values.distributor_id) {
      formik.setFieldValue('distributor_id', userCompany.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, userCompany])

  useEffect(() => {
    if (
      userCompany &&
      userCompany.company_type === CompanyType.Distributor &&
      userCompany.producers_relations.length === 1 &&
      !formik.values.producer_id
    ) {
      formik.setFieldValue('producer_id', userCompany.producers_relations[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, userCompany])

  const handleCardChange = useCallback(
    async (
      index,
      qty: number,
      card?: StorefrontCardForSelect,
      sku?: StorefrontSku,
      wizard_comment?: string,
      wizard_data?: WizardData<any>,
    ) => {
      const sku_items = clone(formik.values.sku_items)
      if (card) {
        const newItem: FarmerOrderSkuItem = {
          card,
          card_id: card.id,
          quantity: String(qty),
          sku_id: sku?.id,
          credit_facility: undefined,
          sku,
          key: uuid.v4(),
          wizard_comment,
          wizard_data,
          newItem: true,
        }
        sku_items.unshift(newItem)
        formik.setFieldValue('sku_items', sku_items)
      } else {
        sku_items[index].quantity = String(qty)
        formik.setFieldValue('sku_items', sku_items)
      }
    },
    [formik],
  )

  const handleCardRemove = useCallback(
    (index: number) => {
      if (formik.values.sku_items[index]) {
        formik.setFieldValue('sku_items', remove(index, 1, formik.values.sku_items))
      }
    },
    [formik],
  )

  const handleFinalQtyChange = useCallback(
    (idx, value: number) => {
      const sku_items = clone(formik.values.sku_items)
      if (idx !== -1) {
        sku_items[idx].final_qty = String(value)
      }
      formik.setFieldValue('sku_items', sku_items)
    },
    [formik],
  )

  const handleDeliveredQtyChange = useCallback(
    (idx, value: number) => {
      const sku_items = clone(formik.values.sku_items)
      if (idx !== -1) {
        sku_items[idx].delivered_qty = String(value)
      }
      formik.setFieldValue('sku_items', sku_items)
    },
    [formik],
  )

  const handlePackageTypeChange = useCallback(
    (idx, value: string) => {
      const sku_items = clone(formik.values.sku_items)
      if (idx !== -1) {
        if (!sku_items[idx].sku) {
          sku_items[idx].sku = {
            ...fakeSku,
            params: {
              ...fakeSku.params,
              package_id: value,
              product_id: sku_items[idx].card?.product_id,
            },
          }
        } else {
          ;(sku_items[idx].sku as StorefrontSku).params.package_id = value
          sku_items[idx].sku_id = undefined
        }
      }
      formik.setFieldValue('sku_items', sku_items)
    },
    [formik],
  )

  const handleOptionsChange = useCallback(
    (idx, value: SkuOption[]) => {
      const sku_items = clone(formik.values.sku_items)
      if (idx !== -1) {
        if (!sku_items[idx].sku) {
          sku_items[idx].sku = {
            ...fakeSku,
            params: {
              ...fakeSku.params,
              options: value,
              product_id: sku_items[idx].card?.product_id,
            },
          }
        } else {
          ;(sku_items[idx].sku as StorefrontSku).params.options = value
        }
      }
      formik.setFieldValue('sku_items', sku_items)
    },
    [formik],
  )

  const handleSkuMatched = useCallback(
    (idx, value?: StorefrontSku) => {
      if (idx === -1) return
      const sku_items = clone(formik.values.sku_items)

      if (value) {
        const numberMinQty = Number(value.min_qty || 1)
        const numberMaxQty = Number(value.max_qty)
        const numberItemQty = Number(sku_items[idx].quantity)

        if (
          (sku_items[idx].sku_id !== value?.id && !sku_items[idx].quantity) ||
          numberItemQty < numberMinQty ||
          (value.max_qty && numberItemQty > numberMaxQty)
        ) {
          sku_items[idx].quantity = value.default_qty || value.min_qty || '1'
        }

        sku_items[idx].sku = value
        sku_items[idx].sku_id = value?.id
      } else {
        sku_items[idx].sku = undefined
        sku_items[idx].sku_id = undefined
      }

      formik.setFieldValue('sku_items', sku_items)
    },
    [formik],
  )

  const handleCommentChange = useCallback(
    (idx, value: string) => {
      const sku_items = clone(formik.values.sku_items)
      if (idx !== -1) {
        sku_items[idx].comment = value
      }
      formik.setFieldValue('sku_items', sku_items)
    },
    [formik],
  )

  const handleCreditOfferAcceptedChange = useCallback(
    isAccepted => {
      formik.setFieldValue('interaction.credit_offer_accepted', isAccepted)
    },
    [formik],
  )

  const handlePromocodesChange = useCallback(
    (promocodes: Promocode[]) => {
      formik.setFieldValue('promocodes', promocodes)
    },
    [formik],
  )

  const onSellerChange = useCallback(
    (id, seller) => {
      if (formik.values.producer_id !== id) {
        formik.setFieldValue('sku_items', [])
        formik.setFieldTouched('sku_items', false)
        if (userCompany?.company_type !== CompanyType.Distributor) {
          formik.setFieldValue('distributor_id', undefined)
        }
      }

      setCurrentProducer(seller)
      formik.setFieldValue('producer_id', id)
      const producerCurrentSeason = (seasons ?? []).filter(s => s.company_id === id).find(s => s.is_current)
      formik.setFieldValue('season_id', producerCurrentSeason?.id)

      if (!initialRender.current) {
        formik.setFieldValue('promocode', undefined)
      } else {
        initialRender.current = false
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik, seasons],
  )

  const changeSeason = useCallback(
    (val: string) => {
      formik.setFieldValue('season_id', val)
      formik.setFieldValue('sku_items', [])
      formik.setFieldTouched('sku_items', false)
      clearProductsLimitsForFarmerOrders()
    },
    [clearProductsLimitsForFarmerOrders, formik],
  )

  const handleSeasonChange = useCallback(
    val => {
      if (!formik.values.season_id || !formik.values.sku_items.length) {
        changeSeason(val as string)
      } else {
        openModal({
          contentProps: {},
          content: (
            <ModalWithButtonContent
              modalContent={t('modalText')}
              allowButtonText={t('common:yes')}
              cancelButtonText={t('common:no')}
              handlleAllowButtonClick={() => changeSeason(val as string)}
            />
          ),
        })
      }
    },
    [changeSeason, formik.values.season_id, formik.values.sku_items.length, openModal, t],
  )

  const handleRefresh = useCallback(() => {
    formik.resetForm()
    onRefresh && onRefresh(isAddRetailerOrderSuggestionEnabled)
  }, [formik, onRefresh, isAddRetailerOrderSuggestionEnabled])

  const onCustomerChange = useCallback(
    (id, customer) => {
      if (customer) {
        formik.setValues({
          ...formik.values,
          owner_id: id,
          delivery_date: customer.delivery_address?.delivery_date || customer.legal_address?.delivery_date || '',
          desired_delivery_date:
            customer.desired_delivery_date?.desired_delivery_date ||
            customer.legal_address?.desired_delivery_date ||
            '',
          address: customer.delivery_address?.address || customer.legal_address?.address || '',
          country: customer.delivery_address?.country || customer.legal_address?.country || country,
          city: customer.delivery_address?.city || customer.legal_address?.city || '',
          region_id: customer.delivery_address?.region_id || customer.legal_address?.region_id || '',
          postal_code: customer.delivery_address?.postal_code || customer.legal_address?.postal_code || '',
        })
        setCustomer(customer)
      } else {
        formik.setFieldValue('owner_id', id)
      }
    },
    [formik, country],
  )

  const allSkuMatched = useMemo(() => {
    const sku_items = clone(formik.values.sku_items)
    const errors: boolean[] = []
    let isAllMatched = true

    sku_items.forEach((item: FarmerOrderSkuItem) => {
      const skuMatched = !!item.sku_id
      if (!skuMatched) {
        isAllMatched = false
      }
      errors.push(skuMatched)
    })

    setSkuMatchingErrors(errors)
    return isAllMatched
  }, [formik.values.sku_items])

  const submit = async (duplicate = false) => {
    try {
      await formik.submitForm()

      if (!formik.isValid || !allSkuMatched || (mode === 'create' && editingDateHasExpired)) {
        return
      }

      const values = { ...formik.values, seller_id: formik.values.producer_id }

      // API doesn't accept empty date so we cut it
      if (!values.delivery_date) {
        delete values.delivery_date
      }
      if (!values.desired_delivery_date) {
        delete values.desired_delivery_date
      }

      if (duplicate) {
        const skuItems = values.sku_items.map(item => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, ...rest } = { ...item }
          return rest
        })
        values.sku_items = skuItems
      }

      onSubmit(values, {
        dirty: formik.dirty,
        duplicate,
        isAddRetailerOrderSuggestionEnabled,
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const handleSubmit = () => {
    submit(false)
  }

  const handleDuplicate = () => {
    submit(true)
  }

  const handleRequiredDocumentClick = (id: string) => {
    setSelectedDocument(id)
    setTimeout(() => {
      setSelectedDocument('')
    }, 3000)
  }

  const farmerLink =
    usersPermitted && order?.customer ? generatePath(FarmerRoutes.Edit, { id: order.customer.id }) : undefined

  const handleStatusChange = useCallback(
    (field: string, value: unknown) => {
      if (field === 'canceled_by_role') {
        if (value !== null) {
          formik.setFieldValue('status', FarmerOrderStatus.Canceled)
        } else if (
          order?.interaction.confirmed_by_agroclub &&
          order?.interaction.confirmed_by_producer &&
          order?.interaction.confirmed_by_distributor === FarmerOrderStatus.Confirmed
        ) {
          formik.setFieldValue('status', FarmerOrderStatus.Confirmed)
        } else {
          formik.setFieldValue('status', FarmerOrderStatus.New)
        }
      }
      formik.setFieldValue(`interaction.${field}`, value)
      !value &&
        formik.setFieldValue(
          `comments`,
          formik.values.comments.filter(e => e.id),
        )
    },
    [formik, order?.interaction],
  )

  const addComment = useCallback(
    (value: string) => {
      if (order?.id) {
        const newComment: CommentEntry = {
          comment: value,
          collection: Collections.Orders,
          obj_id: order.id,
        }
        formik.setFieldValue(`comments`, [...formik.values.comments, newComment])
      }
    },
    [formik, order?.id],
  )

  const handleConfirmedByFarmerChange = useCallback(
    (_, isChecked: boolean) => {
      handleStatusChange('confirmed_by_farmer', isChecked)
    },
    [handleStatusChange],
  )

  const handleFilesChange = useCallback(
    (items: FileItem[]) => {
      formik.setFieldValue('files', items)
    },
    [formik],
  )

  const handleDistributorConfirmation = useCallback(
    (status: DistributorConfirmationStatus) => {
      if (status === 'rejected') {
        setShowOrderCancellationModal(true)
        return
      }

      const updatedItems = status === 'confirmed' ? formik.values.sku_items : undefined
      return onDistributorConfirmation?.(status, isAddRetailerOrderSuggestionEnabled, updatedItems)
    },
    [formik.values.sku_items, isAddRetailerOrderSuggestionEnabled, onDistributorConfirmation],
  )

  const revisionMismatch = progress === Progress.ERROR && updateError === 'conflict_error'
  useValidationErrorNotification(formik.submitCount, formik.isValid && allSkuMatched)

  const docIds = useMemo(() => {
    const ids = formik.values.sku_items
      .map(item => item.sku?.product?.docusign_document_id)
      .filter(helpersObject.nonNullable)
    return [...new Set(ids)]
  }, [formik.values.sku_items])

  const onRadioChange = (value: FarmerSelectType) => {
    setFarmerSelectType(value)
    formik.setFieldValue('farmer_id', '')
  }

  const isCreditOfferProvided = useMemo(() => {
    const producersList = producers as Company[]
    const foundedProducer: Company | undefined = producersList.find(
      (producer: Company) => producer?.id === formik.values.producer_id,
    )
    return !!foundedProducer?.credit_offer
  }, [formik.values.producer_id, producers])

  const isCancelOrderDisabled = useMemo(() => {
    return order?.status === FarmerOrderStatus.Canceled || editingDateHasExpired
  }, [editingDateHasExpired, order?.status])

  const [totalSum, setTotalSum] = useState('')
  const [actualTotalNet, setActualTotalNet] = useState('')

  const [showFarmerSelect, setShowFarmerSelect] = useState(!order)
  const [showOrderCancellationModal, setShowOrderCancellationModal] = useState(false)

  const handleOrderCancellation = useCallback(
    (comment?: string) => {
      if (!comment) return
      return onDistributorCancellation?.(role as ROLES, comment)
    },
    [onDistributorCancellation, role],
  )

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <Wrapper>
          <Column>
            <SectionContainer>
              <SectionBody>
                <FormComponents.FormSection title={t('labels:supplier')}>
                  {order ? (
                    <CompanyInfoPreview
                      company={order.producer}
                      footer={`ID ${order.producer?.id || ''}`}
                      link={
                        isAdmin && order && order.producer
                          ? generatePath(CompanyRoutes.Edit, { id: order.producer?.id })
                          : undefined
                      }
                    />
                  ) : (
                    <div data-test-id="supplier-select">
                      <CompanySelect
                        filter={{ is_seller: true }}
                        label={t('labels:supplier')}
                        placeholder={t('form.placeholders.supplier')}
                        onChange={onSellerChange}
                        value={formik.values.producer_id as string}
                        companyType={CompanyType.Producer}
                        isClearable
                        isSearchable
                        isDisabled={!isEditable}
                        onLoadList={list => {
                          const producerId =
                            userCompany &&
                            ((userCompany.company_type === CompanyType.Distributor &&
                              userCompany.producers_relations.length > 0 &&
                              userCompany.producers_relations[0]) ||
                              (userCompany.company_type === CompanyType.Producer && userCompany.id))
                          const producer = list.data.find(item => item.id === producerId)
                          if (producer) {
                            setCurrentProducer(producer)
                          }
                        }}
                      />
                    </div>
                  )}
                  <SeasonSelect
                    companyId={formik.values.producer_id as string}
                    label={t('form.labels.season')}
                    placeholder={t('form.placeholders.season')}
                    value={formik.values.season_id}
                    onChange={handleSeasonChange}
                    isDisabled={!formik.values.producer_id || isSeasonDisabled || !isEditable}
                    isOptionDisabled={option => !!(!isAdmin && !isProducerHead && option.is_season_closed)}
                    required
                  />
                  <CompanySelect
                    label={t('form.labels.distributor')}
                    onChange={value => {
                      formik.setFieldValue('distributor_id', value)
                      isAdmin && formik.setFieldValue('interaction.confirmed_by_distributor', 'unset')
                    }}
                    onMenuClose={() => {
                      formik.setFieldTouched('distributor_id')
                    }}
                    companyType={CompanyType.Distributor}
                    filter={{ for_producer_id: formik.values.producer_id }}
                    value={formik.values.distributor_id || undefined}
                    isDisabled={!updatePermissions.distributorSelection || !formik.values.producer_id}
                    placeholder={t('form.placeholders.retailer')}
                  />
                  <OrderStatusBlock
                    values={formik.values.interaction}
                    onChange={handleStatusChange}
                    onChangeComment={addComment}
                    hasDistributor={!!formik.values.distributor_id}
                    showCancellation={!!order && isAdmin}
                    isConfirmedByAgroVisible={isAdmin}
                    isConfirmedByManufacturerVisible={isManufacturerConfirmationEnabled || isAdmin}
                    isRetailerInteractionVisible={isRetailerInteractionByProducerEnabled || isAdmin}
                    isEditable={isEditable}
                  />
                  {(isFarmerConfirmationEnabled || isAdmin) && (
                    <div data-test-id="farmer-confirmation-status">
                      <FormComponents.FormSection title={t('form.sectionHeaders.farmersInteraction')}>
                        <Checkbox
                          label={<Label>{t('status.confirmed_by_farmer')}</Label>}
                          isChecked={formik.values.interaction.confirmed_by_farmer}
                          disabled={!isAdmin || !isEditable}
                          onChange={handleConfirmedByFarmerChange}
                        />
                      </FormComponents.FormSection>
                    </div>
                  )}
                  {isProducer && (
                    <CancelSwitch
                      canceledByRole={formik.values.interaction.canceled_by_role}
                      canceledByRoleDefault={role}
                      disabled={isCancelOrderDisabled || !isEditable}
                      onChange={handleStatusChange}
                    />
                  )}
                  {isDistributor && (
                    <OrderCancellationReasonModal
                      isOpen={showOrderCancellationModal}
                      onClose={() => setShowOrderCancellationModal(false)}
                      onChangeComment={handleOrderCancellation}
                    />
                  )}
                  <DatePickerWrapper data-test-id={'order-date'}>
                    <DatePicker
                      onChange={value => {
                        formik.setFieldValue('order_date', value)
                        formik.setFieldTouched('order_date')
                      }}
                      date={formik.getFieldProps('order_date').value}
                      invalid={formik.touched.order_date && !!formik.errors.order_date}
                      errorText={formik.errors.order_date}
                      label={t('form.labels.orderDate')}
                      disabled={!updatePermissions.date || !isEditable}
                      required
                      format={dateFormat}
                    />
                  </DatePickerWrapper>
                </FormComponents.FormSection>
              </SectionBody>
            </SectionContainer>
            <SectionContainer>
              <SectionBody data-test-id={'farmer-section'}>
                <FormComponents.FormSection title={t('form.sectionHeaders.farmer')}>
                  {showFarmerSelect && (
                    <>
                      <div data-test-id={'select-farmer'}>
                        <Radio value={farmerSelectType} onChange={onRadioChange} direction="column">
                          <RadioItem
                            value={FarmerSelectType.Select}
                            label={<Label>{t('form.labels.farmerSelect')}</Label>}
                          />
                          <div data-test-id={'find_by_phone'}>
                            <RadioItem
                              value={FarmerSelectType.Modal}
                              label={<Label>{t('form.labels.farmerModal')}</Label>}
                            />
                          </div>
                        </Radio>
                      </div>
                      {farmerSelectType === FarmerSelectType.Select ? (
                        <FarmersSelect
                          onChange={onCustomerChange}
                          onMenuClose={() => formik.setFieldTouched('owner_id')}
                          value={formik.values.owner_id}
                          isDisabled={!formik.values.distributor_id}
                          invalid={formik.touched.owner_id && !!formik.errors.owner_id}
                          errorText={formik.errors.owner_id}
                          filter={{ distributor_id: distributorRelatedCompanies }}
                          sort={{ sort_field: 'first_name' }}
                          preventFetch={!distributorRelatedCompanies.length}
                        />
                      ) : (
                        <FarmerBlock>
                          <Button
                            style={{ maxWidth: '200px' }}
                            intent={formik.touched.owner_id && !!formik.errors.owner_id ? 'danger' : 'primary'}
                            filled={false}
                            onClick={() => {
                              setShowCustomerModal(true)
                            }}
                            data-test-id={'select-customer-button'}
                          >
                            {customer ? t('form.changeCustomer') : t('form.selectCustomer')}
                          </Button>
                          {formik.values.distributor_id && !!formik.errors.owner_id && formik.touched.owner_id && (
                            <ErrorStyled>{formik.errors.owner_id}</ErrorStyled>
                          )}
                          <CustomerModal
                            isOpen={showCustomerModal}
                            onClose={() => {
                              setShowCustomerModal(false)
                              if (!formik.values.owner_id) {
                                formik.setFieldTouched('owner_id')
                              }
                            }}
                            onCustomerSelect={onCustomerChange}
                            hideFarmerInfoChecking={hideFarmerInfoChecking}
                          />
                          <ErrorStyled>
                            {formik.touched.owner_id && !!formik.errors.owner_id && formik.errors.owner_id}
                          </ErrorStyled>
                        </FarmerBlock>
                      )}
                    </>
                  )}
                  {!!formik.values.owner_id && (
                    <CustomerInfo
                      customer={customer}
                      link={isAdmin ? farmerLink : undefined}
                      creditOfferProvided={isCreditOfferProvided}
                      creditOfferAccepted={formik.values.interaction.credit_offer_accepted}
                      onEditClick={
                        !!order && isAdmin
                          ? () => {
                              setShowFarmerSelect(prev => !prev)
                            }
                          : undefined
                      }
                      onCreditOfferAcceptedChange={
                        updatePermissions.creditOfferAccept ? handleCreditOfferAcceptedChange : undefined
                      }
                      hideFarmerInfoChecking={hideFarmerInfoChecking}
                    />
                  )}
                </FormComponents.FormSection>
                <FormComponents.FormSection title={t('form.sectionHeaders.address')}>
                  <FarmerAddressForm
                    formik={formik}
                    isDesiredDeliveryDateEnabled={isDesiredDeliveryDateEnabled}
                    isEditable={isEditable}
                  />
                </FormComponents.FormSection>
              </SectionBody>
            </SectionContainer>
            {order && (isAdmin || isHistoryCardVisible) && (
              <OrderHistoryCard
                orderId={order.id}
                createdById={order.author_id}
                orderSource={order.order_source}
                createdDate={order.created_at}
                confirmedByProducerId={order.interaction.user_id_confirmed_by_producer}
                confirmedByDistributorId={order.interaction.user_id_confirmed_by_distributor}
                confirmedByAgroClubId={order.interaction.user_id_confirmed_by_agroclub}
              />
            )}
          </Column>
          <Column>
            {(updatePermissions.items && isEditable) || !order ? (
              <OrderSkuProductItems
                context={'farmer'}
                order={order}
                onChange={handleCardChange}
                onRemove={handleCardRemove}
                onRequiredDocumentClick={handleRequiredDocumentClick}
                onMenuClose={() => formik.setFieldTouched('sku_items')}
                isAllowed={!!formik.values.producer_id}
                sku_items={formik.values.sku_items}
                documents={userDocuments}
                producer={currentProducer || order?.producer}
                onFinalQtyChange={handleFinalQtyChange}
                onDeliveredQtyChange={handleDeliveredQtyChange}
                onPackageTypeChange={handlePackageTypeChange}
                onOptionsChange={handleOptionsChange}
                onSkuMatched={handleSkuMatched}
                onCommentChange={handleCommentChange}
                invalid={formik.touched.sku_items && !!formik.errors.sku_items}
                errorText={formik.errors.sku_items as string}
                mode={mode}
                total={totalSum}
                netTotal={actualTotalNet}
                showComment={showComment}
                skuMatchingErrors={skuMatchingErrors}
                showErrors={formik.submitCount >= 1}
                distributorId={formik.values.distributor_id as string}
                seasonId={formik.values.season_id}
                setIsProductQtyMoreThanLimitAfterDuplicate={setIsProductQtyMoreThanLimitAfterDuplicate}
                setIsProductQtyMoreThanLimit={setIsProductQtyMoreThanLimit}
                initialItems={formik.initialValues.sku_items}
              >
                {docIds.length !== 0 ? (
                  <OrderDocuments
                    documentIds={docIds}
                    userId={formik.values.owner_id}
                    selectedDocument={selectedDocument}
                  />
                ) : null}
              </OrderSkuProductItems>
            ) : (
              <OrderSkuProductItemsReadOnly
                sku_items={order.sku_items || []}
                showFinalQty={true}
                currency={order.currency}
                orderTotal={Number(order.total)}
                distributorId={formik.values.distributor_id as string}
                seasonId={formik.values.season_id}
                setIsProductQtyMoreThanLimit={setIsProductQtyMoreThanLimit}
                mode={'edit'}
                context={'farmer'}
              >
                {docIds.length !== 0 ? (
                  <OrderDocuments
                    documentIds={docIds}
                    userId={formik.values.owner_id}
                    selectedDocument={selectedDocument}
                  />
                ) : null}
              </OrderSkuProductItemsReadOnly>
            )}
            <OrderSkuDiscounts
              sellerId={formik.values.producer_id}
              seasonId={formik.values.season_id}
              entries={formik.values.sku_items}
              ownerId={formik.values.owner_id}
              scope={IncentiveProgramScope.FarmerOrders}
              promocodes={formik.values.promocodes}
              selectedPromocode={selectedPromocode}
              onSelectPromocode={setSelectedPromocode}
              onPromocodesChange={handlePromocodesChange}
              onActualTotalNetChange={setActualTotalNet}
              onTotalSumChange={setTotalSum}
              isPromocodeDisabled={isPromocodeDisabled || !isEditable}
              orderDate={formik.values.order_date}
            />
            <SectionContainer>
              <SectionTitle>{t('form.sectionHeaders.comments')}</SectionTitle>
              {order?.id && isCommentsOfRejectReasonsVisible && (
                <>
                  <CommentFormSection title={t('form.sectionHeaders.cancellationComments')} />
                  <SectionBody>
                    <CommentsBlock
                      {...formik.getFieldProps('comments')}
                      onChange={(value: CommentEntry[]) => formik.setFieldValue('comments', value)}
                    />
                  </SectionBody>
                </>
              )}
              <CommentFormSection title={t('form.sectionHeaders.farmerComment')} />
              <SectionBody>
                <TextArea
                  {...formik.getFieldProps('farmer_comment')}
                  placeholder={t('form.placeholders.farmerComment')}
                  disabled={!isAdmin}
                  data-test-id={'farmer-comment-textarea'}
                />
              </SectionBody>
              <CommentFormSection title={t('form.sectionHeaders.comment')} />
              <SectionBody>
                <TextArea
                  {...formik.getFieldProps('comment')}
                  placeholder={t('form.placeholders.comment')}
                  disabled={isCommentDisabled || !isEditable}
                  data-test-id={'comment-textarea'}
                />
              </SectionBody>
              {isAdmin && (
                <>
                  <CommentFormSection title={t('form.sectionHeaders.adminComment')} />
                  <SectionBody>
                    <TextArea
                      {...formik.getFieldProps('admin_comment')}
                      placeholder={t('form.placeholders.adminComment')}
                      data-test-id={'admin-comment-textarea'}
                    />
                  </SectionBody>
                </>
              )}
            </SectionContainer>
            {isAttachmentsVisible && (
              <SectionContainer>
                <SectionTitle>{t('form.sectionHeaders.attachedFiles')}</SectionTitle>
                <SectionBody>
                  <FileManager items={formik.values.files} onChange={handleFilesChange} disabled={!isEditable} />
                </SectionBody>
              </SectionContainer>
            )}
          </Column>
          <Overlay isUpdatePermitted={isUpdatePermitted} />
        </Wrapper>
      </StickyFooterLayout.Body>
      <StickyFooterLayout.ButtonsFooter>
        <ControlButtons
          onCancel={onCancel}
          onSave={handleSubmit}
          onDuplicate={handleDuplicate}
          onRemove={onRemove}
          onRestore={onRestore}
          distributorConfirmButtonDisabled={isDistributor && isProductQtyMoreThanLimit}
          onDistributorConfirmation={handleDistributorConfirmation}
          progress={progress}
          removeProgress={removeProgress}
          isFormDirty={formik.dirty}
          isFormValid={formik.isValid}
          interaction={formik.values.interaction}
          revisionMismatch={revisionMismatch}
          orderId={order?.id}
          orderStatus={order?.status}
          isRestorePermitted={isAdmin}
          orderSlug={order?.slug}
          isUpdatePermitted={isUpdatePermitted}
          editingDateHasExpired={editingDateHasExpired}
          isEditable={isEditable}
          duplicateButtonDisabled={isProductQtyMoreThanLimitAfterDuplicate && !isAdmin}
        />
      </StickyFooterLayout.ButtonsFooter>

      {revisionMismatch && (
        <StickyFooterLayout.NotificationWarning>
          <div>{t('form.errors.revisionMismatch')}</div>
          <Button intent={'warning'} filled progress={progress} onClick={handleRefresh} disabled={!isEditable}>
            {t('form.refreshOrder')}
          </Button>
        </StickyFooterLayout.NotificationWarning>
      )}
    </StickyFooterLayout.Wrapper>
  )
}

export default FarmerOrderSkuDetailsForm
