import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { AddButton, SearchInput, useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import ProductList from './ProductList'
import Routes from '../routes'
import ProductActions from 'modules/domain/product/duck'
import ProductSelectors from 'modules/domain/product/selectors'
import { useSelector } from 'react-redux'
import { isAgro } from 'types/entities'
import AuthSelectors from 'modules/domain/auth/selectors'

const SearchInputWrapper = styled.div`
  padding: 18px 24px;
  width: 50%;
`

const ProductTable: React.FC = () => {
  const filter = useSelector(ProductSelectors.filter)
  const filterUpdated = useAction(ProductActions.filterUpdated)
  const push = useHistoryPush()
  const { t } = useTranslation(['product', 'productOptions'])
  const role = useSelector(AuthSelectors.role)

  const handleSearchChange = React.useCallback(
    (search?: string) => {
      filterUpdated({ ...filter, search })
    },
    [filterUpdated, filter],
  )

  useHelmet({ title: t('product:productsMetaTitle') })

  return (
    <>
      <Layout.Header>
        {isAgro(role) ? (
          <>
            <Header.TabsRoot>
              <Header.Tab title={t('product:list.heading')} isActive onClick={() => push({ route: Routes.List })}>
                <AddButton to={Routes.Add} />
              </Header.Tab>
              <Header.Tab title={t('productOptions:list.heading')} onClick={() => push({ route: Routes.OptionsList })}>
                <AddButton to={Routes.OptionsAdd} data-test-id="add-product-option" />
              </Header.Tab>
            </Header.TabsRoot>
            <SearchInputWrapper>
              <SearchInput
                onChange={handleSearchChange}
                value={filter.search}
                placeholder={t('product:list.searchPlaceholder')}
              />
            </SearchInputWrapper>
          </>
        ) : (
          <Header.Root>
            <Header.Title title={t('list.heading')}>
              <AddButton to={Routes.Add} />
            </Header.Title>
            <SearchInputWrapper>
              <SearchInput
                onChange={handleSearchChange}
                value={filter.search}
                placeholder={t('list.searchPlaceholder')}
              />
            </SearchInputWrapper>
          </Header.Root>
        )}
      </Layout.Header>
      <Layout.Content>
        <ProductList />
      </Layout.Content>
    </>
  )
}

export default ProductTable
