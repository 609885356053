import React, { memo, useMemo, useState } from 'react'
import * as Yup from 'yup'
import * as Styled from './styled'
import { useTranslation } from 'react-i18next'
import { AvailableLanguages, LocalizedValue } from 'types/entities'
import styled from 'styled-components'
import { FormikErrors } from 'formik'
import { remove } from 'ramda'
import { FormikHook, IconCrossCircle, Input, Switch } from '@agro-club/frontend-shared'
import { createLocalizedValue } from 'helpers/localization'

const RemoveIcon = styled(IconCrossCircle)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.1s ease-in;
`

const VerticalInputsContainer = styled(Styled.VerticalInputsContainer)`
  position: relative;
  &:hover {
    ${RemoveIcon} {
      opacity: 1;
    }
  }
`

export type SeedTreatmentProps = {
  seedTreatment: {
    id?: string
    title: LocalizedValue
    is_active: boolean
  }[]
}

const SeedTreatmentForm: React.FC<{
  useFormik: FormikHook
  langList: AvailableLanguages[]
  seedTreatment?: {
    id?: string
    title: LocalizedValue
    is_active: boolean
  }[]
}> = memo(({ useFormik, langList, seedTreatment = [] }) => {
  const { t } = useTranslation(['product', 'common', 'validation'])
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const validationSchema = useMemo(() => {
    const validator = (lang: AvailableLanguages) =>
      langList.includes(lang) ? Yup.string().required(t('validation:field_required')) : Yup.string()
    return Yup.object({
      seedTreatment: Yup.array(
        Yup.object({
          id: Yup.string().notRequired(),
          title: Yup.object(createLocalizedValue(lang => validator(lang))),
        }),
      ),
    })
  }, [langList, t])

  const formik = useFormik<SeedTreatmentProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      seedTreatment,
    },
  })

  const handleAddClick = () => {
    formik.setFieldValue('seedTreatment', [
      ...formik.values.seedTreatment,
      {
        title: createLocalizedValue(() => ''),
        is_active: true,
      },
    ])
  }

  const handleRemoveClick = (idx: number) => {
    formik.setFieldValue('seedTreatment', remove(idx, 1, formik.values.seedTreatment))
  }

  const getError = (
    err: FormikErrors<{ title: LocalizedValue }> | string | undefined,
    field: 'title',
    lang: AvailableLanguages,
  ): string => {
    if (!err) {
      return ''
    }
    if (typeof err === 'string') {
      return err
    }
    return err[field]?.[lang] || ''
  }

  return (
    <Styled.FormContainer>
      <Styled.VerticalFormsContainer data-test-id={'seed-treatment-form'}>
        {formik.values.seedTreatment.map((value, idx) => {
          return (
            <VerticalInputsContainer key={idx}>
              <RemoveIcon
                onMouseEnter={() => setHoveredIndex(idx)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => handleRemoveClick(idx)}
                data-test-id={'remove-button'}
              />
              {langList.map(lang => {
                const index = idx + 1
                const key = `seedTreatment[${idx}].title[${lang}]`
                return (
                  <Input
                    label={t('form.seedTreatment', { index, lang })}
                    key={key}
                    {...formik.getFieldProps(key)}
                    required
                    invalid={
                      formik.touched.seedTreatment?.[idx]?.title?.[lang] &&
                      !!getError(formik.errors.seedTreatment?.[idx], 'title', lang)
                    }
                    errorText={getError(formik.errors.seedTreatment?.[idx], 'title', lang)}
                  />
                )
              })}
              <Switch
                on={value.is_active}
                label={t('form.seedTreatmentIsActive')}
                onClick={val => formik.setFieldValue(`seedTreatment[${idx}].is_active`, val)}
              />
              {hoveredIndex !== null && hoveredIndex === idx ? <Styled.RemoveOverlay /> : null}
            </VerticalInputsContainer>
          )
        })}
      </Styled.VerticalFormsContainer>
      <Styled.AddButton data-test-id={'add-seed-treatment'} onClick={handleAddClick} type={'button'}>
        {t('form.addOption')}
      </Styled.AddButton>
    </Styled.FormContainer>
  )
})

SeedTreatmentForm.displayName = 'SeedTreatmentForm'

export default SeedTreatmentForm
