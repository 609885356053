import {
  Button,
  Checkbox,
  FormComponents,
  helpersSlug,
  SectionContainer,
  useFormManager,
} from '@agro-club/frontend-shared'
import { createLocalizedValue } from 'helpers/localization'
import useValidationErrorNotification from 'hooks/useValidationErrorNotification'
import { Badge } from 'modules/domain/badge/types'
import { StorefrontCardActionData } from 'modules/domain/storefrontCard/types'
import UploadManagerSelectors from 'modules/domain/uploadManager/selectors'
import { Progress } from 'modules/types'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AvailableLanguages, availableLanguagesList, LocalizedValue, Status } from 'types/entities'
import { Dict } from 'types/generics'
import BadgeSelect from 'views/components/BadgeSelect'
import ImageForm, { ImageFormProps } from 'views/components/ImageForm/ImageForm'
import SlugForm from 'views/components/SlugForm/SlugForm'
import StickyFooterDeleteBtn from 'views/components/StickyFormControls/StickyFooterDeleteBtn'
import * as StickyFooterLayout from 'views/layouts/StickyFooterLayout/StickyFooterLayout'
import { sanitizeHtml } from 'views/pages/Product/helpers'
import AboutForm, { AboutFormProps } from 'views/pages/Product/ProductDetailsForm/AboutForm'
import AdditionalOptionsForm, { AdditionalOptions } from 'views/pages/Product/ProductDetailsForm/AdditionalOptionsForm'
import AttributesForm, { AttributesFormProps } from 'views/pages/Product/ProductDetailsForm/AttributesForm'
import { DocumentsFormProps } from 'views/pages/Product/ProductDetailsForm/DocumentsForm'
import FeaturesForm, { FeaturesFormProps } from 'views/pages/Product/ProductDetailsForm/FeaturesForm'
import ProductUnitsForm, { ProductUnitsFormProps } from 'views/pages/Product/ProductDetailsForm/ProductUnitsForm'
import QuoteForm, { QuoteFormProps } from 'views/pages/Product/ProductDetailsForm/QuoteForm'
import RequiredDocumentsForm, {
  RequiredDocumentsFormProps,
} from 'views/pages/Product/ProductDetailsForm/RequiredDocumentsForm'
import StatusForm, { StatusFormProps } from 'views/pages/Product/ProductDetailsForm/StatusForm'
import * as StyledProduct from 'views/pages/Product/ProductDetailsForm/styled'
import StorefrontOriginForm, {
  StorefrontOriginFormProps,
} from 'views/pages/Storefront/StorefrontCard/components/StorefrontOriginForm'
import * as Styled from '../styled'
import StorefrontCardFilesForm, { StorefrontCardFilesFormProps } from './StorefrontCardFilesForm'

type FormProps = StorefrontCardActionData

type FormikManagerData = {
  origin: StorefrontOriginFormProps
  'insights:en': DocumentsFormProps
  'insights:fr': DocumentsFormProps
  'insights:ru': DocumentsFormProps
  'insights:es': DocumentsFormProps
  'summary:en': DocumentsFormProps
  'summary:fr': DocumentsFormProps
  'summary:ru': DocumentsFormProps
  'summary:es': DocumentsFormProps
  'about:en': AboutFormProps
  'about:fr': AboutFormProps
  'about:ru': AboutFormProps
  'about:es': AboutFormProps
  'units:en': ProductUnitsFormProps
  'units:ru': ProductUnitsFormProps
  'units:fr': ProductUnitsFormProps
  'units:es': ProductUnitsFormProps
  features: FeaturesFormProps
  attributes: AttributesFormProps
  filter_attributes: AttributesFormProps
  files: StorefrontCardFilesFormProps
  images: ImageFormProps
  status: StatusFormProps
  quote: QuoteFormProps
  badge_ids: { badge_ids: string[] }
  enrich_badges: Badge[]
  documents: DocumentsFormProps
  requiredDocuments: RequiredDocumentsFormProps
  slug: { slug: string }
  additionalOptions: AdditionalOptions
}

type StorefrontCardsFormProps = {
  initialValues?: Partial<FormProps>
  editing?: boolean
  progress?: Progress
  removeProgress?: Progress
  onSubmit: (form: FormProps) => void
  onCancel(): void
  onRemove?(): void
}

const StorefrontCardsDetailForm: React.FC<StorefrontCardsFormProps> = ({
  initialValues = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  editing = false,
  progress,
  removeProgress,
  onSubmit,
  onCancel,
  onRemove,
}) => {
  const { t } = useTranslation(['storefront', 'product', 'common', 'validation', 'cards'])
  const { bind, slots, submitAll, dirty, valid, reset } = useFormManager<FormikManagerData>()
  const [submitCount, setSubmitCount] = useState(0)
  const isFilesUploading = useSelector(UploadManagerSelectors.isFilesUploading)

  const [languages, setLanguages] = useState(() => {
    const result: AvailableLanguages[] = []
    const detectLang = (lang: AvailableLanguages) =>
      [
        initialValues?.short_description_i18n?.[lang],
        initialValues?.title_i18n?.[lang],
        initialValues?.title_meta_i18n?.[lang],
        initialValues?.description_i18n?.[lang],
        initialValues?.description_meta_i18n?.[lang],
        initialValues?.trademark_language_i18n?.[lang],
      ].some(Boolean)

    availableLanguagesList.forEach(lang => {
      if (detectLang(lang)) {
        result.push(lang)
      }
    })

    if (!result.length) {
      result.push('en')
    }

    return result
  })

  const isLangActive = useMemo(() => {
    const map: Record<string, boolean> = {}
    languages.forEach(lang => {
      map[lang] = true
    })
    return (lang: AvailableLanguages) => {
      return map[lang] || false
    }
  }, [languages])

  const handleLangChange = (lang: AvailableLanguages, on: boolean) => {
    const values = new Set(languages)
    if (on) {
      values.add(lang)
    } else {
      values.delete(lang)
    }
    setLanguages([...values])
  }

  const handleSubmit = async () => {
    try {
      const [valid, forms] = await submitAll()

      setSubmitCount(submitCount + 1)

      if (!valid) {
        return
      }

      const langProp = (lang: AvailableLanguages, prop?: string) => (languages.includes(lang) ? prop || '' : undefined)
      const toLocalizedKeys = (obj: Dict<LocalizedValue>) => {
        return Object.keys(obj).reduce((dict: Dict<LocalizedValue>, key: string) => {
          return { ...dict, [`${key}_i18n`]: obj[key] }
        }, {})
      }

      const descriptionLocalizedValue = createLocalizedValue(lang => {
        if (!forms[`about:${lang}`]?.description) return ''
        const sanitizedDescription = sanitizeHtml(forms[`about:${lang}`]?.description)
        slots[`about:${lang}`].setFieldValue(`description`, sanitizedDescription)

        return langProp(lang, sanitizedDescription) as string
      })

      onSubmit({
        skus: forms.origin.skus || [],
        filter_attributes:
          (forms.filter_attributes.attributes.map(toLocalizedKeys) as FormProps['filter_attributes']) || [],
        attributes: (forms.attributes.attributes.map(toLocalizedKeys) as FormProps['attributes']) || [],
        features_i18n: forms.features.features,
        trademark_language_i18n: createLocalizedValue(
          lang => langProp(lang, forms[`about:${lang}`]?.trademarkLanguage) as string,
        ),
        discount_text_i18n: createLocalizedValue(
          lang => langProp(lang, forms[`about:${lang}`]?.discountText) as string,
        ),
        short_description_i18n: createLocalizedValue(
          lang => langProp(lang, forms[`about:${lang}`]?.shortDescription) as string,
        ),
        description_i18n: descriptionLocalizedValue,
        description_meta_i18n: createLocalizedValue(
          lang => langProp(lang, forms[`about:${lang}`]?.descriptionMeta) as string,
        ),
        title_i18n: createLocalizedValue(lang => langProp(lang, forms[`about:${lang}`]?.title) as string),
        title_meta_i18n: createLocalizedValue(lang => langProp(lang, forms[`about:${lang}`]?.titleMeta) as string),
        video_url_i18n: createLocalizedValue(lang => langProp(lang, forms[`about:${lang}`]?.videoUrl) as string),
        quote: {
          text_i18n: createLocalizedValue(lang => langProp(lang, forms['quote']?.text[lang]) as string),
          author_i18n: createLocalizedValue(lang => langProp(lang, forms['quote']?.author[lang]) as string),
          company_i18n: createLocalizedValue(lang => langProp(lang, forms['quote']?.company[lang]) as string),
        },
        category_id: forms.origin.categoryId,
        seller_id: forms.origin.sellerId,
        product_id: forms.origin.productId,
        rank: forms.origin.rank,
        slug: forms.slug.slug,
        is_new: forms.origin.isNew || false,
        is_featured: forms.origin.isFeatured || false,
        is_limited: forms.origin.isLimited,
        is_exclusive: forms.origin.isExclusive,
        hide_from_crm: forms.origin.hideFromCrm,
        subcategory_id: forms.origin.subcategoryId,
        is_out_of_stock: forms.origin.isOutOfStock,
        out_of_stock_flags: {
          allow_farmer_orders: forms.origin.outOfStockAllowFarmerOrders,
          allow_retail_orders: forms.origin.outOfStockAllowRetailOrders,
        },
        status: forms.status.active ? Status.Active : Status.Inactive,
        images: forms.images.files ?? [],
        files: forms.files?.files ?? [],
        badges: forms.badge_ids.badge_ids as any[],
        docusign_document_id: forms.requiredDocuments.docusignDocumentId,
        additional_options: forms.additionalOptions,
        wizard_id: forms.origin.wizardId,
        is_gift: forms.origin.isGift,
        producer_product_id: forms.origin.producerProductId,
        units: {
          singular_i18n: createLocalizedValue(lang => forms[`units:${lang}`]?.singular),
          plural_i18n: createLocalizedValue(lang => forms[`units:${lang}`]?.plural),
        },
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  const isInputsShown = languages.length > 0
  useValidationErrorNotification(submitCount, valid)

  const handleCancel = () => {
    reset()
    onCancel()
  }

  return (
    <StickyFooterLayout.Wrapper>
      <StickyFooterLayout.Body>
        <SectionContainer>
          <Styled.ContentBody>
            <FormComponents.FormSection title={t('cards:form.originFormTitle')}>
              <StorefrontOriginForm
                useFormik={bind('origin')}
                skus={initialValues.skus}
                sellerId={initialValues.seller_id}
                productId={initialValues.product_id || ''}
                wizardId={initialValues.wizard_id}
                categoryId={initialValues.category_id}
                subcategoryId={initialValues.subcategory_id}
                rank={initialValues.rank}
                isNew={initialValues.is_new}
                isFeatured={initialValues.is_featured}
                isLimited={!!initialValues.is_limited}
                isExclusive={!!initialValues.is_exclusive}
                hideFromCrm={!!initialValues.hide_from_crm}
                isOutOfStock={!!initialValues.is_out_of_stock}
                outOfStockAllowFarmerOrders={!!initialValues?.out_of_stock_flags?.allow_farmer_orders}
                outOfStockAllowRetailOrders={!!initialValues?.out_of_stock_flags?.allow_retail_orders}
                isGift={initialValues.is_gift}
                producerProductId={initialValues.producer_product_id}
              />
            </FormComponents.FormSection>
            <FormComponents.FormSection title={t('product:form.badgesTitle')}>
              <BadgeSelect
                useFormik={bind('badge_ids')}
                badgeIds={initialValues.badges || []}
                enrichedBadges={initialValues.enrich_badges || []}
              />
            </FormComponents.FormSection>
            <FormComponents.FormSection title={t('product:form.aboutTitle')}>
              <StyledProduct.FormContainer>
                <StyledProduct.LabeledContainerStyled label={t('product:form.languagesTitle')}>
                  <StyledProduct.AboutCountriesBlock>
                    {availableLanguagesList.map(lang => (
                      <Checkbox
                        label={t(`common:langNames.${lang}`)}
                        isChecked={isLangActive(lang)}
                        value={lang}
                        onChange={handleLangChange}
                        key={lang}
                        data-test-id={`lang-${lang}`}
                      />
                    ))}
                  </StyledProduct.AboutCountriesBlock>
                  {!languages.length && (
                    <StyledProduct.ErrorStyled>{t('validation:language_required')}</StyledProduct.ErrorStyled>
                  )}
                </StyledProduct.LabeledContainerStyled>
                {isInputsShown && (
                  <StyledProduct.VerticalFormsContainer>
                    {availableLanguagesList.map(lang => {
                      if (isLangActive(lang)) {
                        return (
                          <AboutForm
                            key={lang}
                            lang={lang}
                            title={initialValues.title_i18n}
                            titleMeta={initialValues.title_meta_i18n}
                            description={initialValues.description_i18n}
                            descriptionMeta={initialValues.description_meta_i18n}
                            shortDescription={initialValues.short_description_i18n}
                            trademarkLanguage={initialValues.trademark_language_i18n}
                            videoUrl={initialValues.video_url_i18n}
                            discountText={initialValues.discount_text_i18n}
                            useFormik={bind(`about:${lang}` as keyof FormikManagerData)}
                            setSlug={val => {
                              if (!editing) slots['slug'].setFieldValue('slug', helpersSlug.slugify(val))
                            }}
                          />
                        )
                      }
                      return null
                    })}
                  </StyledProduct.VerticalFormsContainer>
                )}
              </StyledProduct.FormContainer>
              {isInputsShown ? (
                <>
                  <FormComponents.FormSection title={t('product:form.unitsTitle')}>
                    <StyledProduct.VerticalFormsContainer>
                      {availableLanguagesList.map(lang => {
                        if (isLangActive(lang)) {
                          return (
                            <ProductUnitsForm
                              lang={lang}
                              key={lang}
                              useFormik={bind(`units:${lang}` as keyof FormikManagerData)}
                              singular={initialValues.units?.singular_i18n}
                              plural={initialValues.units?.plural_i18n}
                            />
                          )
                        }
                        return null
                      })}
                    </StyledProduct.VerticalFormsContainer>
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('product:form.featuresTitle')}>
                    <FeaturesForm
                      langList={languages}
                      useFormik={bind('features')}
                      features={initialValues.features_i18n}
                    />
                  </FormComponents.FormSection>

                  <FormComponents.FormSection title={t('product:form.filterAttributesTitle')}>
                    <AttributesForm
                      testId={'filter-attributes-form'}
                      useFormik={bind('filter_attributes')}
                      langList={languages}
                      attributes={initialValues?.filter_attributes}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('product:form.attributesTitle')}>
                    <AttributesForm
                      testId={'attributes-form'}
                      useFormik={bind('attributes')}
                      langList={languages}
                      attributes={initialValues?.attributes}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('product:form.filesTitle')}>
                    <ImageForm useFormik={bind('images')} files={initialValues.images} limit={10} />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('product:form.productInfoFilesTitle')}>
                    <StorefrontCardFilesForm
                      useFormik={bind(`files` as keyof FormikManagerData)}
                      files={initialValues?.files ?? []}
                      langList={languages}
                      testId={'card-info-files'}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('product:form.quoteTitle')}>
                    <QuoteForm
                      useFormik={bind('quote')}
                      langList={languages}
                      text={initialValues?.quote?.text_i18n}
                      company={initialValues?.quote?.company_i18n}
                      author={initialValues?.quote?.author_i18n}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('product:form.statusTitle')}>
                    <StatusForm
                      useFormik={bind('status')}
                      active={initialValues.status ? initialValues.status === Status.Active : true}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('product:form.slug')}>
                    <SlugForm useFormik={bind('slug')} initialValue={initialValues.slug} disabled={editing} />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('product:form.requiredDocumentsTitle')}>
                    <RequiredDocumentsForm
                      useFormik={bind('requiredDocuments')}
                      docusignDocumentId={initialValues?.docusign_document_id}
                    />
                  </FormComponents.FormSection>
                  <FormComponents.FormSection title={t('product:form.additionalOptionsTitle')}>
                    <AdditionalOptionsForm
                      useFormik={bind('additionalOptions')}
                      additionalOptions={initialValues?.additional_options}
                    />
                  </FormComponents.FormSection>
                </>
              ) : null}
            </FormComponents.FormSection>
          </Styled.ContentBody>
        </SectionContainer>
      </StickyFooterLayout.Body>
      <StickyFooterLayout.ButtonsFooter>
        <Button
          data-test-id={'submit'}
          filled
          intent={'primary'}
          type={'submit'}
          progress={progress}
          onClick={handleSubmit}
          disabled={!dirty || isFilesUploading || progress === Progress.WORK}
        >
          {t('common:save')}
        </Button>
        <Button onClick={handleCancel} intent={'cancel'}>
          {t('common:cancel')}
        </Button>

        {!!onRemove && (
          <StickyFooterDeleteBtn
            onRemove={onRemove}
            removeProgress={removeProgress}
            popoverText={t('form.removeText', { title: initialValues?.title_i18n?.en || '' })}
          />
        )}
      </StickyFooterLayout.ButtonsFooter>
    </StickyFooterLayout.Wrapper>
  )
}

export default StorefrontCardsDetailForm
