import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Product } from 'modules/domain/product/types'
import { generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import { useSelector } from 'react-redux'
import { CustomFeatureName, Sections } from 'types/entities'

const useOutOfStockEditingIsAllowed = (product: Product): boolean => {
  const isAdmin = useSelector(AuthSelectors.isAdmin)
  const checkFeatureFlag = useFeatureFlags()

  const { is_out_of_stock, out_of_stock_date } = { ...product }
  const hasExpired = !!out_of_stock_date && new Date() >= new Date(out_of_stock_date)
  const editingForbiddenFlag = checkFeatureFlag(
    generateCustomFeatureFlag(Sections.FarmerOrders, CustomFeatureName.OutOfStockQtyEditingForbidden),
  )

  if (isAdmin || !editingForbiddenFlag || !is_out_of_stock) return true

  return !!out_of_stock_date && !hasExpired
}

export default useOutOfStockEditingIsAllowed
