import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import AuthRoutes from './routes'
import { Button, Input, useAction, useHelmet } from '@agro-club/frontend-shared'
import { ErrorWrapper, HintText } from './styled'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const InputStyled = styled(Input)`
  margin-bottom: 24px;
`

type FormFields = {
  email: string
}

const ResetPasswordForm = () => {
  const { t } = useTranslation('auth')
  const { t: commonT } = useTranslation('common')

  const submitAction = useAction(AuthActions.resetPasswordRequested)
  const error = useSelector(AuthSelectors.getResetPasswordError)
  const progress = useSelector(AuthSelectors.getResetPasswordProgress)

  const onSubmit = useCallback(
    (values: FormFields) => {
      submitAction({ email: values.email, redirect: AuthRoutes.ResetEmailSent })
    },
    [submitAction],
  )

  const validationSchema = useMemo(() => {
    return Yup.object({
      email: Yup.string()
        .email(t('validation:email_invalid'))
        .required(t('validation:email_required')),
    })
  }, [t])

  const formik = useFormik<FormFields>({
    initialValues: { email: '' },
    validationSchema,
    onSubmit,
  })

  useHelmet({ title: t('resetPasswordMetaTitle') })

  return (
    <FormWrapper
      onSubmit={e => {
        e.preventDefault()
        formik.submitForm()
      }}
    >
      <HintText>{t('enter_email_to_reset')}</HintText>
      <InputStyled
        label={commonT('email')}
        placeholder={'example@mail.com'}
        type={'email'}
        {...formik.getFieldProps('email')}
        invalid={formik.touched.email && !!formik.errors.email}
        errorText={formik.errors.email}
      />
      {!!error && <ErrorWrapper>{error}</ErrorWrapper>}
      <Button
        type={'submit'}
        filled={true}
        progress={progress}
        disabled={!formik.isValid || !formik.dirty}
        intent={'primary'}
      >
        {commonT('submit')}
      </Button>
    </FormWrapper>
  )
}

export default ResetPasswordForm
