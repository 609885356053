import { BasicSelect } from '@agro-club/frontend-shared'
import { TargetTypes } from 'modules/domain/target2/common/types'
import { endpoints } from 'modules/endpoints'
import React, { useMemo } from 'react'
import { EntitySelect, EntitySelectProps } from 'views/components/EntitySelect/EntitySelect'
import { SelectTestWrapper } from 'views/components/SelectTestWrapper/SelectTestWrapper'
import { createGetList } from './common'

export type TargetRetailer = {
  id: string
  title: string
}

type TargetRetailerFilter = {
  manufacturer_id?: string
  title?: string
  territory_id?: string
}

type TargetRetailerSelectProps = EntitySelectProps<TargetRetailer> & {
  valueLabel?: string
  manufacturerId?: string
  territoryId?: string
  scope?: TargetTypes | 'default'
}

const getListForm = createGetList<TargetRetailer, TargetRetailerFilter>(endpoints.target2('manufacturer_retailers'))

const getListRetailerFilter = createGetList<TargetRetailer, TargetRetailerFilter>(
  endpoints.target2('filters/targets_retailer/retailers'),
)

const getListFarmerFilter = createGetList<TargetRetailer, TargetRetailerFilter>(
  endpoints.target2('filters/targets_farmer/retailers'),
)

const scopeManagerMap = {
  default: getListForm,
  [TargetTypes.Farmer]: getListFarmerFilter,
  [TargetTypes.Retailer]: getListRetailerFilter,
}

const getLabel = (item: TargetRetailer) => item.title

const getValue = (item: TargetRetailer) => item.id

export const TargetRetailerSelect: React.VFC<TargetRetailerSelectProps> = ({
  isDisabled,
  valueLabel,
  onChange,
  manufacturerId,
  territoryId,
  scope = 'default',
  ...props
}) => {
  const filter = useMemo(() => ({ manufacturer_id: manufacturerId, territory_id: territoryId }), [
    manufacturerId,
    territoryId,
  ])
  const fakeValue = useMemo(() => (valueLabel ? { title: valueLabel, id: 'fake' } : undefined), [valueLabel])

  return (
    <SelectTestWrapper data-test-id="target-retailer-select">
      {isDisabled ? (
        <BasicSelect isDisabled getOptionLabel={getLabel} getOptionValue={getValue} {...props} value={fakeValue} />
      ) : (
        <EntitySelect
          filter={filter}
          getEntityList={scopeManagerMap[scope]}
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          onChange={onChange}
          searchParamKey="title"
          {...props}
        />
      )}
    </SelectTestWrapper>
  )
}
