import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import StorefrontCardActions from './duck'
import StorefrontCardSelectors from './selectors'
import { StorefrontCard, StorefrontCardListRequestParams } from './types'
import { makeCancellableResourceListHook } from 'modules/utils/helpers'
import { makeCancelable } from 'modules/utils/httpClient'
import managers from 'modules/domain/storefrontCard/managers'
import { useEffect } from 'react'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'
import { StorefrontCardListRequestFilter } from 'modules/domain/storefrontCard/types'

export const useStorefrontCardList: ResourceHook<StorefrontCard[]> = (filter?: StorefrontCardListRequestFilter) => {
  const progress = useSelector(StorefrontCardSelectors.listFetchProgress)
  const list = useSelector(StorefrontCardSelectors.list)
  const page = usePageQuery()

  const params: StorefrontCardListRequestParams = {}
  if (page) params.page = page
  if (filter) params.filter = filter

  const fetchAction = useAction(StorefrontCardActions.listRequested, params)

  useDidMount(() => {
    fetchAction()
  })

  return [progress, list]
}

export const useStorefrontCard: ResourceHook<StorefrontCard, [string | undefined]> = (id?: string) => {
  const meta = useSelector(state => StorefrontCardSelectors.meta(state, id))
  const item = useSelector(state => StorefrontCardSelectors.item(state, id))
  const fetchAction = useAction(StorefrontCardActions.itemRequested, id)

  useDidMount(() => {
    if (id && (!item || id !== item.id)) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useStorefrontCardById: ResourceHook<StorefrontCard, [string | undefined]> = (id?: string) => {
  const meta = useSelector(state => StorefrontCardSelectors.meta(state, id))
  const item = useSelector(state => StorefrontCardSelectors.item(state, id))
  const fetchAction = useAction(StorefrontCardActions.itemRequested, id)

  useEffect(() => {
    if (id && (!item || id !== item.id)) {
      fetchAction()
    }
  }, [item, fetchAction, id])

  return [meta.fetchProgress, item]
}

export const useStorefrontCardOptions = makeCancellableResourceListHook(makeCancelable(managers.getListOptions))
