import React, { useCallback, useState } from 'react'
import { Checkbox, Radio, RadioItem, FormComponents } from '@agro-club/frontend-shared'
import { CustomFeatureName, isAgro, ROLES, Sections } from 'types/entities'
import { FarmerOrder } from 'types/farmerOrder'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AuthSelectors from 'modules/domain/auth/selectors'
import styled from 'styled-components'
import CancelSwitch from 'views/components/FarmerOrderDetailsForm/CancelSwitch'
import OrderCancellationReasonModal from 'views/components/OrderCancellationReasonModal/OrderCancellationReasonModal'
import { generateCustomFeatureFlag } from 'modules/utils/generateStringHelpers'
import useFeatureFlags from 'hooks/featureFlags/useFeatureFlags'

const Label = styled.span`
  font-size: 14px;
  flex-grow: 1;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
`

const Wrapper = styled.div`
  margin-top: 24px;
`

const OrderStatusBlock: React.FC<{
  values: FarmerOrder['interaction']
  onChangeComment?: (value: string) => void
  onChange: (field: string, value: unknown) => void
  hasDistributor: boolean
  showCancellation?: boolean
  isConfirmedByAgroVisible: boolean
  isConfirmedByManufacturerVisible: boolean
  isRetailerInteractionVisible: boolean
  isEditable?: boolean
}> = ({
  values,
  onChange,
  onChangeComment,
  hasDistributor,
  showCancellation,
  isConfirmedByAgroVisible,
  isConfirmedByManufacturerVisible,
  isEditable = true,
  isRetailerInteractionVisible,
}) => {
  const { t } = useTranslation('farmerOrder')
  const role = useSelector(AuthSelectors.role)
  const [showModal, setShowModal] = useState(false)
  const checkFeatureFlag = useFeatureFlags()
  const isAdmin = isAgro(role)
  const { confirmed_by_agroclub, canceled_by_role, confirmed_by_distributor, confirmed_by_producer } = values

  const handleChangeCheckbox = useCallback(
    field => (_: unknown, isChecked: boolean) => {
      onChange(field, isChecked)
    },
    [onChange],
  )
  const handleChangeDistributorInteraction = useCallback(
    (value: 'confirmed' | 'rejected') => {
      onChange('confirmed_by_distributor', value)
    },
    [onChange],
  )

  const isCommentsOfRejectReasonsVisible =
    isAdmin ||
    checkFeatureFlag(generateCustomFeatureFlag(Sections.FarmerOrders, CustomFeatureName.CommentsOfRejectReasonsVisible))

  const onCancelOrder = (field: string, value: unknown) => {
    onChange(field, value)
    value && setShowModal(true)
  }

  return (
    <>
      {(isConfirmedByAgroVisible || isConfirmedByManufacturerVisible) && (
        <FormComponents.FormSection title={t('labels:status')}>
          {isConfirmedByAgroVisible && (
            <Checkbox
              isChecked={confirmed_by_agroclub}
              onChange={handleChangeCheckbox('confirmed_by_agroclub')}
              label={<Label>{t('status.confirmed_by_agroclub')}</Label>}
            />
          )}
          {isConfirmedByManufacturerVisible && (
            <Checkbox
              isChecked={confirmed_by_producer}
              onChange={handleChangeCheckbox('confirmed_by_producer')}
              label={<Label>{t('status.confirmed_by_producer')}</Label>}
              disabled={!isEditable}
            />
          )}
        </FormComponents.FormSection>
      )}
      {isRetailerInteractionVisible && (
        <Wrapper data-test-id={'distributor-confirmation-status'}>
          <FormComponents.FormSection title={t('form.labels.distributorInteraction')}>
            <Radio value={confirmed_by_distributor} onChange={handleChangeDistributorInteraction} direction={'column'}>
              <RadioItem value={'unset'} label={<Label>{t('status.unset')}</Label>} disabled={!hasDistributor} />
              <RadioItem
                value={'confirmed'}
                label={<Label>{t('status.confirmed')}</Label>}
                disabled={!hasDistributor}
              />
              <RadioItem value={'rejected'} label={<Label>{t('status.rejected')}</Label>} disabled={!hasDistributor} />
            </Radio>
          </FormComponents.FormSection>
        </Wrapper>
      )}
      {showCancellation && (
        <Wrapper>
          <FormComponents.FormSection title={t('form.labels.orderCancellation')}>
            <CancelSwitch
              canceledByRole={canceled_by_role}
              disabled={role !== ROLES.AGRO_ADMIN}
              onChange={onCancelOrder}
            />
          </FormComponents.FormSection>
          {onChangeComment && isCommentsOfRejectReasonsVisible && (
            <OrderCancellationReasonModal
              isOpen={showModal}
              onClose={() => setShowModal(false)}
              onChangeComment={onChangeComment}
            />
          )}
        </Wrapper>
      )}
    </>
  )
}

export default OrderStatusBlock
