import React, { FC } from 'react'
import { Button } from '@agro-club/frontend-shared'
import { useTranslation } from 'react-i18next'
import { ProductWizardType } from '@agro-club/frontend-shared'

type PropsType = {
  handleWizardOpen: () => void
  productWizardType: string
}

export const AdderBtn: FC<PropsType> = ({
  handleWizardOpen,

  productWizardType,
}: PropsType) => {
  const { t } = useTranslation(['order', 'productWizard'])

  return productWizardType === ProductWizardType.DuckFoot ? (
    <Button
      data-test-id={`${productWizardType}-wizard-btn`}
      type={'submit'}
      intent={'primary'}
      filled
      onClick={handleWizardOpen}
    >
      {t('productWizard:adderWidget.duckFootBtnTitle')}
    </Button>
  ) : productWizardType === ProductWizardType.Procote ? (
    <Button type={'submit'} intent={'primary'} filled onClick={handleWizardOpen}>
      {t('productWizard:adderWidget.procoteBtnTitle')}
    </Button>
  ) : null
}
