import { useSelector } from 'react-redux'
import { ResourceHook } from 'modules/types'
import MailingListActions from './duck'
import MailingListSelectors from './selectors'
import { MailingListEntry } from './types'
import { useEffect } from 'react'
import { useAction, useDidMount, usePageQuery } from '@agro-club/frontend-shared'

export const useMailingListList: ResourceHook<MailingListEntry[], void[]> = () => {
  const progress = useSelector(MailingListSelectors.listFetchProgress)
  const list = useSelector(MailingListSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(MailingListActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useMailingListItem: ResourceHook<MailingListEntry, [string]> = (id: string) => {
  const meta = useSelector(state => MailingListSelectors.meta(state, id))
  const item = useSelector(state => MailingListSelectors.item(state, id))
  const fetchAction = useAction(MailingListActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useMailingListItemById: ResourceHook<MailingListEntry, [string]> = (id: string) => {
  const meta = useSelector(state => MailingListSelectors.meta(state, id))
  const item = useSelector(state => MailingListSelectors.item(state, id))
  const fetchAction = useAction(MailingListActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}
