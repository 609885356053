import React, { useCallback, useEffect, useRef, useState } from 'react'
import BusinessModelFilter from 'views/components/TableFilters/BusinessModelFilter'
import { Filter } from 'views/components/TableFilters/TableFilters'
import { useTranslation } from 'react-i18next'
import { CellProps, useRowSelect, useTable } from 'react-table'
import FccOrderActions from 'modules/domain/fccOrder/duck'
import FccOrderSelectors from 'modules/domain/fccOrder/selectors'
import { useFccOrderList } from 'modules/domain/fccOrder/hooks'
import { useSelector } from 'react-redux'
import { FccOrderListRequestFilter } from 'modules/domain/fccOrder/types'
import { FccOrderItem } from 'types/fccOrder'
import {
  RangeDatePicker,
  Input,
  AdvancedHeadCell,
  Table,
  TableHeadRow,
  TableBodyRow,
  TableBody,
  TableNoData,
  useAction,
  usePersistentScroll,
  Button,
  TableHead,
} from '@agro-club/frontend-shared'
import { parseISO, isValid } from 'date-fns'
import * as TComponents from 'views/components/CommonTableComponents/CommonTableComponents'
import { Progress } from 'modules/types'
import {
  Currency,
  isAgro,
  isUsersCompanyBasicDistributor,
  isUsersCompanyHeadDistributor,
  isUsersCompanyProducer,
} from 'types/entities'
import AuthSelectors from 'modules/domain/auth/selectors'
import { SeedTreatment } from 'modules/domain/product/types'
import { getPrettyPrice } from 'modules/utils/helpers'
import * as Styled from './styled'
import { endpoints } from 'modules/endpoints'
import useDownload from 'hooks/useDownload'
import { CAPABILITY, PERMISSION, usePermissions } from 'modules/permissions/permissions'
import DistributorsMultiSelect from 'views/components/DistributorsMultiSelect/DistributorsMultiSelect'
import PreventNavigationModal from 'views/components/PreventNavigationModal/PreventNavigationModal'
import { TWO_ROWS_TABLE_HEAD_HEIGHT } from 'modules/constants'
import useDateFormatFn from 'hooks/useDateFormatFn'
import useDateFormat from 'hooks/useDateFormat'
import { SeasonSelect } from 'views/components/SeasonSelect/SeasonSelect'

const PriceCell: React.FC<CellProps<FccOrderItem>> = ({ row: { values }, cell }) => {
  const isPermitted = usePermissions({ capability: CAPABILITY.FCC_ORDERS, permission: PERMISSION.U })
  const [price, setPrice] = useState(cell.value ? parseFloat(cell.value).toFixed(2) : '0.00')
  const updateAction = useAction(FccOrderActions.updateItem)

  const isInit = useRef(true)

  useEffect(() => {
    setPrice(cell.value ? parseFloat(cell.value).toFixed(2) : '0.00')
  }, [cell.value])

  useEffect(() => {
    if (!isInit.current) {
      updateAction(values.id, {
        price: price ? price : '0',
      })
    }
    isInit.current = false
  }, [price, updateAction, values.id])

  return (
    <Styled.Input
      type="number"
      value={price}
      disabled={!isPermitted}
      onChange={val => {
        setPrice(val.target.value)
      }}
      step={0.01}
    />
  )
}

const CreditFacilityCell: React.FC<CellProps<FccOrderItem>> = ({ row: { values }, cell }) => {
  const isPermitted = usePermissions({ capability: CAPABILITY.FCC_ORDERS, permission: PERMISSION.U })
  const [facility, setFacility] = useState(cell.value || '')
  const { t } = useTranslation('fccOrder')
  const updateAction = useAction(FccOrderActions.updateItem)

  const isInit = useRef(true)

  useEffect(() => {
    if (!isInit.current) {
      updateAction(values.id, {
        credit_facility: facility,
      })
    }
    isInit.current = false
  }, [facility, updateAction, values.id])

  useEffect(() => {
    setFacility(cell.value || '')
  }, [cell.value])

  return (
    <Input
      value={facility}
      disabled={!isPermitted}
      onChange={(_, val) => setFacility(val)}
      placeholder={t('list.facilityPlaceholder')}
    />
  )
}

const CropTypeCell: React.FC<CellProps<FccOrderItem>> = ({ cell }) => {
  return <div>{cell.value.subcategory.title}</div>
}

const TreatmentCell: React.FC<CellProps<FccOrderItem>> = ({ cell, row: { values } }) => {
  const product = values.product
  const treatment = product.seed_treatment.find((st: SeedTreatment) => st.id === cell.value)
  return <div>{treatment ? treatment.title : ' - '}</div>
}

const HybridTitleCell: React.FC<CellProps<FccOrderItem>> = ({ cell }) => {
  return <div>{cell.value.title}</div>
}

const DateCell: React.FC<CellProps<FccOrderItem>> = ({ row, column }) => {
  const getFormatedDate = useDateFormatFn({ withTime: true })

  return (
    <div key={column.id} style={{ whiteSpace: 'nowrap' }}>
      <div>{getFormatedDate(row.values.order_date)}</div>
    </div>
  )
}

const TotalCell: React.FC<CellProps<FccOrderItem>> = ({ cell, row }) => {
  return (
    <span data-test-id={'totalCell'}>
      {getPrettyPrice(parseFloat(cell.value), row.values.product.producer.currency)}
    </span>
  )
}

const DistributorCell: React.FC<CellProps<FccOrderItem>> = ({ cell, column }) => {
  return <span key={column.id}>{(cell.value && cell.value.internal_name) || ''}</span>
}

const CustomerCell: React.FC<CellProps<FccOrderItem>> = ({ cell }) => {
  if (!cell.value) {
    return <span>{''}</span>
  }
  const name = `${cell.value.first_name || ''} ${cell.value.last_name || ''}`
  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <div>{name.trim()}</div>
      <div>{cell.value.farm_name}</div>
      <div>{cell.value.phone_number}</div>
    </div>
  )
}

const SortableHeadCell = AdvancedHeadCell<keyof FccOrderItem>()

const FarmerOrderList = () => {
  const { t } = useTranslation(['fccOrder', 'labels', 'common'])
  const role = useSelector(AuthSelectors.role)
  const userCompany = useSelector(AuthSelectors.userCompany)
  const isBasicRetailer = isUsersCompanyBasicDistributor(userCompany)
  const dateFormat = useDateFormat({ isYearShort: true })

  const visibleColumns = React.useMemo(
    () => [
      {
        Header: t('list.tableHeaders.date'),
        accessor: 'order_date' as const,
        Cell: DateCell,
      },
      {
        Header: t('list.tableHeaders.slug'),
        accessor: 'slug' as const,
      },
      {
        Header: t('list.tableHeaders.distributor'),
        accessor: 'retailer' as const,
        Cell: DistributorCell,
        hidden: isBasicRetailer,
      },
      {
        Header: t('list.tableHeaders.client'),
        accessor: 'farmer' as const,
        Cell: CustomerCell,
      },
      {
        Header: t('list.tableHeaders.cropType'),
        accessor: 'product' as const,
        id: 'cropType',
        Cell: CropTypeCell,
      },
      {
        Header: t('list.tableHeaders.hybrid'),
        accessor: 'product' as const,
        id: 'hybrid',
        Cell: HybridTitleCell,
      },
      {
        Header: t('list.tableHeaders.seedTreatment'),
        accessor: 'seed_treatment_id' as const,
        Cell: TreatmentCell,
      },
      {
        Header: t('list.tableHeaders.quantity'),
        accessor: 'quantity' as const,
      },
      {
        Header: t('list.tableHeaders.finalQty'),
        accessor: 'final_qty' as const,
      },
      {
        Header: t('list.tableHeaders.price'),
        accessor: 'price' as const,
        id: 'price',
        Cell: PriceCell,
      },
      {
        Header: t('list.tableHeaders.total'),
        accessor: 'total' as const,
        Cell: TotalCell,
      },
      {
        Header: t('list.tableHeaders.creditFacilityNo'),
        accessor: 'credit_facility' as const,
        Cell: CreditFacilityCell,
      },
      {
        accessor: 'product' as const,
        hidden: true,
      },
      {
        accessor: 'id' as const,
        hidden: true,
      },
      {
        accessor: 'order_id' as const,
        hidden: true,
      },
    ],
    [t, isBasicRetailer],
  )

  const columnsAll = React.useMemo(() => {
    return [...visibleColumns]
  }, [visibleColumns])

  const [progress, data = []] = useFccOrderList()
  const { columns, rows, prepareRow } = useTable<FccOrderItem>(
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore weird issue with react-table typings — having more then 26 fields in type causes TS error
      columns: columnsAll,
      data,
    },
    useRowSelect,
  )

  const filterUpdated = useAction(FccOrderActions.filterUpdated)
  const sortingUpdated = useAction(FccOrderActions.sortingUpdated)
  const listRequested = useAction(FccOrderActions.listRequested)
  const updateRequested = useAction(FccOrderActions.updateItemsRequested)
  const clearIdsForUpdate = useAction(FccOrderActions.clearItemsForUpdate)
  const filterValue = useSelector(FccOrderSelectors.filter)
  const page = useSelector(FccOrderSelectors.page)
  const pageSize = useSelector(FccOrderSelectors.pageSize)
  const pages = useSelector(FccOrderSelectors.pages)
  const total = useSelector(FccOrderSelectors.total)
  const totalCost = useSelector(FccOrderSelectors.totalCost)
  const totalQty = useSelector(FccOrderSelectors.totalQty)
  const totalFinalQty = useSelector(FccOrderSelectors.totalFinalQty)
  const isChanged = useSelector(FccOrderSelectors.isChanged)
  const updateProgress = useSelector(FccOrderSelectors.updateProgress)

  const itemsForUpdate = useSelector(FccOrderSelectors.itemsForUpdate)

  const { business_model, start_date, end_date } = filterValue
  const { sort_field, sort_reversed } = useSelector(FccOrderSelectors.sorting)

  const onSave = useCallback(() => {
    updateRequested(itemsForUpdate)
  }, [itemsForUpdate, updateRequested])

  const handleFilterChange = useCallback(
    (newFilterValue: Partial<FccOrderListRequestFilter>) => {
      filterUpdated({ ...filterValue, ...newFilterValue })
    },
    [filterUpdated, filterValue],
  )

  const handleDateRangeChange = useCallback(
    ([start, end]) => {
      const parsedStartDate = parseISO(start)
      const parsedEndDate = parseISO(end)
      if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
        handleFilterChange({ start_date: start, end_date: end })
      }
    },
    [handleFilterChange],
  )

  const handleClearFilters = useCallback(() => {
    filterUpdated({})
  }, [filterUpdated])

  const isFilterApplied = Object.values(filterValue).some(Boolean)

  const fetchNextItems = useCallback(
    num => {
      listRequested({ page: num })
    },
    [listRequested],
  )

  const { scrollRef } = usePersistentScroll('farmerOrderList')

  const showDistributorsFilter =
    isAgro(role) || isUsersCompanyProducer(userCompany) || isUsersCompanyHeadDistributor(userCompany)

  const [progressCSV, downloadCSV] = useDownload(endpoints.farmerOrder('fcc/download/csv'), {
    ...filterValue,
    sort_field,
    sort_reversed,
  })

  return (
    <TComponents.Wrapper>
      <PreventNavigationModal
        when={isChanged}
        header={t('fccOrder:navigationModal.title')}
        message={t('fccOrder:navigationModal.message')}
        allowButtonContent={t('common:yes')}
        cancelButtonContent={t('common:no')}
        onAllowTransition={clearIdsForUpdate}
      />
      <TComponents.Filters>
        <Filter title={t('labels:period')}>
          <RangeDatePicker
            start={start_date || ''}
            end={end_date || ''}
            onChange={handleDateRangeChange}
            format={dateFormat}
          />
        </Filter>
        <BusinessModelFilter filterValue={{ business_model }} handleFilterChange={handleFilterChange} disableMulti />
        {showDistributorsFilter && (
          <Filter title={t('labels:retailer')}>
            <DistributorsMultiSelect
              onChange={val => handleFilterChange({ distributor_id: val })}
              selected={filterValue.distributor_id}
            />
          </Filter>
        )}
        <Filter title={t('labels:season')}>
          <SeasonSelect
            onChange={val => handleFilterChange({ season_id: val })}
            value={filterValue.season_id}
            variant="small"
            width="200px"
            placeholder={t('common:any')}
            isClearable
          />
        </Filter>
        <Styled.Buttons>
          <Button
            disabled={!isChanged || updateProgress === Progress.WORK}
            progress={updateProgress}
            onClick={onSave}
            intent={'primary'}
          >
            {t('common:save')}
          </Button>
          <Button filled intent="primary" onClick={downloadCSV} progress={progressCSV}>
            {t('labels:downloadCsv')}
          </Button>
        </Styled.Buttons>
      </TComponents.Filters>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
      >
        <TableHead height={TWO_ROWS_TABLE_HEAD_HEIGHT}>
          <TableHeadRow>
            {columns.map(column => {
              let value
              if (column.hidden) {
                return null
              } else if (column.id === 'quantity') {
                value = totalQty
              } else if (column.id === 'final_qty') {
                value = totalFinalQty
              } else if (column.id === 'total') {
                value = getPrettyPrice(parseFloat(totalCost), Currency.CAD)
              }

              return (
                <SortableHeadCell
                  key={column.getHeaderProps().key}
                  testId={column.id}
                  id={column.id as keyof FccOrderItem}
                  zIndex={column.id === 'price' ? 1 : 0}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onChange={() => {}}
                >
                  {value}
                </SortableHeadCell>
              )
            })}
          </TableHeadRow>
          <TableHeadRow>
            {columns.map(column => {
              return (
                <SortableHeadCell
                  key={column.getHeaderProps().key}
                  id={column.id as keyof FccOrderItem}
                  testId={column.id}
                  sortable={column.sortable}
                  hidden={column.hidden}
                  sortField={sort_field}
                  sortDesc={sort_reversed}
                  onChange={sortingUpdated}
                  width={column.width}
                  zIndex={['price', 'credit_facility'].includes(column.id) ? 1 : 0}
                  emphatic={column.id === 'price'}
                >
                  {column.render('Header')}
                </SortableHeadCell>
              )
            })}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {rows.map(row => {
            prepareRow(row)
            const { key, ...props } = row.getRowProps()
            return (
              <TableBodyRow key={key} {...props} selected={row.isSelected}>
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps()

                  if (cell.column.hidden) {
                    return null
                  }

                  return (
                    <Styled.BodyCell
                      key={key}
                      emphasis={cell.column.id === 'price'}
                      {...props}
                      stopClickPropagation={cell.column.id === 'status'}
                    >
                      {cell.render('Cell')}
                    </Styled.BodyCell>
                  )
                })}
              </TableBodyRow>
            )
          })}
          <TableNoData
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
            loading={<TComponents.Spinner />}
          >
            <div>{isFilterApplied ? t('list.emptyFilterMsg') : t('list.emptyMsg')}</div>
            {isFilterApplied && progress !== Progress.WORK && (
              <TComponents.ClearButton intent={'cancel'} size={'small'} onClick={handleClearFilters}>
                {t('list.resetAllFilters')}
              </TComponents.ClearButton>
            )}
          </TableNoData>
        </TableBody>
      </Table>
    </TComponents.Wrapper>
  )
}

export default FarmerOrderList
