import React, { FC, useState } from 'react'
import { ProductWizardDuckFoot } from 'views/components/ProductWizards/ProductWizardDuckFoot/ProductWizardDuckFoot'
import { ProductWizardProcote } from 'views/components/ProductWizards/ProductWizardProcote/ProductWizardProcote'
import { AdderBtn } from 'views/components/ProductWizards/AdderBtn'
import { Progress } from 'modules/types'
import { ProductWizardNode, ProductWizardType, WizardData } from '@agro-club/frontend-shared'

type PropsType = {
  product: ProductWizardNode
  progress: Progress
  changeOrderList: (id: string, qty: number, wizardComment?: string, wizard_data?: WizardData<any>) => void
}

export const ProductWizardManager: FC<PropsType> = ({ product, progress, changeOrderList }: PropsType) => {
  const [openedProductWizard, setOpenedProductWizard] = useState(false)

  const handleWizardOpen = () => {
    setOpenedProductWizard(true)
  }
  const handleWizardClose = () => {
    setOpenedProductWizard(false)
  }

  const duckFootJSX =
    openedProductWizard && product.type === ProductWizardType.DuckFoot ? (
      <ProductWizardDuckFoot
        product={product}
        opened={openedProductWizard}
        onClose={handleWizardClose}
        progress={progress}
        changeOrderList={changeOrderList}
      />
    ) : null

  const procoteJSX =
    openedProductWizard && product.type === ProductWizardType.Procote ? (
      <ProductWizardProcote
        opened={openedProductWizard}
        onClose={handleWizardClose}
        progress={progress}
        productWizard={product}
        changeOrderList={changeOrderList}
      />
    ) : null

  return (
    <>
      {duckFootJSX}
      {procoteJSX}
      <AdderBtn handleWizardOpen={handleWizardOpen} productWizardType={product.type} />
    </>
  )
}
