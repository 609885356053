import styled from 'styled-components'
import { Checkbox, Modal } from '@agro-club/frontend-shared'
import { FormContentBody } from 'views/components/FormComponents/FormComponents'

export const StorefrontModal = styled(Modal)`
  max-height: 90vh;
  & > [class*='ModalContent'] {
    padding: 20px 24px;
  }
`

export const ModalContent = styled.div``

export const ModalHeading = styled.div`
  width: 100%;
  margin-bottom: 24px;
  padding: 0 5px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.onSurfaceHighEmphasys};
`

export const Content = styled.div`
  display: grid;
  gap: 24px;
  grid-auto-rows: max-content;
  width: 100%;
  margin-bottom: 24px;
  padding: 0 5px;
  align-items: baseline;
  overflow-y: scroll;
`

export const ContentBody = styled(FormContentBody)`
  max-width: 725px;
  padding: 0;
`

export const Footer = styled.div`
  width: 100%;

  & > button:not(:first-child) {
    margin-left: 16px;
  }
`

export const AvailableForBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 154px);
  grid-gap: 8px;
  margin-top: 8px;
`

export const MediumCheckbox = styled(Checkbox)`
  cursor: pointer;
`

export const Grid3Col = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
`

export const ServerErrorMessage = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.accentDestruct500};
`

export const ProductInfo = styled.div`
  display: grid;
  grid-gap: 6px;
  line-height: 20px;
  font-size: 14px;
`

export const ProductTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
`

export const Supplier = styled.div`
  color: ${props => props.theme.color.onSurfaceHighEmphasys};
`

export const SwitchWrapper = styled.div`
  width: fit-content;
`
