import * as managers from 'modules/domain/productOptions/managers'
import { ProductOptions, ProductOptionType } from 'modules/domain/productOptions/types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EntitySelect, EntitySelectProps } from '../EntitySelect/EntitySelect'
import { SelectTestWrapper } from '../SelectTestWrapper/SelectTestWrapper'

export type ProductOptionsSelectProps = EntitySelectProps<ProductOptions> & {
  type?: ProductOptionType
}
export const getProductOptionLabel = (item: ProductOptions) => item.title || ''
export const getProductOptionValue = (item: ProductOptions) => item.id

export const ProductOptionsSelect: React.VFC<ProductOptionsSelectProps> = ({ showLabel, type, ...props }) => {
  const filter = useMemo(() => ({ type }), [type])
  const { t } = useTranslation('ProductOptionSelect')

  return (
    <SelectTestWrapper data-test-id="product-option-select">
      <EntitySelect
        searchParamKey="title"
        filter={filter}
        getEntityById={managers.getItem}
        getEntityList={managers.getList}
        getOptionLabel={getProductOptionLabel}
        getOptionValue={getProductOptionValue}
        placeholder={t('placeholder')}
        label={showLabel ? t('label') : undefined}
        {...props}
      />
    </SelectTestWrapper>
  )
}
