import React from 'react'
import { useSelector } from 'react-redux'
import * as Layout from 'views/layouts/MainLayout/MainLayout'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { useAction, useHelmet, useHistoryPush } from '@agro-club/frontend-shared'
import {
  IncentiveCampaignDetailsForm,
  IncentiveCampaignDetailsFormikProps,
} from './components/IncentiveCampaignDetailsForm'
import IncentiveCampaignSelectors from 'modules/domain/incentiveCampaign/selectors'
import IncentiveCampaignRoutes from './routes'
import IncentiveCampaignActions from 'modules/domain/incentiveCampaign/duck'
import { useParams } from 'react-router-dom'
import { useIncentiveCampaignByIdOnce } from 'modules/domain/incentiveCampaign/hooks'
import { useEditLayout } from 'hooks/useEditLayout'

const IncentiveProgramAdd: React.VFC = () => {
  const { t } = useTranslation('incentiveCampaign')
  const params = useParams<{ id: string }>()
  const [fetchProgress, incentiveCampaign] = useIncentiveCampaignByIdOnce(params.id)
  const submitAction = useAction(IncentiveCampaignActions.updateRequested)
  const updateProgress = useSelector(IncentiveCampaignSelectors.updateProgress)

  const meta = useSelector(IncentiveCampaignSelectors.meta(params.id))
  const page = useSelector(IncentiveCampaignSelectors.page)
  const push = useHistoryPush()

  useHelmet({ title: incentiveCampaign ? incentiveCampaign.title : t('incentiveCampaignMetaTitle') })

  const handleFormSubmit = (props: IncentiveCampaignDetailsFormikProps) => {
    submitAction(incentiveCampaign?.id, props)
  }

  const goBack = () => {
    push({ query: { page }, route: IncentiveCampaignRoutes.List })
  }

  const layout = useEditLayout({
    id: params.id,
    entityName: t('incentiveCampaign'),
    fetchProgress,
    data: incentiveCampaign,
    fetchError: meta.fetchError,
    goBack,
  })

  if (layout) return layout

  return (
    <>
      <Layout.Header>
        <Header.Root onClickBack={goBack}>
          <Header.Title size={'small'} compact={true} title={incentiveCampaign?.title ?? ''} />
          <Header.Id>{`ID ${incentiveCampaign?.id ?? ''}`}</Header.Id>
        </Header.Root>
      </Layout.Header>
      <Layout.Content>
        <IncentiveCampaignDetailsForm
          progress={updateProgress}
          onCancel={goBack}
          onSubmit={handleFormSubmit}
          initialValues={incentiveCampaign}
        />
      </Layout.Content>
    </>
  )
}

export default IncentiveProgramAdd
